import { gql } from '@apollo/client'

const companionFragment = gql`
  fragment fullnameTypePhoto on Companion {
    id
    __typename
    companionType
    person {
      id
      fullName
      photo {
        id
        cdnUrlTemplate
      }
    }
  }
`

export default companionFragment
