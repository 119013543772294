import React from 'react'
import ModalBody from 'components/ModalBody'

interface ModalProps {
  closeModal: Boolean,
  visitType: String
}

const Modal: React.FC<ModalProps> = ({ closeModal, visitType }) => {
  return <ModalBody closeModal={closeModal}>
    {({ CloseX }) => (
      <>
        <CloseX />
        <h3 className=''> Thank you!</h3>
        <div className='tc ph3 mt0'>
          <p>
            Thank you so much for reporting your completed {visitType.toLowerCase()}.
          </p>
        </div>
      </>
    )}
  </ModalBody>
}

export default Modal
