import React from 'react'
import withStyles from 'react-jss'
import style from './styles'

const AddressAndDistance = ({ opportunity, classes }) => {
  return (
    <div>
      <div className={`mb1 ${classes.location}`}>
        {opportunity.address.neighborhood
          ? `${opportunity.address.neighborhood}`
          : `${opportunity.address.city}, ${opportunity.address.state}`}
      </div>
      {opportunity.opportunityType !== 'telepresence' && (
        <div className={`ml1 dark-gray f6`}> {opportunity.distance.toFixed(2)}mi away</div>
      )}
    </div>
  )
}

export default withStyles(style)(AddressAndDistance)
