import colors from 'helpers/colors'
import bookVisit from 'assets/images/book-visit.png'
import story from 'assets/images/submit-story.png'

export default {
  wrapperInnerContainer: {
    height: 'calc(100vh - 100px)'
  },
  textDecoration: {
    textDecoration: 'none',
    color: colors.purple
  },
  image: {
    width: '18rem'
  },
  userGuide: {
    backgroundColor: colors.lighterGreen3,
    color: '#006267'
  },
  importantInfo: {
    backgroundColor: colors.pink,
    color: colors.darkestGreen
  },
  profile: {
    backgroundColor: colors.lighterPurple,
    color: colors.darkestGreen
  },
  story: {
    backgroundColor: colors.lighterPurple,
    opacity: 0.94,
    color: colors.darkestGreen,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  storyBackgroundImage: {
    backgroundImage: `url(${story})`,
    height: '15rem',
    position: 'relative'
  },
  bookVisit: {
    backgroundColor: '#f8f5be',
    opacity: 0.94,
    color: colors.darkestGreen,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  bookVisitBackgroundImage: {
    backgroundImage: `url(${bookVisit})`,
    height: '15rem',
    position: 'relative'
  }
}
