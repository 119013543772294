import { gql } from '@apollo/client'

export const COMPANION_SETTINGS_QUERY = gql`
  query CompanionSettingsQuery {
    availableTransportModes {
      id
      name
    }
    companion {
      id
      companionType
      digestNotifications
      individualNotifications
      distanceWillTravel
      paypalEmail
      transportModes {
        id
        name
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      payouts {
        id
        status
        amountCents
        visit {
          id
          startAt
          visitee {
            id
            customer {
              id
              person {
                id
                firstNameAndLastInitial
              }
            }
          }
          duration
        }
      }
    }
  }
`
