export default {
  container: {
    color: 'white',
    transition: '.5s',
    paddingTop: 'env(safe-area-inset-top)',
    minHeight: '60px',
    lineHeight: '30px',
    marginLeft: '15%'
  }
}
