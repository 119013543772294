import React from 'react'
import Dashboard from 'components/Dashboard'
import { map, mount, route, redirect } from 'navi'
import LoginForm from 'components/LoginForm'
import UpdatePassword from 'components/LoginForm/UpdatePasswordScreen'
import Visits from 'components/Visits'
import BookVisit from 'components/BookVisit'
import VisitDetails from 'components/VisitDetails'
import getEditVisitStoryView from 'components/EditVisitStory/getView'
import { getVisitStoryView } from 'components/VisitStory'
import clientDeepLink from 'queries/clientDeepLink'
import clientReturnTo from 'queries/clientReturnTo'
import ErrorView from 'components/ErrorView'
import Availability from 'components/Availability'
import Opportunities from 'components/Opportunities'
import { getSuggestAnotherTimeView } from 'components/SuggestAnotherTime'
import OpportunityDetails, { OPPORTUNITY_DETAILS_QUERY } from 'components/Opportunities/OpportunityDetails'
import Profile from 'components/Profile'
import SignUp from 'components/AuthFlow/SignUp'
import IS_BUSY_QUERY from 'queries/clientIsBusy'
import { mergeRight, pipe } from 'ramda'
import { notify } from 'components/ClientNotification'
import paths, { opportunityDetailsPath } from './paths'
import getVisitCheckInView from 'components/VisitCheckIn/getView'
import Settings from 'components/Settings'
import Question from 'components/Question'
import Notifications from 'components/Settings/Notifications'
import Account from 'components/Settings/Account'
import Location from 'components/Settings/Location'
import PersonSelection from '../components/PersonSelection'
import UpdateApp from '../components/UpdateApp'
import Request2Fa from '../components/TwoFactor/Request2Fa'
import Verify2Fa from '../components/TwoFactor/Verify2Fa'
import { USER_ROUTE_DETAILS_QUERY } from 'queries/userRouteDetailsQuery'
import { TWO_FACTOR_AUTH } from 'queries/clientTwoFactorAuth'
import { isBrowser } from 'helpers/platform'
import hasOldAppVersionInstalled from 'helpers/versionControl'
import CodeEntryFailed from 'components/AuthFlow/CodeEntryFailed'
import ResetPassword from 'components/AuthFlow/ResetPassword'
import VerifyResetPasswordCode from 'components/AuthFlow/VerifyResetPasswordCode'
import VerifySignUpCode from 'components/AuthFlow/VerifySignupCode'

const excludedDeepLinkPaths = [
  paths.root,
  paths.login,
  paths.settings,
  paths.personSelection,
  paths.request2fa,
  paths.requestSetupCode,
  paths.verifySetupCode,
  paths.codeEntryFailed,
  '/verify2fa/sms',
  '/verify2fa/call'
]

const showLoadingBar = matcher =>
  map(async (request, { client }) => {
    const data = client.readQuery({ query: IS_BUSY_QUERY })
    // guard clause so tests won't fail :(
    // local client resolvers will be fixed in apollo-react 3.0
    if (data && data.isBusy) {
      client.writeQuery({
        query: IS_BUSY_QUERY,
        data: {
          isBusy: mergeRight(data.isBusy, { bar: true })
        }
      })
    }
    return matcher
  })

const saveDeepLink = (client, deepLink) => client.writeQuery({ query: clientDeepLink, data: { deepLink } })

export const withDeepLinksAndAuthentication = matcher =>
  map(async (request, { client, currentUser }) => {
    const isAuthorized = currentUser && global.localStorage.getItem('authenticationToken')

    if (isAuthorized) {
      const {
        data: { currentSession, companion, companions }
      } = await client.query({ query: USER_ROUTE_DETAILS_QUERY, fetchPolicy: 'network-only' })

      if (!isBrowser) {
        const hasUpdateAppPageOpen = request.originalUrl === paths.updateApp

        if (await hasOldAppVersionInstalled(client)) {
          if (hasUpdateAppPageOpen) {
            return matcher
          } else {
            return redirect(paths.updateApp)
          }
        } else if (hasUpdateAppPageOpen) {
          return redirect(paths.root)
        }
      }

      // personSelection
      let currentPersonId = localStorage.getItem('currentPersonId')
      if (companions.length > 1) {
        if (request.mountpath === paths.personSelection) return matcher
        if (currentPersonId === null) return redirect(paths.personSelection)
      } else if (currentPersonId === null) {
        localStorage.setItem('currentPersonId', companions[0].person.id)
      }

      // //two factor auth
      if (companion.person.organization.twoFactorAuth && !currentSession?.verificationApproved) {
        const { twoFactorAuth } = client.readQuery({ query: TWO_FACTOR_AUTH })

        if (request.mountpath === paths.request2fa) {
          return matcher
        } else if (!twoFactorAuth || (request.mountpath.includes('/verify2fa/') && twoFactorAuth !== 'verify2fa')) {
          return redirect(paths.request2fa)
        } else if (request.mountpath.includes('/verify2fa/')) {
          return matcher
        } else if (twoFactorAuth !== 'verify2fa') {
          return redirect(paths.request2fa)
        }
      } else {
        //redirect to login if not allowed on page
        if (request.mountpath.includes('/verify2fa/') || request.mountpath === paths.request2fa) return redirect('/')
      }

      //deep links
      const { deepLink } = client.readQuery({ query: clientDeepLink })
      if (deepLink) {
        saveDeepLink(client, null)
        if (request.mountpath !== deepLink) return redirect(deepLink)
      }

      client.writeQuery({ query: clientReturnTo, data: { returnTo: null } })
      return matcher
    } else {
      if (!excludedDeepLinkPaths.includes(request.originalUrl)) saveDeepLink(client, request.originalUrl)
      return redirect(paths.login)
    }
  })

const redirectToAppRootIfAuthorized = matcher =>
  map(async ({ }, { currentUser }) => {
    const isAuthorized = currentUser && global.localStorage.getItem('authenticationToken')
    return isAuthorized ? redirect('/') : matcher
  })

const redirectIfOpportunityNotFound = () =>
  map(async ({ params: { id } }, { client, currentUser }) => {
    if (!currentUser) {
      client.writeQuery({
        query: clientDeepLink,
        data: { deepLink: opportunityDetailsPath(id) }
      })
      return redirect(paths.login)
    }

    try {
      const {
        data: {
          companion: { opportunity }
        }
      } = await client.query({
        query: OPPORTUNITY_DETAILS_QUERY,
        variables: { opportunityId: id }
      })
      return route({ view: () => <OpportunityDetails opportunity={opportunity} /> })
    } catch (error) {
      if (error.message.includes('RecordNotFound')) {
        notify(client, {
          message: 'This opportunity has already been booked and closed.'
        })
        return redirect(paths.opportunities)
      } else throw error
    }
  })

const withAuth = pipe(route, withDeepLinksAndAuthentication)

const withUnauthorized = pipe(route, redirectToAppRootIfAuthorized)

const withAuthAndLoading = pipe(route, withDeepLinksAndAuthentication, showLoadingBar)

const withRedirectIfOpportunityNotFound = pipe(withAuth, redirectIfOpportunityNotFound)

const routes = mount({
  // Routes that don't need a valid session
  //
  [paths.requestSetupCode]: withUnauthorized(({ params: { email } }) => ({
    view: <SignUp userEmail={email} />
  })),
  [paths.verifySetupCode]: withUnauthorized(({ params: { email } }) => ({
    view: <VerifySignUpCode userEmail={email} />
  })),
  [paths.resetPassword]: withUnauthorized(({ params: { email } }) => ({
    view: <ResetPassword userEmail={email} />
  })),
  [paths.verifyResetPasswordCode]: withUnauthorized(({ params: { email } }) => ({
    view: <VerifyResetPasswordCode userEmail={email} />
  })),
  [paths.codeEntryFailed]: withUnauthorized({ view: <CodeEntryFailed /> }),
  [paths.login]: withUnauthorized({ view: <LoginForm /> }),
  [paths.updatePassword]: withUnauthorized(({ params }) => ({ view: <UpdatePassword params={params} /> })),
  [paths.error]: route({ view: <ErrorView /> }),

  // Routes for Logged in users
  //
  [paths.root]: withAuth({ view: <Dashboard /> }),
  [paths.visits]: withAuth(({ params: { tab } }) => ({
    view: <Visits tab={tab} />
  })),
  [paths.visitDetails]: withAuth(({ params: { id, redirectToKey = 'visits' } }) => ({
    view: <VisitDetails visitId={id} redirectToKey={redirectToKey} />
  })),
  [paths.visitsVisitStoryEdit]: withAuth({ getView: getEditVisitStoryView }),
  [paths.visitVisitStory]: withAuth({ getView: getVisitStoryView }),
  [paths.bookVisit]: withAuth(({ params: { visiteeId, visitId } }) => ({
    view: <BookVisit visiteeId={visiteeId} visitId={visitId} />,
    data: { visiteeId, visitId }
  })),
  [paths.availability]: withAuth({ view: <Availability /> }),
  [paths.opportunities]: withAuthAndLoading({ view: Opportunities }),
  [paths.opportunitiesSuggestAnotherTime]: withAuth({
    getView: getSuggestAnotherTimeView
  }),
  [paths.opportunityDetails]: withRedirectIfOpportunityNotFound({
    view: OpportunityDetails
  }),
  [paths.profile]: withAuth({ view: <Profile /> }),
  [paths.visitCheckIn]: withAuth({ getView: getVisitCheckInView }),
  [paths.settings]: withAuth({ view: <Settings /> }),
  [paths.notifications]: withAuth({ view: <Notifications /> }),
  [paths.account]: withAuth({ view: <Account /> }),
  [paths.location]: withAuth({ view: <Location /> }),
  [paths.personSelection]: withAuth({ view: <PersonSelection /> }),
  [paths.updateApp]: withAuth({ view: <UpdateApp /> }),
  [paths.request2fa]: withAuth({ view: <Request2Fa /> }),
  [paths.verify2fa]: withAuth(({ params: { channel } }) => ({
    view: <Verify2Fa channel={channel} />
  })),
  [paths.question]: withAuth(({ params: { visitId } }) => ({
    view: <Question visitId={visitId} />
  }))
})

export default routes
