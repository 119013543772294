import colors from 'helpers/colors'

const cardHeight = '22vh'

export default {
  cardContainer: {
    borderColor: colors.borderDarkGreen,
    position: 'relative',
    cursor: 'pointer',
    minHeight: cardHeight,
    textAlign: 'center'
  },
  signedInCompanionContainer: {
    color: 'white',
    backgroundColor: colors.purple,
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '0.3rem'
  },
  logoContainer: {
    borderBottom: '0.1rem solid grey',
  },
  noLogoContainer: {
    height: '10vh'
  },
  logoImage: {
    maxHeight: '16vh',
    width: 'auto'
  }
}

