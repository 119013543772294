import React, { useState } from 'react'
import ContentWrapper from 'components/ContentWrapper'
import withApollo from 'helpers/withApollo'
import Button from 'components/Button'
import { Request2fa, Request2faVariables } from 'types/graphql/Request2fa'
import { REQUEST_2FA } from 'mutations/request2fa'
import { ApolloClient, NormalizedCacheObject, useMutation, useQuery } from '@apollo/client'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { notify } from 'components/ClientNotification'
import { useNavigation } from 'react-navi'
import TopBar from 'components/TopBar'
import ChoiceGroup from 'components/ChoiceGroup'
import HiddenPhoneNumber from '../HiddenPhoneNumber'
import { TWO_FACTOR_AUTH } from 'queries/clientTwoFactorAuth'
import { PROFILE_SNIPPET_QUERY } from 'queries/profileSnippetQuery'
import { ProfileSnippetQuery } from 'types/graphql/ProfileSnippetQuery'
import logout from 'helpers/logout'

enum TwoFactorChannel {
  call = "call",
  sms = "sms",
}

interface RequestTwoFactorViewProps {
  client: ApolloClient<NormalizedCacheObject>,
}

const RequestTwoFactorView: React.FC<RequestTwoFactorViewProps> = ({ client }) => {
  const navigation = useNavigation()
  const { data } = useQuery<ProfileSnippetQuery>(PROFILE_SNIPPET_QUERY)
  const [channel, setChannel] = useState<TwoFactorChannel>(TwoFactorChannel.sms)
  const updateChannel = (_: any, { key }: any) => setChannel(TwoFactorChannel[key])

  const [sendVerificationCode] = useMutation<Request2fa, Request2faVariables>(REQUEST_2FA, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Error while sending you verification code',
        type: 'error'
      })
      console.log("Error", error)
    },
    onCompleted: () => {
      client.writeQuery({ query: TWO_FACTOR_AUTH, data: { twoFactorAuth: 'verify2fa' } })
      navigation.navigate(`/verify2fa/${channel}`)
    }
  })

  return <>
    <TopBar title='Verify your identity' />
    <ContentWrapper noPadding>
      <div>
        <p>To ensure security, we need to send a verification code to your phone:</p>
        <HiddenPhoneNumber client={client} />
        <br />
        <p>How would you like to receive it?</p>
        <ChoiceGroup
          selectedKey={channel}
          options={[
            {
              key: 'sms',
              text: 'Text Message'
            },
            {
              key: 'call',
              text: 'Phone Call',
            }
          ]}
          onChange={updateChannel}
        />
        <div className='pt3' />
        <Button onClick={() => { sendVerificationCode({ variables: { input: { channel } } }) }} data-cy='send-request'>Send Code</Button>
        {data?.companions && data.companions.length > 1 ?
          <Button className='bg-red mt3' onClick={() => { navigation.navigate('/personSelection') }} data-cy='switch-org'>
            Switch Organization
          </Button>
          :
          <Button className='mb3 mt3 btnMuted bg-red' onClick={logout.bind(null, client)} data-cy='log-out' >
            Log Out
          </Button>
        }

      </div>
    </ContentWrapper>
  </>
}

export default withApollo(RequestTwoFactorView)
