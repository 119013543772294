import React from 'react'
import Telepresence from './Telepresence'
import Service from './Service'
import Personal from './Personal'
import Shift from './Shift'
import Detail from 'components/VisitDetails/Detail'
import TelLink from './telLink'

import { VisitDetailsQuery_companion_visit, VisitDetailsQuery_metaData } from 'types/graphql/VisitDetailsQuery'

interface LogisticsProps {
  visit: VisitDetailsQuery_companion_visit
  metaData: VisitDetailsQuery_metaData
}

const Logistics: React.FC<LogisticsProps> = ({ visit, metaData }) => {
  const { visitee: {  customer } } = visit

  const CustomerPhoneNumber: React.FC<{}> = () => {
    return (
      <>
        {!visit.visiteeConversationNumber && (
          <Detail heading={`${customer.person.preferredOrFirstName}'s Phone Number`}>
            <TelLink number={customer.phoneNumber} />
          </Detail>
        )}
      </>
    )
  }
  const VisitTypeFields = () => {
    switch (visit.visitType) {
      case 'telepresence':
        return <Telepresence visit={visit} CustomerPhoneNumber={CustomerPhoneNumber} />
      case 'service':
        return <Service visit={visit} metaData={metaData} CustomerPhoneNumber={CustomerPhoneNumber} />
      case 'shift':
        return <Shift visit={visit} />
      default:
        return <Personal visit={visit} metaData={metaData} CustomerPhoneNumber={CustomerPhoneNumber} />
    }
  }

  return <VisitTypeFields />
}

export default Logistics
