import React, { useState } from 'react'
import { useNavigation } from 'react-navi'
import withApollo from 'helpers/withApollo'
import ContentWrapper from 'components/ContentWrapper'
import Link from 'components/Link'
import withStyles from 'react-jss'
import getError from 'helpers/getError'
import style from './styles'
import setTokenAndContextAndRedirect from '../setTokenAndContextAndRedirect'
import Button from 'components/Button'
import InputAndLabel from 'components/InputAndLabel'
import { clearNotice, notify } from 'components/ClientNotification'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import setErrorTrackerIdentity from 'helpers/setErrorTrackerIdentity'
import routePaths from 'routes/paths'
import { NormalizedCacheObject, useMutation, ApolloClient, ApolloError } from '@apollo/client'
import { UpdatePassword, UpdatePasswordVariables } from 'types/graphql/UpdatePassword'
import { UPDATE_PASSWORD } from 'mutations/updatePassword'
import ONBOARDING from 'queries/clientOnboarding'

interface UpdatePasswordProps {
  client: ApolloClient<NormalizedCacheObject>,
  classes: any,
  params: {
    token: string
  }
}

const UpdatePasswordScreen: React.FC<UpdatePasswordProps> = ({ client, classes, params }) => {
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const navigation = useNavigation()

  const [handleUpdatePassword, { error }] = useMutation<UpdatePassword, UpdatePasswordVariables>(UPDATE_PASSWORD, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'There was a problem setting your password',
        type: 'error'
      })
    },
    onCompleted: ({updatePassword}) => {
      if(updatePassword) {
        client.writeQuery({ query: ONBOARDING, data: { onboarding: false } })
        clearNotice(client)
        setErrorTrackerIdentity(updatePassword.user)
        setTokenAndContextAndRedirect(navigation, updatePassword.user)
      }
    }
  })

  const confirmationPasswordErrorMessages = (errors: ApolloError | undefined) => {
    const message = getError('passwordConfirmation', errors)
    return message == "Doesn't match Password" ? 'Passwords do not match' : message
  }

  return (
    <ContentWrapper noPadding styles='justify-center flex'>
      <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
        <div className='h-100 ph125 flex flex-column align-center justify-center'>
          <div className={classes.borderDivide}>
            <h2 className='f3 mb1'>Set Password</h2>
            <InputAndLabel
              label='Password'
              type='password'
              updateValue={setPassword}
              value={password}
              errorMessage={getError('password', error)}
              data-cy='password'
            />
            <InputAndLabel
              label='Password Confirmation'
              type='password'
              updateValue={setPasswordConfirmation}
              value={passwordConfirmation}
              errorMessage={confirmationPasswordErrorMessages(error)}
              data-cy='password-confirmation'
            />
            <ul className='pl4'>
              <li>At least 12 characters long</li>
              <li>Doesn't use common words and expressions</li>
            </ul>
            <Button
              className='mv3'
              data-cy='password-reset--submit'
              onClick={() => handleUpdatePassword({ variables: { input: { resetPasswordToken: params.token, password: password, passwordConfirmation: passwordConfirmation } } })}
              disabled={password == '' || passwordConfirmation == ''}
              >
              Finish
            </Button>

            <Link
              route={routePaths.login}
              className='tc blue pointer'
              data-cy='navigate-to-login'
            >
              Return to login
            </Link>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default withApollo(withStyles(style)(UpdatePasswordScreen))
