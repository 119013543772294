import colors from 'helpers/colors'

export default {
  container: {
    borderBottom: `2px solid ${colors.gray}`
  },
  picture: {
    height: 70,
    width: 70,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: `1px solid ${colors.gray}`
  }
}
