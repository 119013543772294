import React from 'react'
import { useQuery, gql } from '@apollo/client';

import companionCompletedVisitsFragment from 'fragments/companionCompletedVisitsFragment'
import companionUpcomingVisitsFragment from 'fragments/companionUpcomingVisitsFragment'
import companionOpportunitiesFragment from 'fragments/companionOpportunitiesFragment'

export const REFRESH_CACHE_QUERY = gql`
  query refreshCacheQuery {
    companion {
      id
      completedVisits: visits(scope: "completed") {
        ...companionCompletedVisits
      }
      upcomingVisits: visits(scope: "upcoming") {
        ...companionUpcomingVisits
      }
      ...companionOpportunities
    }
    companions {
      id
    }
  }
  ${companionUpcomingVisitsFragment}
  ${companionCompletedVisitsFragment}
  ${companionOpportunitiesFragment}
`

const RefreshDisplayCache: React.FC = () => {
  useQuery(REFRESH_CACHE_QUERY, { pollInterval: 60000, fetchPolicy: 'cache-and-network' })
  return <> </>
}

export default RefreshDisplayCache
