import React, { useState, useEffect } from 'react'
import OffScreenModal from 'components/OffScreenModal'
import TextField from 'components/TextField'
import Button from 'components/Button'
import { path, equals } from 'ramda'
import { Mutation } from '@apollo/client/react/components'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import getError from 'helpers/getError'
import ErrorMessage from 'components/ErrorMessage'
import { CREATE_ADDRESS_MUTATION } from 'mutations/createAddress'

const EditLocationModal = ({ useStateValue, types, refetchQueries }) => {
  const [state, dispatch] = useStateValue()

  const setAddressData = {
    addressLine1: path(['address', 'addressLine1'], state),
    addressLine2: path(['address', 'addressLine2'], state),
    city: path(['address', 'city'], state),
    state: path(['address', 'state'], state),
    zip: path(['address', 'zip'], state)
  }
  const [addressMutationData, setAddressMutationData] = useState(setAddressData)

  useEffect(() => {
    setAddressMutationData(setAddressData)
  }, [state.address])

  const updateAddress = key => ({ target: { value } }) => {
    setAddressMutationData({
      ...addressMutationData,
      [key]: value
    })
  }

  const saveAddress = saveAddressMutation => async () => {
    await saveAddressMutation({
      variables: {
        input: {
          ...addressMutationData
        }
      }
    })
  }

  const close = () => {
    dispatch({ type: types.HIDE_EDIT_LOCATION_MODAL })
  }

  const closeAndDispatch = () => {
    dispatch({ type: types.UPDATE_PARAMS, address: addressMutationData })
    dispatch({ type: types.HIDE_EDIT_LOCATION_MODAL })
  }

  const textAddressField = (label, mutationValue, value, name, error) => {
    return (
      <TextField
        className='mb125'
        label={label}
        value={mutationValue}
        onChange={updateAddress(value)}
        name={name}
        errorMessage={getError(value, error)}
        data-cy={name}
      />
    )
  }

  return (
    <OffScreenModal show={state.showEditLocationModal} title='Edit Location' close={close}>
      <Mutation
        mutation={CREATE_ADDRESS_MUTATION}
        refetchQueries={refetchQueries}
        onError={raiseErrorIfCantBeIgnored}
        onCompleted={closeAndDispatch}
      >
        {(saveAddressMutation, { error, loading }) => (
          <>
            {textAddressField(
              'Street Address',
              addressMutationData.addressLine1,
              'addressLine1',
              'streetAddress',
              error
            )}
            {textAddressField('Unit', addressMutationData.addressLine2, 'addressLine2', 'unit', error)}
            {textAddressField('City', addressMutationData.city, 'city', 'city', error)}
            {textAddressField('State', addressMutationData.state, 'state', 'state', error)}
            {textAddressField('Zip', addressMutationData.zip, 'zip', 'zip', error)}
            {getError('base', error) && <ErrorMessage errorMessage={getError('base', error)} bordered />}
            <Button data-cy='visits-new--save-location-changes' onClick={saveAddress(saveAddressMutation)}>
              Save
            </Button>
            <Button onClick={close} btnClass='btnMuted' className='mt2'>
              Cancel
            </Button>
          </>
        )}
      </Mutation>
    </OffScreenModal>
  )
}

export default React.memo(EditLocationModal, equals)
