import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import { gql, useMutation } from '@apollo/client'
import withStyles from 'react-jss'
import style from './styles'
import MultiClamp from 'react-multi-clamp'
import { prop, map, join, pipe } from 'ramda'
import SinglePhotoInput from 'components/SinglePhotoInput'
import { notify } from 'components/ClientNotification'
import { Query } from '@apollo/client/react/components'
import withApollo from 'helpers/withApollo'
import BusyIndicator from 'components/BusyIndicator'
import RegularProfile from './RegularProfile'
import NotRegularProfile from './NotRegularProfile'
import { UPDATE_COMPANION_MUTATION } from 'mutations/updateCompanion'
import { PROFILE_QUERY } from 'queries/profile'
import { PROFILE_PHOTO_QUERY } from 'queries/profilePhoto'
import { ProfileQuery } from 'types/graphql/ProfileQuery'
import { ProfilePhotoQuery } from 'types/graphql/ProfilePhotoQuery'
import { UpdateCompanion, UpdateCompanionVariables } from 'types/graphql/UpdateCompanion'

export const joinWords = pipe(map(prop('name')), join(', '))

const Profile = ({ classes, client }) => {
  const { data, loading } = useQuery<ProfileQuery>(PROFILE_QUERY)

  const flashMessage = message => {
    notify(client, {
      message: message,
      type: 'success'
    })
  }

  const [updateCompanion] = useMutation<UpdateCompanion, UpdateCompanionVariables>(UPDATE_COMPANION_MUTATION, {
    refetchQueries: ['ProfilePhotoQuery'],
    context: { hasUpload: true },
    onCompleted: () => {
      flashMessage('Your photo has been updated!')
    }
  })

  const [showEditForm, setShowEditForm] = useState(false)
  const currySetShowEditForm = state => () => setShowEditForm(state)


  if (loading || !data) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  const companion = data.companion
  const profile = companion?.profile
  const availableSkills = data.availableSkills
  const availableLanguages = data.availableLanguages

  const ProfileType = () => {
    if (!profile) return null
    switch (companion?.companionType) {
      case 'regular':
        return (
          <RegularProfile
            companion={companion}
            profile={profile}
            setShowEditForm={setShowEditForm}
            showEditForm={showEditForm}
            availableSkills={availableSkills}
            availableLanguages={availableLanguages}
            classes={classes}
            flashMessage={flashMessage}
          />
        )
      default:
        return (
          <NotRegularProfile
            companion={companion}
            profile={profile}
            setShowEditForm={setShowEditForm}
            showEditForm={showEditForm}
            availableLanguages={availableLanguages}
            flashMessage={flashMessage}
          />
        )
    }
  }

  return <>
    <TopBar title='Profile' left='menu' />
    <ContentWrapper noPadding>
      <div className='bb b--gray bg-egg-shell pa125 flex flex-nowrap flex-row'>
        <Query<ProfilePhotoQuery> query={PROFILE_PHOTO_QUERY}>
          {({ data, loading }) => {
            if (loading || !data) return null
            return (
              <SinglePhotoInput
                uploadPhoto={blob => {
                  updateCompanion({
                    variables: {
                      input: {
                        photo: blob
                      }
                    }
                  })
                }}
                photo={data.companion?.person.photo}
              />
            )
          }}
        </Query>

        <div className='ph3 pt2 flex-grow-1'>
          <div className='fw6 f4 mb1' style={{ wordBreak: 'break-all' }}>
            <MultiClamp ellipsis='...' clamp={1}>
              {companion?.person?.fullName}
            </MultiClamp>
          </div>
          <div>
            <label htmlFor='photo'>
              <i className='icon-replace-photo purple mr2' />
              Replace Photo
            </label>
          </div>
          <div
            className={classes.deletePhotoButton}
            onClick={() => {
              updateCompanion({
                variables: {
                  input: {
                    photo: null
                  }
                }
              })
            }}
          >
            <i className='icon-delete purple mr2' />
            Delete Photo
          </div>
        </div>
      </div>
      {profile && !showEditForm && (
        <div className='tr pa3 pointer' onClick={currySetShowEditForm(!showEditForm)}>
          <i className='icon-edit purple' /> Edit Profile
        </div>
      )}
      <ProfileType />
    </ContentWrapper>
  </>
}

export default withStyles(style)(withApollo(Profile))
