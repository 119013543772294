import React from 'react'
import withApollo from 'helpers/withApollo'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import withStyles from 'react-jss'
import style from './styles'
import { useQuery } from '@apollo/client'
import MilestoneMessages from './MilestoneMessages'
import MilestoneBadges from './MilestoneBadges'
import ImportantTasks from './ImportantTasks'
import BusyIndicator from 'components/BusyIndicator'
import { COMPANION_QUERY } from 'queries/companionQuery'
import { CompanionQuery, CompanionQuery_companion } from 'types/graphql/CompanionQuery'

interface WelcomeMessageProps {
  companion: CompanionQuery_companion
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ companion }) => {
  return <div className='f3 fw6 pb3 recoleta-regular'>
    {companion.hasEverBooked ? 'Welcome Back' : 'Welcome to Mon Ami'}, {companion.person.preferredOrFirstName}!
  </div>
}

const Dashboard = ({ classes }) => {
  const { data, loading } = useQuery<CompanionQuery>(COMPANION_QUERY)

  if (loading || !data || !data.companion) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  const companion = data.companion

  return <>
    <TopBar title='Dashboard' left='menu' />
    <ContentWrapper innerContainerStyles={classes.wrapperInnerContainer} noPadding>
      {companion.organization && companion.organization.logoUrl && (
        <div className='tc pa3'>
          <img className={classes.image} src={companion.organization.logoUrl} />
        </div>
      )}
      <div className='pa125'>
        <WelcomeMessage companion={companion} />
        <MilestoneMessages />
        <ImportantTasks companion={companion} classes={classes} />
        <MilestoneBadges companion={companion} />
      </div>
    </ContentWrapper>
  </>
}

export default withApollo(withStyles(style)(Dashboard))
