import React from 'react'
import ModalBody from 'components/ModalBody'
import logout from 'helpers/logout'

const Modal = ({ closeModal, client }) => {
  return (
    <ModalBody closeModal={closeModal}>
      {({ modalClasses, CloseX }) => (
        <>
          <CloseX />
          <h3 className='tc ph3 mt0'>
            Your account has been successfully deactivated. If you wish to reactivate, please email{' '}
            <a href='mailto:support@monami.io'>support@monami.io</a>
          </h3>
          <div className={`flex ${modalClasses.buttonContainer}`}>
            <div onClick={logout.bind(null, client)} data-cy='deactivation--logout'>
              OK
            </div>
          </div>
        </>
      )}
    </ModalBody>
  )
}

export default Modal
