import React from 'react'
import Header from 'components/VisitDetails/Logistics/Header'
import Detail from 'components/VisitDetails/Detail'
import formatPhoneNumber from 'helpers/formatPhoneNumber'

import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface TelepresenceProps {
  visit: VisitDetailsQuery_companion_visit;
  CustomerPhoneNumber: React.FC<{}>;
}

const Telepresence: React.FC<TelepresenceProps> = ({ visit, CustomerPhoneNumber }) => {
  const { visitee: { customer, ongoingSchedulingNotes } } = visit

  return (
    <div className='pa125'>
      <Header visit={visit} />
      <a href={`tel:${formatPhoneNumber(customer.phoneNumber)}`} className='no-underline'>
        <div className={`b`}>
          <i className={`mr2 purple icon-call`} />
          Call
        </div>
      </a>
      {visit.visitCoding?.name && <Detail heading='Visit Coding'>{visit.visitCoding.name}</Detail>}
      {visit.visitInfo && <Detail heading='Call Notes'>{visit.visitInfo}</Detail>}
      {visit.address && <Detail heading='Call Instructions'>{visit.address.notes}</Detail>}
      <CustomerPhoneNumber />
      {ongoingSchedulingNotes && <Detail heading='Ongoing'>{ongoingSchedulingNotes}</Detail>}
    </div>
  )
}

export default Telepresence
