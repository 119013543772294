import React from 'react'

const dateDisplay = (startAt, endAt) => {
  const hours = endAt.diff(startAt, 'hours')
  const minutes = Math.round(((endAt.diff(startAt, 'minute') / 60) % 1) * 60)

  if (hours === 0) return `${minutes}min`
  if (minutes === 0) return `${hours}hr`
  else return `${hours}hr ${minutes}min`
}

const DurationDisplay = ({ openEditTime, startAt, endAt }) => {
  return (
    <>
      <div className='flex mb2'>
        <div className='flex-auto'>
          <h4 className='ma0 fw6'>Time</h4>
        </div>
        <div className='pointer' onClick={openEditTime}>
          <i className='icon-edit purple mr2' />
          Edit time
        </div>
      </div>
      <div className='flex'>
        <div className='flex-auto'>
          {startAt.format('h:mm a')} - {endAt.format('h:mm a')}
        </div>
        <div className='fw6'>{dateDisplay(startAt, endAt)}</div>
      </div>
    </>
  )
}

export default DurationDisplay
