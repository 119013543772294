import dayjs from 'helpers/dayjs'
import { pipe, range, evolve, add } from 'ramda'

export const roundMinutes = (timeInMinutes: number): number => {
  // adjust exclusive arg so things are easier to read and reason about
  const adjustExlcusive = add(1)
  const minutes = Math.round(((timeInMinutes / 60) % 1) * 60)

  if (range(0, adjustExlcusive(7)).includes(minutes)) return 0
  if (range(8, adjustExlcusive(22)).includes(minutes)) return 15
  if (range(23, adjustExlcusive(37)).includes(minutes)) return 30
  if (range(38, adjustExlcusive(52)).includes(minutes)) return 45
  if (range(53, adjustExlcusive(59)).includes(minutes)) return 60
  else return minutes
}

const toDayjs = evolve({
  startAt: dayjs,
  endAt: dayjs
})

export const adjustStartAndEndTime = ({ start, end }) => {
  return fromDayJs({
    startAt: start.minute(roundMinutes(start.minute())),
    endAt: end.minute(roundMinutes(end.minute()))
  })
}

const dayjsToJSON = date => date.toJSON()
const fromDayJs = evolve({ startAt: dayjsToJSON, endAt: dayjsToJSON })

export default pipe(
  ({ startAt, endAt }) => ({
    startAt,
    endAt
  }),
  toDayjs
)
