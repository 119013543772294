import React from 'react'
import formatAddress from 'helpers/formatAddress'
import Header from 'components/VisitDetails/Logistics/Header'
import Detail from 'components/VisitDetails/Detail'
import visitIcon from 'assets/icons/visit-icon.svg'

import { VisitDetailsQuery_companion_visit, VisitDetailsQuery_metaData } from 'types/graphql/VisitDetailsQuery'

const getLivingPlace = (visiteeLivingPlaces, livingPlace) => visiteeLivingPlaces.find(item => item.key === livingPlace).value.toLowerCase()

interface PersonalProps {
  visit: VisitDetailsQuery_companion_visit;
  metaData: VisitDetailsQuery_metaData;
  CustomerPhoneNumber: React.FC<{}>;
}

const Personal: React.FC<PersonalProps> = ({ visit, metaData, CustomerPhoneNumber }) => {
  const {
    visitee: {
      livingPlace,
      customer,
      ongoingSchedulingNotes
    }
  } = visit
  const { visiteeLivingPlaces } = metaData

  return (
    <div className='pa125'>
      <Header visit={visit} />
      <div className={`b`}>
        <img src={visitIcon} className='mr2' />
        Visit
      </div>
      {visit.visitCoding?.name && <Detail heading='Visit Coding'>{visit.visitCoding.name}</Detail>}
      {visit.visitInfo && <Detail heading='Visit Notes'>{visit.visitInfo}</Detail>}
      {visit.address?.notes && <Detail heading={'Arrival Notes'}>{visit.address.notes}</Detail>}
      <Detail heading='Address'>
        {visit.facility && <div>{visit.facility.name}</div>}
        {visit.address && formatAddress(visit.address)}
      </Detail>
      {livingPlace && (
        <Detail heading='Living Situation'>
          {customer.person.preferredOrFirstName} lives {getLivingPlace(visiteeLivingPlaces, livingPlace)}
        </Detail>
      )}

      {visit.opportunity && (
        <Detail heading='Driving required?'>{visit.opportunity.drivingRequired ? 'Yes' : 'No'}</Detail>
      )}
      <CustomerPhoneNumber />
      {ongoingSchedulingNotes && <Detail heading='Ongoing'>{ongoingSchedulingNotes}</Detail>}
    </div>
  )
}

export default Personal
