import { Toggle } from 'office-ui-fabric-react/lib/Toggle'
import { styled } from 'office-ui-fabric-react/lib/Utilities'

const styles = props => {
  return {
    label: [{ fontWeight: '600' }]
  }
}

export default styled(Toggle, styles)
