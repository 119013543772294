import { gql } from '@apollo/client'

export const PROFILE_PHOTO_QUERY = gql`
  query ProfilePhotoQuery {
    companion @client {
      person {
        photo {
          id
          cdnUrlTemplate
        }
      }
    }
  }
`
