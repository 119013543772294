import woff from 'assets/fonts/monami.woff'

const iconStyle = {
  fontFamily: '"monami"',
  speak: 'none',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontVariant: 'normal',
  textTransform: 'none',
  lineHeight: '1',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale'
}

const iconStyles = {
  '@font-face': {
    fontFamily: '"monami"',
    src: `url("${woff}") format("woff")`,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'block'
  },
  '.icon-about-request:before': { content: '"\\e900"', ...iconStyle },
  '.icon-about:before': { content: '"\\e901"', ...iconStyle },
  '.icon-accept:before': { content: '"\\e902"', ...iconStyle },
  '.icon-activity:before': { content: '"\\e903"', ...iconStyle },
  '.icon-add:before': { content: '"\\e904"', ...iconStyle },
  '.icon-arrow-left:before': { content: '"\\e905"', ...iconStyle },
  '.icon-arrow-right:before': { content: '"\\e906"', ...iconStyle },
  '.icon-calendar:before': { content: '"\\e907"', ...iconStyle },
  '.icon-call:before': { content: '"\\e908"', ...iconStyle },
  '.icon-camera:before': { content: '"\\e909"', ...iconStyle },
  '.icon-closed:before': { content: '"\\e90a"', ...iconStyle },
  '.icon-decline:before': { content: '"\\e90b"', ...iconStyle },
  '.icon-delete:before': { content: '"\\e90c"', ...iconStyle },
  '.icon-details:before': { content: '"\\e90d"', ...iconStyle },
  '.icon-drop-closed:before': { content: '"\\e90e"', ...iconStyle },
  '.icon-drop-open:before': { content: '"\\e90f"', ...iconStyle },
  '.icon-econtact:before': { content: '"\\e910"', ...iconStyle },
  '.icon-edit-photo:before': { content: '"\\e911"', ...iconStyle },
  '.icon-edit:before': { content: '"\\e912"', ...iconStyle },
  '.icon-google:before': { content: '"\\e914"', ...iconStyle },
  '.icon-location:before': { content: '"\\e915"', ...iconStyle },
  '.icon-match .path1:before': {
    content: '"\\e916"',
    color: 'rgb(186, 194, 204)'
  },
  '.icon-match .path2:before': {
    content: '"\\e917"',
    marginLeft: '-1em',
    color: 'rgb(104, 213, 109)'
  },
  '.icon-match .path3:before': {
    content: '"\\e918"',
    marginLeft: '-1em',
    color: 'rgb(255, 255, 255)'
  },
  '.icon-message:before': { content: '"\\e919"', ...iconStyle },
  '.icon-navigation:before': { content: '"\\e91a"', ...iconStyle },
  '.icon-open:before': { content: '"\\e91b"', ...iconStyle },
  '.icon-refresh:before': { content: '"\\e91c"', ...iconStyle },
  '.icon-replace-photo:before': { content: '"\\e91d"', ...iconStyle },
  '.icon-reschedule:before': { content: '"\\e91e"', ...iconStyle },
  '.icon-shopping:before': { content: '"\\e91f"', ...iconStyle },
  '.icon-thumbsdown:before': { content: '"\\e920"', ...iconStyle },
  '.icon-thumbsup:before': { content: '"\\e921"', ...iconStyle },
  '.icon-time:before': { content: '"\\e922"', ...iconStyle },
  '.icon-upload:before': { content: '"\\e923"', ...iconStyle },
  '.icon-video:before': { content: '"\\e924"', ...iconStyle }
}

export default iconStyles
