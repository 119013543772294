export default {
  container: {
    lineHeight: '3.125rem'
  },
  icon: {
    // if truly centered, icon rotates like an egg ;-;
    transformOrigin: '50% 46%',
    fontSize: '1.1rem',
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  rotating: {
    '-webkit-animation': 'rotating 2s ease infinite',
    '-moz-animation': 'rotating 2s ease infinite',
    '-ms-animation': 'rotating 2s ease infinite',
    '-o-animation': 'rotating 2s ease infinite',
    animation: 'rotating 2s ease infinite'
  }
}
