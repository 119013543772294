import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import classNames from 'classnames'

const ErrorMessage = ({ classes, errorMessage, bordered = false }) => (
  <div role='alert'>
    <p className={classNames(classes.errorMessage, { [classes.bordered]: bordered })}>
      <span>{errorMessage}</span>
    </p>
  </div>
)

export default withStyles(style)(ErrorMessage)
