import React from 'react'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { useStateValue, types } from 'components/BookVisit/StateProvider'
import withApollo from 'helpers/withApollo'
import { equals } from 'ramda'
import getError from 'helpers/getError'
import PredefinedRecurringAndSubmit from './PredefinedRecurringAndSubmit'
import DefaultRecurringAndSubmit from './DefaultRecurringAndSubmit'
import { PreviousVisitees_companion_visits } from 'types/graphql/PreviousVisitees'

interface RecurringAndSubmitProps {
  predefinedVisit: PreviousVisitees_companion_visits,
}

const RecurringAndSubmit: React.FC<RecurringAndSubmitProps> = ({ predefinedVisit }) => {
  const [{ errors }, dispatch] = useStateValue()

  const updateRecurrenceCount = recurrenceCount => dispatch({ type: types.UPDATE_RECURRENCE_COUNT, recurrenceCount })

  const updateRecurring = (_, { key }) => dispatch({ type: types.UPDATE_RECURRING, recurring: key === 'recurring' })

  const onError = errors => {
    raiseErrorIfCantBeIgnored(errors)
    dispatch({ type: types.UPDATE_ERRORS, errors })
  }

  const baseErrors = getError('base', errors)
  const addressError = getError('address', errors)

  return (
    <>
      {predefinedVisit ? (
        <PredefinedRecurringAndSubmit
          predefinedVisit={predefinedVisit}
          onError={onError}
          baseErrors={baseErrors}
          addressError={addressError}
          updateRecurrenceCount={updateRecurrenceCount}
        />
      ) : (
        <DefaultRecurringAndSubmit
          onError={onError}
          baseErrors={baseErrors}
          addressError={addressError}
          updateRecurrenceCount={updateRecurrenceCount}
          updateRecurring={updateRecurring}
        />
      )}
    </>
  )
}

export default React.memo(withApollo(RecurringAndSubmit), equals)
