const getTextZoomCallback = textZoom => {
  if (textZoom > 175) window.MobileAccessibility.setTextZoom(175)
}

const initializeAccessibility = () => {
  if (window.MobileAccessibility) {
    window.MobileAccessibility.usePreferredTextZoom(true)
    window.MobileAccessibility.getTextZoom(getTextZoomCallback)
  }
}

export default initializeAccessibility
