import happyBirthday from 'assets/images/happy-birthday.svg'
import anniversaryIcecream from 'assets/images/anniversary-icecream.svg'

import visit1 from 'assets/images/badges/visit-1.svg'
import visit10 from 'assets/images/badges/visit-10.svg'
import visit25 from 'assets/images/badges/visit-25.svg'
import visit50 from 'assets/images/badges/visit-50.svg'
import visit100 from 'assets/images/badges/visit-100.svg'
import hours100 from 'assets/images/badges/hours-100.svg'
import hours250 from 'assets/images/badges/hours-250.svg'
import visit250 from 'assets/images/badges/visit-250.svg'
import hours500 from 'assets/images/badges/hours-500.svg'
import visit500 from 'assets/images/badges/visit-500.svg'
import hours1000 from 'assets/images/badges/hours-1000.svg'
import visit1000 from 'assets/images/badges/visit-1000.svg'

import visit1Grey from 'assets/images/badges/visit-1-grey.svg'
import visit10Grey from 'assets/images/badges/visit-10-grey.svg'
import visit25Grey from 'assets/images/badges/visit-25-grey.svg'
import visit50Grey from 'assets/images/badges/visit-50-grey.svg'
import hours100Grey from 'assets/images/badges/hours-100-grey.svg'
import visit100Grey from 'assets/images/badges/visit-100-grey.svg'
import hours250Grey from 'assets/images/badges/hours-250-grey.svg'
import visit250Grey from 'assets/images/badges/visit-250-grey.svg'
import hours500Grey from 'assets/images/badges/hours-500-grey.svg'
import visit500Grey from 'assets/images/badges/visit-500-grey.svg'
import hours1000Grey from 'assets/images/badges/hours-1000-grey.svg'
import visit1000Grey from 'assets/images/badges/visit-1000-grey.svg'

export const milestoneImages = {
  visits_1: { active: visit1, inactive: visit1Grey },
  visits_10: { active: visit10, inactive: visit10Grey },
  visits_25: { active: visit25, inactive: visit25Grey },
  visits_50: { active: visit50, inactive: visit50Grey },
  visit_hours_100: { active: hours100, inactive: hours100Grey },
  visits_100: { active: visit100, inactive: visit100Grey },
  visit_hours_250: { active: hours250, inactive: hours250Grey },
  visits_250: { active: visit250, inactive: visit250Grey },
  visit_hours_500: { active: hours500, inactive: hours500Grey },
  visits_500: { active: visit500, inactive: visit500Grey },
  visit_hours_1000: { active: hours1000, inactive: hours1000Grey },
  visits_1000: { active: visit1000, inactive: visit1000Grey },
  happy_birthday: { active: happyBirthday },
  happy_anniversary: { active: anniversaryIcecream }
}
