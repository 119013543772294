import colors from 'helpers/colors'

export default {
  '@global': {
    html: {
      lineHeight: '1.3',
      '-webkit-text-size-adjust': '100%'
    },
    body: {
      margin: '0'
    },
    h1: {
      fontSize: '2em',
      margin: '.67em 0'
    },
    hr: {
      boxSizing: 'content-box',
      height: '0',
      overflow: 'visible'
    },
    pre: {
      fontFamily: 'monospace, monospace',
      fontSize: '1em'
    },
    a: {
      backgroundColor: 'transparent'
    },
    'abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'none',
      '-webkit-text-decoration': 'none',
      fallbacks: [
        {
          textDecoration: 'none'
        }
      ]
    },
    'b, strong': {
      fontWeight: 'bolder'
    },
    'code, kbd, samp': {
      fontFamily: 'monospace, monospace',
      fontSize: '1em'
    },
    small: {
      fontSize: '80%'
    },
    'sub, sup': {
      fontSize: '75%',
      lineHeight: '0',
      position: 'relative',
      verticalAlign: 'baseline'
    },
    sub: {
      bottom: '-0.25em'
    },
    sup: {
      top: '-0.5em'
    },
    img: {
      borderStyle: 'none',
      maxWidth: '100%'
    },
    'button, input, optgroup, select, textarea': {
      fontFamily: 'inherit',
      fontSize: '100%',
      lineHeight: '1.15',
      margin: '0'
    },
    'button, input': {
      overflow: 'visible'
    },
    'button, select': {
      textTransform: 'none'
    },
    'button, [type="button"], [type="reset"], [type="submit"]': {
      '-webkit-appearance': 'button'
    },
    'button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner': {
      borderStyle: 'none',
      padding: '0'
    },
    'button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring': {
      outline: '1px dotted ButtonText'
    },
    fieldset: {
      padding: '.35em .75em .625em'
    },
    legend: {
      boxSizing: 'border-box',
      color: 'inherit',
      display: 'table',
      maxWidth: '100%',
      padding: '0',
      whiteSpace: 'normal'
    },
    progress: {
      verticalAlign: 'baseline'
    },
    textarea: {
      overflow: 'auto'
    },
    '[type="checkbox"], [type="radio"]': {
      boxSizing: 'border-box',
      padding: '0'
    },
    '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button': {
      height: 'auto'
    },
    '[type="search"]': {
      '-webkit-appearance': 'textfield',
      outlineOffset: -2
    },
    '[type="search"]::-webkit-search-decoration': {
      '-webkit-appearance': 'none'
    },
    '::-webkit-file-upload-button': {
      '-webkit-appearance': 'button',
      font: 'inherit'
    },
    details: {
      display: 'block'
    },
    summary: {
      display: 'list-item'
    },
    template: {
      display: 'none'
    },
    '[hidden]': {
      display: 'none'
    },
    'html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, table, td, th, tr, input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="url"], .border-box': {
      boxSizing: 'border-box'
    },
    '.aspect-ratio': {
      height: '0',
      position: 'relative'
    },
    '.aspect-ratio--16x9': {
      paddingBottom: '56.25%'
    },
    '.aspect-ratio--9x16': {
      paddingBottom: '177.77%'
    },
    '.aspect-ratio--4x3': {
      paddingBottom: '75%'
    },
    '.aspect-ratio--3x4': {
      paddingBottom: '133.33%'
    },
    '.aspect-ratio--6x4': {
      paddingBottom: '66.6%'
    },
    '.aspect-ratio--4x6': {
      paddingBottom: '150%'
    },
    '.aspect-ratio--8x5': {
      paddingBottom: '62.5%'
    },
    '.aspect-ratio--5x8': {
      paddingBottom: '160%'
    },
    '.aspect-ratio--7x5': {
      paddingBottom: '71.42%'
    },
    '.aspect-ratio--5x7': {
      paddingBottom: '140%'
    },
    '.aspect-ratio--1x1': {
      paddingBottom: '100%'
    },
    '.aspect-ratio--object': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: '100'
    },
    '.cover': {
      backgroundSize: 'cover !important'
    },
    '.contain': {
      backgroundSize: 'contain !important'
    },
    '.bg-center': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    },
    '.bg-top': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center'
    },
    '.bg-right': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right'
    },
    '.bg-bottom': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center'
    },
    '.bg-left': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center left'
    },
    '.outline': {
      outline: '1px solid'
    },
    '.outline-transparent': {
      outline: '1px solid transparent'
    },
    '.outline-0': {
      outline: '0'
    },
    '.ba': {
      borderStyle: 'solid',
      borderWidth: 1
    },
    '.bt': {
      borderTopStyle: 'solid',
      borderTopWidth: 1
    },
    '.br': {
      borderRightStyle: 'solid',
      borderRightWidth: 1
    },
    '.bb': {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1
    },
    '.bl': {
      borderLeftStyle: 'solid',
      borderLeftWidth: 1
    },
    '.bn': {
      borderStyle: 'none',
      borderWidth: '0'
    },
    '.b--black': {
      borderColor: '#212224'
    },
    '.b--darkest-gray': {
      borderColor: '#525960'
    },
    '.b--dark-gray': {
      borderColor: '#BAC2CC'
    },
    '.b--gray': {
      borderColor: '#DBDFE4'
    },
    '.b--light-gray': {
      borderColor: '#F8F9FB'
    },
    '.b--white': {
      borderColor: '#FFFFFF'
    },
    '.b--blue': {
      borderColor: '#56CCF7'
    },
    '.b--dark-blue': {
      borderColor: '#4A90E2'
    },
    '.b--egg-shell': {
      borderColor: '#FFFBEE'
    },
    '.b--yellow': {
      borderColor: '#FFC20B'
    },
    '.b--green': {
      borderColor: '#68D56D'
    },
    '.b--border-green': {
      borderColor: colors.borderGreen
    },
    '.b--purple': {
      borderColor: '#6a79d3'
    },
    '.b--teal': {
      borderColor: '#18D3AF'
    },
    '.b--red': {
      borderColor: '#FF6773'
    },
    '.br0': {
      borderRadius: '0'
    },
    '.br1': {
      borderRadius: '.125rem'
    },
    '.br2': {
      borderRadius: '.25rem'
    },
    '.br3': {
      borderRadius: '.5rem'
    },
    '.br4': {
      borderRadius: '1rem'
    },
    '.br-100': {
      borderRadius: '100%'
    },
    '.br-pill': {
      borderRadius: 9999
    },
    '.br--bottom': {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '.br--top': {
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    },
    '.br--right': {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0'
    },
    '.br--left': {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0'
    },
    '.b--dotted': {
      borderStyle: 'dotted'
    },
    '.b--dashed': {
      borderStyle: 'dashed'
    },
    '.b--solid': {
      borderStyle: 'solid'
    },
    '.b--none': {
      borderStyle: 'none'
    },
    '.bw0': {
      borderWidth: '0'
    },
    '.bw1': {
      borderWidth: '.125rem'
    },
    '.bw2': {
      borderWidth: '.25rem'
    },
    '.bw3': {
      borderWidth: '.5rem'
    },
    '.bw4': {
      borderWidth: '1rem'
    },
    '.bw5': {
      borderWidth: '2rem'
    },
    '.bt-0': {
      borderTopWidth: '0'
    },
    '.br-0': {
      borderRightWidth: '0'
    },
    '.bb-0': {
      borderBottomWidth: '0'
    },
    '.bl-0': {
      borderLeftWidth: '0'
    },
    '.pre': {
      overflowX: 'auto',
      overflowY: 'hidden',
      overflow: 'scroll',
      whiteSpace: 'pre'
    },
    '.top-0': {
      top: '0'
    },
    '.right-0': {
      right: '0'
    },
    '.bottom-0': {
      bottom: '0'
    },
    '.left-0': {
      left: '0'
    },
    '.top-1': {
      top: '1rem'
    },
    '.right-1': {
      right: '1rem'
    },
    '.bottom-1': {
      bottom: '1rem'
    },
    '.left-1': {
      left: '1rem'
    },
    '.top-2': {
      top: '2rem'
    },
    '.right-2': {
      right: '2rem'
    },
    '.bottom-2': {
      bottom: '2rem'
    },
    '.left-2': {
      left: '2rem'
    },
    '.top--1': {
      top: '-1rem'
    },
    '.right--1': {
      right: '-1rem'
    },
    '.bottom--1': {
      bottom: '-1rem'
    },
    '.left--1': {
      left: '-1rem'
    },
    '.top--2': {
      top: '-2rem'
    },
    '.right--2': {
      right: '-2rem'
    },
    '.bottom--2': {
      bottom: '-2rem'
    },
    '.left--2': {
      left: '-2rem'
    },
    '.absolute--fill': {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0'
    },
    '.cf:before, .cf:after': {
      content: '" "',
      display: 'table'
    },
    '.cf:after': {
      clear: 'both'
    },
    '.cf': {
      zoom: '1'
    },
    '.cl': {
      clear: 'left'
    },
    '.cr': {
      clear: 'right'
    },
    '.cb': {
      clear: 'both'
    },
    '.cn': {
      clear: 'none'
    },
    '.dn': {
      display: 'none'
    },
    '.di': {
      display: 'inline'
    },
    '.db': {
      display: 'block'
    },
    '.dib': {
      display: 'inline-block'
    },
    '.dit': {
      display: 'inline-table'
    },
    '.dt': {
      display: 'table'
    },
    '.dtc': {
      display: 'table-cell'
    },
    '.dt-row': {
      display: 'table-row'
    },
    '.dt-row-group': {
      display: 'table-row-group'
    },
    '.dt-column': {
      display: 'table-column'
    },
    '.dt-column-group': {
      display: 'table-column-group'
    },
    '.dt--fixed': {
      tableLayout: 'fixed',
      width: '100%'
    },
    '.flex': {
      display: 'flex'
    },
    '.inline-flex': {
      display: 'inline-flex'
    },
    '.flex-auto': {
      flex: '1 1 auto',
      minWidth: '0',
      minHeight: '0'
    },
    '.flex-none': {
      flex: 'none'
    },
    '.flex-column': {
      flexDirection: 'column'
    },
    '.flex-row': {
      flexDirection: 'row'
    },
    '.flex-wrap': {
      flexWrap: 'wrap'
    },
    '.flex-nowrap': {
      flexWrap: 'nowrap'
    },
    '.flex-wrap-reverse': {
      flexWrap: 'wrap-reverse'
    },
    '.flex-column-reverse': {
      flexDirection: 'column-reverse'
    },
    '.flex-row-reverse': {
      flexDirection: 'row-reverse'
    },
    '.items-start': {
      alignItems: 'flex-start'
    },
    '.items-end': {
      alignItems: 'flex-end'
    },
    '.items-center': {
      alignItems: 'center'
    },
    '.items-baseline': {
      alignItems: 'baseline'
    },
    '.items-stretch': {
      alignItems: 'stretch'
    },
    '.self-start': {
      alignSelf: 'flex-start'
    },
    '.self-end': {
      alignSelf: 'flex-end'
    },
    '.self-center': {
      alignSelf: 'center'
    },
    '.self-baseline': {
      alignSelf: 'baseline'
    },
    '.self-stretch': {
      alignSelf: 'stretch'
    },
    '.justify-start': {
      justifyContent: 'flex-start'
    },
    '.justify-end': {
      justifyContent: 'flex-end'
    },
    '.justify-center': {
      justifyContent: 'center'
    },
    '.justify-between': {
      justifyContent: 'space-between'
    },
    '.justify-around': {
      justifyContent: 'space-around'
    },
    '.content-start': {
      alignContent: 'flex-start'
    },
    '.content-end': {
      alignContent: 'flex-end'
    },
    '.content-center': {
      alignContent: 'center'
    },
    '.content-between': {
      alignContent: 'space-between'
    },
    '.content-around': {
      alignContent: 'space-around'
    },
    '.content-stretch': {
      alignContent: 'stretch'
    },
    '.order-0': {
      order: '0'
    },
    '.order-1': {
      order: '1'
    },
    '.order-2': {
      order: '2'
    },
    '.order-3': {
      order: '3'
    },
    '.order-4': {
      order: '4'
    },
    '.order-5': {
      order: '5'
    },
    '.order-6': {
      order: '6'
    },
    '.order-7': {
      order: '7'
    },
    '.order-8': {
      order: '8'
    },
    '.order-last': {
      order: '99999'
    },
    '.flex-grow-0': {
      flexGrow: '0'
    },
    '.flex-grow-1': {
      flexGrow: '1'
    },
    '.flex-shrink-0': {
      flexShrink: '0'
    },
    '.flex-shrink-1': {
      flexShrink: '1'
    },
    '.fl': {
      float: 'left',
      display: 'inline'
    },
    '.fr': {
      float: 'right',
      display: 'inline'
    },
    '.fn': {
      float: 'none'
    },
    '.sans-serif': {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif"
    },
    '.serif': {
      fontFamily: 'georgia, times, serif'
    },
    '.system-sans-serif': {
      fontFamily: 'sans-serif'
    },
    '.system-serif': {
      fontFamily: 'serif'
    },
    '.recoleta-regular': {
      fontFamily: 'recoleta-regular'
    },
    '.acumin-pro-wide': {
      fontFamily: 'acumin-pro-wide'
    },
    'code, .code': {
      fontFamily: 'Consolas, monaco, monospace'
    },
    '.courier': {
      fontFamily: "'Courier Next', courier, monospace"
    },
    '.helvetica': {
      fontFamily: "'helvetica neue', helvetica, sans-serif"
    },
    '.avenir': {
      fontFamily: "'avenir next', avenir, sans-serif"
    },
    '.athelas': {
      fontFamily: 'athelas, georgia, serif'
    },
    '.georgia': {
      fontFamily: 'georgia, serif'
    },
    '.times': {
      fontFamily: 'times, serif'
    },
    '.bodoni': {
      fontFamily: '"Bodoni MT", serif'
    },
    '.calisto': {
      fontFamily: '"Calisto MT", serif'
    },
    '.garamond': {
      fontFamily: 'garamond, serif'
    },
    '.baskerville': {
      fontFamily: 'baskerville, serif'
    },
    '.i': {
      fontStyle: 'italic'
    },
    '.fs-normal': {
      fontStyle: 'normal'
    },
    '.normal': {
      fontWeight: 'normal'
    },
    '.b': {
      fontWeight: 'bold'
    },
    '.fw1': {
      fontWeight: '100'
    },
    '.fw2': {
      fontWeight: '200'
    },
    '.fw3': {
      fontWeight: '300'
    },
    '.fw4': {
      fontWeight: '400'
    },
    '.fw5': {
      fontWeight: '500'
    },
    '.fw6': {
      fontWeight: '600'
    },
    '.fw7': {
      fontWeight: '700'
    },
    '.fw8': {
      fontWeight: '800'
    },
    '.fw9': {
      fontWeight: '900'
    },
    '.input-reset': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none'
    },
    '.button-reset::-moz-focus-inner, .input-reset::-moz-focus-inner': {
      border: '0',
      padding: '0'
    },
    '.h1': {
      height: '1rem'
    },
    '.h2': {
      height: '2rem'
    },
    '.h3': {
      height: '4rem'
    },
    '.h4': {
      height: '8rem'
    },
    '.h5': {
      height: '16rem'
    },
    '.h-25': {
      height: '25%'
    },
    '.h-50': {
      height: '50%'
    },
    '.h-75': {
      height: '75%'
    },
    '.h-100': {
      height: '100%'
    },
    '.min-h-100': {
      minHeight: '100%'
    },
    '.vh-25': {
      height: '25vh'
    },
    '.vh-50': {
      height: '50vh'
    },
    '.vh-75': {
      height: '75vh'
    },
    '.vh-100': {
      height: '100vh'
    },
    '.min-vh-100': {
      minHeight: '100vh'
    },
    '.h-auto': {
      height: 'auto'
    },
    '.h-inherit': {
      height: 'inherit'
    },
    '.tracked': {
      letterSpacing: '.1em'
    },
    '.tracked-tight': {
      letterSpacing: '-.05em'
    },
    '.tracked-mega': {
      letterSpacing: '.25em'
    },
    '.lh-solid': {
      lineHeight: '1'
    },
    '.lh-title': {
      lineHeight: '1.25'
    },
    '.lh-copy': {
      lineHeight: '1.5'
    },
    '.link': {
      textDecoration: 'none',
      transition: 'color .15s ease-in'
    },
    '.link:link, .link:visited': {
      transition: 'color .15s ease-in'
    },
    '.link:hover': {
      transition: 'color .15s ease-in'
    },
    '.link:active': {
      transition: 'color .15s ease-in'
    },
    '.link:focus': {
      transition: 'color .15s ease-in',
      outline: '1px dotted currentColor'
    },
    '.list': {
      listStyleType: 'none'
    },
    '.mw-100': {
      maxWidth: '100%'
    },
    '.mw1': {
      maxWidth: '1rem'
    },
    '.mw2': {
      maxWidth: '2rem'
    },
    '.mw3': {
      maxWidth: '4rem'
    },
    '.mw4': {
      maxWidth: '8rem'
    },
    '.mw5': {
      maxWidth: '16rem'
    },
    '.mw6': {
      maxWidth: '32rem'
    },
    '.mw7': {
      maxWidth: '48rem'
    },
    '.mw8': {
      maxWidth: '64rem'
    },
    '.mw9': {
      maxWidth: '96rem'
    },
    '.mw-none': {
      maxWidth: 'none'
    },
    '.w1': {
      width: '1rem'
    },
    '.w2': {
      width: '2rem'
    },
    '.w3': {
      width: '4rem'
    },
    '.w4': {
      width: '8rem'
    },
    '.w5': {
      width: '16rem'
    },
    '.w-10': {
      width: '10%'
    },
    '.w-20': {
      width: '20%'
    },
    '.w-25': {
      width: '25%'
    },
    '.w-30': {
      width: '30%'
    },
    '.w-33': {
      width: '33%'
    },
    '.w-34': {
      width: '34%'
    },
    '.w-40': {
      width: '40%'
    },
    '.w-50': {
      width: '50%'
    },
    '.w-60': {
      width: '60%'
    },
    '.w-70': {
      width: '70%'
    },
    '.w-75': {
      width: '75%'
    },
    '.w-80': {
      width: '80%'
    },
    '.w-90': {
      width: '90%'
    },
    '.w-100': {
      width: '100%'
    },
    '.w-third': {
      width: '33.33333%'
    },
    '.w-two-thirds': {
      width: '66.66667%'
    },
    '.w-auto': {
      width: 'auto'
    },
    '.overflow-visible': {
      overflow: 'visible'
    },
    '.overflow-hidden': {
      overflow: 'hidden'
    },
    '.overflow-scroll': {
      overflow: 'scroll'
    },
    '.overflow-auto': {
      overflow: 'auto'
    },
    '.overflow-x-visible': {
      overflowX: 'visible'
    },
    '.overflow-x-hidden': {
      overflowX: 'hidden'
    },
    '.overflow-x-scroll': {
      overflowX: 'scroll'
    },
    '.overflow-x-auto': {
      overflowX: 'auto'
    },
    '.overflow-y-visible': {
      overflowY: 'visible'
    },
    '.overflow-y-hidden': {
      overflowY: 'hidden'
    },
    '.overflow-y-scroll': {
      overflowY: 'scroll'
    },
    '.overflow-y-auto': {
      overflowY: 'auto'
    },
    '.static': {
      position: 'static'
    },
    '.relative': {
      position: 'relative'
    },
    '.absolute': {
      position: 'absolute'
    },
    '.fixed': {
      position: 'fixed'
    },
    '.o-100': {
      opacity: '1'
    },
    '.o-90': {
      opacity: '.9'
    },
    '.o-80': {
      opacity: '.8'
    },
    '.o-70': {
      opacity: '.7'
    },
    '.o-60': {
      opacity: '.6'
    },
    '.o-50': {
      opacity: '.5'
    },
    '.o-40': {
      opacity: '.4'
    },
    '.o-30': {
      opacity: '.3'
    },
    '.o-20': {
      opacity: '.2'
    },
    '.o-10': {
      opacity: '.1'
    },
    '.o-05': {
      opacity: '.05'
    },
    '.o-025': {
      opacity: '.025'
    },
    '.o-0': {
      opacity: '0'
    },
    '.rotate-45': {
      '-webkit-transform': 'rotate( 45deg )',
      transform: 'rotate( 45deg )'
    },
    '.rotate-90': {
      '-webkit-transform': 'rotate( 90deg )',
      transform: 'rotate( 90deg )'
    },
    '.rotate-135': {
      '-webkit-transform': 'rotate( 135deg )',
      transform: 'rotate( 135deg )'
    },
    '.rotate-180': {
      '-webkit-transform': 'rotate( 180deg )',
      transform: 'rotate( 180deg )'
    },
    '.rotate-225': {
      '-webkit-transform': 'rotate( 225deg )',
      transform: 'rotate( 225deg )'
    },
    '.rotate-270': {
      '-webkit-transform': 'rotate( 270deg )',
      transform: 'rotate( 270deg )'
    },
    '.rotate-315': {
      '-webkit-transform': 'rotate( 315deg )',
      transform: 'rotate( 315deg )'
    },
    '.black': {
      color: '#212224'
    },
    '.darkest-gray': {
      color: '#525960'
    },
    '.dark-gray': {
      color: '#BAC2CC'
    },
    '.gray': {
      color: '#DBDFE4'
    },
    '.light-gray': {
      color: '#F8F9FB'
    },
    '.white': {
      color: '#FFFFFF'
    },
    '.blue': {
      color: '#56CCF7'
    },
    '.dark-blue': {
      color: '#4A90E2'
    },
    '.egg-shell': {
      color: '#FFFBEE'
    },
    '.yellow': {
      color: '#FFC20B'
    },
    '.green': {
      color: colors.lighterGreen2
    },
    '.lighter-green': {
      color: colors.lighterGreen
    },
    '.lighter-green-2': {
      color: colors.lighterGreen2
    },
    '.darkest-green': {
      color: colors.darkestGreen
    },
    '.label-green': {
      color: colors.labelGreenColor
    },
    '.teal': {
      color: '#18D3AF'
    },
    '.red': {
      color: '#FF6773'
    },
    '.purple': {
      color: colors.purple
    },
    '.bg-black': {
      backgroundColor: '#212224'
    },
    '.bg-darkest-gray': {
      backgroundColor: '#525960'
    },
    '.bg-dark-gray': {
      backgroundColor: '#BAC2CC'
    },
    '.bg-gray': {
      backgroundColor: '#DBDFE4'
    },
    '.bg-light-gray': {
      backgroundColor: '#F8F9FB'
    },
    '.bg-light-blue': {
      backgroundColor: '#eff3fb'
    },
    '.bg-white': {
      backgroundColor: '#FFFFFF'
    },
    '.bg-blue': {
      backgroundColor: '#56CCF7'
    },
    '.bg-dark-blue': {
      backgroundColor: '#4A90E2'
    },
    '.bg-egg-shell': {
      backgroundColor: '#fbf9f4'
    },
    '.bg-yellow': {
      backgroundColor: '#FFC20B'
    },
    '.bg-green': {
      backgroundColor: '#68D56D'
    },
    '.bg-teal': {
      backgroundColor: '#18D3AF'
    },
    '.bg-red': {
      backgroundColor: '#FF6773'
    },
    '.hover-black:hover': {
      color: '#212224'
    },
    '.hover-darkest-gray:hover': {
      color: '#525960'
    },
    '.hover-dark-gray:hover': {
      color: '#BAC2CC'
    },
    '.hover-gray:hover': {
      color: '#DBDFE4'
    },
    '.hover-light-gray:hover': {
      color: '#F8F9FB'
    },
    '.hover-white:hover': {
      color: '#FFFFFF'
    },
    '.hover-blue:hover': {
      color: '#56CCF7'
    },
    '.hover-dark-blue:hover': {
      color: '#4A90E2'
    },
    '.hover-egg-shell:hover': {
      color: '#FFFBEE'
    },
    '.hover-yellow:hover': {
      color: '#FFC20B'
    },
    '.hover-green:hover': {
      color: '#68D56D'
    },
    '.hover-teal:hover': {
      color: '#18D3AF'
    },
    '.hover-red:hover': {
      color: '#FF6773'
    },
    '.hover-bla:focusck': {
      color: '#212224'
    },
    '.hover-dar:focuskestGray': {
      color: '#525960'
    },
    '.hover-dar:focuskGray': {
      color: '#BAC2CC'
    },
    '.hover-gra:focusy': {
      color: '#DBDFE4'
    },
    '.hover-lig:focushtGray': {
      color: '#F8F9FB'
    },
    '.hover-whi:focuste': {
      color: '#FFFFFF'
    },
    '.hover-blu:focuse': {
      color: '#56CCF7'
    },
    '.hover-dar:focuskBlue': {
      color: '#4A90E2'
    },
    '.hover-egg:focusShell': {
      color: '#FFFBEE'
    },
    '.hover-yel:focuslow': {
      color: '#FFC20B'
    },
    '.hover-gre:focusen': {
      color: '#68D56D'
    },
    '.hover-tea:focusl': {
      color: '#18D3AF'
    },
    '.hover-red:focus': {
      color: '#FF6773'
    },
    'hover-bg-black:hover': {
      backgroundColor: '#212224'
    },
    'hover-bg-darkest-gray:hover': {
      backgroundColor: '#525960'
    },
    'hover-bg-dark-gray:hover': {
      backgroundColor: '#BAC2CC'
    },
    'hover-bg-gray:hover': {
      backgroundColor: '#DBDFE4'
    },
    'hover-bg-light-gray:hover': {
      backgroundColor: '#F8F9FB'
    },
    'hover-bg-white:hover': {
      backgroundColor: '#FFFFFF'
    },
    'hover-bg-blue:hover': {
      backgroundColor: '#56CCF7'
    },
    'hover-bg-dark-blue:hover': {
      backgroundColor: '#4A90E2'
    },
    'hover-bg-egg-shell:hover': {
      backgroundColor: '#FFFBEE'
    },
    'hover-bg-yellow:hover': {
      backgroundColor: '#FFC20B'
    },
    'hover-bg-green:hover': {
      backgroundColor: '#68D56D'
    },
    'hover-bg-teal:hover': {
      backgroundColor: '#18D3AF'
    },
    'hover-bg-red:hover': {
      backgroundColor: '#FF6773'
    },
    '.pa0': {
      padding: '0'
    },
    '.pa1': {
      padding: '.25rem'
    },
    '.pa2': {
      padding: '.5rem'
    },
    '.pa3': {
      padding: '1rem'
    },
    '.pa4': {
      padding: '2rem'
    },
    '.pa5': {
      padding: '4rem'
    },
    '.pa6': {
      padding: '8rem'
    },
    '.pa7': {
      padding: '16rem'
    },
    '.pl0': {
      paddingLeft: '0'
    },
    '.pl1': {
      paddingLeft: '.25rem'
    },
    '.pl2': {
      paddingLeft: '.5rem'
    },
    '.pl3': {
      paddingLeft: '1rem'
    },
    '.pl4': {
      paddingLeft: '2rem'
    },
    '.pl5': {
      paddingLeft: '4rem'
    },
    '.pl6': {
      paddingLeft: '8rem'
    },
    '.pl7': {
      paddingLeft: '16rem'
    },
    '.pr0': {
      paddingRight: '0'
    },
    '.pr1': {
      paddingRight: '.25rem'
    },
    '.pr2': {
      paddingRight: '.5rem'
    },
    '.pr3': {
      paddingRight: '1rem'
    },
    '.pr4': {
      paddingRight: '2rem'
    },
    '.pr5': {
      paddingRight: '4rem'
    },
    '.pr6': {
      paddingRight: '8rem'
    },
    '.pr7': {
      paddingRight: '16rem'
    },
    '.pb0': {
      paddingBottom: '0'
    },
    '.pb1': {
      paddingBottom: '.25rem'
    },
    '.pb2': {
      paddingBottom: '.5rem'
    },
    '.pb3': {
      paddingBottom: '1rem'
    },
    '.pb4': {
      paddingBottom: '2rem'
    },
    '.pb5': {
      paddingBottom: '4rem'
    },
    '.pb6': {
      paddingBottom: '8rem'
    },
    '.pb7': {
      paddingBottom: '16rem'
    },
    '.pt0': {
      paddingTop: '0'
    },
    '.pt1': {
      paddingTop: '.25rem'
    },
    '.pt2': {
      paddingTop: '.5rem'
    },
    '.pt3': {
      paddingTop: '1rem'
    },
    '.pt4': {
      paddingTop: '2rem'
    },
    '.pt5': {
      paddingTop: '4rem'
    },
    '.pt6': {
      paddingTop: '8rem'
    },
    '.pt7': {
      paddingTop: '16rem'
    },
    '.pv0': {
      paddingTop: '0',
      paddingBottom: '0'
    },
    '.pv1': {
      paddingTop: '.25rem',
      paddingBottom: '.25rem'
    },
    '.pv2': {
      paddingTop: '.5rem',
      paddingBottom: '.5rem'
    },
    '.pv3': {
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    '.pv4': {
      paddingTop: '2rem',
      paddingBottom: '2rem'
    },
    '.pv5': {
      paddingTop: '4rem',
      paddingBottom: '4rem'
    },
    '.pv6': {
      paddingTop: '8rem',
      paddingBottom: '8rem'
    },
    '.pv7': {
      paddingTop: '16rem',
      paddingBottom: '16rem'
    },
    '.ph0': {
      paddingLeft: '0',
      paddingRight: '0'
    },
    '.ph1': {
      paddingLeft: '.25rem',
      paddingRight: '.25rem'
    },
    '.ph2': {
      paddingLeft: '.5rem',
      paddingRight: '.5rem'
    },
    '.ph3': {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    },
    '.ph4': {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    },
    '.ph5': {
      paddingLeft: '4rem',
      paddingRight: '4rem'
    },
    '.ph6': {
      paddingLeft: '8rem',
      paddingRight: '8rem'
    },
    '.ph7': {
      paddingLeft: '16rem',
      paddingRight: '16rem'
    },
    '.ma0': {
      margin: '0'
    },
    '.ma1': {
      margin: '.25rem'
    },
    '.ma2': {
      margin: '.5rem'
    },
    '.ma3': {
      margin: '1rem'
    },
    '.ma4': {
      margin: '2rem'
    },
    '.ma5': {
      margin: '4rem'
    },
    '.ma6': {
      margin: '8rem'
    },
    '.ma7': {
      margin: '16rem'
    },
    '.ml0': {
      marginLeft: '0'
    },
    '.ml1': {
      marginLeft: '.25rem'
    },
    '.ml2': {
      marginLeft: '.5rem'
    },
    '.ml3': {
      marginLeft: '1rem'
    },
    '.ml4': {
      marginLeft: '2rem'
    },
    '.ml5': {
      marginLeft: '4rem'
    },
    '.ml6': {
      marginLeft: '8rem'
    },
    '.ml7': {
      marginLeft: '16rem'
    },
    '.mr0': {
      marginRight: '0'
    },
    '.mr1': {
      marginRight: '.25rem'
    },
    '.mr2': {
      marginRight: '.5rem'
    },
    '.mr3': {
      marginRight: '1rem'
    },
    '.mr4': {
      marginRight: '2rem'
    },
    '.mr5': {
      marginRight: '4rem'
    },
    '.mr6': {
      marginRight: '8rem'
    },
    '.mr7': {
      marginRight: '16rem'
    },
    '.mb0': {
      marginBottom: '0'
    },
    '.mb1': {
      marginBottom: '.25rem'
    },
    '.mb2': {
      marginBottom: '.5rem'
    },
    '.mb3': {
      marginBottom: '1rem'
    },
    '.mb4': {
      marginBottom: '2rem'
    },
    '.mb5': {
      marginBottom: '4rem'
    },
    '.mb6': {
      marginBottom: '8rem'
    },
    '.mb7': {
      marginBottom: '16rem'
    },
    '.mt0': {
      marginTop: '0'
    },
    '.mt1': {
      marginTop: '.25rem'
    },
    '.mt2': {
      marginTop: '.5rem'
    },
    '.mt3': {
      marginTop: '1rem'
    },
    '.mt4': {
      marginTop: '2rem'
    },
    '.mt5': {
      marginTop: '4rem'
    },
    '.mt6': {
      marginTop: '8rem'
    },
    '.mt7': {
      marginTop: '16rem'
    },
    '.mv0': {
      marginTop: '0',
      marginBottom: '0'
    },
    '.mv1': {
      marginTop: '.25rem',
      marginBottom: '.25rem'
    },
    '.mv2': {
      marginTop: '.5rem',
      marginBottom: '.5rem'
    },
    '.mv3': {
      marginTop: '1rem',
      marginBottom: '1rem'
    },
    '.mv4': {
      marginTop: '2rem',
      marginBottom: '2rem'
    },
    '.mv5': {
      marginTop: '4rem',
      marginBottom: '4rem'
    },
    '.mv6': {
      marginTop: '8rem',
      marginBottom: '8rem'
    },
    '.mv7': {
      marginTop: '16rem',
      marginBottom: '16rem'
    },
    '.mh0': {
      marginLeft: '0',
      marginRight: '0'
    },
    '.mh1': {
      marginLeft: '.25rem',
      marginRight: '.25rem'
    },
    '.mh2': {
      marginLeft: '.5rem',
      marginRight: '.5rem'
    },
    '.mh3': {
      marginLeft: '1rem',
      marginRight: '1rem'
    },
    '.mh4': {
      marginLeft: '2rem',
      marginRight: '2rem'
    },
    '.mh5': {
      marginLeft: '4rem',
      marginRight: '4rem'
    },
    '.mh6': {
      marginLeft: '8rem',
      marginRight: '8rem'
    },
    '.mh7': {
      marginLeft: '16rem',
      marginRight: '16rem'
    },
    '.na1': {
      margin: '-0.25rem'
    },
    '.na2': {
      margin: '-0.5rem'
    },
    '.na3': {
      margin: '-1rem'
    },
    '.na4': {
      margin: '-2rem'
    },
    '.na5': {
      margin: '-4rem'
    },
    '.na6': {
      margin: '-8rem'
    },
    '.na7': {
      margin: '-16rem'
    },
    '.nl1': {
      marginLeft: '-0.25rem'
    },
    '.nl2': {
      marginLeft: '-0.5rem'
    },
    '.nl3': {
      marginLeft: '-1rem'
    },
    '.nl4': {
      marginLeft: '-2rem'
    },
    '.nl5': {
      marginLeft: '-4rem'
    },
    '.nl6': {
      marginLeft: '-8rem'
    },
    '.nl7': {
      marginLeft: '-16rem'
    },
    '.nr1': {
      marginRight: '-0.25rem'
    },
    '.nr2': {
      marginRight: '-0.5rem'
    },
    '.nr3': {
      marginRight: '-1rem'
    },
    '.nr4': {
      marginRight: '-2rem'
    },
    '.nr5': {
      marginRight: '-4rem'
    },
    '.nr6': {
      marginRight: '-8rem'
    },
    '.nr7': {
      marginRight: '-16rem'
    },
    '.nb1': {
      marginBottom: '-0.25rem'
    },
    '.nb2': {
      marginBottom: '-0.5rem'
    },
    '.nb3': {
      marginBottom: '-1rem'
    },
    '.nb4': {
      marginBottom: '-2rem'
    },
    '.nb5': {
      marginBottom: '-4rem'
    },
    '.nb6': {
      marginBottom: '-8rem'
    },
    '.nb7': {
      marginBottom: '-16rem'
    },
    '.nt1': {
      marginTop: '-0.25rem'
    },
    '.nt2': {
      marginTop: '-0.5rem'
    },
    '.nt3': {
      marginTop: '-1rem'
    },
    '.nt4': {
      marginTop: '-2rem'
    },
    '.nt5': {
      marginTop: '-4rem'
    },
    '.nt6': {
      marginTop: '-8rem'
    },
    '.nt7': {
      marginTop: '-16rem'
    },
    '.collapse': {
      borderCollapse: 'collapse',
      borderSpacing: '0'
    },
    '.striped--light-silver:nth-child(odd)': {
      backgroundColor: '#aaa'
    },
    '.striped--moon-gray:nth-child(odd)': {
      backgroundColor: '#ccc'
    },
    '.striped--light-gray:nth-child(odd)': {
      backgroundColor: '#eee'
    },
    '.striped--near-white:nth-child(odd)': {
      backgroundColor: '#f4f4f4'
    },
    '.stripe-light:nth-child(odd)': {
      backgroundColor: 'rgba( 255, 255, 255, .1 )'
    },
    '.stripe-dark:nth-child(odd)': {
      backgroundColor: 'rgba( 0, 0, 0, .1 )'
    },
    '.strike': {
      textDecoration: 'line-through'
    },
    '.underline': {
      textDecoration: 'underline'
    },
    '.no-underline': {
      textDecoration: 'none'
    },
    '.tl': {
      textAlign: 'left'
    },
    '.tr': {
      textAlign: 'right'
    },
    '.tc': {
      textAlign: 'center'
    },
    '.tj': {
      textAlign: 'justify'
    },
    '.ttc': {
      textTransform: 'capitalize'
    },
    '.ttl': {
      textTransform: 'lowercase'
    },
    '.ttu': {
      textTransform: 'uppercase'
    },
    '.ttn': {
      textTransform: 'none'
    },
    '.f-6, .f-headline': {
      fontSize: '6rem'
    },
    '.f-5, .f-subheadline': {
      fontSize: '5rem'
    },
    '.f1': {
      fontSize: '3rem'
    },
    '.f2': {
      fontSize: '2.25rem'
    },
    '.f3': {
      fontSize: '1.5rem'
    },
    '.f4': {
      fontSize: '1.25rem'
    },
    '.f4-5': {
      fontSize: '1.125rem'
    },
    '.f5': {
      fontSize: '1rem'
    },
    '.f6': {
      fontSize: '.875rem'
    },
    '.f7': {
      fontSize: '.75rem'
    },
    '.measure': {
      maxWidth: '30em'
    },
    '.measure-wide': {
      maxWidth: '34em'
    },
    '.measure-narrow': {
      maxWidth: '20em'
    },
    '.indent': {
      textIndent: '1em',
      marginTop: '0',
      marginBottom: '0'
    },
    '.small-caps': {
      fontVariant: 'small-caps'
    },
    '.truncate': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.overflow-container': {
      overflowY: 'scroll'
    },
    '.center': {
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    '.mr-auto': {
      marginRight: 'auto'
    },
    '.ml-auto': {
      marginLeft: 'auto'
    },
    '.clip': {
      position: 'absolute !important',
      fallbacks: [
        {
          clip: 'rect( 1px 1px 1px 1px )'
        },
        {
          position: 'fixed !important'
        }
      ],
      clip: 'rect( 1px, 1px, 1px, 1px )'
    },
    '.ws-normal': {
      whiteSpace: 'normal'
    },
    '.nowrap': {
      whiteSpace: 'nowrap'
    },
    '.v-base': {
      verticalAlign: 'baseline'
    },
    '.v-mid': {
      verticalAlign: 'middle'
    },
    '.v-top': {
      verticalAlign: 'top'
    },
    '.v-btm': {
      verticalAlign: 'bottom'
    },
    '.dim': {
      opacity: '1',
      transition: 'opacity .15s ease-in'
    },
    '.dim:hover, .dim:focus': {
      opacity: '.5',
      transition: 'opacity .15s ease-in'
    },
    '.dim:active': {
      opacity: '.8',
      transition: 'opacity .15s ease-out'
    },
    '.glow': {
      transition: 'opacity .15s ease-in'
    },
    '.glow:hover, .glow:focus': {
      opacity: '1',
      transition: 'opacity .15s ease-in'
    },
    '.hide-child .child': {
      opacity: '0',
      transition: 'opacity .15s ease-in'
    },
    '.hide-child:hover  .child, .hide-child:focus  .child, .hide-child:active .child': {
      opacity: '1',
      transition: 'opacity .15s ease-in'
    },
    '.underline-hover:hover, .underline-hover:focus': {
      textDecoration: 'underline'
    },
    '.pointer:hover': {
      cursor: 'pointer'
    },
    '.bg-animate, .bg-animate:hover, .bg-animate:focus': {
      transition: 'background-color .15s ease-in-out'
    },
    '.z-0': {
      zIndex: '0'
    },
    '.z-1': {
      zIndex: '1'
    },
    '.z-2': {
      zIndex: '2'
    },
    '.z-3': {
      zIndex: '3'
    },
    '.z-4': {
      zIndex: '4'
    },
    '.z-5': {
      zIndex: '5'
    },
    '.z-999': {
      zIndex: '999'
    },
    '.z-9999': {
      zIndex: '9999'
    },
    '.z-max': {
      zIndex: '2147483647'
    },
    '.z-inherit': {
      zIndex: 'inherit'
    },
    '.z-initial': {
      zIndex: 'initial'
    },
    '.z-unset': {
      zIndex: 'unset'
    },
    '.nested-copy-line-height p, .nested-copy-line-height ul, .nested-copy-line-height ol': {
      lineHeight: '1.5'
    },
    '.nested-headline-line-height h1, .nested-headline-line-height h2, .nested-headline-line-height h3, .nested-headline-line-height h4, .nested-headline-line-height h5, .nested-headline-line-height h6': {
      lineHeight: '1.25'
    },
    '.nested-list-reset ul, .nested-list-reset ol': {
      paddingLeft: '0',
      marginLeft: '0',
      listStyleType: 'none'
    },
    '.nested-copy-indent p+p': {
      textIndent: '1em',
      marginTop: '0',
      marginBottom: '0'
    },
    '.nested-copy-separator p+p': {
      marginTop: '1.5em'
    },
    '.nested-img img': {
      width: '100%',
      maxWidth: '100%',
      display: 'block'
    },
    '.nested-links a': {
      color: '#357edd',
      transition: 'color .15s ease-in'
    },
    '.nested-links a:hover': {
      color: '#96ccff',
      transition: 'color .15s ease-in'
    },
    '.nested-links a:focus': {
      color: '#96ccff',
      transition: 'color .15s ease-in'
    },
    '.debug *': {
      outline: '1px solid gold'
    },
    '.debug-white *': {
      outline: '1px solid white'
    },
    '.debug-black *': {
      outline: '1px solid black'
    },
    '.debug-grid': {
      background:
        'transparent url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAFElEQVR4AWPAC97/9x0eCsAEPgwAVLshdpENIxcAAAAASUVORK5CYII= ) repeat top left'
    },
    '.debug-grid-16': {
      background:
        'transparent url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMklEQVR4AWOgCLz/b0epAa6UGuBOqQHOQHLUgFEDnAbcBZ4UGwDOkiCnkIhdgNgNxAYAiYlD+8sEuo8AAAAASUVORK5CYII= ) repeat top left'
    },
    '.debug-grid-8-solid': {
      background:
        'white url( data:image/gif;base64,R0lGODdhCAAIAPEAAADw/wDx/////wAAACwAAAAACAAIAAACDZQvgaeb/lxbAIKA8y0AOw== ) repeat top left'
    },
    '.debug-grid-16-solid': {
      background:
        'white url( data:image/gif;base64,R0lGODdhEAAQAPEAAADw/wDx/xXy/////ywAAAAAEAAQAAACIZyPKckYDQFsb6ZqD85jZ2+BkwiRFKehhqQCQgDHcgwEBQA7 ) repeat top left'
    },
    '@media screen and (min-width: 30em)': {
      '.aspect-ratio-ns': {
        height: '0',
        position: 'relative'
      },
      '.aspect-ratio--16x9-ns': {
        paddingBottom: '56.25%'
      },
      '.aspect-ratio--9x16-ns': {
        paddingBottom: '177.77%'
      },
      '.aspect-ratio--4x3-ns': {
        paddingBottom: '75%'
      },
      '.aspect-ratio--3x4-ns': {
        paddingBottom: '133.33%'
      },
      '.aspect-ratio--6x4-ns': {
        paddingBottom: '66.6%'
      },
      '.aspect-ratio--4x6-ns': {
        paddingBottom: '150%'
      },
      '.aspect-ratio--8x5-ns': {
        paddingBottom: '62.5%'
      },
      '.aspect-ratio--5x8-ns': {
        paddingBottom: '160%'
      },
      '.aspect-ratio--7x5-ns': {
        paddingBottom: '71.42%'
      },
      '.aspect-ratio--5x7-ns': {
        paddingBottom: '140%'
      },
      '.aspect-ratio--1x1-ns': {
        paddingBottom: '100%'
      },
      '.aspect-ratio--object-ns': {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '100'
      },
      '.cover-ns': {
        backgroundSize: 'cover !important'
      },
      '.contain-ns': {
        backgroundSize: 'contain !important'
      },
      '.bg-center-ns': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      },
      '.bg-top-ns': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center'
      },
      '.bg-right-ns': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right'
      },
      '.bg-bottom-ns': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center'
      },
      '.bg-left-ns': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left'
      },
      '.outline-ns': {
        outline: '1px solid'
      },
      '.outline-transparent-ns': {
        outline: '1px solid transparent'
      },
      '.outline-0-ns': {
        outline: '0'
      },
      '.ba-ns': {
        borderStyle: 'solid',
        borderWidth: 1
      },
      '.bt-ns': {
        borderTopStyle: 'solid',
        borderTopWidth: 1
      },
      '.br-ns': {
        borderRightStyle: 'solid',
        borderRightWidth: 1
      },
      '.bb-ns': {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1
      },
      '.bl-ns': {
        borderLeftStyle: 'solid',
        borderLeftWidth: 1
      },
      '.bn-ns': {
        borderStyle: 'none',
        borderWidth: '0'
      },
      '.br0-ns': {
        borderRadius: '0'
      },
      '.br1-ns': {
        borderRadius: '.125rem'
      },
      '.br2-ns': {
        borderRadius: '.25rem'
      },
      '.br3-ns': {
        borderRadius: '.5rem'
      },
      '.br4-ns': {
        borderRadius: '1rem'
      },
      '.br-100-ns': {
        borderRadius: '100%'
      },
      '.br-pill-ns': {
        borderRadius: 9999
      },
      '.br--bottom-ns': {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0'
      },
      '.br--top-ns': {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0'
      },
      '.br--right-ns': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0'
      },
      '.br--left-ns': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0'
      },
      '.b--dotted-ns': {
        borderStyle: 'dotted'
      },
      '.b--dashed-ns': {
        borderStyle: 'dashed'
      },
      '.b--solid-ns': {
        borderStyle: 'solid'
      },
      '.b--none-ns': {
        borderStyle: 'none'
      },
      '.bw0-ns': {
        borderWidth: '0'
      },
      '.bw1-ns': {
        borderWidth: '.125rem'
      },
      '.bw2-ns': {
        borderWidth: '.25rem'
      },
      '.bw3-ns': {
        borderWidth: '.5rem'
      },
      '.bw4-ns': {
        borderWidth: '1rem'
      },
      '.bw5-ns': {
        borderWidth: '2rem'
      },
      '.bt-0-ns': {
        borderTopWidth: '0'
      },
      '.br-0-ns': {
        borderRightWidth: '0'
      },
      '.bb-0-ns': {
        borderBottomWidth: '0'
      },
      '.bl-0-ns': {
        borderLeftWidth: '0'
      },
      '.top-0-ns': {
        top: '0'
      },
      '.left-0-ns': {
        left: '0'
      },
      '.right-0-ns': {
        right: '0'
      },
      '.bottom-0-ns': {
        bottom: '0'
      },
      '.top-1-ns': {
        top: '1rem'
      },
      '.left-1-ns': {
        left: '1rem'
      },
      '.right-1-ns': {
        right: '1rem'
      },
      '.bottom-1-ns': {
        bottom: '1rem'
      },
      '.top-2-ns': {
        top: '2rem'
      },
      '.left-2-ns': {
        left: '2rem'
      },
      '.right-2-ns': {
        right: '2rem'
      },
      '.bottom-2-ns': {
        bottom: '2rem'
      },
      '.top--1-ns': {
        top: '-1rem'
      },
      '.right--1-ns': {
        right: '-1rem'
      },
      '.bottom--1-ns': {
        bottom: '-1rem'
      },
      '.left--1-ns': {
        left: '-1rem'
      },
      '.top--2-ns': {
        top: '-2rem'
      },
      '.right--2-ns': {
        right: '-2rem'
      },
      '.bottom--2-ns': {
        bottom: '-2rem'
      },
      '.left--2-ns': {
        left: '-2rem'
      },
      '.absolute--fill-ns': {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      },
      '.cl-ns': {
        clear: 'left'
      },
      '.cr-ns': {
        clear: 'right'
      },
      '.cb-ns': {
        clear: 'both'
      },
      '.cn-ns': {
        clear: 'none'
      },
      '.dn-ns': {
        display: 'none'
      },
      '.di-ns': {
        display: 'inline'
      },
      '.db-ns': {
        display: 'block'
      },
      '.dib-ns': {
        display: 'inline-block'
      },
      '.dit-ns': {
        display: 'inline-table'
      },
      '.dt-ns': {
        display: 'table'
      },
      '.dtc-ns': {
        display: 'table-cell'
      },
      '.dt-row-ns': {
        display: 'table-row'
      },
      '.dt-row-group-ns': {
        display: 'table-row-group'
      },
      '.dt-column-ns': {
        display: 'table-column'
      },
      '.dt-column-group-ns': {
        display: 'table-column-group'
      },
      '.dt--fixed-ns': {
        tableLayout: 'fixed',
        width: '100%'
      },
      '.flex-ns': {
        display: 'flex'
      },
      '.inline-flex-ns': {
        display: 'inline-flex'
      },
      '.flex-auto-ns': {
        flex: '1 1 auto',
        minWidth: '0',
        minHeight: '0'
      },
      '.flex-none-ns': {
        flex: 'none'
      },
      '.flex-column-ns': {
        flexDirection: 'column'
      },
      '.flex-row-ns': {
        flexDirection: 'row'
      },
      '.flex-wrap-ns': {
        flexWrap: 'wrap'
      },
      '.flex-nowrap-ns': {
        flexWrap: 'nowrap'
      },
      '.flex-wrap-reverse-ns': {
        flexWrap: 'wrap-reverse'
      },
      '.flex-column-reverse-ns': {
        flexDirection: 'column-reverse'
      },
      '.flex-row-reverse-ns': {
        flexDirection: 'row-reverse'
      },
      '.items-start-ns': {
        alignItems: 'flex-start'
      },
      '.items-end-ns': {
        alignItems: 'flex-end'
      },
      '.items-center-ns': {
        alignItems: 'center'
      },
      '.items-baseline-ns': {
        alignItems: 'baseline'
      },
      '.items-stretch-ns': {
        alignItems: 'stretch'
      },
      '.self-start-ns': {
        alignSelf: 'flex-start'
      },
      '.self-end-ns': {
        alignSelf: 'flex-end'
      },
      '.self-center-ns': {
        alignSelf: 'center'
      },
      '.self-baseline-ns': {
        alignSelf: 'baseline'
      },
      '.self-stretch-ns': {
        alignSelf: 'stretch'
      },
      '.justify-start-ns': {
        justifyContent: 'flex-start'
      },
      '.justify-end-ns': {
        justifyContent: 'flex-end'
      },
      '.justify-center-ns': {
        justifyContent: 'center'
      },
      '.justify-between-ns': {
        justifyContent: 'space-between'
      },
      '.justify-around-ns': {
        justifyContent: 'space-around'
      },
      '.content-start-ns': {
        alignContent: 'flex-start'
      },
      '.content-end-ns': {
        alignContent: 'flex-end'
      },
      '.content-center-ns': {
        alignContent: 'center'
      },
      '.content-between-ns': {
        alignContent: 'space-between'
      },
      '.content-around-ns': {
        alignContent: 'space-around'
      },
      '.content-stretch-ns': {
        alignContent: 'stretch'
      },
      '.order-0-ns': {
        order: '0'
      },
      '.order-1-ns': {
        order: '1'
      },
      '.order-2-ns': {
        order: '2'
      },
      '.order-3-ns': {
        order: '3'
      },
      '.order-4-ns': {
        order: '4'
      },
      '.order-5-ns': {
        order: '5'
      },
      '.order-6-ns': {
        order: '6'
      },
      '.order-7-ns': {
        order: '7'
      },
      '.order-8-ns': {
        order: '8'
      },
      '.order-last-ns': {
        order: '99999'
      },
      '.flex-grow-0-ns': {
        flexGrow: '0'
      },
      '.flex-grow-1-ns': {
        flexGrow: '1'
      },
      '.flex-shrink-0-ns': {
        flexShrink: '0'
      },
      '.flex-shrink-1-ns': {
        flexShrink: '1'
      },
      '.fl-ns': {
        float: 'left',
        display: 'inline'
      },
      '.fr-ns': {
        float: 'right',
        display: 'inline'
      },
      '.fn-ns': {
        float: 'none'
      },
      '.i-ns': {
        fontStyle: 'italic'
      },
      '.fs-normal-ns': {
        fontStyle: 'normal'
      },
      '.normal-ns': {
        fontWeight: 'normal'
      },
      '.b-ns': {
        fontWeight: 'bold'
      },
      '.fw1-ns': {
        fontWeight: '100'
      },
      '.fw2-ns': {
        fontWeight: '200'
      },
      '.fw3-ns': {
        fontWeight: '300'
      },
      '.fw4-ns': {
        fontWeight: '400'
      },
      '.fw5-ns': {
        fontWeight: '500'
      },
      '.fw6-ns': {
        fontWeight: '600'
      },
      '.fw7-ns': {
        fontWeight: '700'
      },
      '.fw8-ns': {
        fontWeight: '800'
      },
      '.fw9-ns': {
        fontWeight: '900'
      },
      '.h1-ns': {
        height: '1rem'
      },
      '.h2-ns': {
        height: '2rem'
      },
      '.h3-ns': {
        height: '4rem'
      },
      '.h4-ns': {
        height: '8rem'
      },
      '.h5-ns': {
        height: '16rem'
      },
      '.h-25-ns': {
        height: '25%'
      },
      '.h-50-ns': {
        height: '50%'
      },
      '.h-75-ns': {
        height: '75%'
      },
      '.h-100-ns': {
        height: '100%'
      },
      '.min-h-100-ns': {
        minHeight: '100%'
      },
      '.vh-25-ns': {
        height: '25vh'
      },
      '.vh-50-ns': {
        height: '50vh'
      },
      '.vh-75-ns': {
        height: '75vh'
      },
      '.vh-100-ns': {
        height: '100vh'
      },
      '.min-vh-100-ns': {
        minHeight: '100vh'
      },
      '.h-auto-ns': {
        height: 'auto'
      },
      '.h-inherit-ns': {
        height: 'inherit'
      },
      '.tracked-ns': {
        letterSpacing: '.1em'
      },
      '.tracked-tight-ns': {
        letterSpacing: '-.05em'
      },
      '.tracked-mega-ns': {
        letterSpacing: '.25em'
      },
      '.lh-solid-ns': {
        lineHeight: '1'
      },
      '.lh-title-ns': {
        lineHeight: '1.25'
      },
      '.lh-copy-ns': {
        lineHeight: '1.5'
      },
      '.mw-100-ns': {
        maxWidth: '100%'
      },
      '.mw1-ns': {
        maxWidth: '1rem'
      },
      '.mw2-ns': {
        maxWidth: '2rem'
      },
      '.mw3-ns': {
        maxWidth: '4rem'
      },
      '.mw4-ns': {
        maxWidth: '8rem'
      },
      '.mw5-ns': {
        maxWidth: '16rem'
      },
      '.mw6-ns': {
        maxWidth: '32rem'
      },
      '.mw7-ns': {
        maxWidth: '48rem'
      },
      '.mw8-ns': {
        maxWidth: '64rem'
      },
      '.mw9-ns': {
        maxWidth: '96rem'
      },
      '.mw-none-ns': {
        maxWidth: 'none'
      },
      '.w1-ns': {
        width: '1rem'
      },
      '.w2-ns': {
        width: '2rem'
      },
      '.w3-ns': {
        width: '4rem'
      },
      '.w4-ns': {
        width: '8rem'
      },
      '.w5-ns': {
        width: '16rem'
      },
      '.w-10-ns': {
        width: '10%'
      },
      '.w-20-ns': {
        width: '20%'
      },
      '.w-25-ns': {
        width: '25%'
      },
      '.w-30-ns': {
        width: '30%'
      },
      '.w-33-ns': {
        width: '33%'
      },
      '.w-34-ns': {
        width: '34%'
      },
      '.w-40-ns': {
        width: '40%'
      },
      '.w-50-ns': {
        width: '50%'
      },
      '.w-60-ns': {
        width: '60%'
      },
      '.w-70-ns': {
        width: '70%'
      },
      '.w-75-ns': {
        width: '75%'
      },
      '.w-80-ns': {
        width: '80%'
      },
      '.w-90-ns': {
        width: '90%'
      },
      '.w-100-ns': {
        width: '100%'
      },
      '.w-third-ns': {
        width: '33.33333%'
      },
      '.w-two-thirds-ns': {
        width: '66.66667%'
      },
      '.w-auto-ns': {
        width: 'auto'
      },
      '.overflow-visible-ns': {
        overflow: 'visible'
      },
      '.overflow-hidden-ns': {
        overflow: 'hidden'
      },
      '.overflow-scroll-ns': {
        overflow: 'scroll'
      },
      '.overflow-auto-ns': {
        overflow: 'auto'
      },
      '.overflow-x-visible-ns': {
        overflowX: 'visible'
      },
      '.overflow-x-hidden-ns': {
        overflowX: 'hidden'
      },
      '.overflow-x-scroll-ns': {
        overflowX: 'scroll'
      },
      '.overflow-x-auto-ns': {
        overflowX: 'auto'
      },
      '.overflow-y-visible-ns': {
        overflowY: 'visible'
      },
      '.overflow-y-hidden-ns': {
        overflowY: 'hidden'
      },
      '.overflow-y-scroll-ns': {
        overflowY: 'scroll'
      },
      '.overflow-y-auto-ns': {
        overflowY: 'auto'
      },
      '.static-ns': {
        position: 'static'
      },
      '.relative-ns': {
        position: 'relative'
      },
      '.absolute-ns': {
        position: 'absolute'
      },
      '.fixed-ns': {
        position: 'fixed'
      },
      '.rotate-45-ns': {
        '-webkit-transform': 'rotate( 45deg )',
        transform: 'rotate( 45deg )'
      },
      '.rotate-90-ns': {
        '-webkit-transform': 'rotate( 90deg )',
        transform: 'rotate( 90deg )'
      },
      '.rotate-135-ns': {
        '-webkit-transform': 'rotate( 135deg )',
        transform: 'rotate( 135deg )'
      },
      '.rotate-180-ns': {
        '-webkit-transform': 'rotate( 180deg )',
        transform: 'rotate( 180deg )'
      },
      '.rotate-225-ns': {
        '-webkit-transform': 'rotate( 225deg )',
        transform: 'rotate( 225deg )'
      },
      '.rotate-270-ns': {
        '-webkit-transform': 'rotate( 270deg )',
        transform: 'rotate( 270deg )'
      },
      '.rotate-315-ns': {
        '-webkit-transform': 'rotate( 315deg )',
        transform: 'rotate( 315deg )'
      },
      '.pa0-ns': {
        padding: '0'
      },
      '.pa1-ns': {
        padding: '.25rem'
      },
      '.pa2-ns': {
        padding: '.5rem'
      },
      '.pa3-ns': {
        padding: '1rem'
      },
      '.pa4-ns': {
        padding: '2rem'
      },
      '.pa5-ns': {
        padding: '4rem'
      },
      '.pa6-ns': {
        padding: '8rem'
      },
      '.pa7-ns': {
        padding: '16rem'
      },
      '.pl0-ns': {
        paddingLeft: '0'
      },
      '.pl1-ns': {
        paddingLeft: '.25rem'
      },
      '.pl2-ns': {
        paddingLeft: '.5rem'
      },
      '.pl3-ns': {
        paddingLeft: '1rem'
      },
      '.pl4-ns': {
        paddingLeft: '2rem'
      },
      '.pl5-ns': {
        paddingLeft: '4rem'
      },
      '.pl6-ns': {
        paddingLeft: '8rem'
      },
      '.pl7-ns': {
        paddingLeft: '16rem'
      },
      '.pr0-ns': {
        paddingRight: '0'
      },
      '.pr1-ns': {
        paddingRight: '.25rem'
      },
      '.pr2-ns': {
        paddingRight: '.5rem'
      },
      '.pr3-ns': {
        paddingRight: '1rem'
      },
      '.pr4-ns': {
        paddingRight: '2rem'
      },
      '.pr5-ns': {
        paddingRight: '4rem'
      },
      '.pr6-ns': {
        paddingRight: '8rem'
      },
      '.pr7-ns': {
        paddingRight: '16rem'
      },
      '.pb0-ns': {
        paddingBottom: '0'
      },
      '.pb1-ns': {
        paddingBottom: '.25rem'
      },
      '.pb2-ns': {
        paddingBottom: '.5rem'
      },
      '.pb3-ns': {
        paddingBottom: '1rem'
      },
      '.pb4-ns': {
        paddingBottom: '2rem'
      },
      '.pb5-ns': {
        paddingBottom: '4rem'
      },
      '.pb6-ns': {
        paddingBottom: '8rem'
      },
      '.pb7-ns': {
        paddingBottom: '16rem'
      },
      '.pt0-ns': {
        paddingTop: '0'
      },
      '.pt1-ns': {
        paddingTop: '.25rem'
      },
      '.pt2-ns': {
        paddingTop: '.5rem'
      },
      '.pt3-ns': {
        paddingTop: '1rem'
      },
      '.pt4-ns': {
        paddingTop: '2rem'
      },
      '.pt5-ns': {
        paddingTop: '4rem'
      },
      '.pt6-ns': {
        paddingTop: '8rem'
      },
      '.pt7-ns': {
        paddingTop: '16rem'
      },
      '.pv0-ns': {
        paddingTop: '0',
        paddingBottom: '0'
      },
      '.pv1-ns': {
        paddingTop: '.25rem',
        paddingBottom: '.25rem'
      },
      '.pv2-ns': {
        paddingTop: '.5rem',
        paddingBottom: '.5rem'
      },
      '.pv3-ns': {
        paddingTop: '1rem',
        paddingBottom: '1rem'
      },
      '.pv4-ns': {
        paddingTop: '2rem',
        paddingBottom: '2rem'
      },
      '.pv5-ns': {
        paddingTop: '4rem',
        paddingBottom: '4rem'
      },
      '.pv6-ns': {
        paddingTop: '8rem',
        paddingBottom: '8rem'
      },
      '.pv7-ns': {
        paddingTop: '16rem',
        paddingBottom: '16rem'
      },
      '.ph0-ns': {
        paddingLeft: '0',
        paddingRight: '0'
      },
      '.ph1-ns': {
        paddingLeft: '.25rem',
        paddingRight: '.25rem'
      },
      '.ph2-ns': {
        paddingLeft: '.5rem',
        paddingRight: '.5rem'
      },
      '.ph3-ns': {
        paddingLeft: '1rem',
        paddingRight: '1rem'
      },
      '.ph4-ns': {
        paddingLeft: '2rem',
        paddingRight: '2rem'
      },
      '.ph5-ns': {
        paddingLeft: '4rem',
        paddingRight: '4rem'
      },
      '.ph6-ns': {
        paddingLeft: '8rem',
        paddingRight: '8rem'
      },
      '.ph7-ns': {
        paddingLeft: '16rem',
        paddingRight: '16rem'
      },
      '.ma0-ns': {
        margin: '0'
      },
      '.ma1-ns': {
        margin: '.25rem'
      },
      '.ma2-ns': {
        margin: '.5rem'
      },
      '.ma3-ns': {
        margin: '1rem'
      },
      '.ma4-ns': {
        margin: '2rem'
      },
      '.ma5-ns': {
        margin: '4rem'
      },
      '.ma6-ns': {
        margin: '8rem'
      },
      '.ma7-ns': {
        margin: '16rem'
      },
      '.ml0-ns': {
        marginLeft: '0'
      },
      '.ml1-ns': {
        marginLeft: '.25rem'
      },
      '.ml2-ns': {
        marginLeft: '.5rem'
      },
      '.ml3-ns': {
        marginLeft: '1rem'
      },
      '.ml4-ns': {
        marginLeft: '2rem'
      },
      '.ml5-ns': {
        marginLeft: '4rem'
      },
      '.ml6-ns': {
        marginLeft: '8rem'
      },
      '.ml7-ns': {
        marginLeft: '16rem'
      },
      '.mr0-ns': {
        marginRight: '0'
      },
      '.mr1-ns': {
        marginRight: '.25rem'
      },
      '.mr2-ns': {
        marginRight: '.5rem'
      },
      '.mr3-ns': {
        marginRight: '1rem'
      },
      '.mr4-ns': {
        marginRight: '2rem'
      },
      '.mr5-ns': {
        marginRight: '4rem'
      },
      '.mr6-ns': {
        marginRight: '8rem'
      },
      '.mr7-ns': {
        marginRight: '16rem'
      },
      '.mb0-ns': {
        marginBottom: '0'
      },
      '.mb1-ns': {
        marginBottom: '.25rem'
      },
      '.mb2-ns': {
        marginBottom: '.5rem'
      },
      '.mb3-ns': {
        marginBottom: '1rem'
      },
      '.mb4-ns': {
        marginBottom: '2rem'
      },
      '.mb5-ns': {
        marginBottom: '4rem'
      },
      '.mb6-ns': {
        marginBottom: '8rem'
      },
      '.mb7-ns': {
        marginBottom: '16rem'
      },
      '.mt0-ns': {
        marginTop: '0'
      },
      '.mt1-ns': {
        marginTop: '.25rem'
      },
      '.mt2-ns': {
        marginTop: '.5rem'
      },
      '.mt3-ns': {
        marginTop: '1rem'
      },
      '.mt4-ns': {
        marginTop: '2rem'
      },
      '.mt5-ns': {
        marginTop: '4rem'
      },
      '.mt6-ns': {
        marginTop: '8rem'
      },
      '.mt7-ns': {
        marginTop: '16rem'
      },
      '.mv0-ns': {
        marginTop: '0',
        marginBottom: '0'
      },
      '.mv1-ns': {
        marginTop: '.25rem',
        marginBottom: '.25rem'
      },
      '.mv2-ns': {
        marginTop: '.5rem',
        marginBottom: '.5rem'
      },
      '.mv3-ns': {
        marginTop: '1rem',
        marginBottom: '1rem'
      },
      '.mv4-ns': {
        marginTop: '2rem',
        marginBottom: '2rem'
      },
      '.mv5-ns': {
        marginTop: '4rem',
        marginBottom: '4rem'
      },
      '.mv6-ns': {
        marginTop: '8rem',
        marginBottom: '8rem'
      },
      '.mv7-ns': {
        marginTop: '16rem',
        marginBottom: '16rem'
      },
      '.mh0-ns': {
        marginLeft: '0',
        marginRight: '0'
      },
      '.mh1-ns': {
        marginLeft: '.25rem',
        marginRight: '.25rem'
      },
      '.mh2-ns': {
        marginLeft: '.5rem',
        marginRight: '.5rem'
      },
      '.mh3-ns': {
        marginLeft: '1rem',
        marginRight: '1rem'
      },
      '.mh4-ns': {
        marginLeft: '2rem',
        marginRight: '2rem'
      },
      '.mh5-ns': {
        marginLeft: '4rem',
        marginRight: '4rem'
      },
      '.mh6-ns': {
        marginLeft: '8rem',
        marginRight: '8rem'
      },
      '.mh7-ns': {
        marginLeft: '16rem',
        marginRight: '16rem'
      },
      '.na1-ns': {
        margin: '-0.25rem'
      },
      '.na2-ns': {
        margin: '-0.5rem'
      },
      '.na3-ns': {
        margin: '-1rem'
      },
      '.na4-ns': {
        margin: '-2rem'
      },
      '.na5-ns': {
        margin: '-4rem'
      },
      '.na6-ns': {
        margin: '-8rem'
      },
      '.na7-ns': {
        margin: '-16rem'
      },
      '.nl1-ns': {
        marginLeft: '-0.25rem'
      },
      '.nl2-ns': {
        marginLeft: '-0.5rem'
      },
      '.nl3-ns': {
        marginLeft: '-1rem'
      },
      '.nl4-ns': {
        marginLeft: '-2rem'
      },
      '.nl5-ns': {
        marginLeft: '-4rem'
      },
      '.nl6-ns': {
        marginLeft: '-8rem'
      },
      '.nl7-ns': {
        marginLeft: '-16rem'
      },
      '.nr1-ns': {
        marginRight: '-0.25rem'
      },
      '.nr2-ns': {
        marginRight: '-0.5rem'
      },
      '.nr3-ns': {
        marginRight: '-1rem'
      },
      '.nr4-ns': {
        marginRight: '-2rem'
      },
      '.nr5-ns': {
        marginRight: '-4rem'
      },
      '.nr6-ns': {
        marginRight: '-8rem'
      },
      '.nr7-ns': {
        marginRight: '-16rem'
      },
      '.nb1-ns': {
        marginBottom: '-0.25rem'
      },
      '.nb2-ns': {
        marginBottom: '-0.5rem'
      },
      '.nb3-ns': {
        marginBottom: '-1rem'
      },
      '.nb4-ns': {
        marginBottom: '-2rem'
      },
      '.nb5-ns': {
        marginBottom: '-4rem'
      },
      '.nb6-ns': {
        marginBottom: '-8rem'
      },
      '.nb7-ns': {
        marginBottom: '-16rem'
      },
      '.nt1-ns': {
        marginTop: '-0.25rem'
      },
      '.nt2-ns': {
        marginTop: '-0.5rem'
      },
      '.nt3-ns': {
        marginTop: '-1rem'
      },
      '.nt4-ns': {
        marginTop: '-2rem'
      },
      '.nt5-ns': {
        marginTop: '-4rem'
      },
      '.nt6-ns': {
        marginTop: '-8rem'
      },
      '.nt7-ns': {
        marginTop: '-16rem'
      },
      '.strike-ns': {
        textDecoration: 'line-through'
      },
      '.underline-ns': {
        textDecoration: 'underline'
      },
      '.no-underline-ns': {
        textDecoration: 'none'
      },
      '.tl-ns': {
        textAlign: 'left'
      },
      '.tr-ns': {
        textAlign: 'right'
      },
      '.tc-ns': {
        textAlign: 'center'
      },
      '.tj-ns': {
        textAlign: 'justify'
      },
      '.ttc-ns': {
        textTransform: 'capitalize'
      },
      '.ttl-ns': {
        textTransform: 'lowercase'
      },
      '.ttu-ns': {
        textTransform: 'uppercase'
      },
      '.ttn-ns': {
        textTransform: 'none'
      },
      '.f-6-ns, .f-headline-ns': {
        fontSize: '6rem'
      },
      '.f-5-ns, .f-subheadline-ns': {
        fontSize: '5rem'
      },
      '.f1-ns': {
        fontSize: '3rem'
      },
      '.f2-ns': {
        fontSize: '2.25rem'
      },
      '.f3-ns': {
        fontSize: '1.5rem'
      },
      '.f4-ns': {
        fontSize: '1.25rem'
      },
      '.f5-ns': {
        fontSize: '1rem'
      },
      '.f6-ns': {
        fontSize: '.875rem'
      },
      '.f7-ns': {
        fontSize: '.75rem'
      },
      '.measure-ns': {
        maxWidth: '30em'
      },
      '.measure-wide-ns': {
        maxWidth: '34em'
      },
      '.measure-narrow-ns': {
        maxWidth: '20em'
      },
      '.indent-ns': {
        textIndent: '1em',
        marginTop: '0',
        marginBottom: '0'
      },
      '.small-caps-ns': {
        fontVariant: 'small-caps'
      },
      '.truncate-ns': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '.center-ns': {
        marginRight: 'auto',
        marginLeft: 'auto'
      },
      '.mr-auto-ns': {
        marginRight: 'auto'
      },
      '.ml-auto-ns': {
        marginLeft: 'auto'
      },
      '.clip-ns': {
        position: 'absolute !important',
        fallbacks: [
          {
            clip: 'rect( 1px 1px 1px 1px )'
          },
          {
            position: 'fixed !important'
          }
        ],
        clip: 'rect( 1px, 1px, 1px, 1px )'
      },
      '.ws-normal-ns': {
        whiteSpace: 'normal'
      },
      '.nowrap-ns': {
        whiteSpace: 'nowrap'
      },
      '.pre-ns': {
        whiteSpace: 'pre'
      },
      '.v-base-ns': {
        verticalAlign: 'baseline'
      },
      '.v-mid-ns': {
        verticalAlign: 'middle'
      },
      '.v-top-ns': {
        verticalAlign: 'top'
      },
      '.v-btm-ns': {
        verticalAlign: 'bottom'
      }
    },
    '@media screen and (min-width: 30em) and (max-width: 60em)': {
      '.aspect-ratio-m': {
        height: '0',
        position: 'relative'
      },
      '.aspect-ratio--16x9-m': {
        paddingBottom: '56.25%'
      },
      '.aspect-ratio--9x16-m': {
        paddingBottom: '177.77%'
      },
      '.aspect-ratio--4x3-m': {
        paddingBottom: '75%'
      },
      '.aspect-ratio--3x4-m': {
        paddingBottom: '133.33%'
      },
      '.aspect-ratio--6x4-m': {
        paddingBottom: '66.6%'
      },
      '.aspect-ratio--4x6-m': {
        paddingBottom: '150%'
      },
      '.aspect-ratio--8x5-m': {
        paddingBottom: '62.5%'
      },
      '.aspect-ratio--5x8-m': {
        paddingBottom: '160%'
      },
      '.aspect-ratio--7x5-m': {
        paddingBottom: '71.42%'
      },
      '.aspect-ratio--5x7-m': {
        paddingBottom: '140%'
      },
      '.aspect-ratio--1x1-m': {
        paddingBottom: '100%'
      },
      '.aspect-ratio--object-m': {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '100'
      },
      '.cover-m': {
        backgroundSize: 'cover !important'
      },
      '.contain-m': {
        backgroundSize: 'contain !important'
      },
      '.bg-center-m': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      },
      '.bg-top-m': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center'
      },
      '.bg-right-m': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right'
      },
      '.bg-bottom-m': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center'
      },
      '.bg-left-m': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left'
      },
      '.outline-m': {
        outline: '1px solid'
      },
      '.outline-transparent-m': {
        outline: '1px solid transparent'
      },
      '.outline-0-m': {
        outline: '0'
      },
      '.ba-m': {
        borderStyle: 'solid',
        borderWidth: 1
      },
      '.bt-m': {
        borderTopStyle: 'solid',
        borderTopWidth: 1
      },
      '.br-m': {
        borderRightStyle: 'solid',
        borderRightWidth: 1
      },
      '.bb-m': {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1
      },
      '.bl-m': {
        borderLeftStyle: 'solid',
        borderLeftWidth: 1
      },
      '.bn-m': {
        borderStyle: 'none',
        borderWidth: '0'
      },
      '.br0-m': {
        borderRadius: '0'
      },
      '.br1-m': {
        borderRadius: '.125rem'
      },
      '.br2-m': {
        borderRadius: '.25rem'
      },
      '.br3-m': {
        borderRadius: '.5rem'
      },
      '.br4-m': {
        borderRadius: '1rem'
      },
      '.br-100-m': {
        borderRadius: '100%'
      },
      '.br-pill-m': {
        borderRadius: 9999
      },
      '.br--bottom-m': {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0'
      },
      '.br--top-m': {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0'
      },
      '.br--right-m': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0'
      },
      '.br--left-m': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0'
      },
      '.b--dotted-m': {
        borderStyle: 'dotted'
      },
      '.b--dashed-m': {
        borderStyle: 'dashed'
      },
      '.b--solid-m': {
        borderStyle: 'solid'
      },
      '.b--none-m': {
        borderStyle: 'none'
      },
      '.bw0-m': {
        borderWidth: '0'
      },
      '.bw1-m': {
        borderWidth: '.125rem'
      },
      '.bw2-m': {
        borderWidth: '.25rem'
      },
      '.bw3-m': {
        borderWidth: '.5rem'
      },
      '.bw4-m': {
        borderWidth: '1rem'
      },
      '.bw5-m': {
        borderWidth: '2rem'
      },
      '.bt-0-m': {
        borderTopWidth: '0'
      },
      '.br-0-m': {
        borderRightWidth: '0'
      },
      '.bb-0-m': {
        borderBottomWidth: '0'
      },
      '.bl-0-m': {
        borderLeftWidth: '0'
      },
      '.top-0-m': {
        top: '0'
      },
      '.left-0-m': {
        left: '0'
      },
      '.right-0-m': {
        right: '0'
      },
      '.bottom-0-m': {
        bottom: '0'
      },
      '.top-1-m': {
        top: '1rem'
      },
      '.left-1-m': {
        left: '1rem'
      },
      '.right-1-m': {
        right: '1rem'
      },
      '.bottom-1-m': {
        bottom: '1rem'
      },
      '.top-2-m': {
        top: '2rem'
      },
      '.left-2-m': {
        left: '2rem'
      },
      '.right-2-m': {
        right: '2rem'
      },
      '.bottom-2-m': {
        bottom: '2rem'
      },
      '.top--1-m': {
        top: '-1rem'
      },
      '.right--1-m': {
        right: '-1rem'
      },
      '.bottom--1-m': {
        bottom: '-1rem'
      },
      '.left--1-m': {
        left: '-1rem'
      },
      '.top--2-m': {
        top: '-2rem'
      },
      '.right--2-m': {
        right: '-2rem'
      },
      '.bottom--2-m': {
        bottom: '-2rem'
      },
      '.left--2-m': {
        left: '-2rem'
      },
      '.absolute--fill-m': {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      },
      '.cl-m': {
        clear: 'left'
      },
      '.cr-m': {
        clear: 'right'
      },
      '.cb-m': {
        clear: 'both'
      },
      '.cn-m': {
        clear: 'none'
      },
      '.dn-m': {
        display: 'none'
      },
      '.di-m': {
        display: 'inline'
      },
      '.db-m': {
        display: 'block'
      },
      '.dib-m': {
        display: 'inline-block'
      },
      '.dit-m': {
        display: 'inline-table'
      },
      '.dt-m': {
        display: 'table'
      },
      '.dtc-m': {
        display: 'table-cell'
      },
      '.dt-row-m': {
        display: 'table-row'
      },
      '.dt-row-group-m': {
        display: 'table-row-group'
      },
      '.dt-column-m': {
        display: 'table-column'
      },
      '.dt-column-group-m': {
        display: 'table-column-group'
      },
      '.dt--fixed-m': {
        tableLayout: 'fixed',
        width: '100%'
      },
      '.flex-m': {
        display: 'flex'
      },
      '.inline-flex-m': {
        display: 'inline-flex'
      },
      '.flex-auto-m': {
        flex: '1 1 auto',
        minWidth: '0',
        minHeight: '0'
      },
      '.flex-none-m': {
        flex: 'none'
      },
      '.flex-column-m': {
        flexDirection: 'column'
      },
      '.flex-row-m': {
        flexDirection: 'row'
      },
      '.flex-wrap-m': {
        flexWrap: 'wrap'
      },
      '.flex-nowrap-m': {
        flexWrap: 'nowrap'
      },
      '.flex-wrap-reverse-m': {
        flexWrap: 'wrap-reverse'
      },
      '.flex-column-reverse-m': {
        flexDirection: 'column-reverse'
      },
      '.flex-row-reverse-m': {
        flexDirection: 'row-reverse'
      },
      '.items-start-m': {
        alignItems: 'flex-start'
      },
      '.items-end-m': {
        alignItems: 'flex-end'
      },
      '.items-center-m': {
        alignItems: 'center'
      },
      '.items-baseline-m': {
        alignItems: 'baseline'
      },
      '.items-stretch-m': {
        alignItems: 'stretch'
      },
      '.self-start-m': {
        alignSelf: 'flex-start'
      },
      '.self-end-m': {
        alignSelf: 'flex-end'
      },
      '.self-center-m': {
        alignSelf: 'center'
      },
      '.self-baseline-m': {
        alignSelf: 'baseline'
      },
      '.self-stretch-m': {
        alignSelf: 'stretch'
      },
      '.justify-start-m': {
        justifyContent: 'flex-start'
      },
      '.justify-end-m': {
        justifyContent: 'flex-end'
      },
      '.justify-center-m': {
        justifyContent: 'center'
      },
      '.justify-between-m': {
        justifyContent: 'space-between'
      },
      '.justify-around-m': {
        justifyContent: 'space-around'
      },
      '.content-start-m': {
        alignContent: 'flex-start'
      },
      '.content-end-m': {
        alignContent: 'flex-end'
      },
      '.content-center-m': {
        alignContent: 'center'
      },
      '.content-between-m': {
        alignContent: 'space-between'
      },
      '.content-around-m': {
        alignContent: 'space-around'
      },
      '.content-stretch-m': {
        alignContent: 'stretch'
      },
      '.order-0-m': {
        order: '0'
      },
      '.order-1-m': {
        order: '1'
      },
      '.order-2-m': {
        order: '2'
      },
      '.order-3-m': {
        order: '3'
      },
      '.order-4-m': {
        order: '4'
      },
      '.order-5-m': {
        order: '5'
      },
      '.order-6-m': {
        order: '6'
      },
      '.order-7-m': {
        order: '7'
      },
      '.order-8-m': {
        order: '8'
      },
      '.order-last-m': {
        order: '99999'
      },
      '.flex-grow-0-m': {
        flexGrow: '0'
      },
      '.flex-grow-1-m': {
        flexGrow: '1'
      },
      '.flex-shrink-0-m': {
        flexShrink: '0'
      },
      '.flex-shrink-1-m': {
        flexShrink: '1'
      },
      '.fl-m': {
        float: 'left',
        display: 'inline'
      },
      '.fr-m': {
        float: 'right',
        display: 'inline'
      },
      '.fn-m': {
        float: 'none'
      },
      '.i-m': {
        fontStyle: 'italic'
      },
      '.fs-normal-m': {
        fontStyle: 'normal'
      },
      '.normal-m': {
        fontWeight: 'normal'
      },
      '.b-m': {
        fontWeight: 'bold'
      },
      '.fw1-m': {
        fontWeight: '100'
      },
      '.fw2-m': {
        fontWeight: '200'
      },
      '.fw3-m': {
        fontWeight: '300'
      },
      '.fw4-m': {
        fontWeight: '400'
      },
      '.fw5-m': {
        fontWeight: '500'
      },
      '.fw6-m': {
        fontWeight: '600'
      },
      '.fw7-m': {
        fontWeight: '700'
      },
      '.fw8-m': {
        fontWeight: '800'
      },
      '.fw9-m': {
        fontWeight: '900'
      },
      '.h1-m': {
        height: '1rem'
      },
      '.h2-m': {
        height: '2rem'
      },
      '.h3-m': {
        height: '4rem'
      },
      '.h4-m': {
        height: '8rem'
      },
      '.h5-m': {
        height: '16rem'
      },
      '.h-25-m': {
        height: '25%'
      },
      '.h-50-m': {
        height: '50%'
      },
      '.h-75-m': {
        height: '75%'
      },
      '.h-100-m': {
        height: '100%'
      },
      '.min-h-100-m': {
        minHeight: '100%'
      },
      '.vh-25-m': {
        height: '25vh'
      },
      '.vh-50-m': {
        height: '50vh'
      },
      '.vh-75-m': {
        height: '75vh'
      },
      '.vh-100-m': {
        height: '100vh'
      },
      '.min-vh-100-m': {
        minHeight: '100vh'
      },
      '.h-auto-m': {
        height: 'auto'
      },
      '.h-inherit-m': {
        height: 'inherit'
      },
      '.tracked-m': {
        letterSpacing: '.1em'
      },
      '.tracked-tight-m': {
        letterSpacing: '-.05em'
      },
      '.tracked-mega-m': {
        letterSpacing: '.25em'
      },
      '.lh-solid-m': {
        lineHeight: '1'
      },
      '.lh-title-m': {
        lineHeight: '1.25'
      },
      '.lh-copy-m': {
        lineHeight: '1.5'
      },
      '.mw-100-m': {
        maxWidth: '100%'
      },
      '.mw1-m': {
        maxWidth: '1rem'
      },
      '.mw2-m': {
        maxWidth: '2rem'
      },
      '.mw3-m': {
        maxWidth: '4rem'
      },
      '.mw4-m': {
        maxWidth: '8rem'
      },
      '.mw5-m': {
        maxWidth: '16rem'
      },
      '.mw6-m': {
        maxWidth: '32rem'
      },
      '.mw7-m': {
        maxWidth: '48rem'
      },
      '.mw8-m': {
        maxWidth: '64rem'
      },
      '.mw9-m': {
        maxWidth: '96rem'
      },
      '.mw-none-m': {
        maxWidth: 'none'
      },
      '.w1-m': {
        width: '1rem'
      },
      '.w2-m': {
        width: '2rem'
      },
      '.w3-m': {
        width: '4rem'
      },
      '.w4-m': {
        width: '8rem'
      },
      '.w5-m': {
        width: '16rem'
      },
      '.w-10-m': {
        width: '10%'
      },
      '.w-20-m': {
        width: '20%'
      },
      '.w-25-m': {
        width: '25%'
      },
      '.w-30-m': {
        width: '30%'
      },
      '.w-33-m': {
        width: '33%'
      },
      '.w-34-m': {
        width: '34%'
      },
      '.w-40-m': {
        width: '40%'
      },
      '.w-50-m': {
        width: '50%'
      },
      '.w-60-m': {
        width: '60%'
      },
      '.w-70-m': {
        width: '70%'
      },
      '.w-75-m': {
        width: '75%'
      },
      '.w-80-m': {
        width: '80%'
      },
      '.w-90-m': {
        width: '90%'
      },
      '.w-100-m': {
        width: '100%'
      },
      '.w-third-m': {
        width: '33.33333%'
      },
      '.w-two-thirds-m': {
        width: '66.66667%'
      },
      '.w-auto-m': {
        width: 'auto'
      },
      '.overflow-visible-m': {
        overflow: 'visible'
      },
      '.overflow-hidden-m': {
        overflow: 'hidden'
      },
      '.overflow-scroll-m': {
        overflow: 'scroll'
      },
      '.overflow-auto-m': {
        overflow: 'auto'
      },
      '.overflow-x-visible-m': {
        overflowX: 'visible'
      },
      '.overflow-x-hidden-m': {
        overflowX: 'hidden'
      },
      '.overflow-x-scroll-m': {
        overflowX: 'scroll'
      },
      '.overflow-x-auto-m': {
        overflowX: 'auto'
      },
      '.overflow-y-visible-m': {
        overflowY: 'visible'
      },
      '.overflow-y-hidden-m': {
        overflowY: 'hidden'
      },
      '.overflow-y-scroll-m': {
        overflowY: 'scroll'
      },
      '.overflow-y-auto-m': {
        overflowY: 'auto'
      },
      '.static-m': {
        position: 'static'
      },
      '.relative-m': {
        position: 'relative'
      },
      '.absolute-m': {
        position: 'absolute'
      },
      '.fixed-m': {
        position: 'fixed'
      },
      '.rotate-45-m': {
        '-webkit-transform': 'rotate( 45deg )',
        transform: 'rotate( 45deg )'
      },
      '.rotate-90-m': {
        '-webkit-transform': 'rotate( 90deg )',
        transform: 'rotate( 90deg )'
      },
      '.rotate-135-m': {
        '-webkit-transform': 'rotate( 135deg )',
        transform: 'rotate( 135deg )'
      },
      '.rotate-180-m': {
        '-webkit-transform': 'rotate( 180deg )',
        transform: 'rotate( 180deg )'
      },
      '.rotate-225-m': {
        '-webkit-transform': 'rotate( 225deg )',
        transform: 'rotate( 225deg )'
      },
      '.rotate-270-m': {
        '-webkit-transform': 'rotate( 270deg )',
        transform: 'rotate( 270deg )'
      },
      '.rotate-315-m': {
        '-webkit-transform': 'rotate( 315deg )',
        transform: 'rotate( 315deg )'
      },
      '.pa0-m': {
        padding: '0'
      },
      '.pa1-m': {
        padding: '.25rem'
      },
      '.pa2-m': {
        padding: '.5rem'
      },
      '.pa3-m': {
        padding: '1rem'
      },
      '.pa4-m': {
        padding: '2rem'
      },
      '.pa5-m': {
        padding: '4rem'
      },
      '.pa6-m': {
        padding: '8rem'
      },
      '.pa7-m': {
        padding: '16rem'
      },
      '.pl0-m': {
        paddingLeft: '0'
      },
      '.pl1-m': {
        paddingLeft: '.25rem'
      },
      '.pl2-m': {
        paddingLeft: '.5rem'
      },
      '.pl3-m': {
        paddingLeft: '1rem'
      },
      '.pl4-m': {
        paddingLeft: '2rem'
      },
      '.pl5-m': {
        paddingLeft: '4rem'
      },
      '.pl6-m': {
        paddingLeft: '8rem'
      },
      '.pl7-m': {
        paddingLeft: '16rem'
      },
      '.pr0-m': {
        paddingRight: '0'
      },
      '.pr1-m': {
        paddingRight: '.25rem'
      },
      '.pr2-m': {
        paddingRight: '.5rem'
      },
      '.pr3-m': {
        paddingRight: '1rem'
      },
      '.pr4-m': {
        paddingRight: '2rem'
      },
      '.pr5-m': {
        paddingRight: '4rem'
      },
      '.pr6-m': {
        paddingRight: '8rem'
      },
      '.pr7-m': {
        paddingRight: '16rem'
      },
      '.pb0-m': {
        paddingBottom: '0'
      },
      '.pb1-m': {
        paddingBottom: '.25rem'
      },
      '.pb2-m': {
        paddingBottom: '.5rem'
      },
      '.pb3-m': {
        paddingBottom: '1rem'
      },
      '.pb4-m': {
        paddingBottom: '2rem'
      },
      '.pb5-m': {
        paddingBottom: '4rem'
      },
      '.pb6-m': {
        paddingBottom: '8rem'
      },
      '.pb7-m': {
        paddingBottom: '16rem'
      },
      '.pt0-m': {
        paddingTop: '0'
      },
      '.pt1-m': {
        paddingTop: '.25rem'
      },
      '.pt2-m': {
        paddingTop: '.5rem'
      },
      '.pt3-m': {
        paddingTop: '1rem'
      },
      '.pt4-m': {
        paddingTop: '2rem'
      },
      '.pt5-m': {
        paddingTop: '4rem'
      },
      '.pt6-m': {
        paddingTop: '8rem'
      },
      '.pt7-m': {
        paddingTop: '16rem'
      },
      '.pv0-m': {
        paddingTop: '0',
        paddingBottom: '0'
      },
      '.pv1-m': {
        paddingTop: '.25rem',
        paddingBottom: '.25rem'
      },
      '.pv2-m': {
        paddingTop: '.5rem',
        paddingBottom: '.5rem'
      },
      '.pv3-m': {
        paddingTop: '1rem',
        paddingBottom: '1rem'
      },
      '.pv4-m': {
        paddingTop: '2rem',
        paddingBottom: '2rem'
      },
      '.pv5-m': {
        paddingTop: '4rem',
        paddingBottom: '4rem'
      },
      '.pv6-m': {
        paddingTop: '8rem',
        paddingBottom: '8rem'
      },
      '.pv7-m': {
        paddingTop: '16rem',
        paddingBottom: '16rem'
      },
      '.ph0-m': {
        paddingLeft: '0',
        paddingRight: '0'
      },
      '.ph1-m': {
        paddingLeft: '.25rem',
        paddingRight: '.25rem'
      },
      '.ph2-m': {
        paddingLeft: '.5rem',
        paddingRight: '.5rem'
      },
      '.ph3-m': {
        paddingLeft: '1rem',
        paddingRight: '1rem'
      },
      '.ph4-m': {
        paddingLeft: '2rem',
        paddingRight: '2rem'
      },
      '.ph5-m': {
        paddingLeft: '4rem',
        paddingRight: '4rem'
      },
      '.ph6-m': {
        paddingLeft: '8rem',
        paddingRight: '8rem'
      },
      '.ph7-m': {
        paddingLeft: '16rem',
        paddingRight: '16rem'
      },
      '.ma0-m': {
        margin: '0'
      },
      '.ma1-m': {
        margin: '.25rem'
      },
      '.ma2-m': {
        margin: '.5rem'
      },
      '.ma3-m': {
        margin: '1rem'
      },
      '.ma4-m': {
        margin: '2rem'
      },
      '.ma5-m': {
        margin: '4rem'
      },
      '.ma6-m': {
        margin: '8rem'
      },
      '.ma7-m': {
        margin: '16rem'
      },
      '.ml0-m': {
        marginLeft: '0'
      },
      '.ml1-m': {
        marginLeft: '.25rem'
      },
      '.ml2-m': {
        marginLeft: '.5rem'
      },
      '.ml3-m': {
        marginLeft: '1rem'
      },
      '.ml4-m': {
        marginLeft: '2rem'
      },
      '.ml5-m': {
        marginLeft: '4rem'
      },
      '.ml6-m': {
        marginLeft: '8rem'
      },
      '.ml7-m': {
        marginLeft: '16rem'
      },
      '.mr0-m': {
        marginRight: '0'
      },
      '.mr1-m': {
        marginRight: '.25rem'
      },
      '.mr2-m': {
        marginRight: '.5rem'
      },
      '.mr3-m': {
        marginRight: '1rem'
      },
      '.mr4-m': {
        marginRight: '2rem'
      },
      '.mr5-m': {
        marginRight: '4rem'
      },
      '.mr6-m': {
        marginRight: '8rem'
      },
      '.mr7-m': {
        marginRight: '16rem'
      },
      '.mb0-m': {
        marginBottom: '0'
      },
      '.mb1-m': {
        marginBottom: '.25rem'
      },
      '.mb2-m': {
        marginBottom: '.5rem'
      },
      '.mb3-m': {
        marginBottom: '1rem'
      },
      '.mb4-m': {
        marginBottom: '2rem'
      },
      '.mb5-m': {
        marginBottom: '4rem'
      },
      '.mb6-m': {
        marginBottom: '8rem'
      },
      '.mb7-m': {
        marginBottom: '16rem'
      },
      '.mt0-m': {
        marginTop: '0'
      },
      '.mt1-m': {
        marginTop: '.25rem'
      },
      '.mt2-m': {
        marginTop: '.5rem'
      },
      '.mt3-m': {
        marginTop: '1rem'
      },
      '.mt4-m': {
        marginTop: '2rem'
      },
      '.mt5-m': {
        marginTop: '4rem'
      },
      '.mt6-m': {
        marginTop: '8rem'
      },
      '.mt7-m': {
        marginTop: '16rem'
      },
      '.mv0-m': {
        marginTop: '0',
        marginBottom: '0'
      },
      '.mv1-m': {
        marginTop: '.25rem',
        marginBottom: '.25rem'
      },
      '.mv2-m': {
        marginTop: '.5rem',
        marginBottom: '.5rem'
      },
      '.mv3-m': {
        marginTop: '1rem',
        marginBottom: '1rem'
      },
      '.mv4-m': {
        marginTop: '2rem',
        marginBottom: '2rem'
      },
      '.mv5-m': {
        marginTop: '4rem',
        marginBottom: '4rem'
      },
      '.mv6-m': {
        marginTop: '8rem',
        marginBottom: '8rem'
      },
      '.mv7-m': {
        marginTop: '16rem',
        marginBottom: '16rem'
      },
      '.mh0-m': {
        marginLeft: '0',
        marginRight: '0'
      },
      '.mh1-m': {
        marginLeft: '.25rem',
        marginRight: '.25rem'
      },
      '.mh2-m': {
        marginLeft: '.5rem',
        marginRight: '.5rem'
      },
      '.mh3-m': {
        marginLeft: '1rem',
        marginRight: '1rem'
      },
      '.mh4-m': {
        marginLeft: '2rem',
        marginRight: '2rem'
      },
      '.mh5-m': {
        marginLeft: '4rem',
        marginRight: '4rem'
      },
      '.mh6-m': {
        marginLeft: '8rem',
        marginRight: '8rem'
      },
      '.mh7-m': {
        marginLeft: '16rem',
        marginRight: '16rem'
      },
      '.na1-m': {
        margin: '-0.25rem'
      },
      '.na2-m': {
        margin: '-0.5rem'
      },
      '.na3-m': {
        margin: '-1rem'
      },
      '.na4-m': {
        margin: '-2rem'
      },
      '.na5-m': {
        margin: '-4rem'
      },
      '.na6-m': {
        margin: '-8rem'
      },
      '.na7-m': {
        margin: '-16rem'
      },
      '.nl1-m': {
        marginLeft: '-0.25rem'
      },
      '.nl2-m': {
        marginLeft: '-0.5rem'
      },
      '.nl3-m': {
        marginLeft: '-1rem'
      },
      '.nl4-m': {
        marginLeft: '-2rem'
      },
      '.nl5-m': {
        marginLeft: '-4rem'
      },
      '.nl6-m': {
        marginLeft: '-8rem'
      },
      '.nl7-m': {
        marginLeft: '-16rem'
      },
      '.nr1-m': {
        marginRight: '-0.25rem'
      },
      '.nr2-m': {
        marginRight: '-0.5rem'
      },
      '.nr3-m': {
        marginRight: '-1rem'
      },
      '.nr4-m': {
        marginRight: '-2rem'
      },
      '.nr5-m': {
        marginRight: '-4rem'
      },
      '.nr6-m': {
        marginRight: '-8rem'
      },
      '.nr7-m': {
        marginRight: '-16rem'
      },
      '.nb1-m': {
        marginBottom: '-0.25rem'
      },
      '.nb2-m': {
        marginBottom: '-0.5rem'
      },
      '.nb3-m': {
        marginBottom: '-1rem'
      },
      '.nb4-m': {
        marginBottom: '-2rem'
      },
      '.nb5-m': {
        marginBottom: '-4rem'
      },
      '.nb6-m': {
        marginBottom: '-8rem'
      },
      '.nb7-m': {
        marginBottom: '-16rem'
      },
      '.nt1-m': {
        marginTop: '-0.25rem'
      },
      '.nt2-m': {
        marginTop: '-0.5rem'
      },
      '.nt3-m': {
        marginTop: '-1rem'
      },
      '.nt4-m': {
        marginTop: '-2rem'
      },
      '.nt5-m': {
        marginTop: '-4rem'
      },
      '.nt6-m': {
        marginTop: '-8rem'
      },
      '.nt7-m': {
        marginTop: '-16rem'
      },
      '.strike-m': {
        textDecoration: 'line-through'
      },
      '.underline-m': {
        textDecoration: 'underline'
      },
      '.no-underline-m': {
        textDecoration: 'none'
      },
      '.tl-m': {
        textAlign: 'left'
      },
      '.tr-m': {
        textAlign: 'right'
      },
      '.tc-m': {
        textAlign: 'center'
      },
      '.tj-m': {
        textAlign: 'justify'
      },
      '.ttc-m': {
        textTransform: 'capitalize'
      },
      '.ttl-m': {
        textTransform: 'lowercase'
      },
      '.ttu-m': {
        textTransform: 'uppercase'
      },
      '.ttn-m': {
        textTransform: 'none'
      },
      '.f-6-m, .f-headline-m': {
        fontSize: '6rem'
      },
      '.f-5-m, .f-subheadline-m': {
        fontSize: '5rem'
      },
      '.f1-m': {
        fontSize: '3rem'
      },
      '.f2-m': {
        fontSize: '2.25rem'
      },
      '.f3-m': {
        fontSize: '1.5rem'
      },
      '.f4-m': {
        fontSize: '1.25rem'
      },
      '.f5-m': {
        fontSize: '1rem'
      },
      '.f6-m': {
        fontSize: '.875rem'
      },
      '.f7-m': {
        fontSize: '.75rem'
      },
      '.measure-m': {
        maxWidth: '30em'
      },
      '.measure-wide-m': {
        maxWidth: '34em'
      },
      '.measure-narrow-m': {
        maxWidth: '20em'
      },
      '.indent-m': {
        textIndent: '1em',
        marginTop: '0',
        marginBottom: '0'
      },
      '.small-caps-m': {
        fontVariant: 'small-caps'
      },
      '.truncate-m': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '.center-m': {
        marginRight: 'auto',
        marginLeft: 'auto'
      },
      '.mr-auto-m': {
        marginRight: 'auto'
      },
      '.ml-auto-m': {
        marginLeft: 'auto'
      },
      '.clip-m': {
        position: 'absolute !important',
        fallbacks: [
          {
            clip: 'rect( 1px 1px 1px 1px )'
          },
          {
            position: 'fixed !important'
          }
        ],
        clip: 'rect( 1px, 1px, 1px, 1px )'
      },
      '.ws-normal-m': {
        whiteSpace: 'normal'
      },
      '.nowrap-m': {
        whiteSpace: 'nowrap'
      },
      '.pre-m': {
        whiteSpace: 'pre'
      },
      '.v-base-m': {
        verticalAlign: 'baseline'
      },
      '.v-mid-m': {
        verticalAlign: 'middle'
      },
      '.v-top-m': {
        verticalAlign: 'top'
      },
      '.v-btm-m': {
        verticalAlign: 'bottom'
      }
    },
    '@media screen and (min-width: 60em)': {
      '.aspect-ratio-l': {
        height: '0',
        position: 'relative'
      },
      '.aspect-ratio--16x9-l': {
        paddingBottom: '56.25%'
      },
      '.aspect-ratio--9x16-l': {
        paddingBottom: '177.77%'
      },
      '.aspect-ratio--4x3-l': {
        paddingBottom: '75%'
      },
      '.aspect-ratio--3x4-l': {
        paddingBottom: '133.33%'
      },
      '.aspect-ratio--6x4-l': {
        paddingBottom: '66.6%'
      },
      '.aspect-ratio--4x6-l': {
        paddingBottom: '150%'
      },
      '.aspect-ratio--8x5-l': {
        paddingBottom: '62.5%'
      },
      '.aspect-ratio--5x8-l': {
        paddingBottom: '160%'
      },
      '.aspect-ratio--7x5-l': {
        paddingBottom: '71.42%'
      },
      '.aspect-ratio--5x7-l': {
        paddingBottom: '140%'
      },
      '.aspect-ratio--1x1-l': {
        paddingBottom: '100%'
      },
      '.aspect-ratio--object-l': {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '100'
      },
      '.cover-l': {
        backgroundSize: 'cover !important'
      },
      '.contain-l': {
        backgroundSize: 'contain !important'
      },
      '.bg-center-l': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      },
      '.bg-top-l': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center'
      },
      '.bg-right-l': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right'
      },
      '.bg-bottom-l': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center'
      },
      '.bg-left-l': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left'
      },
      '.outline-l': {
        outline: '1px solid'
      },
      '.outline-transparent-l': {
        outline: '1px solid transparent'
      },
      '.outline-0-l': {
        outline: '0'
      },
      '.ba-l': {
        borderStyle: 'solid',
        borderWidth: 1
      },
      '.bt-l': {
        borderTopStyle: 'solid',
        borderTopWidth: 1
      },
      '.br-l': {
        borderRightStyle: 'solid',
        borderRightWidth: 1
      },
      '.bb-l': {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1
      },
      '.bl-l': {
        borderLeftStyle: 'solid',
        borderLeftWidth: 1
      },
      '.bn-l': {
        borderStyle: 'none',
        borderWidth: '0'
      },
      '.br0-l': {
        borderRadius: '0'
      },
      '.br1-l': {
        borderRadius: '.125rem'
      },
      '.br2-l': {
        borderRadius: '.25rem'
      },
      '.br3-l': {
        borderRadius: '.5rem'
      },
      '.br4-l': {
        borderRadius: '1rem'
      },
      '.br-100-l': {
        borderRadius: '100%'
      },
      '.br-pill-l': {
        borderRadius: 9999
      },
      '.br--bottom-l': {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0'
      },
      '.br--top-l': {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0'
      },
      '.br--right-l': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0'
      },
      '.br--left-l': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0'
      },
      '.b--dotted-l': {
        borderStyle: 'dotted'
      },
      '.b--dashed-l': {
        borderStyle: 'dashed'
      },
      '.b--solid-l': {
        borderStyle: 'solid'
      },
      '.b--none-l': {
        borderStyle: 'none'
      },
      '.bw0-l': {
        borderWidth: '0'
      },
      '.bw1-l': {
        borderWidth: '.125rem'
      },
      '.bw2-l': {
        borderWidth: '.25rem'
      },
      '.bw3-l': {
        borderWidth: '.5rem'
      },
      '.bw4-l': {
        borderWidth: '1rem'
      },
      '.bw5-l': {
        borderWidth: '2rem'
      },
      '.bt-0-l': {
        borderTopWidth: '0'
      },
      '.br-0-l': {
        borderRightWidth: '0'
      },
      '.bb-0-l': {
        borderBottomWidth: '0'
      },
      '.bl-0-l': {
        borderLeftWidth: '0'
      },
      '.top-0-l': {
        top: '0'
      },
      '.left-0-l': {
        left: '0'
      },
      '.right-0-l': {
        right: '0'
      },
      '.bottom-0-l': {
        bottom: '0'
      },
      '.top-1-l': {
        top: '1rem'
      },
      '.left-1-l': {
        left: '1rem'
      },
      '.right-1-l': {
        right: '1rem'
      },
      '.bottom-1-l': {
        bottom: '1rem'
      },
      '.top-2-l': {
        top: '2rem'
      },
      '.left-2-l': {
        left: '2rem'
      },
      '.right-2-l': {
        right: '2rem'
      },
      '.bottom-2-l': {
        bottom: '2rem'
      },
      '.top--1-l': {
        top: '-1rem'
      },
      '.right--1-l': {
        right: '-1rem'
      },
      '.bottom--1-l': {
        bottom: '-1rem'
      },
      '.left--1-l': {
        left: '-1rem'
      },
      '.top--2-l': {
        top: '-2rem'
      },
      '.right--2-l': {
        right: '-2rem'
      },
      '.bottom--2-l': {
        bottom: '-2rem'
      },
      '.left--2-l': {
        left: '-2rem'
      },
      '.absolute--fill-l': {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      },
      '.cl-l': {
        clear: 'left'
      },
      '.cr-l': {
        clear: 'right'
      },
      '.cb-l': {
        clear: 'both'
      },
      '.cn-l': {
        clear: 'none'
      },
      '.dn-l': {
        display: 'none'
      },
      '.di-l': {
        display: 'inline'
      },
      '.db-l': {
        display: 'block'
      },
      '.dib-l': {
        display: 'inline-block'
      },
      '.dit-l': {
        display: 'inline-table'
      },
      '.dt-l': {
        display: 'table'
      },
      '.dtc-l': {
        display: 'table-cell'
      },
      '.dt-row-l': {
        display: 'table-row'
      },
      '.dt-row-group-l': {
        display: 'table-row-group'
      },
      '.dt-column-l': {
        display: 'table-column'
      },
      '.dt-column-group-l': {
        display: 'table-column-group'
      },
      '.dt--fixed-l': {
        tableLayout: 'fixed',
        width: '100%'
      },
      '.flex-l': {
        display: 'flex'
      },
      '.inline-flex-l': {
        display: 'inline-flex'
      },
      '.flex-auto-l': {
        flex: '1 1 auto',
        minWidth: '0',
        minHeight: '0'
      },
      '.flex-none-l': {
        flex: 'none'
      },
      '.flex-column-l': {
        flexDirection: 'column'
      },
      '.flex-row-l': {
        flexDirection: 'row'
      },
      '.flex-wrap-l': {
        flexWrap: 'wrap'
      },
      '.flex-nowrap-l': {
        flexWrap: 'nowrap'
      },
      '.flex-wrap-reverse-l': {
        flexWrap: 'wrap-reverse'
      },
      '.flex-column-reverse-l': {
        flexDirection: 'column-reverse'
      },
      '.flex-row-reverse-l': {
        flexDirection: 'row-reverse'
      },
      '.items-start-l': {
        alignItems: 'flex-start'
      },
      '.items-end-l': {
        alignItems: 'flex-end'
      },
      '.items-center-l': {
        alignItems: 'center'
      },
      '.items-baseline-l': {
        alignItems: 'baseline'
      },
      '.items-stretch-l': {
        alignItems: 'stretch'
      },
      '.self-start-l': {
        alignSelf: 'flex-start'
      },
      '.self-end-l': {
        alignSelf: 'flex-end'
      },
      '.self-center-l': {
        alignSelf: 'center'
      },
      '.self-baseline-l': {
        alignSelf: 'baseline'
      },
      '.self-stretch-l': {
        alignSelf: 'stretch'
      },
      '.justify-start-l': {
        justifyContent: 'flex-start'
      },
      '.justify-end-l': {
        justifyContent: 'flex-end'
      },
      '.justify-center-l': {
        justifyContent: 'center'
      },
      '.justify-between-l': {
        justifyContent: 'space-between'
      },
      '.justify-around-l': {
        justifyContent: 'space-around'
      },
      '.content-start-l': {
        alignContent: 'flex-start'
      },
      '.content-end-l': {
        alignContent: 'flex-end'
      },
      '.content-center-l': {
        alignContent: 'center'
      },
      '.content-between-l': {
        alignContent: 'space-between'
      },
      '.content-around-l': {
        alignContent: 'space-around'
      },
      '.content-stretch-l': {
        alignContent: 'stretch'
      },
      '.order-0-l': {
        order: '0'
      },
      '.order-1-l': {
        order: '1'
      },
      '.order-2-l': {
        order: '2'
      },
      '.order-3-l': {
        order: '3'
      },
      '.order-4-l': {
        order: '4'
      },
      '.order-5-l': {
        order: '5'
      },
      '.order-6-l': {
        order: '6'
      },
      '.order-7-l': {
        order: '7'
      },
      '.order-8-l': {
        order: '8'
      },
      '.order-last-l': {
        order: '99999'
      },
      '.flex-grow-0-l': {
        flexGrow: '0'
      },
      '.flex-grow-1-l': {
        flexGrow: '1'
      },
      '.flex-shrink-0-l': {
        flexShrink: '0'
      },
      '.flex-shrink-1-l': {
        flexShrink: '1'
      },
      '.fl-l': {
        float: 'left',
        display: 'inline'
      },
      '.fr-l': {
        float: 'right',
        display: 'inline'
      },
      '.fn-l': {
        float: 'none'
      },
      '.i-l': {
        fontStyle: 'italic'
      },
      '.fs-normal-l': {
        fontStyle: 'normal'
      },
      '.normal-l': {
        fontWeight: 'normal'
      },
      '.b-l': {
        fontWeight: 'bold'
      },
      '.fw1-l': {
        fontWeight: '100'
      },
      '.fw2-l': {
        fontWeight: '200'
      },
      '.fw3-l': {
        fontWeight: '300'
      },
      '.fw4-l': {
        fontWeight: '400'
      },
      '.fw5-l': {
        fontWeight: '500'
      },
      '.fw6-l': {
        fontWeight: '600'
      },
      '.fw7-l': {
        fontWeight: '700'
      },
      '.fw8-l': {
        fontWeight: '800'
      },
      '.fw9-l': {
        fontWeight: '900'
      },
      '.h1-l': {
        height: '1rem'
      },
      '.h2-l': {
        height: '2rem'
      },
      '.h3-l': {
        height: '4rem'
      },
      '.h4-l': {
        height: '8rem'
      },
      '.h5-l': {
        height: '16rem'
      },
      '.h-25-l': {
        height: '25%'
      },
      '.h-50-l': {
        height: '50%'
      },
      '.h-75-l': {
        height: '75%'
      },
      '.h-100-l': {
        height: '100%'
      },
      '.min-h-100-l': {
        minHeight: '100%'
      },
      '.vh-25-l': {
        height: '25vh'
      },
      '.vh-50-l': {
        height: '50vh'
      },
      '.vh-75-l': {
        height: '75vh'
      },
      '.vh-100-l': {
        height: '100vh'
      },
      '.min-vh-100-l': {
        minHeight: '100vh'
      },
      '.h-auto-l': {
        height: 'auto'
      },
      '.h-inherit-l': {
        height: 'inherit'
      },
      '.tracked-l': {
        letterSpacing: '.1em'
      },
      '.tracked-tight-l': {
        letterSpacing: '-.05em'
      },
      '.tracked-mega-l': {
        letterSpacing: '.25em'
      },
      '.lh-solid-l': {
        lineHeight: '1'
      },
      '.lh-title-l': {
        lineHeight: '1.25'
      },
      '.lh-copy-l': {
        lineHeight: '1.5'
      },
      '.mw-100-l': {
        maxWidth: '100%'
      },
      '.mw1-l': {
        maxWidth: '1rem'
      },
      '.mw2-l': {
        maxWidth: '2rem'
      },
      '.mw3-l': {
        maxWidth: '4rem'
      },
      '.mw4-l': {
        maxWidth: '8rem'
      },
      '.mw5-l': {
        maxWidth: '16rem'
      },
      '.mw6-l': {
        maxWidth: '32rem'
      },
      '.mw7-l': {
        maxWidth: '48rem'
      },
      '.mw8-l': {
        maxWidth: '64rem'
      },
      '.mw9-l': {
        maxWidth: '96rem'
      },
      '.mw-none-l': {
        maxWidth: 'none'
      },
      '.w1-l': {
        width: '1rem'
      },
      '.w2-l': {
        width: '2rem'
      },
      '.w3-l': {
        width: '4rem'
      },
      '.w4-l': {
        width: '8rem'
      },
      '.w5-l': {
        width: '16rem'
      },
      '.w-10-l': {
        width: '10%'
      },
      '.w-20-l': {
        width: '20%'
      },
      '.w-25-l': {
        width: '25%'
      },
      '.w-30-l': {
        width: '30%'
      },
      '.w-33-l': {
        width: '33%'
      },
      '.w-34-l': {
        width: '34%'
      },
      '.w-40-l': {
        width: '40%'
      },
      '.w-50-l': {
        width: '50%'
      },
      '.w-60-l': {
        width: '60%'
      },
      '.w-70-l': {
        width: '70%'
      },
      '.w-75-l': {
        width: '75%'
      },
      '.w-80-l': {
        width: '80%'
      },
      '.w-90-l': {
        width: '90%'
      },
      '.w-100-l': {
        width: '100%'
      },
      '.w-third-l': {
        width: '33.33333%'
      },
      '.w-two-thirds-l': {
        width: '66.66667%'
      },
      '.w-auto-l': {
        width: 'auto'
      },
      '.overflow-visible-l': {
        overflow: 'visible'
      },
      '.overflow-hidden-l': {
        overflow: 'hidden'
      },
      '.overflow-scroll-l': {
        overflow: 'scroll'
      },
      '.overflow-auto-l': {
        overflow: 'auto'
      },
      '.overflow-x-visible-l': {
        overflowX: 'visible'
      },
      '.overflow-x-hidden-l': {
        overflowX: 'hidden'
      },
      '.overflow-x-scroll-l': {
        overflowX: 'scroll'
      },
      '.overflow-x-auto-l': {
        overflowX: 'auto'
      },
      '.overflow-y-visible-l': {
        overflowY: 'visible'
      },
      '.overflow-y-hidden-l': {
        overflowY: 'hidden'
      },
      '.overflow-y-scroll-l': {
        overflowY: 'scroll'
      },
      '.overflow-y-auto-l': {
        overflowY: 'auto'
      },
      '.static-l': {
        position: 'static'
      },
      '.relative-l': {
        position: 'relative'
      },
      '.absolute-l': {
        position: 'absolute'
      },
      '.fixed-l': {
        position: 'fixed'
      },
      '.rotate-45-l': {
        '-webkit-transform': 'rotate( 45deg )',
        transform: 'rotate( 45deg )'
      },
      '.rotate-90-l': {
        '-webkit-transform': 'rotate( 90deg )',
        transform: 'rotate( 90deg )'
      },
      '.rotate-135-l': {
        '-webkit-transform': 'rotate( 135deg )',
        transform: 'rotate( 135deg )'
      },
      '.rotate-180-l': {
        '-webkit-transform': 'rotate( 180deg )',
        transform: 'rotate( 180deg )'
      },
      '.rotate-225-l': {
        '-webkit-transform': 'rotate( 225deg )',
        transform: 'rotate( 225deg )'
      },
      '.rotate-270-l': {
        '-webkit-transform': 'rotate( 270deg )',
        transform: 'rotate( 270deg )'
      },
      '.rotate-315-l': {
        '-webkit-transform': 'rotate( 315deg )',
        transform: 'rotate( 315deg )'
      },
      '.pa0-l': {
        padding: '0'
      },
      '.pa1-l': {
        padding: '.25rem'
      },
      '.pa2-l': {
        padding: '.5rem'
      },
      '.pa3-l': {
        padding: '1rem'
      },
      '.pa4-l': {
        padding: '2rem'
      },
      '.pa5-l': {
        padding: '4rem'
      },
      '.pa6-l': {
        padding: '8rem'
      },
      '.pa7-l': {
        padding: '16rem'
      },
      '.pl0-l': {
        paddingLeft: '0'
      },
      '.pl1-l': {
        paddingLeft: '.25rem'
      },
      '.pl2-l': {
        paddingLeft: '.5rem'
      },
      '.pl3-l': {
        paddingLeft: '1rem'
      },
      '.pl4-l': {
        paddingLeft: '2rem'
      },
      '.pl5-l': {
        paddingLeft: '4rem'
      },
      '.pl6-l': {
        paddingLeft: '8rem'
      },
      '.pl7-l': {
        paddingLeft: '16rem'
      },
      '.pr0-l': {
        paddingRight: '0'
      },
      '.pr1-l': {
        paddingRight: '.25rem'
      },
      '.pr2-l': {
        paddingRight: '.5rem'
      },
      '.pr3-l': {
        paddingRight: '1rem'
      },
      '.pr4-l': {
        paddingRight: '2rem'
      },
      '.pr5-l': {
        paddingRight: '4rem'
      },
      '.pr6-l': {
        paddingRight: '8rem'
      },
      '.pr7-l': {
        paddingRight: '16rem'
      },
      '.pb0-l': {
        paddingBottom: '0'
      },
      '.pb1-l': {
        paddingBottom: '.25rem'
      },
      '.pb2-l': {
        paddingBottom: '.5rem'
      },
      '.pb3-l': {
        paddingBottom: '1rem'
      },
      '.pb4-l': {
        paddingBottom: '2rem'
      },
      '.pb5-l': {
        paddingBottom: '4rem'
      },
      '.pb6-l': {
        paddingBottom: '8rem'
      },
      '.pb7-l': {
        paddingBottom: '16rem'
      },
      '.pt0-l': {
        paddingTop: '0'
      },
      '.pt1-l': {
        paddingTop: '.25rem'
      },
      '.pt2-l': {
        paddingTop: '.5rem'
      },
      '.pt3-l': {
        paddingTop: '1rem'
      },
      '.pt4-l': {
        paddingTop: '2rem'
      },
      '.pt5-l': {
        paddingTop: '4rem'
      },
      '.pt6-l': {
        paddingTop: '8rem'
      },
      '.pt7-l': {
        paddingTop: '16rem'
      },
      '.pv0-l': {
        paddingTop: '0',
        paddingBottom: '0'
      },
      '.pv1-l': {
        paddingTop: '.25rem',
        paddingBottom: '.25rem'
      },
      '.pv2-l': {
        paddingTop: '.5rem',
        paddingBottom: '.5rem'
      },
      '.pv3-l': {
        paddingTop: '1rem',
        paddingBottom: '1rem'
      },
      '.pv4-l': {
        paddingTop: '2rem',
        paddingBottom: '2rem'
      },
      '.pv5-l': {
        paddingTop: '4rem',
        paddingBottom: '4rem'
      },
      '.pv6-l': {
        paddingTop: '8rem',
        paddingBottom: '8rem'
      },
      '.pv7-l': {
        paddingTop: '16rem',
        paddingBottom: '16rem'
      },
      '.ph0-l': {
        paddingLeft: '0',
        paddingRight: '0'
      },
      '.ph1-l': {
        paddingLeft: '.25rem',
        paddingRight: '.25rem'
      },
      '.ph2-l': {
        paddingLeft: '.5rem',
        paddingRight: '.5rem'
      },
      '.ph3-l': {
        paddingLeft: '1rem',
        paddingRight: '1rem'
      },
      '.ph4-l': {
        paddingLeft: '2rem',
        paddingRight: '2rem'
      },
      '.ph5-l': {
        paddingLeft: '4rem',
        paddingRight: '4rem'
      },
      '.ph6-l': {
        paddingLeft: '8rem',
        paddingRight: '8rem'
      },
      '.ph7-l': {
        paddingLeft: '16rem',
        paddingRight: '16rem'
      },
      '.ma0-l': {
        margin: '0'
      },
      '.ma1-l': {
        margin: '.25rem'
      },
      '.ma2-l': {
        margin: '.5rem'
      },
      '.ma3-l': {
        margin: '1rem'
      },
      '.ma4-l': {
        margin: '2rem'
      },
      '.ma5-l': {
        margin: '4rem'
      },
      '.ma6-l': {
        margin: '8rem'
      },
      '.ma7-l': {
        margin: '16rem'
      },
      '.ml0-l': {
        marginLeft: '0'
      },
      '.ml1-l': {
        marginLeft: '.25rem'
      },
      '.ml2-l': {
        marginLeft: '.5rem'
      },
      '.ml3-l': {
        marginLeft: '1rem'
      },
      '.ml4-l': {
        marginLeft: '2rem'
      },
      '.ml5-l': {
        marginLeft: '4rem'
      },
      '.ml6-l': {
        marginLeft: '8rem'
      },
      '.ml7-l': {
        marginLeft: '16rem'
      },
      '.mr0-l': {
        marginRight: '0'
      },
      '.mr1-l': {
        marginRight: '.25rem'
      },
      '.mr2-l': {
        marginRight: '.5rem'
      },
      '.mr3-l': {
        marginRight: '1rem'
      },
      '.mr4-l': {
        marginRight: '2rem'
      },
      '.mr5-l': {
        marginRight: '4rem'
      },
      '.mr6-l': {
        marginRight: '8rem'
      },
      '.mr7-l': {
        marginRight: '16rem'
      },
      '.mb0-l': {
        marginBottom: '0'
      },
      '.mb1-l': {
        marginBottom: '.25rem'
      },
      '.mb2-l': {
        marginBottom: '.5rem'
      },
      '.mb3-l': {
        marginBottom: '1rem'
      },
      '.mb4-l': {
        marginBottom: '2rem'
      },
      '.mb5-l': {
        marginBottom: '4rem'
      },
      '.mb6-l': {
        marginBottom: '8rem'
      },
      '.mb7-l': {
        marginBottom: '16rem'
      },
      '.mt0-l': {
        marginTop: '0'
      },
      '.mt1-l': {
        marginTop: '.25rem'
      },
      '.mt2-l': {
        marginTop: '.5rem'
      },
      '.mt3-l': {
        marginTop: '1rem'
      },
      '.mt4-l': {
        marginTop: '2rem'
      },
      '.mt5-l': {
        marginTop: '4rem'
      },
      '.mt6-l': {
        marginTop: '8rem'
      },
      '.mt7-l': {
        marginTop: '16rem'
      },
      '.mv0-l': {
        marginTop: '0',
        marginBottom: '0'
      },
      '.mv1-l': {
        marginTop: '.25rem',
        marginBottom: '.25rem'
      },
      '.mv2-l': {
        marginTop: '.5rem',
        marginBottom: '.5rem'
      },
      '.mv3-l': {
        marginTop: '1rem',
        marginBottom: '1rem'
      },
      '.mv4-l': {
        marginTop: '2rem',
        marginBottom: '2rem'
      },
      '.mv5-l': {
        marginTop: '4rem',
        marginBottom: '4rem'
      },
      '.mv6-l': {
        marginTop: '8rem',
        marginBottom: '8rem'
      },
      '.mv7-l': {
        marginTop: '16rem',
        marginBottom: '16rem'
      },
      '.mh0-l': {
        marginLeft: '0',
        marginRight: '0'
      },
      '.mh1-l': {
        marginLeft: '.25rem',
        marginRight: '.25rem'
      },
      '.mh2-l': {
        marginLeft: '.5rem',
        marginRight: '.5rem'
      },
      '.mh3-l': {
        marginLeft: '1rem',
        marginRight: '1rem'
      },
      '.mh4-l': {
        marginLeft: '2rem',
        marginRight: '2rem'
      },
      '.mh5-l': {
        marginLeft: '4rem',
        marginRight: '4rem'
      },
      '.mh6-l': {
        marginLeft: '8rem',
        marginRight: '8rem'
      },
      '.mh7-l': {
        marginLeft: '16rem',
        marginRight: '16rem'
      },
      '.na1-l': {
        margin: '-0.25rem'
      },
      '.na2-l': {
        margin: '-0.5rem'
      },
      '.na3-l': {
        margin: '-1rem'
      },
      '.na4-l': {
        margin: '-2rem'
      },
      '.na5-l': {
        margin: '-4rem'
      },
      '.na6-l': {
        margin: '-8rem'
      },
      '.na7-l': {
        margin: '-16rem'
      },
      '.nl1-l': {
        marginLeft: '-0.25rem'
      },
      '.nl2-l': {
        marginLeft: '-0.5rem'
      },
      '.nl3-l': {
        marginLeft: '-1rem'
      },
      '.nl4-l': {
        marginLeft: '-2rem'
      },
      '.nl5-l': {
        marginLeft: '-4rem'
      },
      '.nl6-l': {
        marginLeft: '-8rem'
      },
      '.nl7-l': {
        marginLeft: '-16rem'
      },
      '.nr1-l': {
        marginRight: '-0.25rem'
      },
      '.nr2-l': {
        marginRight: '-0.5rem'
      },
      '.nr3-l': {
        marginRight: '-1rem'
      },
      '.nr4-l': {
        marginRight: '-2rem'
      },
      '.nr5-l': {
        marginRight: '-4rem'
      },
      '.nr6-l': {
        marginRight: '-8rem'
      },
      '.nr7-l': {
        marginRight: '-16rem'
      },
      '.nb1-l': {
        marginBottom: '-0.25rem'
      },
      '.nb2-l': {
        marginBottom: '-0.5rem'
      },
      '.nb3-l': {
        marginBottom: '-1rem'
      },
      '.nb4-l': {
        marginBottom: '-2rem'
      },
      '.nb5-l': {
        marginBottom: '-4rem'
      },
      '.nb6-l': {
        marginBottom: '-8rem'
      },
      '.nb7-l': {
        marginBottom: '-16rem'
      },
      '.nt1-l': {
        marginTop: '-0.25rem'
      },
      '.nt2-l': {
        marginTop: '-0.5rem'
      },
      '.nt3-l': {
        marginTop: '-1rem'
      },
      '.nt4-l': {
        marginTop: '-2rem'
      },
      '.nt5-l': {
        marginTop: '-4rem'
      },
      '.nt6-l': {
        marginTop: '-8rem'
      },
      '.nt7-l': {
        marginTop: '-16rem'
      },
      '.strike-l': {
        textDecoration: 'line-through'
      },
      '.underline-l': {
        textDecoration: 'underline'
      },
      '.no-underline-l': {
        textDecoration: 'none'
      },
      '.tl-l': {
        textAlign: 'left'
      },
      '.tr-l': {
        textAlign: 'right'
      },
      '.tc-l': {
        textAlign: 'center'
      },
      '.tj-l': {
        textAlign: 'justify'
      },
      '.ttc-l': {
        textTransform: 'capitalize'
      },
      '.ttl-l': {
        textTransform: 'lowercase'
      },
      '.ttu-l': {
        textTransform: 'uppercase'
      },
      '.ttn-l': {
        textTransform: 'none'
      },
      '.f-6-l, .f-headline-l': {
        fontSize: '6rem'
      },
      '.f-5-l, .f-subheadline-l': {
        fontSize: '5rem'
      },
      '.f1-l': {
        fontSize: '3rem'
      },
      '.f2-l': {
        fontSize: '2.25rem'
      },
      '.f3-l': {
        fontSize: '1.5rem'
      },
      '.f4-l': {
        fontSize: '1.25rem'
      },
      '.f5-l': {
        fontSize: '1rem'
      },
      '.f6-l': {
        fontSize: '.875rem'
      },
      '.f7-l': {
        fontSize: '.75rem'
      },
      '.measure-l': {
        maxWidth: '30em'
      },
      '.measure-wide-l': {
        maxWidth: '34em'
      },
      '.measure-narrow-l': {
        maxWidth: '20em'
      },
      '.indent-l': {
        textIndent: '1em',
        marginTop: '0',
        marginBottom: '0'
      },
      '.small-caps-l': {
        fontVariant: 'small-caps'
      },
      '.truncate-l': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '.center-l': {
        marginRight: 'auto',
        marginLeft: 'auto'
      },
      '.mr-auto-l': {
        marginRight: 'auto'
      },
      '.ml-auto-l': {
        marginLeft: 'auto'
      },
      '.clip-l': {
        position: 'absolute !important',
        fallbacks: [
          {
            clip: 'rect( 1px 1px 1px 1px )'
          },
          {
            position: 'fixed !important'
          }
        ],
        clip: 'rect( 1px, 1px, 1px, 1px )'
      },
      '.ws-normal-l': {
        whiteSpace: 'normal'
      },
      '.nowrap-l': {
        whiteSpace: 'nowrap'
      },
      '.pre-l': {
        whiteSpace: 'pre'
      },
      '.v-base-l': {
        verticalAlign: 'baseline'
      },
      '.v-mid-l': {
        verticalAlign: 'middle'
      },
      '.v-top-l': {
        verticalAlign: 'top'
      },
      '.v-btm-l': {
        verticalAlign: 'bottom'
      }
    }
  }
}
