import React from 'react'
import dayjs from 'helpers/dayjs'
import { useStateValue } from 'components/Availability/StateProvider'
import withStyles from 'react-jss'
import style from './styles'
import { openModal } from 'components/Layout/Modal'
import withApollo from 'helpers/withApollo'
import Modal from './Modal'
import { isEmpty, equals } from 'ramda'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import visitIsPending from 'helpers/visitIsPending'

export const getWeekInfo = (data, mondayOfChosenWeek, sundayOfChosenWeek) =>
  [...data.companion.availableTimes, ...data.companion.unavailableDays]
    // get all time slots for chosen week
    .filter(({ startAt, date }) =>
      dayjs(startAt || date).isBetween(mondayOfChosenWeek, sundayOfChosenWeek, 'day', '[]')
    )
    // construct them in a way that's easy to digest;
    // reduce over array with seven arrays representing each day of the week. Monday === 0
    .reduce(
      (current, timeSlot) => {
        const index =
          // index work because dayjs assumes Sunday is 0
          dayjs(timeSlot.startAt || timeSlot.date).weekday() === 0
            ? current.length - 1
            : dayjs(timeSlot.startAt || timeSlot.date).weekday() - 1
        current[index] = [...current[index], timeSlot]
        return current
      },
      // array with seven arrays representing each day of the week. Monday === 0
      [[], [], [], [], [], [], []]
    )
    // grab any visits for a day, if available
    // add them to the end of the day array of timeslots
    .map((timeSlots, i) => {
      const visits = data.companion.visits.filter(
        visit =>
          // use monday of the currently chosen week and add the index to get that day of the week
          // 0 === Monday, 1 === Tuesday, etc
          // retreiving the current day to work on this way allows us to show visits even if
          // there are no timeslots
          // this only retrieves visits that are today or in the future)
          dayjs(visit.startAt).isSame(dayjs(mondayOfChosenWeek).add(i, 'day'), 'day') &&
          dayjs(visit.startAt).isSameOrAfter(dayjs(), 'day')
      )

      if (!isEmpty(visits))
        // add isVisit: true to divide the display work for rendering
        return [...timeSlots, ...visits.map(v => ({ ...v, isVisit: true }))]
      else return timeSlots
    })

const DayDisplay = ({ data, classes, client }) => {
  const [state] = useStateValue()
  const [mondayOfChosenWeek, sundayOfChosenWeek] = state.weeks[state.chosenWeek]

  const weekInfo = getWeekInfo(data, mondayOfChosenWeek, sundayOfChosenWeek)

  const openDestroyTimeSlotModal = timeSlot => () =>
    openModal(client, {
      Component: ({ closeModal }) => <Modal closeModal={closeModal} timeSlot={timeSlot} />
    })
  return (
    <div data-cy='timeslot-container'>
      {weekInfo
        .filter(timeSlots => timeSlots.length)
        .map((timeSlots, i) => {
          const isDayOff = timeSlots.some(ts => ts.__typename === 'UnavailableDay')
          const day = dayjs(timeSlots[0].startAt || timeSlots[0].date)
          return (
            <div key={i} className={`bg-white mb125 radius ${classes.dayContainer}`} data-cy='timeslot-day-container'>
              <div className={`pv75 ph125 bb b--gray`}>
                {day.format('dddd M/D')}
                {isDayOff && <span className='fr f6 lighter-green-2 i'>Day Off</span>}
              </div>
              {!isDayOff &&
                timeSlots
                  .filter(o => !o.isVisit)
                  .map((timeSlot, i) => {
                    return (
                      <div
                        key={i}
                        className={`flex flex-nowrap pv75 ph125 darkest-gray ${timeSlots.length - 1 === i ? '' : 'bb b--gray'
                          }`}
                      >
                        <div>
                          {dayjs(timeSlot.startAt).format('h:mm a')} -{' '}
                          {dayjs(timeSlot.startAt)
                            .add(timeSlot.duration, 'minute')
                            .format('h:mm a')}
                        </div>
                        {timeSlot.futureOccurrenceCount > 1 && (
                          <div data-cy='timeslot-recurring-badge' className='lighter-green-2 i f6 ml2'>
                            Recurring
                          </div>
                        )}
                        <div
                          className='flex-grow-1'
                          onClick={openDestroyTimeSlotModal(timeSlot)}
                          data-cy='open-destroy-timeslot-modal'
                        >
                          <i className='pointer fr icon-delete purple' />
                        </div>
                      </div>
                    )
                  })}
              {timeSlots
                .filter(o => o.isVisit)
                .map((visit, i) => {
                  return (
                    <div
                      data-cy={`timeslot-visit-${i}`}
                      key={i}
                      className={`pv75 ph125 darkest-gray bg-light-gray ${timeSlots.filter(o => o.isVisit).length - 1 === i ? 'radius-bottom' : 'bb b--gray'
                        }`}
                    >
                      <div className='f6 mb1 label-green fw6'>SCHEDULED VISIT</div>
                      <div className='flex flex-nowrap justify-between'>
                        <div>{visit.visitee.customer.person.firstNameAndLastInitial}</div>
                        <div>
                          {dayjs(visit.startAt).format('h:mm a')} -{' '}
                          {dayjs(visit.startAt)
                            .add(visit.duration, 'minute')
                            .format('h:mm a')}
                        </div>
                        {!visitIsPending(visit) && (
                          <Link
                            route={`/visits/${visit.id}?redirectTo=availability`}
                            returnTo={`${routePaths.availability}`}
                            className='purple'
                          >
                            View Details
                          </Link>
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        })}
    </div>
  )
}

export default React.memo(withApollo(withStyles(style)(DayDisplay)), equals)
