import React from 'react'
import ErrorMessage from 'components/ErrorMessage'
import withStyles from 'react-jss'
import styles from './styles'

const InputAndLabel = ({ label, type, classes, updateValue, errorMessage, value, ...rest }) => (
  <div className={'mb3'}>
    <div className='mb1'>
      <label>{label}</label>
    </div>
    <input
      className={`${classes.input} ba b--gray ph2 input-reset w-100`}
      onClick={({ target }) => target.focus()}
      type={type}
      onChange={({ target: { value } }) => updateValue(value)}
      value={value}
      autoCapitalize='off'
      {...rest}
    />
    {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
  </div>
)

export default withStyles(styles)(InputAndLabel)
