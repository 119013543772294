import generalStyles from './general'
import { jss } from 'react-jss'
import tachyons from 'helpers/tachyons'
import tachyonExtensions from 'helpers/tachyons/extensions'
import './fabricTheme'
import './acumin_pro.css'
import './recoleta-regular.css'

jss.createStyleSheet(generalStyles).attach()
jss.createStyleSheet(tachyons).attach()
jss.createStyleSheet(tachyonExtensions).attach()
