import React from 'react'
import { isEmpty } from 'ramda'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import { isBrowser } from 'helpers/platform'
import userGuide from 'assets/icons/user-guide.svg'
import importantInfo from 'assets/icons/important-info.svg'
import story from 'assets/icons/story.svg'
import bookVisit from 'assets/icons/book-visit.svg'
import profile from 'assets/icons/profile.svg'
import { inAppBrowserOpen } from 'helpers/inAppBrowser'

const TaskBox = ({ title, subtext, icon, taskStyle, backgroundImage }) => {
  return (
    <div className={`mb3 br3 ${backgroundImage}`}>
      <div className={`${taskStyle}`}>
        {subtext && <div className='pa3 pb0'>{subtext}</div>}
        <div className={`flex flex-nowrap flex-row pa3`}>
          <div className='flex-grow-1'>
            <div className='flex'>
              <img src={icon} />
              <div className='ml3 mt1 fw6 recoleta-regular f4-5'>{title}</div>
            </div>
          </div>

          <i className='icon-arrow-right purple pointer f4' />
        </div>
      </div>
    </div>
  )
}

const TaskBoxHref = ({ title, subtext, href, classes, icon, taskStyle }) => {
  if (isBrowser)
    return (
      <a href={href} target='_blank' className={classes.textDecoration}>
        <TaskBox title={title} subtext={subtext} icon={icon} taskStyle={taskStyle} />
      </a>
    )
  return (
    <a onClick={() => inAppBrowserOpen(href, '_blank')} className={classes.textDecoration}>
      <TaskBox title={title} subtext={subtext} icon={icon} taskStyle={taskStyle} />
    </a>
  )
}

const ImportantTasks = ({ companion, classes }) => {
  const { opportunities, hasEverBooked, hasIncompleteVisitStories, companionType } = companion
  const navigation = useNavigation()
  const goTo = route => () => {
    if (route) navigation.navigate(route)
  }
  const bookVisitTitle = _ => {
    switch (companionType) {
      case 'lite':
        return 'Book or report a call'
      case 'service':
        return 'Book or report a call or errand'
      default:
        return 'Book or report a visit'
    }
  }
  return (
    <>
      {hasIncompleteVisitStories && (
        <div onClick={goTo(`${routePaths.visits}?tab=completed`)}>
          <TaskBox
            title={'Submit a recap'}
            icon={story}
            taskStyle={classes.story}
            backgroundImage={classes.storyBackgroundImage}
          />
        </div>
      )}
      {!isEmpty(opportunities) && !hasEverBooked ? (
        <div onClick={goTo(routePaths.opportunities)}>
          <TaskBox
            title={'Book your first visit'}
            icon={bookVisit}
            taskStyle={classes.bookVisit}
            backgroundImage={classes.bookVisitBackgroundImage}
          />
        </div>
      ) : (
        <div onClick={goTo(routePaths.bookVisit)}>
          <TaskBox
            title={bookVisitTitle()}
            icon={bookVisit}
            taskStyle={classes.bookVisit}
            backgroundImage={classes.bookVisitBackgroundImage}
          />
        </div>
      )}

      <TaskBoxHref
        title={'Important Information'}
        subtext={
          'Thank you for making a difference. By using this app, you agree to comply with local laws and health and safety requirements and follow our terms and conditions. Tap here to review.'
        }
        href={
          companion.organization && companion.organization.importantInformationLink
            ? companion.organization.importantInformationLink
            : 'https://www.monami.io/volunteer-guidelines/'
        }
        classes={classes}
        icon={importantInfo}
        taskStyle={classes.importantInfo}
      />
      <TaskBoxHref
        title={'Read the app user guide'}
        href='https://www.monami.io/app-userguide/'
        classes={classes}
        icon={userGuide}
        taskStyle={classes.userGuide}
      />
      {!companion.person.photo && (
        <div onClick={goTo(`${routePaths.profile}?tab=completed`)}>
          <TaskBox
            title={'Add a profile picture'}
            subtext={'Show off your awesome smile and add your profile picture.'}
            icon={profile}
            taskStyle={classes.profile}
          />
        </div>
      )}
    </>
  )
}

export default ImportantTasks
