import { head, prop } from 'ramda'
import capitalizeFirstLetter from '../capitalizeFirstLetter'

interface ErrorOptions {
  nested?: boolean
}

const getError = (path: string, error: any, options?: ErrorOptions): string | undefined =>
  head(
    (prop('graphQLErrors', error) || []).map(({ errorMessages }) => {
      if (errorMessages && errorMessages[path]) {
        const msg = errorMessages[path].join(', ')
        return options?.nested ? `${capitalizeFirstLetter(path.split('.')[1])} ${msg}` : capitalizeFirstLetter(msg)
      } else return undefined
    })
  )

export default getError
