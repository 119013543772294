import colors from 'helpers/colors'

export default {
  button: {
    height: '3.125rem',
    color: 'white',
    borderRadius: 4,
    backgroundColor: colors.purple,
    outline: 'none',
    '&:disabled, &[disabled]': {
      backgroundColor: colors.purple,
      opacity: 0.6,
      transition: '0.3s'
    }
  },
  btnDanger: {
    backgroundColor: colors.red
  },
  btnMuted: {
    backgroundColor: colors.lightGray,
    color: colors.darkestGray,
    border: `1px solid ${colors.gray} !important`
  }
}
