import React from 'react'
import { milestoneImages } from 'components/Dashboard/milestoneImages'
import style from './styles'
import withStyles from 'react-jss'
import withApollo from 'helpers/withApollo'
import { UPDATE_MESSAGE_MUTATION } from './queries'

const MessageBox = ({ client, classes, message, refreshQuery }) => {
  const dismissMessage = async () => {
    await client.mutate({
      mutation: UPDATE_MESSAGE_MUTATION,
      variables: {
        input: {
          id: message.id,
          dismissed: true
        }
      }
    })
    refreshQuery()
  }

  return (
    <div className='flex flex-nowrap flex-row bg-white pa3 mb3 br3 ba b--border-green'>
      <div className='flex-grow-1 w-20 pr3'>
        <img className={classes.icon} src={milestoneImages[message.messageType].active} />
      </div>
      <div className='flex-grow-1 w-80'>
        <div className='mb1 fw6 recoleta-regular'>{message.title}</div>
        <div className='darkest-gray'>{message.subtext}</div>
      </div>
      <i onClick={dismissMessage} className='icon-decline purple f6 pointer' />
    </div>
  )
}

export default withApollo(withStyles(style)(MessageBox))
