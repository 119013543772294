export default {
  container: {
    borderRadius: '4px',
    fontSize: '15px',
    '@media screen and (max-width: 374px)': {
      fontSize: '0.6rem'
    }
  },
  photoContainer: {
    width: '100%',
    height: '100%',
    verticalAlign: 'bottom',
    borderTopLeftRadius: '4px'
  },
  button: {
    padding: '.875rem 0'
  },
  badge: {
    bottom: 0
  },
  descriptionContainer: {
    '& > div': {
      minHeight: '2.6rem'
    }
  }
}
