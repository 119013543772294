import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import dayjs from 'helpers/dayjs'
import { useStateValue, types } from 'components/VisitDetails/StateProvider'
import check from 'assets/icons/accept.svg'
import { createVisitStoryFromVisit, redirectToEditVisitStory } from 'components/Visits/UpcomingVisits/UpcomingVisitCard'
import withApollo from 'helpers/withApollo'
import { useNavigation } from 'react-navi'

import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface CardProps {
  visit: VisitDetailsQuery_companion_visit
  client: any
  classes: any
}

const Card: React.FC<CardProps> = ({ visit, classes, client }) => {
  const [, dispatch] = useStateValue()
  const navigation = useNavigation()

  const startAt = dayjs(visit.startAt)
  return (
    <>
      <div className={`bg-white br2 tc mt3 mb2 pt3 relative ${classes.cardContainer}`}>
        <div className='fw6 mb1 recoleta-regular'>Upcoming {visit.visitTypeFriendlyName}</div>
        {visit.scheduleType === 'flexible' ? (
          <div>Timing is flexible. Complete by {dayjs(visit.expectCompletionAt).format('ddd, MMM DD')}.</div>
        ) : (
            <div className='fw6 recoleta-regular'>
              {startAt.format('ddd, MMM DD')} at {startAt.format('h:mmA')} - {dayjs(visit.endAt).format('h:mmA')}.
            </div>
          )}
        {visit.visitType === 'shift' && <br />}
        {visit.visitType !== 'shift' && (
          <div className='mt3 bt b--gray'>
            <div
              onClick={_ => dispatch({ type: types.SHOW_CANCEL_VISIT_MODAL })}
              className={`dib pv3 br b--gray pointer ${visit.scheduleType === 'flexible' ? 'w-33' : 'w-50'}`}
              data-cy='upcoming--open-cancel-modal'
            >
              <i className='icon-decline red mr2' />
              Cancel
            </div>
            {visit.scheduleType !== 'flexible' && (
              <div
                onClick={_ => dispatch({ type: types.SHOW_RESCHEDULE_VISIT_MODAL })}
                className={`dib w-33 pv3 pointer ${visit.scheduleType === 'flexible' ? 'w-33' : 'w-50'}`}
                data-cy='upcoming--open-reschedule-modal'
              >
                <i className='icon-reschedule purple mr2' />
                Reschedule
              </div>
            )}
            {visit.scheduleType === 'flexible' && (
              <div
                onClick={() => {
                  createVisitStoryFromVisit(client, visit)
                  redirectToEditVisitStory(visit, navigation)
                }}
                className={`dib w-33 pv3 pointer ${visit.scheduleType === 'flexible' ? 'w-33' : 'w-50'}`}
                data-cy='complete-flexible-visit'
              >
                <img className='mr2' src={check} />
                Complete
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default withStyles(style)(withApollo(Card))
