import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
  address: null,
  showEditLocationModal: false
}

export const types = {
  UPDATE_PARAMS: 'UPDATE_PARAMS',
  SHOW_EDIT_LOCATION_MODAL: 'SHOW_EDIT_LOCATION_MODAL',
  HIDE_EDIT_LOCATION_MODAL: 'HIDE_EDIT_LOCATION_MODAL',
  UPDATE_RECURRING: 'UPDATE_RECURRING'
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.UPDATE_PARAMS:
      return {
        ...state,
        address: action.address ? { ...state.address, ...action.address } : null
      }
    case types.SHOW_EDIT_LOCATION_MODAL:
      return { ...state, showEditLocationModal: true }
    case types.HIDE_EDIT_LOCATION_MODAL:
      return { ...state, showEditLocationModal: false }
    case types.UPDATE_ERRORS:
      return { ...state, errors: action.errors }
  }
}

export const StateContext = createContext()

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
