import React, { useState, useEffect } from 'react'
import withStyles from 'react-jss'
import style from './styles'
import PhotoHandler from 'components/PhotoHandler'
import classNames from 'classnames'
import LazyPhoto from 'components/LazyPhoto'
import PhotoCarousel from 'components/EditVisitStory/PhotoCarousel'

const MultiPhotoInput = ({ classes, uploadPhoto, visit, photos, error, label, id }) => {
  const [showPhotoCarousel, setShowPhotoCarousel] = useState(false)
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0)
  const [carouselPhotos, setCarouselPhotos] = useState([])

  const [files, setFiles] = useState([])
  const [photoError, setPhotoError] = useState(error)
  const [uploading, setUploading] = useState(false)
  const setFile = ({ target }) => {
    if (target.files.length === 0) return
    setFiles([...files, { file: target.files[0], id: Date.now() }])
    setPhotoError(null)
  }

  const removeFile = id => {
    setFiles(files.filter(f => f.id !== id))
  }

  const openCarousel = index => () => {
    setShowPhotoCarousel(true)
    setSelectedPhotoIndex(index)
    setCarouselPhotos(photos)
  }

  useEffect(() => {
    setPhotoError(error)
  }, [error])

  const closePhotoCarousel = () => setShowPhotoCarousel(false)

  return (
    <div className='mb3 flex flex-wrap justify-between'>
      <PhotoCarousel
        show={showPhotoCarousel}
        close={closePhotoCarousel}
        visit={visit}
        photosFromData={carouselPhotos}
        visible={showPhotoCarousel}
        selectedIndex={selectedPhotoIndex}
        setSelectedIndex={setSelectedPhotoIndex}
      />
      {photos.map((photo, i) => (
        <LazyPhoto
          key={i}
          onClick={openCarousel(i)}
          photo={photo}
          className={`grid-item mb2 ${classes.photoPreview}`}
          dataCy={`story-multiple-photos-${i}`}
        />
      ))}
      {files.map(({ file, id }, i) => (
        <PhotoHandler
          key={i}
          id={id}
          {...{
            file,
            uploadPhoto,
            removeFile,
            setUploading,
            uploading,
            setPhotoError
          }}
        />
      ))}
      <div
        className={classNames('grid-item mb2 ba', classes.add, {
          'b--dark-gray': !photoError,
          'b--red': photoError
        })}
      >
        <label htmlFor={id} className='pointer h-100 w-100'>
          <div className='flex flex-column justify-center pointer tc ba b--purple h-100 w-100'>
            <div className='f3'>
              <i className='icon-camera purple' />
            </div>
            <div className='f7'>
              {label || 'Add photos'}
              <div>
                <input
                  className={classes.fileInput}
                  onChange={setFile}
                  type='file'
                  name='photo'
                  id={id}
                  accept='image/jpeg'
                  data-cy='story--file-input'
                  disabled={uploading}
                />
              </div>
              {photoError && <div className='red i f6'>{photoError}</div>}
            </div>
          </div>
        </label>
      </div>
      {((files.length + photos.length) / 3).toFixed(1).split('.')[1] === '3' && (
        <div className={`grid-item mb2 ${classes.add}`} />
      )}
    </div>
  )
}

export default withStyles(style)(MultiPhotoInput)
