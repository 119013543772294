export const gridItem = {
  width: '32%',
  height: '130px',
  borderRadius: '4px'
}

export default {
  add: gridItem,
  fileInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute'
  },
  photoPreview: {
    ...gridItem,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
}
