import { InMemoryCache } from '@apollo/client/cache';
import { gql } from '@apollo/client';

export const cache = new InMemoryCache({})

cache.writeQuery({
  query: gql`
    query SetCache {
      onboarding
      deepLink
      oauthProvider
      showMenu
      notification
      twoFactorAuth
      modal
      returnTo
      isBusy
    }
  `,
  data: {
    onboarding: null,
    deepLink: null,
    oauthProvider: null,
    showMenu: null,
    notification: null,
    twoFactorAuth: null,
    modal: null,
    returnTo: null,
    isBusy: { bar: false, screen: false, __typename: 'IsBusy' }
  }
});
