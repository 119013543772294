import React, { Fragment, useState } from 'react'
import { useStateValue, types } from 'components/BookVisit/StateProvider'
import EditLocationModal from 'components/EditLocationModal'
import formatAddress from 'helpers/formatAddress'
import ChoiceGroup from 'components/ChoiceGroup'
import { path, find, propEq, isEmpty } from 'ramda'
import Select from 'components/Select'

const VisiteeSelector = () => {
  const [
    { address, availableVisitTypes, visitType, visitCoding, availableOrganizationCodes },
    dispatch
  ] = useStateValue()

  const [showAddress, setShowAddress] = useState(path([0, 'addressRequired'], availableVisitTypes))

  const [visitTypeState, setVisitType] = useState(visitType || 'telepresence')

  const getOrganizationVisitCodes = () => {
    const organizationCodes = availableOrganizationCodes.filter(code => code.visitType === visitTypeState)
    const organizationCodesList = organizationCodes.map(code => ({ text: code.name, value: code.id }))
    return organizationCodesList
  }

  if (!address) {
    return null
  }

  const updateVisitType = (_, { key }) => {
    dispatch({ type: types.UPDATE_VISIT_TYPE, visitType: key })
    const addressRequired = path(['addressRequired'], find(propEq('key', key))(availableVisitTypes))
    setShowAddress(addressRequired || false)
    setVisitType(key)
    updateVisitCode(undefined)
  }

  const updateVisitCode = value => {
    dispatch({ type: types.UPDATE_VISIT_CODING_TYPE, visitCoding: value })
  }

  return (
    <Fragment>
      <EditLocationModal refetchQueries={['ProfileQuery']} useStateValue={useStateValue} types={types} />
      {availableVisitTypes && (
        <>
          <div className='mb3'>
            <div className='flex'>
              <ChoiceGroup
                label='Visit type'
                required
                selectedKey={visitType || 'telepresence'}
                options={availableVisitTypes}
                onChange={updateVisitType}
              />
            </div>
          </div>
          {!isEmpty(getOrganizationVisitCodes()) && (
            <>
              <div className='mb3'>
                <p>
                  Activity Description <span className='red'>*</span>
                </p>
                <Select
                  required
                  handler={updateVisitCode}
                  items={getOrganizationVisitCodes()}
                  placeholder='Please Select'
                  data-cy='visit-coding'
                  value={visitCoding}
                  blankValue={true}
                />
              </div>
            </>
          )}
        </>
      )}
      {showAddress && (
        <div className='mb3'>
          <div className='mb2 fw6'>Location</div>
          <div className='flex'>
            <div className='flex-grow-1' data-testid='addressDisplay'>
              {formatAddress(address)}
            </div>
            <div
              className='f6'
              onClick={() => dispatch({ type: types.SHOW_EDIT_LOCATION_MODAL })}
              data-cy='visits-new--open-edit-location-modal'
            >
              <i className='icon-edit purple' /> Edit
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default VisiteeSelector
