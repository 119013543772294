import { gql } from '@apollo/client'

export const REQUEST_LOGIN_CODE = gql`
  mutation RequestLoginCode($input: RequestLoginCodeInput!) {
    requestLoginCode(input: $input) {
      void {
        nothing
      }
    }
  }
`
