import React, { createContext, useContext, useReducer } from 'react'
import { createWeeks } from './helpers'
import dayjs from 'helpers/dayjs'

export const DISPLAY_WEEKS = 21

const newTimeSlotDefaults = () => {
  const anHourLater = dayjs()
    .add(1, 'hour')
    .minute(0)

  return {
    startAt: anHourLater.toDate(),
    endAt: anHourLater.add(4, 'hour').toDate(),
    recurring: false,
    additionalDaysOfWeek: []
  }
}
const initialState = {
  showNewTimeSlotModal: false,
  showSelectDateModal: false,
  date: new Date(),
  recurrenceCount: 10,
  errors: null,
  weeks: createWeeks(),
  chosenWeek: 0,
  showNewDaysOffModal: false,
  unavailableDays: [],
  ...newTimeSlotDefaults()
}

export const types = {
  SHOW_NEW_TIME_SLOT_MODAL: 'SHOW_NEW_TIME_SLOT_MODAL',
  HIDE_NEW_TIME_SLOT_MODAL: 'HIDE_NEW_TIME_SLOT_MODAL',
  SHOW_SELECT_DATE_MODAL: 'SHOW_SELECT_DATE_MODAL',
  HIDE_SELECT_DATE_MODAL: 'HIDE_SELECT_DATE_MODAL',
  UPDATE_DATE: 'UPDATE_DATE',
  UPDATE_START_AT: 'UPDATE_START_AT',
  UPDATE_END_AT: 'UPDATE_END_AT',
  UPDATE_RECURRING: 'UPDATE_RECURRING',
  UPDATE_RECURRENCE_COUNT: 'UPDATE_RECURRENCE_COUNT',
  UPDATE_ADDITIONAL_DAYS_OF_THE_WEEK: 'UPDATE_ADDITIONAL_DAYS_OF_THE_WEEK',
  UPDATE_ERRORS: 'UPDATE_ERRORS',
  UPDATE_CHOSEN_WEEK: 'UPDATE_CHOSEN_WEEK',
  RESET_NEW_TIME_SLOT_FORM: 'RESET_NEW_TIME_SLOT_FORM',
  SHOW_NEW_DAYS_OFF_MODAL: 'SHOW_NEW_DAYS_OFF_MODAL',
  HIDE_NEW_DAYS_OFF_MODAL: 'HIDE_NEW_DAYS_OFF_MODAL',
  UPDATE_UNAVAILABLE_DAYS: 'UPDATE_UNAVAILABLE_DAYS'
}

const updateSingleKey = (key, state, action) => ({ ...state, [key]: action[key] })

const reducer = (state, action) => {
  switch (action.type) {
    case types.SHOW_NEW_TIME_SLOT_MODAL:
      return { ...state, showNewTimeSlotModal: true }
    case types.HIDE_NEW_TIME_SLOT_MODAL:
      return { ...state, showNewTimeSlotModal: false }
    case types.SHOW_SELECT_DATE_MODAL:
      return { ...state, showSelectDateModal: true }
    case types.HIDE_SELECT_DATE_MODAL:
      return { ...state, showSelectDateModal: false }
    case types.UPDATE_DATE:
      return updateSingleKey('date', state, action)
    case types.UPDATE_START_AT:
      return updateSingleKey('startAt', state, action)
    case types.UPDATE_END_AT:
      return updateSingleKey('endAt', state, action)
    case types.UPDATE_RECURRING:
      return updateSingleKey('recurring', state, action)
    case types.UPDATE_RECURRENCE_COUNT:
      return updateSingleKey('recurrenceCount', state, action)
    case types.UPDATE_ADDITIONAL_DAYS_OF_THE_WEEK:
      return updateSingleKey('additionalDaysOfWeek', state, action)
    case types.UPDATE_ERRORS:
      return updateSingleKey('errors', state, action)
    case types.UPDATE_CHOSEN_WEEK:
      return updateSingleKey('chosenWeek', state, action)
    case types.RESET_NEW_TIME_SLOT_FORM:
      return { ...state, ...newTimeSlotDefaults() }
    case types.SHOW_NEW_DAYS_OFF_MODAL:
      return { ...state, showNewDaysOffModal: true }
    case types.HIDE_NEW_DAYS_OFF_MODAL:
      return { ...state, showNewDaysOffModal: false }
    case types.UPDATE_UNAVAILABLE_DAYS:
      return updateSingleKey('unavailableDays', state, action)
  }
}

export const StateContext = createContext()

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
