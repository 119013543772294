import React, { useState, Fragment } from 'react'
import Select from 'components/Select'
import { useStateValue, types } from 'components/BookVisit/StateProvider'
import { dissoc, equals } from 'ramda'
import { useCurrentRoute } from 'react-navi'
import VisiteeSelector from 'components/BookVisit/VisiteeSelector'
import { PreviousVisitees } from 'types/graphql/PreviousVisitees'

interface VisiteeAndLocationEditProps {
  data?: PreviousVisitees | undefined | null,
  loading?: boolean,
}

const VisiteeAndLocationEdit: React.FC<VisiteeAndLocationEditProps> = React.memo(({ data = {}, loading }) => {
  const [{ address, visiteeId }, dispatch] = useStateValue()
  const currentRoute = useCurrentRoute()
  // keep track of visitee id being set from params
  const [visiteeIdHasBeenSetFromParams, setVisiteeIdHasBeenSetFromParams] = useState(false)
  const visitees = data?.companion?.visitees || []
  const updateVisiteeIdAndAddressAndVisitCodings = id => {
    const visiteeById = visitees.find(i => i.id === id);
    // allow clearing of visitee id and address
    if (!id || !visiteeById) {
      dispatch({ type: types.UPDATE_VISITEE_ID, id: null })
      dispatch({
        type: types.UPDATE_PARAMS,
        address: null,
        availableVisitTypes: null,
        availableOrganizationCodes: [],
        useVisitCodes: null
      })
      return
    }

    const { customer: { address }, availableVisitTypes, useVisitCodes } = visiteeById
    const organization = visiteeById?.customer?.organization
    let availableOrganizationCodes: any[] = []

    availableOrganizationCodes = useVisitCodes === true && organization?.visitCodings ? organization.visitCodings : []


    dispatch({ type: types.UPDATE_VISITEE_ID, id })
    dispatch({
      type: types.UPDATE_PARAMS,
      address: address,
      availableVisitTypes: availableVisitTypes,
      availableOrganizationCodes: availableOrganizationCodes,
      useVisitCodes: useVisitCodes
    })
  }

  // if we have a visitee id from URL params and it has not been set yet,
  // update address, visitee id and visitee id from params flag
  if (currentRoute.data.visiteeId && !address && !loading && !visiteeIdHasBeenSetFromParams) {
    updateVisiteeIdAndAddressAndVisitCodings(currentRoute.data.visiteeId)
    setVisiteeIdHasBeenSetFromParams(true)
  }

  return (
    <Fragment>
      <Select
        data-cy='visits-new--visitee-select'
        handler={updateVisiteeIdAndAddressAndVisitCodings}
        items={[
          { value: null, text: 'Select person being visited' },
          ...visitees.map(v => ({
            value: v.id,
            text: `${v.customer.person.firstNameAndLastInitial}`,
            selected: v.id === visiteeId
          }))
        ]}
      />
      <VisiteeSelector />
    </Fragment>
  )
}, equals)

export default VisiteeAndLocationEdit
