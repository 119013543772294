import React from 'react'
import VerifyLoginCode from '../VerifyLoginCodeScreen'

interface VerifySignUpCodeProps {
  userEmail: string
}

const VerifySignUpCode: React.FC<VerifySignUpCodeProps> = ({ userEmail }) => {
  return (
    <VerifyLoginCode
      userEmail={userEmail}
      title='Verify your Email'
      description={`We sent you an email to ${userEmail} containing a verification code. Enter the six-digit code below to continue`}
      submitText='Verify Email'
    />
  )
}

export default VerifySignUpCode
