import React, { useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import { notify } from 'components/ClientNotification'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { storyIcons } from 'components/EditVisitStory/storyIcons'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import { UPDATE_VISIT_STORY_MUTATION } from 'mutations/updateVisitStory'
import { UpdateVisitStory, UpdateVisitStoryVariables } from 'types/graphql/UpdateVisitStory'
import YesNoQuestion from '../YesNoQuestion'

const Shift = ({ visit, client, state, anythingPrivateToShare, anyPhotosOrSpecialMoments }) => {
  const navigation = useNavigation()
  const [hasAnyPhotosMemories, setHasAnyPhotosMemories] = useState(null)
  const [anythingPrivate, setAnythingPrivate] = useState(null)
  const [shiftSafe, setShiftSafe] = useState<boolean | null>(null)
  const [shiftExperience, setShiftExperience] = useState<string | null>(null)
  const [completeButton, setCompleteButton] = useState(false)

  const flashMessage = message => {
    notify(client, {
      message: message,
      type: 'success'
    })
  }

  const visitStoryCreated = _ => {
    navigation.navigate(`${routePaths.visits}?tab=completed`)
    flashMessage('Your recap has been created!')
  }
  const rateExperienceList = [
    { value: 'poor', icon: 'faceSad' },
    { value: 'okay', icon: 'faceMeh' },
    { value: 'excellent', icon: 'faceHappy' }
  ]
  const [visitMutationData, setVisitMutationData] = useState({
    companionAdminNotes: '',
    description: ''
  })

  const stateStartAtEndAt = () => {
    return {
      startAt: setTimeOnDate(state.date, state.startAt),
      endAt: setTimeOnDate(state.date, state.endAt)
    }
  }

  const createVS = createVSMutation => async () => {
    setCompleteButton(true)
    if (shiftExperience === null || shiftSafe === null || hasAnyPhotosMemories === null || anythingPrivate === null)
      return
    return createVSMutation({
      variables: {
        input: {
          ...visitMutationData,
          ...stateStartAtEndAt(),
          id: visit.visitStory.id,
          status: 'completed',
          shiftExperience: shiftExperience,
          shiftSafe: shiftSafe
        }
      }
    })
  }

  const inputChangeHandler = (e, value) => {
    setVisitMutationData({
      ...visitMutationData,
      [e.target.name]: value
    })
  }

  return (
    <>
      <Mutation<UpdateVisitStory, UpdateVisitStoryVariables>
        mutation={UPDATE_VISIT_STORY_MUTATION}
        refetchQueries={[
          { query: COMPLETED_VISITS, variables: { scope: 'completed' } },
          { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
          'CompanionQuery',
          'CompanionMilestoneMessages'
        ]}
        onError={raiseErrorIfCantBeIgnored}
        context={{ hasUpload: true }}
        onCompleted={visitStoryCreated}
      >
        {(createVSMutation, { error }) => (
          <>
            <div className='ph3'>
              <h4 className='fw6'>Recap</h4>
              <div className='flex-l flex-row flex-nowrap justify-between mt3'>
                <p className='fw6 w-60'>How would you rate your experience? *</p>
                <div className='w-70 flex flex-row flex-nowrap justify-between mt-3'>
                  {rateExperienceList.map(experience => (
                    <div
                      key={experience.value}
                      onClick={() => setShiftExperience(experience.value)}
                      data-cy={`story--shift-experience-${experience.value}`}
                      className={`pointer f6`}
                    >
                      {shiftExperience === experience.value ? (
                        <img src={storyIcons[experience.icon].active} />
                      ) : (
                          <img src={storyIcons[experience.icon].inactive} />
                        )}
                    </div>
                  ))}
                </div>
              </div>
              {shiftExperience === null && completeButton && (
                <div className='red'>We need to know how {visit.visitee.customer.person.preferredOrFirstName} is doing.</div>
              )}
              <br />
              <YesNoQuestion
                question='Did you feel you had adequate safety measures? *'
                setMethod={setShiftSafe}
                getMethod={shiftSafe}
                completeButton={completeButton}
                name='safety-measures'
              />
              {anyPhotosOrSpecialMoments(
                setHasAnyPhotosMemories,
                hasAnyPhotosMemories,
                completeButton,
                visitMutationData,
                inputChangeHandler,
                error
              )}
              {anythingPrivateToShare(
                setAnythingPrivate,
                anythingPrivate,
                completeButton,
                visitMutationData,
                inputChangeHandler
              )}
              <Button onClick={createVS(createVSMutation)} className='mt3' data-cy='story--submit'>
                Complete {visit.visitTypeFriendlyName} Recap
              </Button>
            </div>
          </>
        )}
      </Mutation>
    </>
  )
}

export default Shift
