import { gql } from '@apollo/client'

export const TENTATIVE_VISITS = gql`
  query TentativeVisits($scope: String) {
    metaData {
      visitCancelationReasons {
        key
        value
      }
    }
    companion {
      id
      person {
        id
        preferredOrFirstName
      }
      visits(scope: $scope) {
        id
        scheduleType
        startAt
        endAt
        expectCompletionAt
        createdAt
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
          }
        }
        address {
          id
          addressLine1
          addressLine2
          city
          state
          zip
        }
      }
    }
  }
`
