import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import { gql } from '@apollo/client'
import withApollo from 'helpers/withApollo'
import TextField from 'components/TextField'
import { Mutation } from '@apollo/client/react/components'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import Button from 'components/Button'
import TimeOption from './TimeOption'
import { dissoc, map } from 'ramda'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import withStyles from 'react-jss'
import style from './styles'
import getError from 'helpers/getError'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'

const VISIT_DETAILS_QUERY = gql`
  query OpportunitySuggestAnotherTimeQuery($opportunityId: ID!) {
    companion {
      id
      opportunity(id: $opportunityId) {
        id
        opportunityFriendlyName
        opportunitySuggestions {
          id
        }
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
          }
        }
      }
    }
  }
`

const CREATE_OPPORTUNITY_SUGGESTION_MUTATION = gql`
  mutation CreateOpportunitySuggestion($input: CreateOpportunitySuggestionInput!) {
    createOpportunitySuggestion(input: $input) {
      opportunitySuggestion {
        id
      }
    }
  }
`

export const getSuggestAnotherTimeView = async ({ params: { id } }, { client }) => {
  const {
    data: {
      companion: { opportunity }
    }
  } = await client.query({
    query: VISIT_DETAILS_QUERY,
    variables: { opportunityId: id }
  })
  return <SuggestAnotherTimeWrapper opportunity={opportunity} />
}
const SuggestAnotherTime = ({ opportunity, classes }) => {
  const [companionNotes, setCompanionNotes] = useState('')
  const [opportunitySuggestionSlots, setOpportunitySuggestionSlots] = useState([{ id: 0 }])
  const navigation = useNavigation()

  const setOpportunitySuggestionSlot = ({ id }) => ({ startAt, endAt }) => {
    setOpportunitySuggestionSlots([
      ...opportunitySuggestionSlots.filter(slot => slot.id !== id),
      { id: id, startAt: startAt, endAt: endAt }
    ])
  }

  const addSlot = _ => {
    setOpportunitySuggestionSlots([...opportunitySuggestionSlots, { id: opportunitySuggestionSlots.length }])
  }

  const removeSlot = id => _ => {
    setOpportunitySuggestionSlots(opportunitySuggestionSlots.filter(slot => slot.id !== id))
  }

  const submit = mutation => async () => {
    const attrs = {
      companionNotes: companionNotes,
      opportunityId: opportunity.id,
      opportunitySuggestionSlots: map(dissoc('id'), opportunitySuggestionSlots)
    }
    return mutation({
      variables: {
        input: attrs
      }
    })
  }

  const canSubmit = _ => {
    return (
      companionNotes &&
      opportunitySuggestionSlots.every(
        slot => slot.startAt && slot.startAt.isValid() && slot.endAt && slot.endAt.isValid()
      )
    )
  }

  const suggestionSubmitted = _ => navigation.navigate(routePaths.opportunities)

  return (
    <>
      <TopBar title='Suggest Another Time' left='menu' />
      <ContentWrapper noPadding>
        <Mutation
          mutation={CREATE_OPPORTUNITY_SUGGESTION_MUTATION}
          onError={raiseErrorIfCantBeIgnored}
          onCompleted={suggestionSubmitted}
          refetchQueries={['OpportunitiesQuery']}
        >
          {(mutation, { error, loading }) => (
            <>
              <div className='ph125 pt125 bb b--gray'>
                <p className='mt0'>
                  You are suggesting another time for this {opportunity.opportunityFriendlyName} opportunity with{' '}
                  {opportunity.visitee.customer.person.firstNameAndLastInitial} You can suggest more than one time.
                </p>
                <TimeOption setSlot={setOpportunitySuggestionSlot({ id: 0 })} />
              </div>
              <SlideDown>
                {opportunitySuggestionSlots
                  .filter(slot => slot.id !== 0)
                  .sort((a, b) => a.id - b.id)
                  .map(slot => (
                    <div className='ph125 pt125 pb2 bb b--gray' key={slot.id}>
                      <TimeOption setSlot={setOpportunitySuggestionSlot({ id: slot.id })} />
                      <div className={`darkest-gray f6 ${classes.removeOption}`} onClick={removeSlot(slot.id)}>
                        <i className='icon-delete' />
                        Remove Option
                      </div>
                    </div>
                  ))}
              </SlideDown>
              <div className='ph125 pv3 bb b--gray pointer' onClick={addSlot}>
                <i className='icon-add purple' /> Add Another Suggestion
              </div>
              <div className='ph125 pt125'>
                <p className='mt0'>
                  <strong>
                    Why are you excited about this {opportunity.opportunityFriendlyName}? We will share this with{' '}
                    {opportunity.visitee.customer.person.preferredOrFirstName}.
                  </strong>
                </p>
                <TextField
                  multiline
                  autoAdjustHeight
                  rows={5}
                  className='mb2 mv3'
                  onChange={(_, value) => setCompanionNotes(value)}
                  name='description'
                  errorMessage={getError('companionNotes', error)}
                  data-cy='opportunities--suggest-companion-note'
                />
                <Button disabled={!canSubmit()} onClick={submit(mutation)} data-cy='opportunities--suggest-submit'>
                  Send Suggestion
                </Button>
                {getError('opportunitySuggestionSlots', error) && (
                  <SlideDown>
                    <div className='red'>One or more time options are invalid.</div>
                  </SlideDown>
                )}
              </div>
            </>
          )}
        </Mutation>
      </ContentWrapper>
    </>
  )
}

const SuggestAnotherTimeWrapper = withApollo(withStyles(style)(SuggestAnotherTime))
