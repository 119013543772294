import React, { useState } from 'react'
import TextField from 'components/TextField'
import getError from 'helpers/getError'
import { Mutation } from '@apollo/client/react/components'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { ReimbursementQuestion } from '../ReimbursementQuestion'
import { UPDATE_VISIT_STORY_MUTATION } from 'mutations/updateVisitStory'
import { UpdateVisitStory, UpdateVisitStoryVariables } from 'types/graphql/UpdateVisitStory'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import YesNoQuestion from '../YesNoQuestion'
import { currentMileageRepresentation } from '../helpers'

const Service = ({
  visit,
  visitStoryCreated,
  state,
  anythingPrivateToShare,
  anyPhotosOrSpecialMoments,
  howVisiteeIsDoing,
  usesReimbursements
}) => {
  const [healthCondition, setHealthCondition] = useState<boolean | null>(null)
  const [healthConcern, setHealthConcern] = useState<boolean | null>(null)
  const [hasAnyPhotosMemories, setHasAnyPhotosMemories] = useState<boolean | null>(null)
  const [everythingDone, setEverythingDone] = useState<boolean | null>(null)
  const [needReimbursement, setNeedReimbursement] = useState<boolean | null>(null)
  const [anythingPrivate, setAnythingPrivate] = useState<boolean | null>(null)
  const [completeButton, setCompleteButton] = useState<boolean>(false)

  const [visitMutationData, setVisitMutationData] = useState({
    companionAdminNotes: '',
    description: '',
    serviceExplanation: '',
    expensesCents: undefined,
    mileage: undefined
  })

  const inputChangeHandler = (e, value) => {
    setVisitMutationData({
      ...visitMutationData,
      [e.target.name]: value
    })
  }

  const stateStartAtEndAt = () => {
    return {
      startAt: setTimeOnDate(state.date, state.startAt),
      endAt: setTimeOnDate(state.date, state.endAt)
    }
  }

  const createVS = createVSMutation => async () => {
    setCompleteButton(true)
    if (
      healthCondition === null ||
      hasAnyPhotosMemories === null ||
      everythingDone === null ||
      (usesReimbursements && needReimbursement === null) ||
      anythingPrivate === null
    )
      return
    const expensesCents = Math.round((visitMutationData.expensesCents || 0) * 100)
    return createVSMutation({
      variables: {
        input: {
          ...visitMutationData,
          ...stateStartAtEndAt(),
          id: visit.visitStory.id,
          status: 'completed',
          expensesCents: expensesCents === 0 || !needReimbursement ? null : expensesCents,
          surveyWellbeing: healthCondition,
          surveyHealthConcern: healthConcern,
          paymentDetails: needReimbursement ? 'reimbursement' : null,
          mileage: currentMileageRepresentation(visitMutationData.mileage)
        }
      }
    })
  }

  return (
    <>
      <Mutation<UpdateVisitStory, UpdateVisitStoryVariables>
        mutation={UPDATE_VISIT_STORY_MUTATION}
        refetchQueries={[
          { query: COMPLETED_VISITS, variables: { scope: 'completed' } },
          { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
          'CompanionQuery',
          'CompanionMilestoneMessages'
        ]}
        onError={raiseErrorIfCantBeIgnored}
        context={{ hasUpload: true }}
        onCompleted={visitStoryCreated}
      >
        {(createVSMutation, { error, loading }) => (
          <>
            <div className='ph3'>
              <h4 className='fw6 f4-5'>Recap</h4>
              {howVisiteeIsDoing(
                healthCondition,
                setHealthCondition,
                healthConcern,
                setHealthConcern,
                completeButton,
                error
              )}
              {anyPhotosOrSpecialMoments(
                setHasAnyPhotosMemories,
                hasAnyPhotosMemories,
                completeButton,
                visitMutationData,
                inputChangeHandler,
                error
              )}
              <YesNoQuestion
                question='Did you get everything done? *'
                setMethod={setEverythingDone}
                getMethod={everythingDone}
                completeButton={completeButton}
                name='everything-done'
              />
              {!everythingDone && everythingDone != null && (
                <>
                  <p className='fw6'>Explain below:</p>
                  <TextField
                    multiline
                    autoAdjustHeight
                    rows={6}
                    className='mb2 mt3'
                    value={visitMutationData.serviceExplanation}
                    onChange={inputChangeHandler}
                    name='serviceExplanation'
                    errorMessage={getError('serviceExplanation', error)}
                    data-cy='story--service-explanation'
                  />
                </>
              )}
              { usesReimbursements &&
                <ReimbursementQuestion
                  setNeedReimbursement={setNeedReimbursement}
                  needReimbursement={needReimbursement}
                  completeButton={completeButton}
                  visitMutationData={visitMutationData}
                  inputChangeHandler={inputChangeHandler}
                  error={error}
                  visit={visit}
                />
              }
              {anythingPrivateToShare(
                setAnythingPrivate,
                anythingPrivate,
                completeButton,
                visitMutationData,
                inputChangeHandler
              )}
              <Button onClick={createVS(createVSMutation)} className='mt3' data-cy='story--submit'>
                Complete {visit.visitTypeFriendlyName} Recap
              </Button>
            </div>
          </>
        )}
      </Mutation>
    </>
  )
}

export default Service
