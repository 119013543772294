import React, { useState, useEffect } from 'react'
import withApollo from 'helpers/withApollo'
import { NormalizedCacheObject, useMutation, ApolloClient } from '@apollo/client'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { notify } from 'components/ClientNotification'
import { RequestLoginCode, RequestLoginCodeVariables } from 'types/graphql/RequestLoginCode'
import { REQUEST_LOGIN_CODE } from 'mutations/requestLoginCode'
import { setEndpointEnvAndTrimEmail } from 'helpers/graphqlConfig'

const RESET_TOKEN_DELAY = 30

interface RequestNewCodeProps {
  client: ApolloClient<NormalizedCacheObject>,
  userEmail: string,
}

const RequestNewCode: React.FC<RequestNewCodeProps> = ({ client, userEmail }) => {
  const [seconds, setSeconds ] =  useState(RESET_TOKEN_DELAY);

  useEffect(()=>{
    let myInterval = setInterval(() => {
      if (seconds > 0) {
          setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval)
      }
    }, 1000)
    return ()=> {
        clearInterval(myInterval);
      };
  });


  const [requestCode] = useMutation<RequestLoginCode, RequestLoginCodeVariables>(REQUEST_LOGIN_CODE, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Error with server. Code was not sent',
        type: 'error'
      })
    },
    onCompleted: () => {
      setSeconds(RESET_TOKEN_DELAY)
      notify(client, {
        message: 'Email Sent',
        type: 'success'
      })

    }
  })

  return (
    <a
      onClick={() => { !seconds && requestCode({ variables: { input: { email: setEndpointEnvAndTrimEmail(userEmail) } } })}}
      className={`mv3 underline pointer ${seconds && 'disabled'}`}
      data-cy='request-login-code--submit'
    >
      Request new Code {seconds !== 0 && `(${seconds} seconds)`}
    </a>
  )
}

export default withApollo(RequestNewCode)
