import { gql } from '@apollo/client'

export const COMPANION_TYPE_QUERY = gql`
  query CompanionTypeQuery {
    companion @client {
      id
      companionType
    }
  }
`
