import colors from 'helpers/colors'

export const FurtherActionButtonStyles = {
  button: {
    border: `1px solid ${colors.purple} !important`,
    color: colors.black,
    background: colors.white
  }
}

export default {}
