import { gql } from '@apollo/client'

export const PROFILE_QUERY = gql`
  query ProfileQuery {
    availableSkills {
      id
      name
    }
    availableLanguages {
      id
      name
    }
    companion {
      id
      schoolName
      companionType
      skills {
        id
        name
      }
      person {
        id
        fullName
        primaryLanguage {
          id
          name
        }
        languages {
          id
          name
        }
      }
      profile {
        id
        isStudent
        currentJob
        childhoodCareerAspiration
        about
        interestedReason
        elderlyInteractionExperience
        lovedOneStory
        hobbiesAndInterests
        favoriteThing
        favoriteQuote
      }
    }
  }
`
