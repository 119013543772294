import React, { useEffect } from 'react'
import withApollo from 'helpers/withApollo'
import { equals } from 'ramda'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import getError from 'helpers/getError'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import Header from './Header'
import Telepresence from './Telepresence'
import Service from './Service'
import Personal from './Personal'
import Shift from './Shift'
import dayjs from 'helpers/dayjs'
import EditDateModal from './EditDateModal'
import CalendarDisplay from 'components/CalendarDisplay'
import { useStateValue, types } from 'components/EditVisitStory/StateProvider'
import TimeRangeInput, { timeRangeErrors } from 'components/TimeRangeInput'
import datesAreDifferent from 'helpers/datesAreDifferent'
import EditVisitTime from './EditVisitTime'
import DurationDisplay from './DurationDisplay'
import withStyles from 'react-jss'
import styles from './styles'
import TextField from 'components/TextField'
import { storyIcons } from 'components/EditVisitStory/storyIcons'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { useCurrentRoute } from 'react-navi'
import { openModal } from 'components/Layout/Modal'
import Modal from './Modal'
import { EditVisitStoryQuery_companion_visit, EditVisitStoryQuery_metaData } from 'types/graphql/EditVisitStoryQuery'
import PhotoInput from './PhotoInput'
import YesNoQuestion from './YesNoQuestion'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

interface EditVisitStoryProps {
  visit: EditVisitStoryQuery_companion_visit
  metaData: EditVisitStoryQuery_metaData
  classes: any
  client: ApolloClient<NormalizedCacheObject>
}

const EditVisitStory: React.FC<EditVisitStoryProps> = ({ classes, client, visit, metaData }) => {

  const navigation = useNavigation()
  const currentRoute = useCurrentRoute()
  const [state, dispatch] = useStateValue()

  const healthConditions = [
    { value: 'concerned', icon: 'faceSad' },
    { value: 'okay', icon: 'faceMeh' },
    { value: 'excellent', icon: 'faceHappy' }
  ]
  const concerns = ['mental_health', 'access_to_food', 'flu_symptoms', 'other'].map(a => ({
    value: a,
    text: metaData.visitStorySurveyHealthConcerns.find(b => b.key === a)?.value
  }))

  const organization = visit?.visitee?.customer?.organization?.name || 'Mon Ami'

  const openCalendar = () => dispatch({ type: types.SHOW_SELECT_DATE_MODAL })

  const updateTime = ([start, end]) => {
    const startAt = setTimeOnDate(visit.startAt, start).toDate()
    const endAt = setTimeOnDate(visit.endAt, end).toDate()
    if (datesAreDifferent(state.startAt, start)) dispatch({ type: types.UPDATE_START_AT, startAt })
    if (datesAreDifferent(state.endAt, end)) dispatch({ type: types.UPDATE_END_AT, endAt })
  }

  const visitStoryCreated = () => {
    const visitStartDate = dayjs(visit.startAt).toDate()
    const diffInDaysFromToday = dayjs().diff(visitStartDate, 'day')

    if (currentRoute.url.query['isOldVisitSubmission']) {
      openModal(client, {
        Component: ({ closeModal }) => (
          <Modal closeModal={closeModal} visitType={visit.visitTypeFriendlyName}/>
        )
      })
      navigation.navigate(`${routePaths.visits}?tab=completed`)
    } else if (visit.visitRecurrenceGroupPresent || diffInDaysFromToday >= 7)
      navigation.navigate(`${routePaths.visits}?tab=completed`)
    else
      navigation.navigate(
        `${routePaths.bookVisit}?visiteeId=${encodeURIComponent(visit.visitee.id)}&visitId=${encodeURIComponent(
          visit.id
        )}`
      )
  }

  useEffect(() => {
    const startAt = visit.startAt ? dayjs(visit.startAt).toDate() : dayjs(state.startAt).toDate()
    const endAt = visit.endAt ? dayjs(visit.endAt).toDate() : dayjs(state.endAt).toDate()
    const date = visit.startAt
    dispatch({ type: types.UPDATE_START_AT, startAt })
    dispatch({ type: types.UPDATE_END_AT, endAt })
    dispatch({ type: types.UPDATE_DATE, date })
  }, [visit.startAt, visit.endAt])

  const setHealthConditionAndNullConcern = (value, setHealthConcern, setHealthCondition) => {
    setHealthCondition(value)
    setHealthConcern(null)
  }

  const howVisiteeIsDoing = (
    healthCondition,
    setHealthCondition,
    healthConcern,
    setHealthConcern,
    completeButton,
    error
  ) => {
    return (
      <>
        <div className='flex-l flex-row flex-nowrap justify-between mt3'>
          <p className='fw6 w-60'>How's {visit.visitee.customer.person.preferredOrFirstName} doing? *</p>
          <div className='w-70 flex flex-row flex-nowrap justify-between mt-3'>
            {healthConditions.map(condition => (
              <div
                key={condition.value}
                onClick={() => setHealthConditionAndNullConcern(condition.value, setHealthConcern, setHealthCondition)}
                data-cy={`story--condition-${condition.value}`}
                className={`pointer f6`}
              >
                {healthCondition === condition.value ? (
                  <img src={storyIcons[condition.icon].active} />
                ) : (
                    <img src={storyIcons[condition.icon].inactive} />
                  )}
              </div>
            ))}
          </div>
        </div>
        {healthCondition === null && completeButton && (
          <div className='red'>We need to know how {visit.visitee.customer.person.preferredOrFirstName} is doing.</div>
        )}
        <br />
        {healthCondition === 'concerned' && (
          <>
            <p className='fw6'>Top Concern (Select one)</p>
            <div className='flex flex-row flex-wrap justify-between'>
              {concerns.map(concern => (
                <a
                  key={concern.value}
                  onClick={() => setHealthConcern(concern.value)}
                  className='w-50 mb2'
                  data-cy={`story--concern-${concern.value}`}
                >
                  <div className={`${classes.concernBox} ${healthConcern === concern.value && 'active'}`}>
                    {concern.text}
                  </div>
                </a>
              ))}
            </div>
            {getError('surveyHealthConcern', error) && <div className='red'>You must select one.</div>}
          </>
        )}
      </>
    )
  }

  const anythingPrivateToShare = (
    setAnythingPrivate,
    anythingPrivate,
    completeButton,
    visitMutationData,
    inputChangeHandler
  ) => {
    return (
      <>
        <YesNoQuestion
          question={`Anything private to share with ${organization}? *`}
          setMethod={setAnythingPrivate}
          getMethod={anythingPrivate}
          completeButton={completeButton}
          name={'anything-private'}
        />
        {anythingPrivate && (
          <>
            <p>
              <span className='fw6'>Add comments below</span> ({visit.visitee.customer.person.preferredOrFirstName} won’t see these comments)
            </p>
            <TextField
              multiline
              autoAdjustHeight
              rows={6}
              className='mb2 mt3'
              placeholder='(Optional)'
              value={visitMutationData.companionAdminNotes}
              onChange={inputChangeHandler}
              name='companionAdminNotes'
              data-cy='story--private'
            />
          </>
        )}
      </>
    )
  }

  const anyPhotosOrSpecialMoments = (
    setHasAnyPhotosMemories,
    hasAnyPhotosMemories,
    completeButton,
    visitMutationData,
    inputChangeHandler,
    error
  ) => {
    return (
      <>
        <YesNoQuestion
          question='Do you have any photos or special moments to share? *'
          setMethod={setHasAnyPhotosMemories}
          getMethod={hasAnyPhotosMemories}
          completeButton={completeButton}
          name={'memories-photos'}
        />
        {hasAnyPhotosMemories && (
          <>
            <PhotoInput error={error} photoLabel='Add Photo' isReceipt={false} id={1} visit={visit}/>
            <p className='fw6 w-60'>Describe your experience</p>
            <TextField
              multiline
              autoAdjustHeight
              rows={6}
              className='mb2 mt3'
              value={visitMutationData.description}
              onChange={inputChangeHandler}
              name='description'
              errorMessage={getError('description', error)}
              data-cy='story--description'
            />
          </>
        )}
      </>
    )
  }

  const VisitTypeFields = ({usesReimbursements}) => {
    switch (visit.visitType) {
      case 'telepresence':
        return (
          <Telepresence
            visit={visit}
            visitStoryCreated={visitStoryCreated}
            state={state}
            anythingPrivateToShare={anythingPrivateToShare}
            anyPhotosOrSpecialMoments={anyPhotosOrSpecialMoments}
            howVisiteeIsDoing={howVisiteeIsDoing}
          />
        )
      case 'service':
        return (
          <Service
            visit={visit}
            visitStoryCreated={visitStoryCreated}
            state={state}
            anythingPrivateToShare={anythingPrivateToShare}
            anyPhotosOrSpecialMoments={anyPhotosOrSpecialMoments}
            howVisiteeIsDoing={howVisiteeIsDoing}
            usesReimbursements={usesReimbursements}
          />
        )
      case 'shift':
        return (
          <Shift
            visit={visit}
            client={client}
            state={state}
            anythingPrivateToShare={anythingPrivateToShare}
            anyPhotosOrSpecialMoments={anyPhotosOrSpecialMoments}
          />
        )
      default:
        return (
          <Personal
            visit={visit}
            visitStoryCreated={visitStoryCreated}
            state={state}
            anythingPrivateToShare={anythingPrivateToShare}
            anyPhotosOrSpecialMoments={anyPhotosOrSpecialMoments}
            howVisiteeIsDoing={howVisiteeIsDoing}
            usesReimbursements={usesReimbursements}
          />
        )
    }
  }

  const openEditTime = () => dispatch({ type: types.SHOW_EDIT_VISIT_TIME_MODAL })
  return (
    <>
      <EditDateModal />

      <EditVisitTime timeChangeHandler={updateTime} startAt={state.startAt} endAt={state.endAt} visit={visit} />

      <TopBar
        title={`Complete ${visit.visitTypeFriendlyName} Recap`}
        left='visits'
        visiteeId={visit.visitee.id}
      />

      <ContentWrapper noPadding Header={<Header visit={visit} />}>
        {visit.scheduleType === 'flexible' ? (
          <div className='pa3 bb b--gray'>
            <div className='mb3 b'>When did you visit {visit.visitee.customer.person.preferredOrFirstName}?</div>
            <CalendarDisplay date={state.date || dayjs()} openCalendar={openCalendar} />
            <TimeRangeInput
              timeChangeHandler={updateTime}
              errorMessage={timeRangeErrors(state)}
              time={[state.startAt, state.endAt]}
              setDefaultEndTime
            />
          </div>
        ) : (
            <div className='pa3 bb b--gray'>
              <DurationDisplay openEditTime={openEditTime} startAt={dayjs(state.startAt)} endAt={dayjs(state.endAt)} />
            </div>
          )}

        <VisitTypeFields usesReimbursements={visit.visitee.customer.organization?.usesReimbursements}/>
      </ContentWrapper>
    </>
  )
}

export default withStyles(styles)(withApollo(React.memo(EditVisitStory, equals)))
