import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import OpportunityCard from './OpportunityCard'
import { Query } from '@apollo/client/react/components'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import withApollo from 'helpers/withApollo'
import withStyles from 'react-jss'
import { isEmpty } from 'ramda'
import { NoOpportunitiesStyle, RefreshButtonStyle } from './styles'
import { OPPORTUNITIES_QUERY } from 'components/Availability/queries'
import { OpportunitiesQuery } from 'types/graphql/OpportunitiesQuery'

const NoOpportunities = withStyles(NoOpportunitiesStyle)(({ classes, refetch }) => (
  <div className={`tc ${classes.noOpportunities} pt2`}>
    <RefreshButton refetch={refetch} />
    <div className='f3 tc mt3 mb4 ph3'>No new opportunities are open at the moment. Check back here later!</div>
  </div>
))

const RefreshButton = withStyles(RefreshButtonStyle)(({ classes, refetch }) => {
  const [refreshing, setRefreshing] = useState(false)
  return (
    <div className='flex justify-center mb2'>
      <div
        className={`purple m0auto bg-white pa2 fw4 ${classes.button}`}
        onClick={async () => {
          setRefreshing(true)
          await refetch()
          setRefreshing(false)
        }}
      >
        <i className={`icon-refresh mr2 purple ${classes.icon} ${refreshing ? classes.rotating : ''}`} />
        Refresh
      </div>
    </div>
  )
})

const Opportunities = ({ client }) => {
  return (
    <>
      <TopBar title='Browse Opportunities' left='menu' />
      <ContentWrapper styles='bg-light-gray' noPadding>
        <Query<OpportunitiesQuery> query={OPPORTUNITIES_QUERY}>
          {({ data, loading, refetch }) => {
            updateBusyIndicators(client, { bar: loading })

            const companion = data?.companion
            const opportunities = companion?.opportunities

            if (loading || !companion || !opportunities || isEmpty(opportunities))
              return <NoOpportunities refetch={refetch} />

            return (
              <div className='pt2 pr125 pb125 pl125'>
                <RefreshButton refetch={refetch} />
                {opportunities.map(opportunity => (
                  <OpportunityCard
                    key={opportunity.id}
                    availableTimes={companion.availableTimes}
                    opportunity={opportunity}
                  />
                ))}
              </div>
            )
          }}
        </Query>
      </ContentWrapper>
    </>
  )
}

export default withApollo(Opportunities)
