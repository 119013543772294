export default {
  black: '#212224',
  darkestGray: '#525960',
  darkGray: '#BAC2CC',
  gray: '#DBDFE4',
  lightGray: '#F8F9FB',
  white: '#FFFFFF',
  blue: '#56CCF7',
  darkBlue: '#4A90E2',
  eggShell: '#FFFBEE',
  yellow: '#F8F5BE',
  green: '#68D56D',
  teal: '#18D3AF',
  red: '#FF6773',
  lightRed: 'rgba(255, 51, 67, 0.15)',
  googleRed: '#DB5335',
  lightGreen: '#EAFDEB',
  lighterGreen: '#0a6267',
  lighterGreen2: '#5eb5ab',
  lighterGreen3: '#e1efef',
  borderGreen: '#b6d1cf',
  borderDarkGreen: '#006267',
  darkestGreen: '#19434c',
  darkestGreen2: '#006468',
  labelGreenColor: '#b1ccca',
  purple: '#6a79d3',
  lighterPurple: '#e1eaff',
  pink: '#ffd9d2',
}
