import { gql } from '@apollo/client'

const companionUpcomingVisitsFragment = gql`
  fragment companionUpcomingVisits on Visit {
    id
    startAt
    endAt
    expectCompletionAt
    status
    visitRecurrenceGroupPresent
    visitType
    scheduleType
    address {
      id
      addressLine1
      addressLine2
      city
      state
      zip
    }
    visitee {
      id
      phoneNumber
      visiteePhoto {
        id
        cdnUrlTemplate
      }
      customer {
        id
        person {
          id
          preferredOrFirstName
          firstNameAndLastInitial
        }
        designatedContact {
          id
          person {
            id
            preferredOrFirstName
          }
        }
        phoneNumber
      }
    }
  }
`

export default companionUpcomingVisitsFragment
