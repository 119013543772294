import visitIsPending from 'helpers/visitIsPending'

export default {
  container: {
    borderRadius: '4px',
    fontSize: '15px',
    '@media screen and (max-width: 374px)': {
      fontSize: '0.6rem'
    }
  },
  photoContainer: {
    width: '100%',
    height: '100%',
    verticalAlign: 'bottom',
    borderBottomLeftRadius: ({ visit }) => (visitIsPending(visit) ? '4px' : null)
  },
  button: {
    height: '52px',
    lineHeight: '52px'
  },
  badge: {
    bottom: 0
  },
  type: {
    backgroundColor: '#f8f9fb',
    textAlign: 'center',
    borderBottom: '1px solid #dbdfe4',
    boxShadow: '0px 0px 9px 0px rgba(33, 34, 36, 0.06)'
  }
}
