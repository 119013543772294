import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import dayjs from 'helpers/dayjs'
import colors from 'helpers/colors'
import Badge from 'components/Badge'
import { openModal } from 'components/Layout/Modal'
import withApollo from 'helpers/withApollo'
import { pipe } from 'ramda'
import AcceptModal from './AcceptModal'
import CancelModal from './CancelModal'
import { TentativeVisits_companion_visits, TentativeVisits_metaData_visitCancelationReasons } from 'types/graphql/TentativeVisits'

interface TentativeVisitCardProps {
  visit: TentativeVisits_companion_visits
  cancellationReasons: TentativeVisits_metaData_visitCancelationReasons[] | undefined
  client: any
  classes: any
}

const TentativeVisitCard: React.FC<TentativeVisitCardProps> = ({ classes, visit, client, cancellationReasons }) => {
  const startAt = dayjs(visit.startAt)
  const endAt = dayjs(visit.endAt || visit.expectCompletionAt)

  const showAcceptModal = () =>
    openModal(client, {
      isBlocking: true,
      Component: ({ closeModal }) => <AcceptModal {...{ closeModal, visit }} />
    })

  const showCancelModal = () =>
    openModal(client, {
      isBlocking: true,
      Component: ({ closeModal }) => <CancelModal {...{ closeModal, visit, cancellationReasons }} />
    })

  return (
    <div className={`bg-white pt3 bottom-shadow mb3 ${classes.container}`} data-cy='tentative--card'>
      <div className='ph3 pb4 relative'>
        <div className='mb2'>
          <span className='fw6'>{visit.visitee.customer.person.firstNameAndLastInitial}</span> is requesting a visit with you
        </div>
        {visit.scheduleType === 'flexible' ? (
          <>
            <div className='mb1'>
              <i className='icon-calendar label-green mr2' />
              Timing is flexible. Complete by {endAt.format('ddd, MMM DD')}
            </div>
          </>
        ) : (
            <>
              <div className='mb1'>
                <i style={{ width: '1.25rem' }} className='icon-calendar tc label-green mr2' />
                {startAt.format('ddd, MMM DD')}
              </div>
              <div className='mb1'>
                <i style={{ width: '1.25rem' }} className='icon-time tc label-green mr2' />
                {startAt.format('h:mmA')} - {endAt.format('h:mmA')}
              </div>
            </>
          )}
        {visit.address && (
          <div className='flex'>
            <i className='icon-location label-green f4' />
            <div className={`ml2 ${classes.address}`}>
              <div>{visit.address.addressLine1}</div>
              <div>{visit.address.addressLine2}</div>
              <div>
                {visit.address.city}, {visit.address.state} {visit.address.zip}
              </div>
            </div>
          </div>
        )}
        <Badge
          // @ts-ignore Need to figure out JSS props
          direction='right'
          text={`Expires ${dayjs(visit.createdAt)
            .add(1, 'day')
            .fromNow()}`}
          color={colors.red}
          backgroundColor={colors.lightGray}
          containerClass={classes.badge}
        />
      </div>
      <div>
        <div
          data-cy='tentative--show-cancel-modal'
          onClick={showCancelModal}
          className={`dib tc bt br b--gray w-50 pointer ${classes.button}`}
        >
          <i className='icon-decline red mr1' />
          Decline
        </div>
        <div
          data-cy='tentative--show-accept-modal'
          onClick={showAcceptModal}
          className={`dib tc bt b--gray w-50 pointer ${classes.button}`}
        >
          <i className='icon-accept green mr1' />
          Accept
        </div>
      </div>
    </div>
  )
}

export default pipe(withStyles(style), withApollo)(TentativeVisitCard)
