import React from 'react'
import NoVisits from 'components/Visits/NoVisits'
import { useQuery } from '@apollo/client'
import { isEmpty } from 'ramda'
import CompletedVisitCard from './CompletedVisitCard'
import BusyIndicator from 'components/BusyIndicator'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import { CompletedVisits as CompletedVisitsQuery, CompletedVisitsVariables } from 'types/graphql/CompletedVisits'

const variables = { scope: 'completed' }

export const tabRefreshCompletedVisits = { query: COMPLETED_VISITS, variables }

const CompletedVisits: React.FC = () => {
  const { data, loading } = useQuery<CompletedVisitsQuery, CompletedVisitsVariables>(COMPLETED_VISITS, { variables })
  return <>
    { loading && <BusyIndicator isBusy delayMs={0} color='#ffc20d' />}
    { isEmpty(data?.companion?.visits) ? (
      <NoVisits visitType='completed' companionName={data?.companion?.person?.preferredOrFirstName!} />
    ) : (
        data?.companion?.visits?.map(visit => <CompletedVisitCard visit={visit} key={visit.id} />)
      )}
  </>
}

export default CompletedVisits
