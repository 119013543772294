import React, { useRef, useEffect } from 'react'
import withStyles from 'react-jss'
import style from './styles'
import { equals } from 'ramda'
import { blobToBinaryString } from 'blob-util'
import piexifjs from 'piexifjs'
import photoToBlob from './photoToBlob'
import companionPlaceholder from 'assets/images/companionPlaceholder.png'
import LazyPhoto from 'components/LazyPhoto'

const PhotoHandler = ({
  file,
  classes,
  id,
  uploadPhoto,
  removeFile,
  setUploading,
  uploading,
  photo,
  isSinglePhoto,
  setPhotoError
}) => {
  const destinationCanvas = useRef(null)
  const srcCanvas = useRef(null)

  const prepImageAndUpload = async () => {
    setUploading(true)
    try {
      const data = piexifjs.load(await blobToBinaryString(file))

      const previewURL = URL.createObjectURL(file)

      const image = new Image()
      image.onload = async () => {
        srcCanvas.current.width = image.naturalWidth
        srcCanvas.current.height = image.naturalHeight

        const blob = await photoToBlob({
          data,
          srcCanvas,
          destinationCanvas,
          image
        })

        await uploadPhoto(blob)
        !isSinglePhoto && removeFile(id)
        setUploading(false)
      }
      image.src = previewURL

      return image
    } catch (error) {
      console.warn(error)
      removeFile(id)
      setPhotoError('Image format is invalid, please use jpeg')
      setUploading(false)
      return null
    }
  }

  useEffect(() => {
    file && prepImageAndUpload()
  }, [file])

  return (
    <>
      <div
        className={`relative br2 overflow-hidden ${isSinglePhoto ? classes.photoContainer : classes.previewContainer}`}
        style={{
          opacity: uploading ? '.2' : ''
        }}
      >
        <LazyPhoto photo={photo} placeholderImage={companionPlaceholder} className='mb2 w-100 radius' />

        {uploading && <i className={`icon-refresh purple ${classes.loading}`} />}
      </div>

      <canvas ref={destinationCanvas} className='dn' />
      <canvas ref={srcCanvas} className='dn' />
    </>
  )
}

export default React.memo(withStyles(style)(PhotoHandler), equals)
