export default {
  container: {
    borderRadius: '4px'
  },
  button: {
    height: '52px',
    lineHeight: '52px'
  },
  badge: {
    bottom: 0,
    right: 0,
    lineHeight: '16px'
  }
}
