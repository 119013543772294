import React, { useState, useEffect } from 'react'
import withStyles from 'react-jss'
import style, { ArrowStyle } from './styles'
import dayjs from 'helpers/dayjs'
import { isEmpty, isNil, head, nth, flip, prop, pipe, splitEvery } from 'ramda'
import Swipe from 'react-easy-swipe'

const isPresent = item => !isEmpty(item) && !isNil(item)

const Arrow = withStyles(ArrowStyle)(({ right = false, active, classes, onClick }) => (
  <div className={`self-end ${active ? '' : 'disabled'} ${classes.container}`}>
    <i onClick={onClick} className={`pointer icon-arrow-${right ? 'right' : 'left'} ${right && 'fr'}`} />
  </div>
))

const createPlacholderPills = arr => {
  if (arr.length === 2) return [...arr, { placeholder: true }]
  if (arr.length === 1) return [...arr, { placeholder: true }, { placeholder: true }]
  else return arr
}

const AvailableDatesAndTimes = ({ timeSlots, classes, setChosenTimeSlot, length, shift, opportunityFriendlyName }) => {
  const [chosenDay, setChosenDay] = useState(0)
  const [chosenTime, setChosenTime] = useState(0)
  const [viewableDays, setViewableDays] = useState(0)
  const [viewableTimes, setViewableTimes] = useState(0)
  const [swipeLocked, setSwipeLocked] = useState(false)

  const groupedTimeSlots = splitEvery(3, timeSlots)
  const groupedTimes = splitEvery(3, timeSlots[chosenDay])

  useEffect(() => {
    setChosenTimeSlot(displayedDays[chosenDay][chosenTime])
  }, [chosenDay, chosenTime, viewableDays, viewableTimes])

  const displayedDays = createPlacholderPills(timeSlots.slice(viewableDays * 3, viewableDays * 3 + 3))

  const displayedTimes = createPlacholderPills(splitEvery(3, displayedDays[chosenDay])[viewableTimes])

  const daysBefore = viewableDays === 0 ? false : isPresent(groupedTimeSlots[viewableDays - 1])
  const daysAfter = viewableDays + 1 === groupedTimeSlots.length ? false : isPresent(groupedTimeSlots[viewableDays + 1])

  const timesBefore = viewableTimes === 0 ? false : isPresent(groupedTimes[viewableTimes - 1])
  const timesAfter = viewableTimes + 1 === groupedTimes.length ? false : isPresent(groupedTimes[viewableTimes + 1])

  const weekPillText = pipe(flip(nth)(displayedDays), head, prop('startAt'), date => dayjs(date).format('ddd M/D'))

  const navigateToDaysBefore = () => {
    if (daysBefore) {
      setChosenDay(0)
      setChosenTime(0)
      setViewableTimes(0)
      setViewableDays(viewableDays - 1)
    }
  }

  const navigateToDaysAfter = () => {
    if (daysAfter) {
      setChosenDay(0)
      setChosenTime(0)
      setViewableTimes(0)
      setViewableDays(viewableDays + 1)
    }
  }

  const navigateToTimesBefore = () => {
    if (timesBefore) {
      setChosenTime((viewableTimes - 1) * 3)
      setViewableTimes(viewableTimes - 1)
    }
  }

  const navigateToTimesAfter = () => {
    if (timesAfter) {
      setChosenTime((viewableTimes + 1) * 3)
      setViewableTimes(viewableTimes + 1)
    }
  }

  const onSwipeMoveDates = (position, event) => {
    if (swipeLocked) return
    if (position.x > 20) {
      setSwipeLocked(true)
      navigateToDaysBefore()
    } else if (position.x < -20) {
      setSwipeLocked(true)
      navigateToDaysAfter()
    }
  }

  const onSwipeMoveTimes = (position, event) => {
    if (swipeLocked) return
    if (position.x > 20) {
      setSwipeLocked(true)
      navigateToTimesBefore()
    } else if (position.x < -20) {
      setSwipeLocked(true)
      navigateToTimesAfter()
    }
  }

  const onSwipeEnd = () => setSwipeLocked(false)

  const availableTimesLabel = () => {
    if (shift && displayedDays[chosenDay][chosenTime]) {
      const shiftLength = displayedDays[chosenDay][chosenTime].duration / 60
      return `Available Times for a ${shiftLength}hr ${opportunityFriendlyName} on ${weekPillText(chosenDay)}`
    } else {
      return `Available Times for a ${length}hr ${opportunityFriendlyName} on ${weekPillText(chosenDay)}`
    }
  }

  return <>
    {/* DAYS */}
    <div className='fw6 mb125'>Available Dates</div>
    <div className='flex flex-row flex-nowrap mb4'>
      <Arrow active={daysBefore} onClick={navigateToDaysBefore} />
      <div className='flex-grow-1'>
        <Swipe onSwipeMove={onSwipeMoveDates} onSwipeEnd={onSwipeEnd}>
          <div className={`flex flex-row flex-nowrap justify-between ph2`}>
            {displayedDays.map((days, index) => {
              if (days.placeholder) return <div key={index} className={classes.dayPlaceholder} />

              return (
                <div
                  className={`relative pointer f6 tc ${chosenDay === index ? classes.selectedDayPill : classes.dayPill
                    }`}
                  onClick={() => {
                    if (chosenDay === index) return
                    setChosenDay(index)
                    setChosenTime(0)
                    setViewableTimes(0)
                  }}
                  key={index}
                  data-cy={`opportunities--day-pill-${index}`}
                >
                  {weekPillText(index)}
                </div>
              )
            })}
          </div>
        </Swipe>
      </div>
      <Arrow right active={daysAfter} onClick={navigateToDaysAfter} />
    </div>
    {/* TIMES */}
    <div className='fw6 mb125'>{availableTimesLabel()}</div>
    <div className='flex flex-row flex-nowrap mb4'>
      <Arrow active={timesBefore} onClick={navigateToTimesBefore} />
      <div className='flex-grow-1'>
        <Swipe onSwipeMove={onSwipeMoveTimes} onSwipeEnd={onSwipeEnd}>
          <div className={`flex flex-row flex-nowrap justify-between w-100 ph2`}>
            {displayedTimes.map(({ startAt, placeholder }, index) => {
              if (placeholder) return <div key={index} className={classes.timePlaceholder} />
              return (
                <div
                  className={`relative pointer tc ${chosenTime === index + 3 * viewableTimes ? classes.selectedTimePill : classes.timePill
                    }`}
                  onClick={_ => setChosenTime(index + 3 * viewableTimes)}
                  key={index}
                  data-cy={`opportunities--time-pill-${index}`}
                >
                  {dayjs(startAt).format('h:mma')}
                </div>
              )
            })}
          </div>
        </Swipe>
      </div>
      <Arrow right active={timesAfter} onClick={navigateToTimesAfter} />
    </div>
  </>
}

export default withStyles(style)(AvailableDatesAndTimes)
