import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import withApollo from 'helpers/withApollo'
import { CANCEL_VISIT_MUTATION } from 'mutations/cancelVisit'
import TextField from 'components/TextField'
import { CompletedVisits_companion_visits } from 'types/graphql/CompletedVisits'
import { CancelVisit, CancelVisitVariables } from 'types/graphql/CancelVisit'

interface CancelModalProps {
  visit: CompletedVisits_companion_visits
  closeModal: any
}

const CancelModal: React.FC<CancelModalProps> = ({ closeModal, visit }) => {
  const startAt = dayjs(visit.startAt)
  const [cancelationMessage, setCancelationMessage] = useState<string | undefined>('')
  const [cancelVisit] = useMutation<CancelVisit, CancelVisitVariables>(CANCEL_VISIT_MUTATION, {
    onError: raiseErrorIfCantBeIgnored,
    refetchQueries: ['CompletedVisits']
  })
  const dba = visit.visitee.customer.organization?.name || 'Mon Ami'

  return (
    <ModalBody closeModal={closeModal}>
      {({ modalClasses, CloseX }) => (
        <>
          <CloseX />
          <h3 className='ph3 f5'>Thanks for marking this {visit.visitTypeFriendlyName} as not completed</h3>
          <div className='tc ph3 mt0'>
            Please confirm you want to indicate that you weren’t able to make this {visit.visitTypeFriendlyName} with{' '}
            {visit.visitee.customer.person.preferredOrFirstName} on {startAt.format('ddd, MMM DD')} from {startAt.format('h:mmA')} to{' '}
            {dayjs(visit.endAt).format('h:mmA')}. It will notify {dba} that they may need to find a replacement.
            <TextField
              multiline
              autoAdjustHeight
              rows={6}
              className='mb2 mt3'
              label={`If you’d like to share anything with ${dba} about it, you can do so here (optional)`}
              value={cancelationMessage}
              onChange={(_, value) => setCancelationMessage(value)}
              name='cancelationMessage'
            />
          </div>
          <div className={`flex ${modalClasses.buttonContainer}`}>
            <div onClick={
              () => {
                cancelVisit({
                  variables: {
                    input: {
                      visitId: visit.id,
                      reason: 'visit_did_not_happen',
                      onBehalfOfCustomer: false,
                      cancelFuture: false,
                      cancelationMessage: cancelationMessage
                    }
                  }
                }).then(() => closeModal())
              }
            } data-cy='story--cancel'>
              <i className='icon-decline red f6 mr1' />
              Mark as not completed
            </div>
          </div>
        </>
      )}
    </ModalBody>
  )
}

export default withApollo(CancelModal)
