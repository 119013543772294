export default {
  clampContainer: {
    '& > div': {
      display: 'inline'
    }
  },
  claendarStarImage: {
    height: '15px'
  }
}
