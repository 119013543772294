import React from 'react'
import { useQuery } from '@apollo/client'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import routePaths from 'routes/paths'
import profile from 'assets/icons/profile.svg'
import notifications from 'assets/icons/notifications.svg'
import availability from 'assets/icons/availability.svg'
import account from 'assets/icons/account.svg'
import location from 'assets/icons/location.svg'
import payments from 'assets/icons/payments.svg'
import changeOrganization from 'assets/icons/change-organization.svg'
import Link from 'components/Link'
import BusyIndicator from 'components/BusyIndicator'
import withApollo from 'helpers/withApollo'
import logout from 'helpers/logout'
import { COMPANION_TYPE_QUERY } from 'queries/companionType'
import { CompanionTypeQuery } from 'types/graphql/CompanionTypeQuery'
import { useMutation } from '@apollo/client'
import { UpdateCompanion, UpdateCompanionVariables } from 'types/graphql/UpdateCompanion'
import { UPDATE_COMPANION_MUTATION } from 'mutations/updateCompanion'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { notify } from 'components/ClientNotification'
import { COMPANIONS_QUERY } from 'queries/companionsQuery'
import { CompanionsQuery } from 'types/graphql/CompanionsQuery'

const Item = ({ title, icon, route, dataCy }) => {
  return (
    <Link returnTo={routePaths.settings} route={route}>
      <div className='flex flex-nowrap flex-row bg-white pa3 mb3 br3 ba b--purple pointer' data-cy={dataCy}>
        <img src={icon} />
        <div className='flex-grow-1 ph2'>
          <div className='f6'>{title}</div>
        </div>
      </div>
    </Link>
  )
}

export const flashMessage = (client, message) => {
  notify(client, {
    message: message,
    type: 'success'
  })
}

export const curryFlashMessage = (client, message) => () => flashMessage(client, message)

export const updateCompanionMutation = onCompleted => {
  return useMutation<UpdateCompanion, UpdateCompanionVariables>(UPDATE_COMPANION_MUTATION, {
    refetchQueries: ['CompanionSettingsQuery'],
    onError: raiseErrorIfCantBeIgnored,
    onCompleted: onCompleted
  })
}

const Settings = ({ client }) => {
  const { data, loading } = useQuery<CompanionTypeQuery>(COMPANION_TYPE_QUERY)
  const { data: dataCompanions, loading: loadingCompanions } = useQuery<CompanionsQuery>(COMPANIONS_QUERY)

  if (loading || !data || !dataCompanions || loadingCompanions) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  const isRegular = data.companion?.companionType === 'regular'
  const isService = data.companion?.companionType === 'service'
  const isRegularOrService = isService || isRegular

  return <>
    <TopBar title='Settings' left='menu' />
    <ContentWrapper styles='justify-center flex'>
      <>
        <Item title={'Edit Profile'} icon={profile} route={routePaths.profile} dataCy={'settings--profile'} />
        <Item
          title={'Notifications'}
          icon={notifications}
          route={routePaths.notifications}
          dataCy={'settings--notifications'}
        />
        {isRegularOrService && (
          <Item title={'Location'} icon={location} route={routePaths.location} dataCy={'settings--location'} />
        )}
        <Item
          title={'Availability'}
          icon={availability}
          route={routePaths.availability}
          dataCy={'settings--availability'}
        />

        {dataCompanions.companions && dataCompanions.companions?.length > 1 && <Item title={'Switch Organization'} icon={changeOrganization} route={routePaths.personSelection} dataCy={'settings--person-selection'} />}
        <Item title={'Deactivate Account'} icon={account} route={routePaths.account} dataCy={'settings--account'} />
        <div
          className='flex flex-nowrap flex-row bg-white pa3 mb3 br3 ba b--red pointer'
          onClick={() => logout(client)}
          data-cy='log-out'
        >
          Log Out
        </div>
      </>
    </ContentWrapper>
  </>
}

export default withApollo(Settings)
