import IS_BUSY_QUERY from 'queries/clientIsBusy'
import { mergeRight, curry } from 'ramda'

const updateBusyIndicators = (client, options) => {
  const { isBusy } = client.readQuery({ query: IS_BUSY_QUERY })
  client.writeQuery({
    query: IS_BUSY_QUERY,
    data: {
      isBusy: mergeRight(isBusy, options)
    }
  })
}

export default curry(updateBusyIndicators)
