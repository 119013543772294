import React from 'react'
import { Query } from '@apollo/client/react/components'
import { VISIT_STORY_PHOTOS_QUERY, uploadPhoto } from '../queries'
import MultiPhotoInput from '../../../components/MultiPhotoInput'
import { photosFromData } from '../helpers'
import getError from '../../../helpers/getError'
import withApollo from 'helpers/withApollo'
import { ApolloClient, NormalizedCacheObject, ApolloError } from '@apollo/client'
import { EditVisitStoryQuery_companion_visit } from 'types/graphql/EditVisitStoryQuery'

interface PhotoInputProps {
  error: ApolloError | undefined
  photoLabel: string
  isReceipt: boolean
  id: number,
  visit: EditVisitStoryQuery_companion_visit,
  client: ApolloClient<NormalizedCacheObject>
}

const PhotoInput: React.FC<PhotoInputProps> = ({ error, photoLabel, isReceipt, id, visit, client }) => (
  <Query query={VISIT_STORY_PHOTOS_QUERY} variables={{ visitId: visit.id }}>
    {({ data }) => (
      <MultiPhotoInput
        uploadPhoto={uploadPhoto(visit, client, isReceipt)}
        photos={photosFromData(data, isReceipt ? 'receiptPhotos' : 'photos')}
        visit={visit}
        error={getError(isReceipt ? 'receiptPhotos' : 'photos', error)}
        label={photoLabel}
        id={id}
      />
    )}
  </Query>
)

export default withApollo(PhotoInput)
