import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import Card from './Card'
import ContentWrapper from 'components/ContentWrapper'

import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface HeaderProps {
  visit: VisitDetailsQuery_companion_visit
  classes: any
}

const Header: React.FC<HeaderProps> = ({ visit, classes }) => {
  return (
    <ContentWrapper noScroll noGrow styles='pb125 bg-egg-shell'>
      {visit.firstVisitForLinkage && (
        <div className={`tc red ${classes.firstVisit}`}>
          This is your first visit with {visit.visitee.customer.person.preferredOrFirstName}. Be sure to read all background info below before your
          first visit.
        </div>
      )}
      <Card visit={visit} />
    </ContentWrapper>
  )
}

export default withStyles(style)(Header)
