import React, { useEffect } from 'react'
import withApollo from 'helpers/withApollo'
import ContentWrapper from 'components/ContentWrapper'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import logo from 'assets/images/new-logo.svg'
import withStyles from 'react-jss'
import style from './styles'
import { NormalizedCacheObject, ApolloClient } from '@apollo/client'
import RequestLoginCode from '../RequestLoginCodeScreen'
import ONBOARDING from 'queries/clientOnboarding'

interface SignUpProps {
  client: ApolloClient<NormalizedCacheObject>,
  classes,
  userEmail: string
}

const SignUp: React.FC<SignUpProps> = ({ client, classes, userEmail }) => {

  useEffect(() => {
    client.writeQuery({ query: ONBOARDING, data: { onboarding: true } })
  }, [])

  return (
    <ContentWrapper styles='justify-center flex'>
      <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
        <div className='h-100 flex flex-column align-center justify-center'>
          <img className={`db margin-auto mb2 ${classes.logo}`} src={logo} />
          <div className={`tc f3 ${classes.header}`}>Volunteer</div>
          <div className='flex flex-column items-center justify-center'>
            <h2 className='mb0'>Set up your Account</h2>
            <p className='tc'>Enter the email you provided to the organization you're volunteering for</p>
          </div>

          <RequestLoginCode client={client} userEmail={userEmail} successRoute={routePaths.verifySetupCode} submitText='Next'/>

          <Link route={routePaths.login} className='tc pointer underline mt5' data-cy='navigate-to-login'>
            I already have an account—Log in
          </Link>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default withApollo(withStyles(style)(SignUp))
