import { equals, type, cond, isNil, always, pathOr } from 'ramda'

export const currentMileageRepresentation = cond([
  [mileage => equals('String', type(mileage)), Number],
  [isNil, always(0)]
])

export const photosFromData = (data, photos) => {
  return pathOr([], ['companion', 'visit', 'visitStory', photos], data)
}

export const photosFromVisit = (data, photos) => {
  return pathOr([], ['visitStory', photos], data)
}
