import { gql } from '@apollo/client'

export const COMPANIONS_QUERY = gql`
  query CompanionsQuery {
    companions {
      id
      person {
        id
        firstNameAndLastInitial
        organization {
          id
          name
          logoUrl
        }
      }
    }
  }
`
