const environmentNames = ['demo', 'staging', 'tofu', 'kale', 'colmar', 'maple', 'bacon']

export const setEndpointEnvAndTrimEmail = email => {
  const environment = environmentNames.find(name => email.endsWith(`:${name}`))

  if (environment) {
    global.GRAPHQL_ENDPOINT_ENVIRONMENT = environment
    return email.replace(`:${environment}`, '')
  } else {
    global.GRAPHQL_ENDPOINT_ENVIRONMENT = null
  }

  return email
}
