import React, { useState } from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'
import withApollo from 'helpers/withApollo'
import { head, prop, pipe, filter, includes, __, map } from 'ramda'
import { renameKeys } from 'ramda-adjunct'
import Select from 'components/Select'
import { CANCEL_VISIT_MUTATION } from 'mutations/cancelVisit'
import { Mutation } from '@apollo/client/react/components'
import { TentativeVisits_companion_visits, TentativeVisits_metaData_visitCancelationReasons } from 'types/graphql/TentativeVisits'

const formatCancelationReasons = pipe(
  // @ts-ignore
  filter(pipe(prop('key'), includes(__, ['other', 'too_busy_this_week']))),
  map(renameKeys({ key: 'value', value: 'text' }))
)

// @ts-ignore
const firstValue = pipe(head, prop('value'))

interface CancelModalProps {
  visit: TentativeVisits_companion_visits
  closeModal: any
  cancellationReasons: TentativeVisits_metaData_visitCancelationReasons
}

export const CancelModal: React.FC<CancelModalProps> = ({ closeModal, visit, cancellationReasons }) => {
  // @ts-ignore
  const reasons = formatCancelationReasons(cancellationReasons)
  // @ts-ignore
  const [reason, setReason] = useState(firstValue(reasons))

  return (
    <ModalBody closeModal={closeModal}>
      {({ modalClasses, CloseX }) => (
        <>
          <CloseX />
          <h3>Decline Visit</h3>
          <p className='tc ph3 mt0'>
            You are declining a visit with <strong>{visit.visitee.customer.person.firstNameAndLastInitial}</strong> for{' '}
            <strong>
              {dayjs(visit.startAt).format('ddd MMM D')} at {dayjs(visit.startAt).format('h:mma')}-
              {dayjs(visit.endAt).format('h:mma')}
            </strong>
          </p>
          <div className='tc mb3'>Please tell us why this visit doesn't work for you:</div>
          <Select handler={setReason} items={reasons} />
          <div className={`flex ${modalClasses.buttonContainer}`}>
            <Mutation
              mutation={CANCEL_VISIT_MUTATION}
              variables={{
                input: {
                  reason,
                  visitId: visit.id,
                  cancelFuture: false,
                  onBehalfOfCustomer: false
                }
              }}
              refetchQueries={['TentativeVisits']}
              onCompleted={closeModal}
            >
              {mutation => (
                <div onClick={mutation} data-cy='tentative--decline-visit'>
                  Decline
                </div>
              )}
            </Mutation>
          </div>
        </>
      )}
    </ModalBody>
  )
}

export default withApollo(CancelModal)
