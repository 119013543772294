import React, { useState } from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'
import ChoiceGroup from 'components/ChoiceGroup'
import { Mutation } from '@apollo/client/react/components'
import { DELETE_AVAILABLE_TIME_MUTATION } from 'components/Availability/queries'

const Modal = ({ closeModal, timeSlot }) => {
  const [cancelFuture, setCancelFuture] = useState(false)
  const updateCancelFuture = (_, { key }) => setCancelFuture(key === 'everyTimeSlot')

  return (
    <ModalBody closeModal={closeModal}>
      {({ modalClasses, CloseX }) => (
        <>
          <CloseX />
          <Mutation
            refetchQueries={['AvailableTimes', 'OpportunitiesQuery']}
            mutation={DELETE_AVAILABLE_TIME_MUTATION}
            variables={{ input: { availableTimeId: timeSlot.id, cancelFuture } }}
            onCompleted={closeModal}
          >
            {mutation => (
              <>
                <h3 className='darkest-green'>Delete Timeslot</h3>
                <p className='tc ph3 mt0'>
                  {dayjs(timeSlot.startAt).format('ddd, MMM D [at] h:mm A')} -
                  {dayjs(timeSlot.startAt)
                    .add(timeSlot.duration, 'minutes')
                    .format('h:mm A')}
                </p>
                {timeSlot.futureOccurrenceCount > 1 && (
                  <ChoiceGroup
                    defaultSelectedKey='oneTimeSlot'
                    options={[
                      {
                        key: 'oneTimeSlot',
                        text: 'This timeslot'
                      },
                      {
                        key: 'everyTimeSlot',
                        text: `This and future ${dayjs(timeSlot.startAt).format('dddd[s]')}`
                      }
                    ]}
                    onChange={updateCancelFuture}
                  />
                )}
                <div className={`flex ${modalClasses.buttonContainer}`}>
                  <div onClick={mutation} data-cy='destroy-timeslot'>
                    <i className='mr2 green icon-accept' />
                    Confirm
                  </div>
                </div>
              </>
            )}
          </Mutation>
        </>
      )}
    </ModalBody>
  )
}

export default Modal
