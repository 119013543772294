import colors from 'helpers/colors'

export default {
  timeRangeContainer: {
    position: 'relative',
    padding: '0.75rem',
    border: `1px solid ${colors.darkestGreen2}`,
    borderRadius: '4px'
  },
  timeRange: {
    '& > div': {
      border: 0
    }
  },
  iconContainer: {
    lineHeight: '44px',
    borderLeft: `1px solid ${colors.darkestGreen2}`
  }
}
