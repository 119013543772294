import { gql } from '@apollo/client'

export const UPDATE_VISIT_STORY_MUTATION = gql`
  mutation UpdateVisitStory($input: UpdateVisitStoryInput!) {
    updateVisitStory(input: $input) {
      visitStory {
        id
        photos {
          id
          cdnUrlTemplate
        }
      }
    }
  }
`
