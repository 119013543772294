import { CurrentUser_currentUser } from "types/graphql/CurrentUser"

const setTokenAndContextAndRedirect = (
  navigation: any,
  currentUser: CurrentUser_currentUser,
): void => {
  localStorage.setItem('authenticationToken', currentUser.authenticationToken)
  navigation.setContext({ ...navigation._router.context, currentUser })
  navigation.navigate('/')
}

export default setTokenAndContextAndRedirect
