import React from 'react'
import { NEXT_VISIT_DETAILS_QUERY, createVisitStoryFromCheckIn } from './queries'
import LocationExplanation from './LocationExplanation/pre'
import getCurrentPosition, { currentPositionCloseToAddress } from './getCurrentPosition'
import { pipe, prop, isNil, props, complement, any } from 'ramda'
import VisitCheckIn from './index'
import paths from 'routes/paths'
import Redirect from 'components/Redirect'

const visitStoryIsNil = pipe(
  prop('visitStory'),
  isNil
)

export const visitAddressPositionExists = pipe(
  prop('address'),
  props(['latitude', 'longitude']),
  complement(any(isNil))
)

const getLocationAndRenderView = async ({ visit, position }) => {
  if (position && visitAddressPositionExists(visit)) {
    const closeEnough = await currentPositionCloseToAddress(
      visit.address.latitude,
      visit.address.longitude,
      position
    )
    return closeEnough ? (
      <VisitCheckIn visit={visit} />
    ) : (
      <LocationExplanation visit={visit} />
    )
  } else return <VisitCheckIn visit={visit} />
}

const getVisitCheckInView = async ({ params: {} }, { client }) => {
  const {
    data: {
      companion: { nextVisit }
    }
  } = await client.query({
    query: NEXT_VISIT_DETAILS_QUERY,
    fetchPolicy: 'no-cache'
  })

  if (isNil(nextVisit)) return <Redirect to={paths.root} />

  if (visitStoryIsNil(nextVisit)) {
    const position = await getCurrentPosition(client)
    const {
      data: {
        createVisitStory: { visitStory }
      }
    } = await createVisitStoryFromCheckIn(nextVisit, client, position)
    return getLocationAndRenderView({
      visit: { ...nextVisit, visitStory },
      position
    })
  } else {
    return getLocationAndRenderView({ visit: nextVisit })
  }
}

export default getVisitCheckInView
