import React, { useState } from 'react'
import Button from 'components/Button'
import InputAndLabel from 'components/InputAndLabel'
import { useNavigation } from 'react-navi'
import { NormalizedCacheObject, useMutation } from '@apollo/client'
import { ApolloClient } from '@apollo/client/core';
import { RequestLoginCode, RequestLoginCodeVariables } from 'types/graphql/RequestLoginCode'
import { REQUEST_LOGIN_CODE } from 'mutations/requestLoginCode'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { clearNotice, notify } from 'components/ClientNotification'
import { setEndpointEnvAndTrimEmail } from 'helpers/graphqlConfig'

interface RequestLoginCodeProps {
  client: ApolloClient<NormalizedCacheObject>,
  userEmail: string
  successRoute: string
  submitText: string
}

const RequestLoginCodeScreen: React.FC<RequestLoginCodeProps> = ({ client, userEmail, successRoute, submitText }) => {
  const navigation = useNavigation()
  const [email, setEmail] = useState(userEmail)

  const [requestCode] = useMutation<RequestLoginCode, RequestLoginCodeVariables>(REQUEST_LOGIN_CODE, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Error with server',
        type: 'error'
      })
    },
    onCompleted: () => {
      clearNotice(client)
      navigation.navigate(`${successRoute}?email=${email}`)
    }
  })

  return (
    <>
      <InputAndLabel
        type='text'
        updateValue={setEmail}
        value={email}
        data-cy='email'
        placeholder='me@example.com'
      />
      <Button className='mb3' disabled={email === ''} onClick={() => { requestCode({ variables: { input: { email: setEndpointEnvAndTrimEmail(email) } } }) }} data-cy='request-login-code--submit'>
        {submitText}
      </Button>
    </>
  )
}

export default RequestLoginCodeScreen
