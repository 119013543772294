import colors from 'helpers/colors'

export const OpportunitiesInfoStyle = {
  button: {
    height: '52px',
    lineHeight: '52px'
  },
  calendarStarImage: {
    height: '15px'
  },
  borderTop: {
    borderTopStyle: 'solid',
    borderTopColor: colors.green
  }
}

export const NoOpportunitiesStyle = {
  noOpportunities: {
    margin: '0 auto',
    '& img': {
      margin: '50px auto 0 auto',
      maxWidth: '190px'
    }
  }
}

export const RefreshButtonStyle = {
  button: {
    height: '30px',
    lineHeight: '15px',
    borderRadius: '15px',
    cursor: 'pointer'
  },
  icon: {
    // if truly centered, icon rotates like an egg ;-;
    transformOrigin: '50% 44%',
    fontSize: '1.1rem',
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  rotating: {
    '-webkit-animation': 'rotating 2s ease infinite',
    '-moz-animation': 'rotating 2s ease infinite',
    '-ms-animation': 'rotating 2s ease infinite',
    '-o-animation': 'rotating 2s ease infinite',
    animation: 'rotating 2s ease infinite'
  }
}
