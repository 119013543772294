import React, { useState } from 'react'
import withStyles from 'react-jss'
import styles from './styles'
import { dissoc, pipe } from 'ramda'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import withApollo from 'helpers/withApollo'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'

const stripProps = pipe(
  dissoc('children'),
  dissoc('classes'),
  dissoc('className'),
  dissoc('btnClass'),
  dissoc('handler'),
  dissoc('onClick'),
  dissoc('client')
)

const fireHandler = (handler, client, setButtonDisabled) => async () => {
  setButtonDisabled(true)
  updateBusyIndicators(client, { bar: true, screen: true })
  try {
    await handler()
  } catch (error) {
    setButtonDisabled(false)
    updateBusyIndicators(client, { bar: false, screen: false })
    raiseErrorIfCantBeIgnored(error)
  }
  setButtonDisabled(false)
  updateBusyIndicators(client, { bar: false, screen: false })
}

const Button = props => {
  const { classes, children, className, btnClass, onClick, disabled, client } = props
  const buttonClasses = `pointer w-100 mb2 bn ${classes.button} ${
    classes[btnClass]
  } ${className || ''}`
  const [buttonDisabled, setButtonDisabled] = useState(false)

  return (
    <button
      {...stripProps(props)}
      onClick={onClick ? fireHandler(onClick, client, setButtonDisabled) : onClick}
      className={buttonClasses}
      disabled={disabled || buttonDisabled}
    >
      {children}
    </button>
  )
}

export default withApollo(withStyles(styles)(Button))
