import React, { useState } from 'react'
import { useNavigation } from 'react-navi'
import withApollo from 'helpers/withApollo'
import setTokenAndContextAndRedirect from './setTokenAndContextAndRedirect'
import Button from 'components/Button'
import InputAndLabel from 'components/InputAndLabel'
import ContentWrapper from 'components/ContentWrapper'
import { clearNotice, notify } from 'components/ClientNotification'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import setErrorTrackerIdentity from 'helpers/setErrorTrackerIdentity'
import logo from 'assets/images/new-logo.svg'
import withStyles from 'react-jss'
import style from './styles'
import routePaths from 'routes/paths'
import { setEndpointEnvAndTrimEmail } from 'helpers/graphqlConfig'
import { ApolloClient, NormalizedCacheObject, useMutation } from '@apollo/client';
import { LoginUser, LoginUserVariables } from 'types/graphql/LoginUser'
import { LOGIN_USER } from 'mutations/loginUser'
import Link from 'components/Link'

interface LoginFormProps {
  client: ApolloClient<NormalizedCacheObject>,
  classes: any
}

const LoginForm: React.FC<LoginFormProps> = ({ client, classes }) => {
  const navigation = useNavigation()

  const [login] = useMutation<LoginUser, LoginUserVariables>(LOGIN_USER, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Invalid email or password, please try again',
        type: 'error'
      })
    },
    onCompleted: ({ loginUser }) => {
      if (loginUser) {
        clearNotice(client)
        setErrorTrackerIdentity(loginUser.user)
        setTokenAndContextAndRedirect(navigation, loginUser.user)
      }
    }
  })

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const trimmedEmail = setEndpointEnvAndTrimEmail(email)

  return <ContentWrapper noPadding styles='justify-center flex'>
    <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
      <div className='h-100 ph125 flex flex-column align-center justify-center mt'>
        <div className={classes.borderDivide}>
          <img className={`db margin-auto mb2 ${classes.logo}`} src={logo} />
          <div className={`tc f3 ${classes.header}`}>Log in</div>
          <InputAndLabel label='Email Address' type='text' updateValue={setEmail} value={email} data-cy='email' />
          <InputAndLabel
            label='Password'
            type='password'
            updateValue={setPassword}
            value={password}
            data-cy='password'
          />

          <Link route={`${routePaths.resetPassword}?email=${email}`} className='pointer underline' data-cy='navigate-to-reset-password'>
            Forgot password
          </Link>

          <Button
            className='mv4'
            data-cy='login-submit'
            onClick={() => { login({ variables: { input: { email: trimmedEmail, password } } }) }}>
            Log In
          </Button>
        </div>

        <Link route={`${routePaths.requestSetupCode}?email=${email}`} className='tc pointer underline' data-cy='navigate-to-onboarding'>
          I don't have an account yet—Set up
        </Link>
      </div>
    </div>
  </ContentWrapper>
}

export default withApollo(withStyles(style)(LoginForm))
