import colors from 'helpers/colors'
import iconStyles from './icons'

export default {
  '@global': {
    html: {
      fontSize: '1rem'
    },
    body: {
      fontFamily: "'acumin-pro-wide', 'Source Sans Pro', sans-serif",
      color: colors.darkestGreen,
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
      height: '100vh'
    },
    select: {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      border: `1px solid ${colors.darkestGreen2}`
    },
    a: {
      color: colors.purple
    },
    button: {
      '-webkit-appearance': 'none',
      border: 'none',
      background: 'none'
    },
    // all inputs should do this
    'input:focus, textarea:focus': {
      outline: 'none !important',
      borderColor: colors.yellow
    },
    'input[type=number]::-webkit-inner-spin-button, \ninput[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: '0'
    },
    'input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0'
    },
    // time range picker
    '.react-timerange-picker__inputGroup__input': {
      boxSizing: 'content-box !important',
      color: colors.darkestGray
    },
    // change font size for very small screens
    '@media screen and (max-width: 374px)': {
      html: {
        fontSize: '13px'
      }
    },
    // full height for react container
    '#app': { height: '100vh' },
    // useful to apply when needed
    '.safePaddingTop': { paddingTop: 'env(safe-area-inset-top)' },
    '.safePaddingBottom': { paddingBottom: 'env(safe-area-inset-bottom)' },
    '.safePadding': {
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)'
    },
    '.radius': { borderRadius: '4px' },
    '.radius-bottom': { borderRadius: '0 0 4px 4px' },
    '.margin-auto': { margin: '0 auto' },
    '.bottom-shadow': { boxShadow: '0px 4px 9px 0px rgba(33, 34, 36, 0.06)' },
    '.shadow': { boxShadow: '0 2px 18px 0 rgba(33, 34, 36, 0.06)' },
    '.hr': {
      borderBottom: `1px solid ${colors.gray}`
    },
    '.disabled': {
      opacity: '0.5'
    },
    '.bg--none': { background: 'none' },
    ...iconStyles
  }
}
