import React from 'react'
import withStyles from 'react-jss'
import { gql } from '@apollo/client'
import style from './styles'
import withApollo from 'helpers/withApollo'
import dayjs from 'helpers/dayjs'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import LazyPhoto from 'components/LazyPhoto'

import { VisitStoryQuery_companion_visit, VisitStoryQuery_metaData } from 'types/graphql/VisitStoryQuery'

const VISIT_STORY_QUERY = gql`
  query VisitStoryQuery($visitId: ID!) {
    metaData {
      visitStoryShiftExperiences {
        key
        value
      }
    }
    companion {
      id
      visit(id: $visitId) {
        id
        visitType
        visitTypeFriendlyName
        visitStory {
          id
          description
          customerThanks
          startAt
          endAt
          photos {
            id
            cdnUrlTemplate
          }
          receiptPhotos {
            id
            cdnUrlTemplate
          }
          shiftComments
          shiftExperience
          shiftSafe
        }
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
            designatedContact {
              id
              person {
                id
                preferredOrFirstName
              }
            }
          }
        }
      }
    }
  }
`

export const getVisitStoryView = async ({ params: { id } }, { client }) => {
  const {
    data: {
      companion: { visit },
      metaData
    }
  } = await client.query({
    query: VISIT_STORY_QUERY,
    variables: { visitId: id }
  })
  return <VisitStory visit={visit} metaData={metaData} />
}

interface VisitStoryProps {
  visit: VisitStoryQuery_companion_visit
  metaData: VisitStoryQuery_metaData
  classes?: any
}

const VisitStory: React.FC<VisitStoryProps> = ({ classes, visit, metaData }) => {
  const { visitType, visitTypeFriendlyName, visitStory } = visit
  const { startAt, endAt, description, customerThanks, photos, shiftComments, shiftSafe, shiftExperience } = visitStory!
  const visitee = visit.visitee
  const customer = visit.visitee.customer

  const Header = _ => <>
    {photos.length > 0 && (
      <LazyPhoto photo={photos[0]} className={classes.photoContainer} operation={'resize_and_pad'} crop={'none'} />
    )}
    <div className='bg-yellow white fw6 pa1 tc'>
      <i className='icon-time' /> {dayjs(startAt).format('ddd, MMM DD')} at {dayjs(startAt).format('h:mmA')} -{' '}
      {dayjs(endAt).format('h:mmA')}
    </div>
  </>

  const isShiftVisit = visitType === 'shift'
  const getExperience = (visitStoryShiftExperiences, shiftExperience) => visitStoryShiftExperiences.find(item => item.key === shiftExperience).value
  const getShiftSafe = shiftSafe => (shiftSafe === true ? 'Yes' : 'No')

  return <>
    <TopBar title={`${visitTypeFriendlyName} Recap`} left='visits' visiteeId={visitee.id} />
    <ContentWrapper noPadding Header={Header}>
      <div className='ph3 pt3'>
        {(description || isShiftVisit) && (
          <>
            <div className='fw6'>
              Here’s what you shared with{' '}
              {customer.designatedContact
                ? `${customer.person.preferredOrFirstName}'s designated contact, ${customer.designatedContact.person.preferredOrFirstName}:`
                : `${customer.person.preferredOrFirstName}:`
              }
            </div>
            <p>{description}</p>
          </>
        )}

        {isShiftVisit && (
          <>
            <p>{shiftComments}</p>
            <div className='fw6'> How would you rate your experience? </div>
            <p>{getExperience(metaData.visitStoryShiftExperiences, shiftExperience)}</p>
            <div className='fw6'> Did you feel you had adequate safety measures? </div>
            <p>{getShiftSafe(shiftSafe)}</p>
          </>
        )}
      </div>
      {customerThanks && (
        <div className='ph3 pt3 bt b--gray'>
          <div className='fw6'>{customer.person.preferredOrFirstName}'s Thank You Note:</div>
          <p>{customerThanks}</p>
        </div>
      )}
    </ContentWrapper>
  </>
}

export default withStyles(style)(withApollo(VisitStory))
