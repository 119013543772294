import React, { useState, Fragment, RefAttributes } from 'react'
import withStyles from 'react-jss'
import style from './styles'
import Modal from './Modal'
import dayjs from 'helpers/dayjs'
import routePaths from 'routes/paths'
import Button from 'components/Button'
import getError from 'helpers/getError'
import { Mutation } from '@apollo/client/react/components'
import { useNavigation } from 'react-navi'
import TextField from 'components/TextField'
import ErrorMessage from 'components/ErrorMessage'
import { openModal } from 'components/Layout/Modal'
import { pipe, dissoc, isNil, isEmpty } from 'ramda'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { BOOK_VISIT_FROM_OPPORTUNITY } from 'mutations/bookVisitFromOpportunity'

import { OpportunityDetailsQuery_companion_opportunity } from 'types/graphql/OpportunityDetailsQuery'

interface FlexibleScheduleOpportunityProps {
  opportunity: OpportunityDetailsQuery_companion_opportunity
  opportunityMessage: string
  client: any
  classes: any
}

const FlexibleScheduleOpportunity: React.FC<FlexibleScheduleOpportunityProps> = ({ opportunity, opportunityMessage, client, classes }) => {
  const [companionMessage, setCompanionMessage] = useState<string | undefined>(undefined)
  const [companionMessageErrorMessage, setCompanionMessageErrorMessage] = useState<string | JSX.Element | undefined>(undefined)

  const customer = opportunity.visitee.customer
  const contactName = customer.designatedContact ? customer.designatedContact.person.preferredOrFirstName : customer.person.preferredOrFirstName

  const bookVisitIfCompanionMessagePresent = bookVisit => async () => {
    if (isEmpty(companionMessage) || isNil(companionMessage)) return setCompanionMessageErrorMessage('Message required')
    else return bookVisit()
  }

  const [errors, setErrors] = useState<Array<string> | null>(null)
  const onError = errors => {
    raiseErrorIfCantBeIgnored(errors)
    setErrors(errors)
  }
  const baseErrors = getError('base', errors)

  const visitBooked = () => {
    navigation.navigate(routePaths.opportunities)
    openModal(client, {
      Component: ({ closeModal }) => <Modal closeModal={closeModal} opportunity={opportunity} />
    })
  }

  const bookVisitVariables = {
    input: {
      companionMessage,
      address: pipe(dissoc('__typename'), dissoc('id'))(opportunity.address),
      opportunityId: opportunity.id,
      visiteeId: opportunity.visitee.id,
      duration: opportunity.length,
      status: 'pending'
    }
  }

  const navigation = useNavigation()

  return (
    <Fragment>
      <div className='pb125 pl125 pr125'>
        <div className={`mt3 fw6 ttu label-green ${classes.detailHeading}`}>TIMING FOR THIS VISIT IS FLEXIBLE</div>
        <div className={classes.detailText}>
          When confirmed, you will contact {contactName} to arrange a time before the below deadline.
        </div>
      </div>
      <div className='pb125 pl125 pr125'>
        <div className={`mt3 fw6 ttu label-green ${classes.detailHeading}`}>DEADLINE TO COMPLETE THIS VISIT</div>
        <div className={classes.detailText}>
          {dayjs(opportunity.flexibleScheduleEndAt).format('dddd MMMM DD, YYYY')}
        </div>
      </div>
      <div className='ph125 pv125 bb b--gray'>
        <div className='mb3 fw6'>{opportunityMessage}</div>
        <TextField
          multiline
          autoAdjustHeight
          placeholder={`I'm excited for this ${opportunity.opportunityFriendlyName}...`}
          rows={4}
          className='mb125'
          value={companionMessage}
          onChange={(_, notes) => setCompanionMessage(notes)}
          name='notes'
          errorMessage={companionMessageErrorMessage}
          data-cy='opportunities--companion-message'
        />
        {baseErrors && <ErrorMessage errorMessage={baseErrors} bordered />}
        <Mutation
          mutation={BOOK_VISIT_FROM_OPPORTUNITY}
          onError={onError}
          variables={bookVisitVariables}
          onCompleted={visitBooked}
          refetchQueries={['OpportunitiesQuery', 'UpcomingVisits']}
        >
          {bookVisit => (
            <div>
              <Button onClick={bookVisitIfCompanionMessagePresent(bookVisit)} data-cy='opportunities--book-visit'>
                Offer Assistance
              </Button>
            </div>
          )}
        </Mutation>
      </div>
    </Fragment>
  )
}

export default withStyles(style)(FlexibleScheduleOpportunity)
