import { pipe, prop, props, pipeP } from 'ramda'
import updateBusyIndicators from 'helpers/updateBusyIndicators'

const milesBetweenTwoPositions = (lat1, lng1, lat2, lng2) => {
  const earthsRadius = 3958.8 // in miles
  const dLat = toRad(lat2 - lat1)
  const dLng = toRad(lng2 - lng1)
  const lat1InRads = toRad(lat1)
  const lat2InRads = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1InRads) * Math.cos(lat2InRads)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthsRadius * c
}
const toRad = x => (x * global.Math.PI) / 180

const getPosition = () =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 3000
    })
  )

const extractCoordinates = pipe(
  prop('coords'),
  props(['latitude', 'longitude'])
)

const getCurrentLatLng = pipeP(
  getPosition,
  extractCoordinates
)

export const currentPositionCloseToAddress = async (
  lat,
  lng,
  [currentLat, currentLng]
) => {
  console.log('address lat and lng', lat, lng)
  console.log('does navigator exist?', !!global.navigator.geolocation)
  console.log('current lat and lng exists?', !!(currentLat && currentLng))
  if (global.navigator.geolocation && currentLat && currentLng) {
    try {
      console.log('current lat and lng', currentLat, currentLng)
      const miles = milesBetweenTwoPositions(lat, lng, currentLat, currentLng)
      console.log('miles', miles)
      return miles < 1
    } catch (e) {
      console.error(e)
      return true
    }
  } else return true
}

const getCurrentPosition = async client => {
  if (global.navigator.geolocation) {
    try {
      updateBusyIndicators(client, { bar: true, screen: true })
      const position = await getCurrentLatLng()
      updateBusyIndicators(client, { bar: false, screen: false })
      return position
    } catch (error) {
      console.error(error)
      updateBusyIndicators(client, { bar: false, screen: false })
      return [null, null]
    }
  }
}

export default getCurrentPosition
