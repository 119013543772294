import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import BusyIndicator from 'components/BusyIndicator'
import withApollo from 'helpers/withApollo'
import ChoiceGroup from 'components/ChoiceGroup'
import Button from 'components/Button'
import { COMPANION_SETTINGS_QUERY } from 'queries/companionSettingsQuery'
import { CompanionSettingsQuery } from 'types/graphql/CompanionSettingsQuery'
import { updateCompanionMutation, curryFlashMessage } from 'components/Settings'

const Notifications = ({ client }) => {
  const { data, loading } = useQuery<CompanionSettingsQuery>(COMPANION_SETTINGS_QUERY)
  const companion = data?.companion

  const onCompleted = curryFlashMessage(client, 'Your settings has been updated!')

  const [updateCompanion] = updateCompanionMutation(onCompleted)

  const [digestNotifications, setDigestNotifications] = useState(companion?.digestNotifications)
  const [individualNotifications, setIndividualNotifications] = useState(companion?.individualNotifications)
  const initialSelectedKey = () => {
    if (companion?.digestNotifications && companion?.individualNotifications) {
      return 'both'
    } else if (companion?.digestNotifications) {
      return 'digestNotifications'
    } else {
      return 'individualNotifications'
    }
  }

  const [selectedKey, setSelectedKey] = useState(initialSelectedKey()) as any

  const batchingOptions = [
    { key: 'individualNotifications', text: 'Notify me of every opportunity' },
    { key: 'digestNotifications', text: 'Notify me of opportunities in a digest once per day' },
    { key: 'both', text: 'Both' }
  ]

  useEffect(() => {
    setSelectedKey(initialSelectedKey())
  }, [companion])

  const handleChange = key => {
    setSelectedKey(key)
    switch (key) {
      case 'individualNotifications':
        setIndividualNotifications(true)
        setDigestNotifications(false)
        break
      case 'digestNotifications':
        setIndividualNotifications(false)
        setDigestNotifications(true)
        break
      case 'both':
        setIndividualNotifications(true)
        setDigestNotifications(true)
    }
  }

  if (loading || !data) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  return (
    <>
      <TopBar title='Notifications' left='menu' />
      <ContentWrapper styles='justify-center flex'>
        <ChoiceGroup
          label='Mon Ami sends out opportunities to make a difference throughout the day. Below, you can also modify your settings if you prefer to be notified only once per day. Note, some opportunities will match and close before your digest arrives.'
          options={batchingOptions}
          selectedKey={selectedKey}
          onChange={(_, { key }) => handleChange(key)}
        />

        <div className='pt3' />
        <Button
          onClick={() => {
            updateCompanion({
              variables: {
                input: {
                  individualNotifications: individualNotifications,
                  digestNotifications: digestNotifications
                }
              }
            })
          }}
          data-cy='settings--submit'
        >
          Save
        </Button>
      </ContentWrapper>
    </>
  )
}

export default withApollo(Notifications)
