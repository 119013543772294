export default {
  container: {
    '& img': { margin: '0 1rem' }
  },
  deleteButton: {
    marginTop: '3rem'
  },
  image: {
    width: 400,
    height: 400
  }
}
