import React, { useState } from 'react'
import OffScreenModal from 'components/OffScreenModal'
import TimeRangeInput from 'components/TimeRangeInput'
import TextField from 'components/TextField'
import Button from 'components/Button'
import { useStateValue, types } from 'components/EditVisitStory/StateProvider'
import { adjustStartAndEndTime } from '../extractAndAdjustStartAndEndTime'
import setTimeOnDate from 'helpers/setTimeOnDate'
import dayjs from 'helpers/dayjs'

const EditVisitTime = ({ startAt, endAt, timeChangeHandler, visit }) => {
  const [state, dispatch] = useStateValue()
  const [errorMessage, setErrorMessage] = useState(null)

  const closeAndResetValues = () => {
    const startAt = dayjs(visit.startAt).toDate()
    const endAt = dayjs(visit.endAt).toDate()
    const editTimeReason = ''
    dispatch({ type: types.UPDATE_START_AT, startAt })
    dispatch({ type: types.UPDATE_END_AT, endAt })
    dispatch({ type: types.EDIT_TIME_REASON, editTimeReason })
    dispatch({ type: types.HIDE_EDIT_VISIT_TIME_MODAL })
  }

  const roundStartAndEndTime = () => {
    const adjustedStartAndEndTime = adjustStartAndEndTime({
      start: setTimeOnDate(state.startAt, state.startAt),
      end: setTimeOnDate(state.endAt, state.endAt)
    })
    const startAt = dayjs(adjustedStartAndEndTime.startAt).toDate()
    const endAt = dayjs(adjustedStartAndEndTime.endAt).toDate()
    dispatch({ type: types.UPDATE_START_AT, startAt })
    dispatch({ type: types.UPDATE_END_AT, endAt })
  }

  const closeAndSave = () => {
    const hours = dayjs(endAt).diff(startAt, 'hours')
    const minutes = Math.round(((dayjs(endAt).diff(startAt, 'minute') / 60) % 1) * 60)
    if (hours < 0 || minutes < 0) {
      setErrorMessage('End time must be beyond start time!')
    } else if (hours === 0 && minutes < 15) {
      setErrorMessage('Visit duration is too short!')
    } else if (hours > 10) {
      setErrorMessage('Visit duration is too long!')
    } else {
      roundStartAndEndTime()
      setErrorMessage(null)
      dispatch({ type: types.HIDE_EDIT_VISIT_TIME_MODAL })
    }
  }

  return (
    <OffScreenModal close={closeAndResetValues} show={state.showEditVisitTimeModal} title='Edit Time'>
      <>
        <h4 className='fw6'>Edit Time</h4>
        {visit.visitType !== 'personal' && visit.visitee.customer.organization ? (
          <p>
            This is the time that will be reported to {visit.visitee.customer.organization.name}; make sure it's
            accurate! <br />
            Once submitted, it can't be changed.
          </p>
        ) : (
          <p>This is the time you will be paid for; make sure it’s accurate! Once submitted, it can’t be changed</p>
        )}

        <TimeRangeInput
          timeChangeHandler={timeChangeHandler}
          errorMessage={errorMessage}
          time={[startAt, endAt]}
          setDefaultEndTime
        />
        {visit.visitType === 'personal' && (
          <TextField
            multiline
            autoAdjustHeight
            rows={6}
            label='Let us know why you are editing the time.'
            value={state.editTimeReason}
            onChange={(_, editTimeReason) => dispatch({ type: types.EDIT_TIME_REASON, editTimeReason })}
            name='editTimeReason'
            data-cy='edit-time-reason'
          />
        )}
        <Button
          className='mt3'
          onClick={closeAndSave}
          data-cy='save-edit-time'
          disabled={visit.visitType === 'personal' && state.editTimeReason === ''}
        >
          Save
        </Button>
      </>
    </OffScreenModal>
  )
}

export default EditVisitTime
