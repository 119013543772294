import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import { prop, propEq, pipe, find } from 'ramda'
import uuid from 'uuid'

const selectedItemValue = pipe(find(propEq('selected', true)), prop('value'))

const Select = ({ items, placeholder, label, classes, handler, value, blankValue, ...rest }) => {
  const id = process.env.NODE_ENV === 'test' ? '123' : uuid.v4()
  return (
    <div>
      {label && (
        <label className='db mb3' htmlFor={id}>
          {label}
        </label>
      )}
      <select
        id={id}
        onChange={({ target: { value } }) => handler(/select/i.test(value) ? null : value)}
        className={`${classes.select} mb125 radius ph3 w-100 bg-white`}
        value={value ? value : blankValue ? '' : selectedItemValue(items)}
        defaultValue=''
        {...rest}
      >
        {placeholder && (
          <option value='' disabled>
            {placeholder}
          </option>
        )}
        {items.map(({ value, text }, i) => (
          <option value={value} key={i}>
            {text}
          </option>
        ))}
      </select>
    </div>
  )
}

export default withStyles(style)(Select)
