import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import dayjs from 'helpers/dayjs'

const CalendarDisplay = ({ openCalendar, classes, date }) => {
  return (
    <div
      data-cy='open-calendar'
      onClick={openCalendar}
      className={`${classes.timeRangeContainer} mb3 pointer`}
    >
      {date ? dayjs(date).format('dddd M/D/YYYY') : 'View calendar'}
      <div className={`${classes.iconContainer} top-0 right-0 absolute h-100`}>
        <i className='icon-calendar mh3 purple' />
      </div>
    </div>
  )
}

export default withStyles(style)(CalendarDisplay)
