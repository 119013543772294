import React from 'react'
import withStyles from 'react-jss'
import styles from './styles'
import ProfileSnippet from './ProfileSnippet'
import { useCurrentRoute } from 'react-navi'
import withApollo from 'helpers/withApollo'
import logout from 'helpers/logout'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import { PROFILE_SNIPPET_QUERY } from 'queries/profileSnippetQuery'
import { useQuery } from '@apollo/client';
import { isBrowser } from 'helpers/platform'
import { ProfileSnippetQuery } from 'types/graphql/ProfileSnippetQuery'

const Menu = ({ client, classes }) => {
  const { url: { pathname } } = useCurrentRoute()
  const { data, loading } = useQuery<ProfileSnippetQuery>(PROFILE_SNIPPET_QUERY)
  const companion = data?.companion

  if (loading || !companion) return null

  const companionType = companion.companionType
  const isCurrentRoute = key => pathname === routePaths[key] || pathname === `${routePaths[key]}/`

  return <div className={`z-3 flex flex-column bg-white h-100 top-0 left-0 absolute ${classes.container}`}>
    <ProfileSnippet companion={companion}/>
    <div
      className={`relative flex flex-wrap flex-row items-start bg-light-blue flex-grow-1 ${classes.linksContainer}`}
    >
      <div className={`pointer w-100 ${classes.links}`}>
        <Link
          className={`pointer f4 ${pathname === routePaths.root ? 'fw7' : ''}`}
          text='Dashboard'
          route={routePaths.root}
          data-cy='navigate-to-dashboard'
        />
        <Link
          className={`pointer f4 ${pathname === routePaths.profile ? 'fw7' : ''}`}
          text='My Profile'
          route={routePaths.profile}
          data-cy='navigate-to-profile'
        />
        <Link
          className={`f4 ${pathname === routePaths.opportunities ? 'fw7' : ''}`}
          text='Visit Opportunities'
          route={routePaths.opportunities}
          persistLoadingBar
          data-cy='navigate-to-opportunities'
        />
        <Link
          className={`f4 ${isCurrentRoute('visits') ? 'fw7' : ''}`}
          text={companionType === 'lite' ? 'Manage Calls' : 'Manage Visits'}
          route={routePaths.visits}
          data-cy='navigate-to-visits'
        />
        <Link
          className={`pointer f4 ${isCurrentRoute('bookVisit') ? 'fw7' : ''}`}
          text={companionType === 'lite' ? 'Schedule a Call' : 'Book Visit'}
          route={routePaths.bookVisit}
          data-cy='navigate-to-visits-new'
        />
        <Link
          className={`pointer f4 ${pathname === routePaths.settings ? 'fw7' : ''}`}
          text='Settings'
          route={routePaths.settings}
          data-cy='navigate-to-settings'
        />
      </div>
      {isBrowser && (
        <div className={`w-100`}>
          <div className={`pointer f4 ${classes.logout}`} onClick={() => logout(client)}>
            Log Out
          </div>
        </div>
      )}
    </div>
  </div>
}

export default withStyles(styles)(withApollo(Menu))
