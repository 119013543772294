import colors from 'helpers/colors'

const weekPill = {
  height: '44px',
  width: '95px',
  borderRadius: '22px',
  border: `1px solid ${colors.lighterGreen}`,
  backgroundColor: 'white',
  lineHeight: '42px'
}

export default {
  changeWeek: {
    height: '44px',
    width: '44px',
    lineHeight: '42px',
    paddingLeft: '14px',
    color: colors.purple
  },
  weekPill,
  selectedWeekPill: {
    ...weekPill,
    backgroundColor: colors.lighterGreen,
    color: 'white'
  }
}
