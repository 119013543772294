export default {
  yesNoCircle: {
    height: '3rem',
    width: '3rem',
    display: 'inline-table',
    borderRadius: '50%',
    border: 'solid 2px #b6d1cf',
    backgroundColor: '#fbf9f4',
    padding: '0.7rem',
    textAlign: 'center',
    color: '#0a6267',
    cursor: 'pointer',

    '&.activeGreen': {
      backgroundColor: '#0a6267',
      color: '#fff'
    },

    '&.activePurple': {
      backgroundColor: '#828dc3',
      color: '#fff'
    }
  }
}
