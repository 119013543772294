import React from 'react'
import ContentWrapper from 'components/ContentWrapper'
import Button from 'components/Button'
import TopBar from 'components/TopBar'
import { isAndroid } from 'helpers/platform'
import { inAppBrowserOpen } from 'helpers/inAppBrowser';

const UpdateApp = () => {
  const storeLink = isAndroid
    ? 'https://play.google.com/store/apps/details?id=io.monami.companion'
    : 'https://apps.apple.com/us/app/mon-ami-companions/id1450532391'
  const storeText = isAndroid ? 'Open play store' : 'Open app store'

  const openStore = () => {
    inAppBrowserOpen(storeLink, '_system')
  }

  return (
    <div className='mx-2'>
      <TopBar title='Update App' />
      <ContentWrapper styles='justify-center flex'>
        <>
          <p>You are using an older version of the app. Please click the link below to upgrade</p>

          <Button className='mb3 mt3 btnMuted' data-cy='open-store' onClick={() => openStore()}>
            {storeText}
          </Button>
        </>
      </ContentWrapper>
    </div>
  )
}

export default UpdateApp
