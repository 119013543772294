import React from 'react'
import withStyles from 'react-jss'
import withApollo from 'helpers/withApollo'
import style from './styles'
import dayjs from 'helpers/dayjs'
import seniorPlaceHolderImage from 'helpers/seniorPlaceHolderImage'
import Badge from 'components/Badge'
import colors from 'helpers/colors'
import MultiClamp from 'react-multi-clamp'
import { openModal } from 'components/Layout/Modal'
import CancelModal from './CancelModal'
import { useNavigation } from 'react-navi'
import classNames from 'classnames'
import { editVisitStoryPath } from 'routes/paths'
import LazyPhoto from 'components/LazyPhoto'
import { CompletedVisits_companion_visits } from 'types/graphql/CompletedVisits'

interface CompletedVisitCardProps {
  client: any,
  classes: any,
  visit: CompletedVisits_companion_visits
}

const CompletedVisitCard: React.FC<CompletedVisitCardProps> = ({ client, classes, visit }) => {
  const navigation = useNavigation()
  const startAt = dayjs(visit.startAt || visit.completionAt)
  const endAt = dayjs(visit.endAt || visit.expectCompletionAt)
  const visitStoryDesc = visit.visitStory?.description
  const visitStoryPhotos = visit.visitStory ? visit.visitStory.photos : []
  const visitStoryHasCheckIn = visit.visitStory?.checkInAt

  const showCancelModal = () =>
    openModal(client, {
      isBlocking: true,
      Component: ({ closeModal }) => <CancelModal closeModal={closeModal} visit={visit} />
    })

  return (
    <div
      className={`bg-white relative bottom-shadow mb3 ${classes.container}`}
    >
      <div className='flex flex-nowrap flex-row' data-cy='story-view-visit-story'>
        <div className='relative w-33'>
          <LazyPhoto
            photo={visitStoryPhotos.length > 0 ? visitStoryPhotos[0] : visit.visitee.visiteePhoto}
            placeholderImage={seniorPlaceHolderImage[visit.visitType]}
            className={classes.photoContainer}
          />
          {visit.visitStory?.completed && (
            <Badge
              // @ts-ignore Need to figure out JSS props
              direction='left'
              text='Story Submitted'
              containerClass={classes.badge}
              color={colors.white}
              backgroundColor={colors.lighterGreen2}
            />
          )}
        </div>
        <div className='w-60 ph3 pt2 pb3 flex-grow-1 b--gray'>
          <div className='tr dark-gray f6'>{`${endAt.fromNow(true)} ago`}</div>
          <div className='recoleta-regular mb2'>
            <span className='fw6'>
              <MultiClamp ellipsis='...' clamp={1}>
                {visit.visitee.customer.person.firstNameAndLastInitial}
              </MultiClamp>
            </span>
          </div>
          {visit.scheduleType === 'flexible' ? (
            visit.completionAt && (
              <div className='mb1'>
                <i className='icon-calendar label-green mr2' />
                Timing of this {visit.visitTypeFriendlyName} was flexible. It was marked completed on{' '}
                {dayjs(visit.completionAt).format('ddd, MMM DD')}
              </div>
            )
          ) : (
            <>
              <div className='mb1'>
                <i className='icon-calendar label-green mr2' />
                {startAt.format('ddd, MMM DD')}
              </div>
              <div className='mb1'>
                <i className='icon-time label-green mr2' />
                {startAt.format('h:mmA')} - {endAt.format('h:mmA')}
              </div>
            </>
          )}
          {visitStoryDesc && (
            <div className={`flex ${classes.descriptionContainer}`}>
              <i className='icon-activity label-green mr2' />
              <MultiClamp ellipsis='...' clamp={2}>
                {visitStoryDesc}
              </MultiClamp>
              <div className='cf' />
            </div>
          )}
        </div>
      </div>
      {!visit.visitStory?.completed && (
        <div className='dt w-100'>
          {!visitStoryHasCheckIn && (
            <div
              onClick={showCancelModal}
              className={`pointer dib tc bt br b--gray purple dtc ${classes.button}`}
              data-cy='story--open-visit-did-not-happen'
            >
              {visit.visitTypeFriendlyName} Didn't Happen
            </div>
          )}
          <div
            className={classNames('pointer dib tc bt b--gray purple dtc', classes.button, {
              'w-100': visitStoryHasCheckIn,
              'w-50': !visitStoryHasCheckIn
            })}
            onClick={() => navigation.navigate(editVisitStoryPath(visit.id))}
            data-cy='story-open-submit-visit-story'
          >
            {visitStoryHasCheckIn ? 'Complete' : 'Submit'} {visit.visitTypeFriendlyName} Recap
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(style)(withApollo(CompletedVisitCard))
