import { gql } from '@apollo/client'
import companionFragment from 'fragments/companionFragment'

export const PROFILE_SNIPPET_QUERY = gql`
  query ProfileSnippetQuery {
    companion {
      ...fullnameTypePhoto
    },
    companions {
      id
    }
  }
  ${companionFragment}
`
