import React from 'react'
import ModalBody from 'components/ModalBody'
import { Mutation } from '@apollo/client/react/components'
import dayjs from 'helpers/dayjs'
import { DELETE_DAYS_OFF_MUTATION } from 'components/Availability/queries'

const Modal = ({ closeModal, unavailableDays, updateBusyIndicators }) => {
  const multiple = unavailableDays.length > 1

  const deleteUnavailableDays = mutation => async () => {
    updateBusyIndicators({ bar: true })
    await Promise.all(
      unavailableDays.map(({ id }) => {
        return mutation({
          variables: { input: { unavailableDayId: id } }
        })
      })
    )
    updateBusyIndicators({ bar: false })
  }

  return (
    <ModalBody closeModal={closeModal}>
      {({ modalClasses, CloseX }) => (
        <>
          <CloseX />
          <Mutation refetchQueries={['DaysOff']} mutation={DELETE_DAYS_OFF_MUTATION} onCompleted={closeModal}>
            {mutation => (
              <>
                <h3>Delete Days Off</h3>
                <p className='tc ph3 mt0'>
                  Are you sure you want to delete {multiple ? 'these days off' : 'this day off'}?
                </p>
                <ul className='fw6'>
                  {unavailableDays.map(({ date }, i) => (
                    <li key={i}>{dayjs(date).format('M/D/YYYY dddd')}</li>
                  ))}
                </ul>
                <div className={`flex ${modalClasses.buttonContainer}`}>
                  <div data-cy='availability--destroy-days-off' onClick={deleteUnavailableDays(mutation)}>
                    <i className='mr2 green icon-accept' />
                    Confirm
                  </div>
                </div>
              </>
            )}
          </Mutation>
        </>
      )}
    </ModalBody>
  )
}

export default Modal
