import { gql } from '@apollo/client'
import companionCompletedVisitsFragment from 'fragments/companionCompletedVisitsFragment'

export const COMPLETED_VISITS = gql`
  query CompletedVisits($scope: String) {
    companion {
      id
      visits(scope: $scope) {
        ...companionCompletedVisits
      }
      person {
        id
        preferredOrFirstName
      }
    }
  }
  ${companionCompletedVisitsFragment}
`
