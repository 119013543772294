import React, { useEffect } from 'react'
import Button from 'components/Button'
import { useStateValue, types } from 'components/Availability/StateProvider'
import { Query } from '@apollo/client/react/components'
import dayjs from 'helpers/dayjs'
import { ifElse, pipe, path, always, prop, reduce, last, head, dropLast, findIndex, insert, equals } from 'ramda'
import withStyles from 'react-jss'
import style from './styles'
import Modal from './Modal'
import { openModal } from 'components/Layout/Modal'
import withApollo from 'helpers/withApollo'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import { DAYS_OFF_QUERY } from 'components/Availability/queries'

const unavailableDaysPath = path(['companion', 'unavailableDays'])

export const companionsUnavailableDays = pipe(
  prop('data'),
  ifElse(unavailableDaysPath, unavailableDaysPath, always([]))
)

export const orderUnavailableDays = reduce((acc, unavailableDay) => {
  if (acc.length === 0) return [unavailableDay]
  const index = findIndex(day => {
    return dayjs(unavailableDay.date).isBefore(dayjs(day.date))
  }, acc)
  if (index === -1) return [...acc, unavailableDay]
  else return insert(index, unavailableDay, acc)
}, [])

export const groupSubsequentDays = reduce((acc, unavailableDay) => {
  const lastDate = last(last(acc) || [])
  if (!lastDate || dayjs(unavailableDay.date).diff(lastDate.date, 'day') > 1) return [...acc, [unavailableDay]]
  else return [...dropLast(1, acc), [...last(acc), unavailableDay]]
}, [])

export const formatDays = (day1, day2) =>
  !day2
    ? dayjs(day1.date).format('M/D/YYYY dddd')
    : `${dayjs(day1.date).format('M/D/YYYY ddd')} - ${dayjs(day2.date).format('M/D/YYYY ddd')}`

const unavailableDayLineItems = (classes, client, updateBusyIndicators) => unavailableDays => {
  const openDestroyDaysOffModal = unavailableDays => () => {
    openModal(client, {
      Component: ({ closeModal }) => (
        <Modal
          {...{
            closeModal,
            unavailableDays,
            updateBusyIndicators
          }}
        />
      )
    })
  }

  return (
    <div className='bg-white shadowradius'>
      {unavailableDays.map((days, i) => (
        <div
          className={`flex fw6 b bg--none pv75 ph125 bb b--gray ${classes.unavailableDay}`}
          key={i}
          data-cy='availability--days-off-line-item'
        >
          <div className={`flex-grow-1 `}>
            {days.length === 1 ? formatDays(head(days)) : formatDays(head(days), last(days))}
          </div>
          <div>
            <i
              data-cy='availability--open-destroy-days-off-modal'
              onClick={openDestroyDaysOffModal(days)}
              className='purple icon-delete'
            />
          </div>
        </div>
      ))}
    </div>
  )
}

const DaysOff = ({ classes, client }) => {
  const [state, dispatch] = useStateValue()

  // in order to leverage useEffect, we must swallow Apollo's readQuery errors on initial render
  useEffect(() => {
    try {
      if (state.unavailableDays.length === 0) updateUnavailbleDays(client.readQuery({ query: DAYS_OFF_QUERY }))
    } catch (e) {
      if (!`${e}`.includes('Invariant Violation')) throw e
    }
  }, [state.unavailableDays])

  const openNewDaysOffModal = () => dispatch({ type: types.SHOW_NEW_DAYS_OFF_MODAL })

  const updateUnavailbleDays = ({ companion: { unavailableDays } }) => {
    if (!equals(unavailableDays, state.unavailableDays)) {
      dispatch({
        type: types.UPDATE_UNAVAILABLE_DAYS,
        unavailableDays
      })
    }
  }

  return (
    <div>
      <Button onClick={openNewDaysOffModal} data-cy='availability--open-new-days-off-modal'>
        <i className='icon-add' /> Add Days Off
      </Button>
      <Query query={DAYS_OFF_QUERY} onCompleted={updateUnavailbleDays}>
        {pipe(
          companionsUnavailableDays,
          orderUnavailableDays,
          groupSubsequentDays,
          unavailableDayLineItems(classes, client, updateBusyIndicators(client))
        )}
      </Query>
    </div>
  )
}

export default withApollo(withStyles(style)(DaysOff))
