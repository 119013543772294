import React from 'react'
import formatAddress from 'helpers/formatAddress'
import Header from 'components/VisitDetails/Logistics/Header'
import Detail from 'components/VisitDetails/Detail'

import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface ShiftProps {
  visit: VisitDetailsQuery_companion_visit
}

const Shift: React.FC<ShiftProps> = ({ visit }) => {
  const {
    visitee: { moreAboutMyInterests, moreAboutMe }
  } = visit
  return (
    <div className='pa125'>
      <Header visit={visit} />
      {visit.address?.notes && <Detail heading='Special Arrival Instructions'>{visit.address.notes}</Detail>}
      {visit.visitInfo && <Detail heading='Shift Info'>{visit.visitInfo}</Detail>}
      {!visit.opportunity && moreAboutMyInterests && <Detail heading='Shift Activities'>{moreAboutMyInterests}</Detail>}
      {visit.address &&
        <Detail heading='Address'>
          {visit.facility && <div>{visit.facility.name}</div>}
          {formatAddress(visit.address)}
        </Detail>
      }
      {moreAboutMe && <Detail heading={`More about ${visit.visitee.customer.person.preferredOrFirstName}`}>{moreAboutMe}</Detail>}
    </div>
  )
}

export default Shift
