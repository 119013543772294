const codeInputInputStyle: React.CSSProperties = {
  margin:  '4px',
  MozAppearance: 'textfield',
  width: '50px',
  fontSize: '20px',
  height: '60px',
  textAlign: 'center',
  paddingLeft: '7px',
  border: '1px solid black'
}

export default {
  codeInputInputStyle: {
    ...codeInputInputStyle
  },
  codeInputInvalidInputStyle: {
    ...codeInputInputStyle,
    border: '1px solid red'
  }
}
