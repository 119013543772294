import React from 'react'
import OffScreenModal from 'components/OffScreenModal'
import Calendar from 'components/Calendar'
import { useStateValue, types } from 'components/Availability/StateProvider'
import withApollo from 'helpers/withApollo'
import dayjs from 'helpers/dayjs'
import { none, filter } from 'ramda'
import { DELETE_DAYS_OFF_MUTATION, CREATE_DAY_OFF } from 'components/Availability/queries'

export const getNewDates = (dates, unavailableDays) =>
  filter(
    dateFromCalendar => none(({ date }) => dayjs(date).isSame(dayjs(dateFromCalendar), 'day'), unavailableDays),
    dates
  )

export const getRemovedDates = (dates, unavailableDays) =>
  filter(
    ({ date }) => none(dateFromCalendar => dayjs(date).isSame(dayjs(dateFromCalendar), 'day'), dates),
    unavailableDays
  )

const NewDaysOff = ({ client }) => {
  const [state, dispatch] = useStateValue()

  const close = () => dispatch({ type: types.HIDE_NEW_DAYS_OFF_MODAL })

  const doneHandler = async dates => {
    const newDates = getNewDates(dates, state.unavailableDays)
    const removedDates = getRemovedDates(dates, state.unavailableDays)

    close()
    await Promise.all(
      newDates.map(date =>
        client.mutate({
          mutation: CREATE_DAY_OFF,
          variables: { input: { date: dayjs(date).toJSON() } },
          refetchQueries: ['DaysOff']
        })
      )
    )

    await Promise.all(
      removedDates.map(({ id }) =>
        client.mutate({
          mutation: DELETE_DAYS_OFF_MUTATION,
          variables: { input: { unavailableDayId: id } },
          refetchQueries: ['DaysOff']
        })
      )
    )
  }

  return (
    <OffScreenModal show={state.showNewDaysOffModal} title='Add Days Off' close={close}>
      <Calendar
        doneHandler={doneHandler}
        reactCalendarProps={{ minDate: new Date() }}
        defaultDates={state.unavailableDays.map(({ date }) => dayjs(date).toDate())}
        controlled
        cypressContext='days-off'
      />
    </OffScreenModal>
  )
}

export default withApollo(NewDaysOff)
