import faceHappy from 'assets/icons/recap/face-happy-unselected.svg'
import faceMeh from 'assets/icons/recap/face-meh-unselected.svg'
import faceSad from 'assets/icons/recap/face-sad-unselected.svg'

import faceHappySelected from 'assets/icons/recap/face-happy-selected.svg'
import faceMehSelected from 'assets/icons/recap/face-meh-selected.svg'
import faceSadSelected from 'assets/icons/recap/face-sad-selected.svg'

export const storyIcons = {
  faceHappy: { active: faceHappySelected, inactive: faceHappy },
  faceMeh: { active: faceMehSelected, inactive: faceMeh },
  faceSad: { active: faceSadSelected, inactive: faceSad }
}
