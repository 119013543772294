import clientShowMenu from 'queries/clientShowMenu'
import { useEffect } from 'react'

const menuListener = client => {
  return (event) => {
    const { showMenu } = client.readQuery({ query: clientShowMenu })
    if (showMenu) {
      event.preventDefault()
      event.stopPropagation()
      client.writeQuery({ query: clientShowMenu, data: { showMenu: false } })
    }
  }
}

const useCloseMenuOnClick = (element, client) => {
  useEffect(() => {
    const currentEl = element.current
    currentEl.addEventListener('click', menuListener(client), [])

    return () => {
      currentEl.removeEventListener('click', menuListener(client));
    }
  })
}

export default useCloseMenuOnClick
