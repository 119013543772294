import React, { useEffect } from 'react'
import withApollo from 'helpers/withApollo'
import ContentWrapper from 'components/ContentWrapper'
import routePaths from 'routes/paths'
import { NormalizedCacheObject, ApolloClient } from '@apollo/client'
import RequestLoginCode from '../RequestLoginCodeScreen'
import BackArrow from '../BackArrow'
import ONBOARDING from 'queries/clientOnboarding'

interface SignUpProps {
  client: ApolloClient<NormalizedCacheObject>,
  userEmail: string
}

const SignUp: React.FC<SignUpProps> = ({ client, userEmail }) => {
  useEffect(() => {
    client.writeQuery({ query: ONBOARDING, data: { onboarding: false } })
  }, [])

  return (
    <ContentWrapper styles='justify-center flex'>
      <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
        <div className='h-100 flex flex-column'>
          <BackArrow route={routePaths.login} />
          <div>
            <div className='flex flex-column items-center justify-center'>
              <h2 className='mb0'>Forgot Password</h2>
              <p className='tc'>Enter your email address to reset your password.</p>
            </div>

            <RequestLoginCode client={client} userEmail={userEmail} successRoute={routePaths.verifyResetPasswordCode} submitText='Submit'/>
          </div>
          <div></div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default withApollo(SignUp)
