import { gql } from '@apollo/client'

export const VERIFY_2FA = gql`
  mutation Verify2fa($input: Verify2faInput!) {
    verify2fa(input: $input) {
      session {
        id
        verificationApproved
        verificationCheckSid
      }
    }
  }
`
