import React, { Fragment } from 'react'
import { pipe, slice, map, join, uniq } from 'ramda'
import dayjs from 'helpers/dayjs'
import MultiClamp from 'react-multi-clamp'
import withStyles from 'react-jss'
import style from './styles'

const timeSlotDisplay = pipe(
  slice(0, 4),
  map(({ startAt }) => dayjs(startAt).format('ddd M/D')),
  uniq,
  join(', ')
)

const OpportunityDateAndTimeDisplay = withStyles(style)(({ classes, opportunity }) => {
  const fixedSchedule = opportunity.scheduleType === 'fixed'

  if (fixedSchedule) {
    return (
      <Fragment>
        <div className='mb1'>
          <i className='icon-calendar purple mr2' />
          <span className={classes.clampContainer}>
            {opportunity.timeSlots.length > 1 && <span className='b'>Pick 1: </span>}
            <MultiClamp ellipsis={`+${opportunity.timeSlots.length - 4} more`} clamp={2} disableCssClamp>
              {timeSlotDisplay(opportunity.timeSlots)}
            </MultiClamp>
          </span>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className='mb1'>
          <i className='icon-calendar purple mr2' />
          <span className={classes.clampContainer}>
            Timing is flexible. Complete by { dayjs(opportunity.flexibleScheduleEndAt).format('dddd, MMMM DD') }
          </span>
        </div>
      </Fragment>
    )
  }
})

export default OpportunityDateAndTimeDisplay
