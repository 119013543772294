import { gql } from '@apollo/client'

const isBusy = gql`
  query IsBusy {
    isBusy @client {
      bar
      screen
    }
  }
`

export default isBusy
