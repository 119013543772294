import React from 'react'
import { gql } from '@apollo/client'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import withStyles from 'react-jss'
import style from './styles'
import seniorPlaceHolderImage from 'helpers/seniorPlaceHolderImage'
import MultiClamp from 'react-multi-clamp'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import withApollo from 'helpers/withApollo'
import LazyPhoto from 'components/LazyPhoto'
import AddressAndDistance from './AddressAndDistance'
import FixedScheduleOpportunity from './FixedScheduleOpportunity'
import FlexibleScheduleOpportunity from './FlexibleScheduleOpportunity'

import { OpportunityDetailsQuery_companion_opportunity, OpportunityDetailsQuery_companion_opportunity_visitee } from 'types/graphql/OpportunityDetailsQuery'

export const OPPORTUNITY_DETAILS_QUERY = gql`
  query OpportunityDetailsQuery($opportunityId: ID!) {
    companion {
      id
      opportunity(id: $opportunityId) {
        id
        hasLinkage
        marketingHeadline
        distance
        drivingRequired
        length
        miscNotes
        opportunityType
        opportunityFriendlyName
        scheduleType
        flexibleScheduleEndAt
        visitCoding {
          id
          name
        }
        visitTimeSuggestions {
          timeSlotId
          startAt
          endAt
          duration
        }
        timeSlots {
          id
          startAt
          endAt
          duration
        }
        opportunitySuggestions {
          id
        }
        address {
          id
          addressLine1
          addressLine2
          neighborhood
          city
          state
          zip
        }
        visitee {
          id
          visiteePhoto {
            id
            cdnUrlTemplate
          }
          primaryLanguage
          secondaryLanguage
          pastCareer
          ongoingSchedulingNotes
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
            designatedContact {
              id
              person {
                id
                preferredOrFirstName
              }
            }
          }
          interests {
            id
            name
          }
        }
      }
    }
  }
`

const getLanguages = ({ primaryLanguage, secondaryLanguage }: OpportunityDetailsQuery_companion_opportunity_visitee) =>
  [primaryLanguage, secondaryLanguage].filter(Boolean).map(capitalizeFirstLetter).join(', ')

interface OpportunityDetailsProps {
  opportunity: OpportunityDetailsQuery_companion_opportunity
  classes: any
  client: any
}

const OpportunityDetails: React.FC<OpportunityDetailsProps> = ({ opportunity, classes, client }) => {
  if (!opportunity) return null

  const customer = opportunity.visitee.customer

  const telepresence = opportunity.opportunityType === 'telepresence'
  const shift = opportunity.opportunityType === 'shift'
  const interests = opportunity.visitee.interests || []

  const Detail = ({ heading, children }) => (
    <div className='mt3'>
      <div className={`mt3 fw6 ttu label-green ${classes.detailHeading}`}>{heading}</div>
      <div className={classes.detailText}>{children}</div>
    </div>
  )

  const messageTagLine = customer.designatedContact ?
    `Tell ${customer.person.preferredOrFirstName}'s contact ${customer.designatedContact.person.preferredOrFirstName} why you're excited about this ${opportunity.opportunityFriendlyName}` :
    `Tell ${customer.person.preferredOrFirstName} why you're excited about this ${opportunity.opportunityFriendlyName}`

  const renderFixedOrFlexibleScheduleComponent = () => {
    if (opportunity.scheduleType === 'flexible') {
      return (
        <FlexibleScheduleOpportunity
          opportunity={opportunity}
          opportunityMessage={messageTagLine}
          client={client}
        />
      )
    } else {
      return (
        <FixedScheduleOpportunity
          opportunity={opportunity}
          opportunityMessage={messageTagLine}
          client={client}
        />
      )
    }
  }

  return (
    <>
      <TopBar title='Opportunity Details' left='opportunities' />
      <ContentWrapper noPadding>
        <div className='pa3 tc bg-egg-shell bb b--border-green'>
          <div className='f4-5 fw6 recoleta-regular lighter-green'>
            <div>{opportunity.marketingHeadline}</div>
          </div>
        </div>
        <div className='ph125 pt125 mb3 flex flex-nowrap flex-row'>
          <div>
            <LazyPhoto
              photo={opportunity.visitee.visiteePhoto}
              placeholderImage={seniorPlaceHolderImage[opportunity.opportunityType]}
              className={`relative br2 ${classes.photoContainer}`}
            />
          </div>
          <div className='ph3 pt2 flex-grow-1'>
            <div className='fw6 f4 mb1' style={{ wordBreak: 'break-all' }}>
              <MultiClamp ellipsis='...' clamp={1}>
                {customer.person.firstNameAndLastInitial}
              </MultiClamp>
            </div>
            <AddressAndDistance opportunity={opportunity} />
          </div>
        </div>
        <div className='ph125 pb125'>
          {opportunity.miscNotes && <p>{opportunity.miscNotes}</p>}
          {opportunity.visitCoding && <Detail heading='ACTIVITY DESCRIPTION'>{opportunity.visitCoding.name}</Detail>}
          <div className='mt3'>
            <div className={`mt3 fw6 ttu label-green ${classes.detailHeading}`}>SPEAKS</div>
            <div className={classes.detailText} data-testid='language'>
              {getLanguages(opportunity.visitee) || '-'}
            </div>
          </div>
          {opportunity.visitee.pastCareer && <Detail heading='CAREER'>{opportunity.visitee.pastCareer}</Detail>}
          {interests.length > 0 && (
            <Detail heading='ACTIVITIES'>{interests.map(interest => interest.name).join(', ')}</Detail>
          )}
          {!telepresence && !shift && <Detail heading='VISIT LENGTH'>{opportunity.length / 60} hrs</Detail>}
          {!telepresence && (
            <Detail heading='IS DRIVING REQUIRED?'>{opportunity.drivingRequired ? 'Yes' : 'No'}</Detail>
          )}
          {opportunity.visitee.ongoingSchedulingNotes && (
            <Detail heading='ONGOING'>{opportunity.visitee.ongoingSchedulingNotes}</Detail>
          )}
        </div>
        {renderFixedOrFlexibleScheduleComponent()}
      </ContentWrapper>
    </>
  )
}

export default withApollo(withStyles(style)(OpportunityDetails))
