import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import BusyIndicator from 'components/BusyIndicator'
import withApollo from 'helpers/withApollo'
import Button from 'components/Button'
import formatAddress from 'helpers/formatAddress'
import { StateProvider, useStateValue, types } from './StateProvider'
import { equals, dissoc, prop } from 'ramda'
import EditLocationModal from 'components/EditLocationModal'
import TextField from 'components/TextField'
import getError from 'helpers/getError'
import Checkbox from 'components/Checkbox'
import { COMPANION_SETTINGS_QUERY } from 'queries/companionSettingsQuery'
import ErrorMessage from 'components/ErrorMessage'
import { CompanionSettingsQuery } from 'types/graphql/CompanionSettingsQuery'
import { updateCompanionMutation, curryFlashMessage } from 'components/Settings'

interface LocationProps {
  client?: any
}

const Location: React.FC<LocationProps> = React.memo(({ client }) => {
  const { data, loading } = useQuery<CompanionSettingsQuery>(COMPANION_SETTINGS_QUERY)
  const companion = data?.companion
  const availableTransportModes = data?.availableTransportModes || []

  const [distanceWillTravel, setDistanceWillTravel] = useState<string>('')
  const [distanceWillTravelError, setDistanceWillTravelError] = useState(false)
  const getTransportModeIds = companion?.transportModes?.map(prop('id'))
  const [transportModeIds, setTransportModeIds] = useState<any>([])

  const [{ address }, dispatch] = useStateValue()
  const addressInput = dissoc('__typename', address)

  const onCompleted = curryFlashMessage(client, 'Your settings has been updated!')

  const [updateCompanion, mutationState] = updateCompanionMutation(onCompleted)

  useEffect(() => {
    dispatch({
      type: types.UPDATE_PARAMS,
      address: companion?.address
    })
    setTransportModeIds(getTransportModeIds)
    setDistanceWillTravel(companion?.distanceWillTravel?.toString() || '')
  }, [companion])

  const editAddress = () => {
    dispatch({
      type: types.UPDATE_PARAMS,
      address: companion?.address
    })
    dispatch({ type: types.SHOW_EDIT_LOCATION_MODAL })
  }

  if (!address) {
    return null
  }

  const updateTransportModes = transportMode => () => {
    if (transportModeIds?.includes(transportMode.id)) {
      setTransportModeIds(transportModeIds.filter(f => f !== transportMode.id))
    } else {
      setTransportModeIds([...transportModeIds, transportMode.id])
    }
  }

  const updateDistanceWillTravel = value => {
    setDistanceWillTravel(value)
    if (value < 0) {
      setDistanceWillTravelError(true)
    } else {
      setDistanceWillTravelError(false)
    }
  }

  if (loading || !data) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  return (
    <>
      <Fragment>
        <EditLocationModal refetchQueries={['CompanionSettingsQuery']} useStateValue={useStateValue} types={types} />
        <TopBar title='Location' left='menu' />
        <ContentWrapper styles='justify-center flex'>
          <p>
            This is information we need to offer you opportunities to make a difference in person (grocery runs,
            prescription pickups, etc).
          </p>
          <div className='mb3'>
            <div className='mb2 fw6'>Location</div>
            <div className='flex'>
              <div className='flex-grow-1' data-testid='addressDisplay'>
                {formatAddress(address)}
              </div>
              <div className='f6' onClick={editAddress} data-cy='visits-new--open-edit-location-modal'>
                <i className='icon-edit purple' /> Edit
              </div>
            </div>
          </div>

          <TextField
            styles={{ fieldGroup: ['w4'] }}
            className='mb3'
            label='Distance willing to travel'
            suffix='miles'
            value={distanceWillTravel || ''}
            onChange={(_, value) => updateDistanceWillTravel(value)}
            name='expensesCents'
            errorMessage={getError('distanceWillTravel', mutationState.error)}
            data-cy='distance-will-travel'
          />
          {distanceWillTravelError && <p className='red'>Distance willing to drive must be a positive number</p>}
          <p className={'b mt3'}>Transport modes</p>
          <div className='mt3'>
            {availableTransportModes.map(mode => (
              <div key={mode.id}>
                <Checkbox
                  label={mode.name || ''}
                  name={mode.name || ''}
                  className={'mb2'}
                  checked={transportModeIds?.includes(mode.id)}
                  onChange={updateTransportModes(mode)}
                  id={mode.id}
                />
              </div>
            ))}
          </div>
          <Button
            className='mt3'
            disabled={distanceWillTravelError}
            onClick={() => {
              updateCompanion({
                variables: {
                  input: {
                    address: dissoc('id', addressInput),
                    distanceWillTravel: distanceWillTravel ? parseInt(distanceWillTravel) : null,
                    transportModeIds: transportModeIds
                  }
                }
              })
            }}
            data-cy='settings--submit'
          >
            Save
          </Button>
          {getError('address', mutationState.error) && (
            <ErrorMessage errorMessage={"Location can't be blank!"} bordered />
          )}
        </ContentWrapper>
      </Fragment>
    </>
  )
}, equals)

const StateProviderWrap = props => (
  <StateProvider>
    <Location {...props} />
  </StateProvider>
)

export default withApollo(StateProviderWrap)
