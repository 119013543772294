import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from 'dayjs/plugin/weekday'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

if (INTEGRATION_TEST) {
  const mockdate = require('mockdate')
  // July 1, 2030 8:00am
  mockdate.set(new Date(2030, 6, 1, 8))
}

dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export default dayjs
