import React, { useState, useEffect } from 'react'
import ReactCalendar from 'react-calendar/dist/entry.nostyle'
import withStyles from 'react-jss'
import style from './styles'
import dayjs from 'helpers/dayjs'
import Button from 'components/Button'

const dateAlreadyClicked = (dates, date) => dates.some(d => dayjs(date).isSame(dayjs(d)))
const datesExcept = (dates, date) => dates.filter(d => !dayjs(date).isSame(dayjs(d)))

const Calendar = ({
  classes,
  singlePick,
  defaultDates = [],
  doneHandler,
  reactCalendarProps = {},
  controlled = false,
  cypressContext,
  maxDate = undefined
}) => {
  const [dates, setDates] = useState(defaultDates)

  if (controlled) {
    useEffect(() => {
      setDates(defaultDates)
    }, [defaultDates])
  }

  const onClickDay = date => {
    if (singlePick) {
      return dayjs(dates[0]).isSame(dayjs(date)) ? setDates([]) : setDates([date])
    }
    if (dateAlreadyClicked(dates, date)) setDates(datesExcept(dates, date))
    else setDates([...dates, date])
  }

  const tileClassName = ({ date }) => {
    const classNames = [classes.dayTile]
    if (singlePick) {
      return dayjs(date).isSame(dayjs(dates[0])) ? [classes.activeDay, ...classNames] : classNames
    } else {
      if (dateAlreadyClicked(dates, date)) return [classes.activeDay, ...classNames]
      return classNames
    }
  }

  return (
    <span data-cy={`${cypressContext}-calendar`}>
      <ReactCalendar
        calendarType='US'
        next2Label={null}
        prev2Label={null}
        minDetail='month'
        className={`${classes.calendar} mb4`}
        tileClassName={tileClassName}
        formatShortWeekday={(_, date) => dayjs(date).format('ddd').toUpperCase()}
        onClickDay={onClickDay}
        {...reactCalendarProps}
        maxDate={maxDate}
      />
      <Button
        data-cy={`${cypressContext}-choose-calendar-day`}
        onClick={_ => doneHandler(singlePick ? dates[0] : dates)}
      >
        Done
      </Button>
    </span>
  )
}

export default withStyles(style)(Calendar)
