import React from 'react'
import { Query } from '@apollo/client/react/components'
import withStyles from 'react-jss'
import style from './styles'
import withApollo from 'helpers/withApollo'
import { pipe, path } from 'ramda'
import clientShowMenu from 'queries/clientShowMenu'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import clientReturnTo from 'queries/clientReturnTo'
import { gql } from '@apollo/client'

const VISITEE_PHONE_NUMBER_QUERY = gql`
  query VisiteePhoneNumber($visiteeId: ID!) {
    companion {
      id
      visiteeConversationNumber(visiteeId: $visiteeId)
    }
  }
`

const ItemWrap = ({ children, onClick }) => (
  <div onClick={onClick} className='pointer f4 self-center' style={{ width: '1.5rem' }}>
    {children}
  </div>
)

const toggleMenu = client => _ => {
  const { showMenu } = client.readQuery({ query: clientShowMenu })
  client.writeQuery({ query: clientShowMenu, data: { showMenu: !showMenu } })
}

const leftSide = (item, leftHandler, client, navigation, returnTo) => {
  if (returnTo && item !== 'closeOffscreenModal' && item !== 'opportunities') {
    return (
      <ItemWrap onClick={_ => navigation.navigate(returnTo)}>
        <i className='icon-arrow-left purple f4' data-cy='arrow-back' />
      </ItemWrap>
    )
  }
  switch (item) {
    case 'menu':
      return (
        <ItemWrap onClick={toggleMenu(client)}>
          <i data-cy='open-menu' className='icon-navigation purple f4' />
        </ItemWrap>
      )
    case 'visits':
      return (
        <ItemWrap onClick={_ => navigation.navigate(`${routePaths.visits}?tab=completed`)}>
          <i className='icon-arrow-left purple' />
        </ItemWrap>
      )
    case 'opportunities':
      return (
        <ItemWrap onClick={_ => navigation.navigate(routePaths.opportunities)}>
          <i className='icon-arrow-left purple' />
        </ItemWrap>
      )
    case 'closeOffscreenModal':
      return (
        <ItemWrap onClick={leftHandler}>
          <i className='icon-arrow-left purple' />
        </ItemWrap>
      )
    default:
      return <ItemWrap />
  }
}

const TopBar = ({ client, left = '', right = '', title, classes, leftHandler, hideDropShadow = false, visiteeId }) => {
  const navigation = useNavigation()

  return (
    <div className={`z-1 ${classes.container} ${hideDropShadow ? '' : 'bottom-shadow'}`}>
      <div className={`${hideDropShadow ? 'bb b--gray' : ''} flex flex-row justify-between content-center ph125`}>
        <Query query={clientReturnTo}>
          {({ data, loading }) => {
            const { returnTo } = data || {}
            return leftSide(left, leftHandler, client, navigation, returnTo)
          }}
        </Query>
        <div className='self-center'>{title}</div>
        <ItemWrap />
      </div>
    </div>
  )
}

export default pipe(withStyles(style), withApollo)(TopBar)
