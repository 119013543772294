import React from 'react'
import Detail from '../Detail'
import formatPhoneNumber from 'helpers/formatPhoneNumber'
import { VisitDetailsQuery_companion_visit_visitee_customer_emergencyContact } from 'types/graphql/VisitDetailsQuery'
import { monamiPhone } from 'helpers/globals'

interface EmergencyContactProps {
  emergencyContact: VisitDetailsQuery_companion_visit_visitee_customer_emergencyContact,
  visiteeFirstName: string,
  visitType: string,
  organization: any
}

const EmergencyContact: React.FC<EmergencyContactProps> = ({ emergencyContact, visiteeFirstName, visitType, organization }) => {
  const title = visitType === 'shift' ? 'Organization' : 'Emergency'
  const emergencyName = `${visiteeFirstName}'s ${emergencyContact.relationship.replace('_', ' ')}, ${emergencyContact.person.firstNameAndLastInitial}`
  const orgPhone = organization?.directPhoneNumber
  const orgPhoneWithExtension = `${formatPhoneNumber(orgPhone?.number || monamiPhone)}${orgPhone?.extension ? ` x${orgPhone?.extension}` : ''}`

  return (
    <div>
      <div className='pb3 ph3'>
        <div className='bt bb b--border-green pa3 fw6 f6'>
          <i className='icon-econtact label-green mr2' />
          {`${title} Contact`}
        </div>
        <Detail heading={`${title} Contact`}>{emergencyName}</Detail>
        <Detail heading={`${title} Contact Phone`}>
          {formatPhoneNumber(emergencyContact.phoneNumber?.number)}
        </Detail>
        <Detail heading={orgPhone ? organization.name : 'Mon Ami Admins'}>{orgPhone ? orgPhoneWithExtension : '(650) 267 - 2474'}</Detail>
        <Detail heading=''>
          <span className='i'>In the event of a medical emergency, please call 911.</span>
        </Detail>
      </div>
    </div>
  )
}

export default EmergencyContact
