import React, { useState } from 'react'
import TextField from 'components/TextField'
import getError from 'helpers/getError'
import { Mutation } from '@apollo/client/react/components'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import { UPDATE_VISIT_STORY_MUTATION } from 'mutations/updateVisitStory'
import { UpdateVisitStory, UpdateVisitStoryVariables } from 'types/graphql/UpdateVisitStory'
import { EditVisitStoryQuery_companion_visit } from 'types/graphql/EditVisitStoryQuery'
import YesNoQuestion from '../YesNoQuestion'

interface TelepresenceProps {
  visit: EditVisitStoryQuery_companion_visit
  visitStoryCreated: any
  state: any
  anythingPrivateToShare: any
  anyPhotosOrSpecialMoments: any
  howVisiteeIsDoing: any
}

const Telepresence: React.FC<TelepresenceProps> = ({
  visit,
  visitStoryCreated,
  state,
  anythingPrivateToShare,
  anyPhotosOrSpecialMoments,
  howVisiteeIsDoing
}) => {
  const [connected, setConnected] = useState(null)
  const [healthCondition, setHealthCondition] = useState(null)
  const [healthConcern, setHealthConcern] = useState(null)
  const [hasAnyPhotosMemories, setHasAnyPhotosMemories] = useState(null)
  const [anythingPrivate, setAnythingPrivate] = useState(null)
  const [showButton, setShowButton] = useState<boolean>(false)
  const [completeButton, setCompleteButton] = useState<boolean>(false)

  const [visitMutationData, setVisitMutationData] = useState({
    companionAdminNotes: '',
    description: ''
  })

  const stateStartAtEndAt = () => {
    return {
      startAt: setTimeOnDate(state.date, state.startAt),
      endAt: setTimeOnDate(state.date, state.endAt)
    }
  }

  const createVS = createVSMutation => async () => {
    setCompleteButton(true)
    if (connected && (healthCondition === null || hasAnyPhotosMemories === null || anythingPrivate === null)) return
    return createVSMutation({
      variables: {
        input: {
          ...visitMutationData,
          ...stateStartAtEndAt(),
          id: visit.visitStory?.id,
          status: connected ? 'completed' : 'attempted',
          surveyWellbeing: healthCondition,
          surveyHealthConcern: healthConcern
        }
      }
    })
  }

  const inputChangeHandler = (e, value) => {
    setVisitMutationData({
      ...visitMutationData,
      [e.target.name]: value
    })
  }

  const updateChoice = key => {
    setConnected(key)
    setHealthConcern(null)
    setHealthCondition(null)
    setShowButton(true)
  }

  return (
    <>
      <Mutation<UpdateVisitStory, UpdateVisitStoryVariables>
        mutation={UPDATE_VISIT_STORY_MUTATION}
        refetchQueries={[
          { query: COMPLETED_VISITS, variables: { scope: 'completed' } },
          { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
          'CompanionQuery',
          'CompanionMilestoneMessages'
        ]}
        onError={raiseErrorIfCantBeIgnored}
        context={{ hasUpload: true }}
        onCompleted={visitStoryCreated}
      >
        {(createVSMutation, { error }) => (
          <>
            <div className='ph3'>
              <h4 className='fw6'>Recap</h4>
              <YesNoQuestion
                question={`Did you connect with ${visit.visitee.customer.person.preferredOrFirstName}?`}
                setMethod={updateChoice}
                getMethod={connected}
                completeButton={completeButton}
                name={'did-connect'}
              />
              {connected && (
                <>
                  {howVisiteeIsDoing(
                    healthCondition,
                    setHealthCondition,
                    healthConcern,
                    setHealthConcern,
                    completeButton,
                    error
                  )}
                  {anyPhotosOrSpecialMoments(
                    setHasAnyPhotosMemories,
                    hasAnyPhotosMemories,
                    completeButton,
                    visitMutationData,
                    inputChangeHandler,
                    error
                  )}
                  {anythingPrivateToShare(
                    setAnythingPrivate,
                    anythingPrivate,
                    completeButton,
                    visitMutationData,
                    inputChangeHandler
                  )}
                </>
              )}
              {connected === false && (
                <>
                  <p className='fw6'>Oh No, what happened?</p>
                  <TextField
                    multiline
                    autoAdjustHeight
                    rows={6}
                    className='mb2 mt3'
                    value={visitMutationData.description}
                    onChange={inputChangeHandler}
                    name='description'
                    errorMessage={getError('description', error)}
                    data-cy='story--description'
                  />
                </>
              )}
              {showButton && (
                <Button onClick={createVS(createVSMutation)} className='mt3' data-cy='story--submit'>
                  Complete {visit.visitTypeFriendlyName} Recap
                </Button>
              )}
            </div>
          </>
        )}
      </Mutation>
    </>
  )
}

export default Telepresence
