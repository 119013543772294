import colors from 'helpers/colors'

export default {
  calendar: {
    // container for left / right arrow and month title
    '& .react-calendar__navigation': {
      marginBottom: '1.25rem'
    },
    // month title
    '& button.react-calendar__navigation__label': {
      color: colors.darkestGreen,
      fontSize: '1rem',
      fontWeight: 600
    },
    // left / right nav arrow
    '& .react-calendar__navigation__arrow': {
      height: '2.75rem',
      width: '2.75rem',
      background: colors.lightGray,
      border: 'none',
      borderRadius: '22px',
      color: colors.purple,
      fontSize: '1.5rem'
    },
    // weekday shortnames
    '& .react-calendar__month-view__weekdays': {
      borderBottom: `1px solid ${colors.lighterGreen}`,
      textAlign: 'center',
      fontSize: '.875rem',
      color: colors.borderGreen,
      '& .react-calendar__month-view__weekdays__weekday': {
        marginBottom: '.5rem'
      }
    },
    // current day
    '& .react-calendar__tile--now': {
      color: colors.purple
    }
  },
  dayTile: {
    height: '2.75rem',
    width: '2.75rem',
    outline: 'none'
  },
  activeDay: {
    background: colors.lighterGreen,
    color: 'white',
    border: 'none',
    borderRadius: '22px'
  }
}
