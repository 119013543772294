import React, { useRef } from 'react'
import useCloseMenuOnClick from 'helpers/useCloseMenuOnClick'
import withApollo from 'helpers/withApollo'
import withStyles from 'react-jss'
import style from './styles'
import { pipe } from 'ramda'

const BackgroundDimmer = ({ classes, client }) => {
  const container = useRef(null)
  useCloseMenuOnClick(container, client)
  return (
    <div
      ref={container}
      className={`absolute top-0 right-0 z-2 vh-100 ${classes.container}`}
    />
  )
}

export default pipe(
  withStyles(style),
  withApollo
)(BackgroundDimmer)
