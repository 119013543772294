import React from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'

import { OpportunityDetailsQuery_companion_opportunity } from 'types/graphql/OpportunityDetailsQuery'

interface ModalProps {
  opportunity: OpportunityDetailsQuery_companion_opportunity,
  closeModal: any
  chosenDate?: any
}

const Modal: React.FC<ModalProps> = ({ closeModal, opportunity, chosenDate }) => {
  const customer = opportunity.visitee.customer
  const name = customer.designatedContact ? customer.designatedContact.person.preferredOrFirstName : customer.person.preferredOrFirstName

  return (
    <ModalBody closeModal={closeModal}>
      {({ CloseX }) => (
        <>
          <CloseX />
          <div className='tc ph3 mt0'>
            {opportunity.hasLinkage ? (
              <p>
                Excellent, your {opportunity.opportunityFriendlyName} with {name} is confirmed
                {chosenDate && ` for ${dayjs(chosenDate).format('ddd, MMM D [at] h:mm A')}`}!
              </p>
            ) : (
                <p>
                  Thanks for booking this visit! Look out for a confirmation email in the next 48 hours.
                </p>
              )}
          </div>
        </>
      )}
    </ModalBody>
  )
}

export default Modal
