export default {
  container: {
    boxSizing: 'border-box',
    position: 'absolute',
    overflow: 'visible',
    fontSize: '13px',
    lineHeight: '13px',
    fontWeight: 600,
    '& span': {
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      display: 'block',
      padding: '5px 10px',
      position: 'relative',
      background: props => props.backgroundColor,
      overflow: 'visible',
      color: props => props.color,
      textDecoration: 'none',
      height: '26px'
    },
    '& span:before': {
      content: '""',
      height: '0',
      width: '0',
      display: 'block',
      position: 'absolute',
      top: '0',
      right: props => (props.direction === 'left' ? '-13px' : 'initial'),
      left: props => (props.direction === 'right' ? '-13px' : 'initial'),
      borderTop: props => `13px solid ${props.backgroundColor}`,
      borderBottom: props => `13px solid ${props.backgroundColor}`,
      borderRight: '13px solid transparent',
      borderLeft: '13px solid transparent'
    }
  }
}
