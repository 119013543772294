import React, { useState } from 'react'
import { useStateValue, types } from 'components/VisitDetails/StateProvider'
import DiscourageCancelationMessages from './DiscourageCancelationMessages'
import OffScreenModal from 'components/OffScreenModal'
import dayjs from 'helpers/dayjs'
import Select from 'components/Select'
import { isNil } from 'ramda'
import { Mutation } from '@apollo/client/react/components'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import Button from 'components/Button'
import ChoiceGroup from 'components/ChoiceGroup'
import Checkbox from 'components/Checkbox'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import withApollo from 'helpers/withApollo'
import { openModal } from 'components/Layout/Modal'
import Modal from './Modal'
import { CANCEL_VISIT_MUTATION } from 'mutations/cancelVisit'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import getError from 'helpers/getError'
import TextField from 'components/TextField'
import pluralize from 'helpers/pluralize'

const formatVisitCancelationReasons = visitCancelationReasons => {
  return [
    'other',
    'reschedule_this_week',
    'too_busy_this_week',
    'not_interested_in_visitee',
    'not_interested_in_mon_ami'
  ].map(a => ({
    value: a,
    text: visitCancelationReasons.find(b => b.key === a).value
  }))
}

const CancelVisit = ({ visit, visitCancelationReasons, client }) => {
  const {
    companionCanCancel,
    discourageCompanionCancelationMessages,
    visitRecurrenceGroupPresent,
    visitee: { customer: { person: { preferredOrFirstName, firstNameAndLastInitial } } }
  } = visit
  const startAt = dayjs(visit.startAt)
  const [state, dispatch] = useStateValue()
  const navigation = useNavigation()
  const [showReasonMessageInput, setShowReasonMessageInput] = useState(true)

  const reasons = formatVisitCancelationReasons(visitCancelationReasons)

  const [cancelvisitMutationData, setCancelVisitMutationData] = useState({
    visitId: visit.id,
    reason: 'visitee_requested_this_cancelation',
    cancelFuture: false,
    cancelationMessage: '',
    onBehalfOfCustomer: false
  })

  const handleChange = key => value => {
    if (key === 'onBehalfOfCustomer') {
      setShowReasonMessageInput(!value)
    }
    setCancelVisitMutationData({
      ...cancelvisitMutationData,
      [key]: value
    })
  }

  const close = () => dispatch({ type: types.HIDE_CANCEL_VISIT_MODAL })

  const submit = cancelVisitMutation => async () => {
    return cancelVisitMutation({
      variables: {
        input: cancelvisitMutationData
      }
    })
  }

  const visitCanceled = _ => {
    navigation.navigate(`${routePaths.visits}?tab=upcoming`)
    openModal(client, {
      Component: ({ closeModal }) => (
        <Modal closeModal={closeModal} visit={visit} cancelFuture={cancelvisitMutationData.cancelFuture} />
      )
    })
  }

  const reasonIsInvalid = reason =>
    (reason === 'visitee_requested_this_cancelation' || cancelvisitMutationData.cancelationMessage === '') &&
    !cancelvisitMutationData.onBehalfOfCustomer

  const remainingFutureVisits = visit.futureOccurrencesCount - 1

  return (
    <OffScreenModal show={state.showCancelVisitModal} title='Cancel Visit' close={close}>
      {companionCanCancel ? (
        <div>
          You are canceling your {visit.visitTypeFriendlyName} with <strong>{firstNameAndLastInitial}</strong>
          {visit.startAt && (
            <>
              on
              <strong >
                {startAt.format('ddd, MMM DD')} at {startAt.format('h:mmA')} - {dayjs(visit.endAt).format('h:mmA')}
              </strong>
            </>
          )}
          . {visitRecurrenceGroupPresent && 'This is a recurring visit.'}
          <DiscourageCancelationMessages messages={discourageCompanionCancelationMessages} />
          <Mutation
            mutation={CANCEL_VISIT_MUTATION}
            refetchQueries={[
              { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
              'AvailableTimes'
            ]}
            onError={raiseErrorIfCantBeIgnored}
            onCompleted={visitCanceled}
          >
            {(cancelVisitMutation, { error, loading }) => (
              <div className='mt3'>
                {remainingFutureVisits > 0 && (
                  <ChoiceGroup
                    label='Cancel Recurring Visit'
                    className='mb3'
                    defaultSelectedKey={false}
                    options={[
                      {
                        key: false,
                        text: 'This Visit'
                      },
                      {
                        key: true,
                        text: `This and ${remainingFutureVisits} upcoming ${pluralize(remainingFutureVisits, 'visit')}`
                      }
                    ]}
                    onChange={(_, { key }) => handleChange('cancelFuture')(key)}
                  />
                )}
                <p>
                  Why are you canceling your {visit.visitTypeFriendlyName} with {preferredOrFirstName}?
                </p>
                <Checkbox
                  name='customer-canceled'
                  checked={cancelvisitMutationData.onBehalfOfCustomer}
                  label={`${preferredOrFirstName} requested this cancellation`}
                  className='mb3'
                  onChange={(_, checked) => handleChange('onBehalfOfCustomer')(checked)}
                />
                {showReasonMessageInput && (
                  <>
                    <Select handler={handleChange('reason')} items={reasons} placeholder='Or select a reason...' />
                    <TextField
                      multiline
                      autoAdjustHeight
                      rows={6}
                      className='mb2 mt3'
                      label={`Write a message to ${preferredOrFirstName} to explain or offer a time to reschedule:`}
                      value={cancelvisitMutationData.cancelationMessage}
                      onChange={(_, value) => handleChange('cancelationMessage')(value)}
                      name='cancelationMessage'
                      errorMessage={getError('cancelationMessage', error)}
                    />
                  </>
                )}
                {!isNil(discourageCompanionCancelationMessages) && (
                  <Button onClick={close} btnClass='btnMuted' className='mt2'>
                    Keep Scheduled Visit
                  </Button>
                )}
                <Button
                  disabled={reasonIsInvalid(cancelvisitMutationData.reason) || loading}
                  onClick={submit(cancelVisitMutation)}
                  data-cy='upcoming--cancel-visit'
                  btnClass='btnDanger'
                  className='mt2'
                >
                  Cancel Visit
                </Button>
              </div>
            )}
          </Mutation>
        </div>
      ) : (
          <div>
            <DiscourageCancelationMessages messages={discourageCompanionCancelationMessages} />
            <Button onClick={close}>Go Back</Button>
          </div>
        )
      }
    </OffScreenModal >
  )
}

export default withApollo(CancelVisit)
