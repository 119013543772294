import { replace } from 'ramda'

const visitsVisitStoryEdit: string = '/visits/:id/visit_story/edit'
const opportunityDetails: string = '/opportunities/:id'

interface PathValues {
  root: string;
  login: string;
  personSelection: string;
  updateApp: string;
  request2fa: string;
  verify2fa: string;
  updatePassword: string;
  visits: string;
  upcomingVisits: string;
  visitDetails: string;
  visitVisitStory: string;
  visitsVisitStoryEdit: string;
  bookVisit: string;
  error: string;
  availability: string;
  opportunities: string;
  opportunitiesSuggestAnotherTime: string;
  opportunityDetails: string;
  profile: string;
  resetPassword: string;
  verifyResetPasswordCode: string;
  visitCheckIn: string;
  settings: string;
  notifications: string;
  account: string;
  location: string;
  question: string;
  requestSetupCode: string;
  verifySetupCode: string;
  codeEntryFailed: string;
}

const paths: PathValues = {
  root: '/',
  login: '/login',
  personSelection: '/personSelection',
  updateApp: '/updateApp',
  request2fa: '/request2fa',
  verify2fa: '/verify2fa/:channel',
  updatePassword: '/update_password',
  visits: '/visits',
  upcomingVisits: '/visits?tab=upcoming',
  visitDetails: '/visits/:id',
  visitVisitStory: '/visits/:id/visit_story',
  visitsVisitStoryEdit,
  bookVisit: '/visits/new',
  error: '/error',
  availability: '/availability',
  opportunities: '/opportunities',
  opportunitiesSuggestAnotherTime: '/opportunities/:id/suggest',
  opportunityDetails,
  profile: '/profile',
  resetPassword: '/reset-password',
  verifyResetPasswordCode: '/verify-reset-code',
  visitCheckIn: '/visit-check-in',
  settings: '/settings',
  notifications: '/notifications',
  account: '/account',
  location: '/location',
  question: '/question',
  requestSetupCode: '/request-setup-code',
  verifySetupCode: '/verify-setup-code',
  codeEntryFailed: '/code-entry-failed'
}

export const unauthenticatedPaths = [
  paths.root,
  paths.login,
  paths.updatePassword,
  paths.error,
  paths.requestSetupCode,
  paths.verifySetupCode,
  paths.resetPassword,
  paths.verifyResetPasswordCode
]

export const editVisitStoryPath = (id: string) =>  replace(':id', id, visitsVisitStoryEdit)

export const opportunityDetailsPath = (id: string) => replace(':id', id, opportunityDetails)

export default paths
