export default {
  intergenImg: {
    flexBasis: '105px',
    flexGrow: '0',
    flexShrink: '0'
  },
  photoContainer: {
    width: '5.625rem',
    height: '5.625rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  location: {
    fontSize: '.9375rem'
  }
}
