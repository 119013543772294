import { gql } from '@apollo/client'

export const CANCEL_VISIT_MUTATION = gql`
  mutation CancelVisit($input: CancelVisitInput!) {
    cancelVisit(input: $input) {
      visit {
        id
      }
    }
  }
`
