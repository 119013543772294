import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import Button from 'components/Button'
import TextField from 'components/TextField'
import Checkbox from 'components/Checkbox'
import { openModal } from 'components/Layout/Modal'
import Modal from './Modal'
import withApollo from 'helpers/withApollo'
import { updateCompanionMutation } from 'components/Settings'

const Account = ({ client }) => {
  const [certify, setCertify] = useState(false)
  const [reason, setReason] = useState<string>()

  const submitDisabled = !reason || !certify

  const deactivated = () => {
    openModal(client, {
      Component: ({ closeModal }) => <Modal closeModal={closeModal} client={client} />
    })
  }

  const [updateCompanion] = updateCompanionMutation(deactivated)

  return (
    <>
      <TopBar title='Deactivate Account' left='menu' />
      <ContentWrapper styles='justify-center flex'>
        <h3 className='recoleta-regular'>Deactivate Account</h3>
        <p>This will close your account, any connections you have, and stop all notifications. Are you sure?</p>

        <Checkbox
          label='I certify that I would like to deactivate my account'
          name='certify'
          className='mt125 mb3'
          checked={certify}
          onChange={(_, checked) => setCertify(checked || false)}
          id='add-timeslot-recurring'
        />
        <p> Tell us why you are deactivating your account (required) </p>
        <TextField
          multiline
          autoAdjustHeight
          rows={6}
          className='mb2 mt3'
          value={reason}
          onChange={(_, value) => setReason(value || '')}
          name='reason'
          data-cy='deactivation-reason'
        />

        <div className='pt3' />
        <Button
          onClick={() => {
            updateCompanion({
              variables: {
                input: {
                  status: 'deactivated',
                  deactivationReason: reason
                }
              }
            })
          }}
          disabled={submitDisabled}
          data-cy='deactivation--submit'
        >
          Deactivate my Account
        </Button>
      </ContentWrapper>
    </>
  )
}

export default withApollo(Account)
