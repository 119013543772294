import { ApolloLink, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error";
import { RetryLink } from '@apollo/client/link/retry'
import { ApolloClient } from '@apollo/client/core';

import { createUploadLink } from 'apollo-upload-client'
import apolloLogger from 'apollo-link-logger'
import { isNetworkError } from 'helpers/errors'
import { cache } from 'helpers/cache'

import { localStateTypeDefs } from 'types/localState'

const getUri = () => {
  if (global.GRAPHQL_ENDPOINT_ENVIRONMENT) {
    return `https://app.${global.GRAPHQL_ENDPOINT_ENVIRONMENT}.monami.io/graphql`
  } else {
    return GRAPHQL_ENDPOINT || 'https://app.monami.io/graphql'
  }
}

export const raiseErrorIfCantBeIgnored = error => {
  if (error.message.includes('NotAuthorized') || error.message.includes('RecordInvalid')) return null
  else throw error
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) console.warn('GRAPHQL ERROR', graphQLErrors)
  if (networkError) console.warn('NETWORK ERROR', networkError)
})

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 5000,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: error => isNetworkError(error)
  }
})

const setHeaderLink = setContext((_, previousContext) => {
  const token = localStorage.getItem('authenticationToken')
  const notificationRegistrationType = localStorage.getItem('notificationRegistrationType')
  const notificationRegistrationId = localStorage.getItem('notificationRegistrationId')

  return {
    ...previousContext,
    headers: Object.assign(
      {},
      previousContext.headers,
      token && {
        Authorization: `Bearer ${token}`,
        NOTIFICATION_REGISTRATION_TYPE: notificationRegistrationType,
        NOTIFICATION_REGISTRATION_ID: notificationRegistrationId
      }
    )
  }
})

const setUri = setContext((_, previousContext) => ({
  ...previousContext,
  uri: getUri()
}))

const withLogger = link => ApolloLink.from([apolloLogger, link])

const httpOptions = {
  credentials: 'include'
}

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  withLogger(createUploadLink(httpOptions)),
  withLogger(new HttpLink(httpOptions))
)

export const client = new ApolloClient({
  cache,
  typeDefs: localStateTypeDefs,
  link: ApolloLink.from([errorLink, retryLink, setHeaderLink, setUri, httpLink]),
  resolvers: {},
  assumeImmutableResults: true,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first'
    },
    query: {
      fetchPolicy: 'cache-first'
    }
  }
})
