import React from 'react'
import dayjs from 'helpers/dayjs'
import DateTimePicker from 'components/TimeRangeInput/NativeTimePicker/DateTimePicker'

const displayDate = date => dayjs(date).format(`h:mmA`)

const sharedOptions = date => ({
  date,
  mode: 'time',
  allowOldDates: true,
  allowFutureDates: true,
  minDate: dayjs()
    .subtract(1, 'year')
    .toDate(),
  maxDate: null,
  minuteInterval: 15,
  locale: 'EN',
  okText: 'Select',
  cancelText: 'Cancel',
  android: {
    is24HourView: false,
    theme: 0
  }
})

const NativeTimePicker = ({ time, timeChangeHandler, setNativeDefaultEndTime }) => {
  return (
    <>
      <span
        className='underline'
        data-testid='startAt'
        onClick={() =>
          DateTimePicker().show({
            ...sharedOptions(time[0]),
            success: date =>
              timeChangeHandler([
                date,
                setNativeDefaultEndTime
                  ? dayjs(date)
                      .add(1, 'hour')
                      .toDate()
                  : time[1]
              ])
          })
        }
      >
        {displayDate(time[0])}
      </span>
      {` - `}
      <span
        className='underline'
        data-testid='endAt'
        onClick={() =>
          DateTimePicker().show({
            ...sharedOptions(time[1]),
            success: date => timeChangeHandler([time[0], date])
          })
        }
      >
        {displayDate(time[1])}
      </span>
    </>
  )
}

export default NativeTimePicker
