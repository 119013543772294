import React from 'react'
import OffScreenModal from 'components/OffScreenModal'
import Calendar from 'components/Calendar'
import { useStateValue, types } from 'components/EditVisitStory/StateProvider'

const EditDateModal = () => {
  const [state, dispatch] = useStateValue()

  const close = () => dispatch({ type: types.HIDE_SELECT_DATE_MODAL })

  const doneHandler = date => {
    dispatch({ type: types.UPDATE_DATE, date })
    close()
  }

  return (
    <OffScreenModal show={state.showSelectDateModal} title='Select a Date' close={close}>
      <Calendar singlePick doneHandler={doneHandler} cypressContext='visits-new' maxDate={new Date()} />
    </OffScreenModal>
  )
}

export default EditDateModal
