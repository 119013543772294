import React from 'react'
import seniorPlaceHolderImage from 'helpers/seniorPlaceHolderImage'
import MultiClamp from 'react-multi-clamp'
import withStyles from 'react-jss'
import style from './styles'
import OpportunityDateAndTimeDisplay from './OpportunityDateAndTimeDisplay'
import withApollo from 'helpers/withApollo'
import { isEmpty } from 'ramda'
import { gql } from '@apollo/client'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import Link from 'components/Link'
import routePaths, { opportunityDetailsPath } from 'routes/paths'
import dayjs from 'helpers/dayjs'
import colors from 'helpers/colors'
import Badge from 'components/Badge'
import LazyPhoto from 'components/LazyPhoto'
import { OpportunitiesQuery_companion_opportunities } from 'types/graphql/OpportunitiesQuery'

export const DISMISS_OPPORTUNITY_MUTATION = gql`
  mutation DismissOpportunityMutation($input: DismissOpportunityInput!) {
    dismissOpportunity(input: $input) {
      opportunity {
        id
      }
    }
  }
`

interface OpportunityCardProps {
  client: any,
  classes: any,
  opportunity: OpportunitiesQuery_companion_opportunities
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({ client, opportunity, classes }) => {
  const pendingVisit = opportunity.companionPendingVisit
  const dismissOpportunity = async () => {
    updateBusyIndicators(client, { bar: true })

    await client.mutate({
      mutation: DISMISS_OPPORTUNITY_MUTATION,
      variables: { input: { opportunityId: opportunity.id } },
      refetchQueries: ['OpportunitiesQuery']
    })
  }

  const opportunitySuggestionsPresent = !isEmpty(opportunity.opportunitySuggestions)

  return (
    <div className={`bg-white relative bottom-shadow mb3 radius ${classes.container}`}>
      <div className={`flex-nowrap flex-row ${classes.flex}`}>
        <div className='relative'>
          <LazyPhoto
            photo={opportunity.visitee.visiteePhoto}
            placeholderImage={seniorPlaceHolderImage[opportunity.opportunityType]}
            className={`h-100 ${classes.photoContainer}`}
          />
        </div>
        <div
          className={`${classes.width} ph3 pt2 pb2 w-80 flex-grow-1 bb
            ${opportunity.hasLinkage ? 'b--border-green bg-egg-shell' : 'b--gray'}`}
        >
          { opportunity.distance !== undefined && opportunity.distance !== null && <div className='tr dark-gray f6 i'>
            {opportunity.distance.toFixed(1)} mi away
            {!opportunitySuggestionsPresent && (
              <i
                data-testid='dismissOpportunity'
                className='icon-decline ml3 purple pointer'
                onClick={dismissOpportunity}
              />
            )}
          </div>}
          <div className='mb2'>
            <span className='fw6 recoleta-regular f4-5'>
              <MultiClamp ellipsis='...' clamp={1}>
                {opportunity.visitee.customer.person.firstNameAndLastInitial}
              </MultiClamp>
              {opportunity.hasLinkage && (
                <div className='pt1 f6 i'>You’ve visited {opportunity.visitee.customer.person.preferredOrFirstName} before!</div>
              )}
            </span>
          </div>
          <div className='mb1'>
            <MultiClamp ellipsis='...' clamp={2}>
              {opportunity.marketingHeadline}
            </MultiClamp>
          </div>
          <OpportunityDateAndTimeDisplay opportunity={opportunity} />
        </div>
      </div>
      {opportunitySuggestionsPresent || pendingVisit ? (
        <div className={`tc ${classes.button} green bg-egg-shell`}>
          You've offered assistance to {opportunity.visitee.customer.person.preferredOrFirstName}
          {opportunity.scheduleType === 'fixed' && pendingVisit && !opportunitySuggestionsPresent && (
            <>
              {' '}
              on {dayjs(pendingVisit.startAt).format('ddd, MMM DD')} at {dayjs(pendingVisit.startAt).format('h:mmA')} -{' '}
              {dayjs(pendingVisit.endAt).format('h:mmA')}
            </>
          )}
          . We'll get back to you soon!
        </div>
      ) : (
        <>
          <Link
            returnTo={routePaths.opportunities}
            route={opportunityDetailsPath(opportunity.id)}
            data-cy='opportunities--view-details'
          >
            <div className={`tc pointer ${classes.button}`}>
              View Details And Book <i className='icon-arrow-right purple ml1' />
            </div>
          </Link>
        </>
      )}
    </div>
  )
}

export default withStyles(style)(withApollo(OpportunityCard))
