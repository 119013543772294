import { gridItem } from 'components/MultiPhotoInput/styles'
import colors from 'helpers/colors'

export default {
  previewContainer: gridItem,
  loading: {
    animation: 'rotating 2s ease infinite',
    position: 'absolute',
    top: '32px',
    left: '35%',
    fontSize: '2rem',
    transformOrigin: '50% 40%'
  },

  photoContainer: {
    width: '5.625rem',
    height: '5.625rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: `1px solid ${colors.gray}`
  }
}
