import React, { useEffect } from 'react'
import withApollo from 'helpers/withApollo'
import withStyles from 'react-jss'
import style from './styles'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import colors from 'helpers/colors'
import { prop } from 'ramda'

const SECONDS_TO_SHOW_MESSAGE = 10000

const query = gql`
  query ClientNotification {
    notification @client
  }
`

const backgroundColor = notification => {
  switch (prop('type', notification)) {
    case 'error':
      return colors.red
    case 'success':
      return colors.green
    default:
      return colors.blue
  }
}

export const clearNotice = (client) => {
  client.writeQuery({ query, data: { notification: null } })
}

export const notify = (client, notification) => client.writeQuery({ query, data: { notification } })

const ClientNotification = ({ client, notification, classes }) => {
  useEffect(() => {
    if (notification) {
      setTimeout(() => client.writeQuery({ query, data: { notification: null } }), SECONDS_TO_SHOW_MESSAGE)
    }
  })

  return (
    <div
      className={`w-70 tc pv3 top-0 absolute ${classes.container}`}
      style={{
        transform: notification ? 'translateY(0px)' : 'translateY(-200px)',
        backgroundColor: backgroundColor(notification),
        zIndex: 2
      }}
    >
      {notification && notification.message}
    </div>
  )
}

const QueryWrap = props => (
  <Query query={query}>
    {({ data: { notification } }) => <ClientNotification {...props} notification={notification} />}
  </Query>
)

export default withStyles(style)(withApollo(QueryWrap))
