import React from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'

const OffScreenModal = ({ title, show = false, children, close, noPadding = false }) => {
  return (
    <div
      className=' bg-white h-100 top-0 left-0 w-100 absolute z-2'
      style={{
        transform: show ? `translateX(0px)` : 'translateX(100vw)',
        transition: '.2s'
      }}
    >
      <TopBar
        title={title}
        left={close ? 'closeOffscreenModal' : null}
        leftHandler={close}
      />
      <ContentWrapper noPadding={noPadding}>{children}</ContentWrapper>
    </div>
  )
}

export default OffScreenModal
