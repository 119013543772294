import React from 'react'
import { useNavigation } from 'react-navi'

interface BackArrowProps {
  route: string
}
const BackArrow: React.FC<BackArrowProps> = ({route}) => {
  const navigation = useNavigation()

  return (
    <a className='mb3' onClick={() => { navigation.navigate(route) }}>
      <i className='icon-arrow-left purple f4' data-cy='arrow-back' />
    </a>
  )
}

export default BackArrow
