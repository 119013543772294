import React from 'react'
import withStyles from 'react-jss'
import styles from './styles'
import LazyPhoto from 'components/LazyPhoto'
import companionPlaceholder from 'assets/images/companionPlaceholder.png'
import { ProfileSnippetQuery_companion } from 'types/graphql/ProfileSnippetQuery'
import SwitchOrganization from '../SwitchOrganization'

interface ProfileSnippetProps {
  classes: any,
  companion: ProfileSnippetQuery_companion
}

const ProfileSnippet: React.FC<ProfileSnippetProps> = ({ classes, companion }) => {

  return (
    <div className={`flex pa3 ${classes.container}`}>
      <LazyPhoto
        photo={companion.person.photo}
        className={`br2 ${classes.picture}`}
        placeholderImage={companionPlaceholder}
      />
       <div className='ph3 pv2'>
        <div className='f4 mb1'>{companion.person.fullName}</div>
        <SwitchOrganization/>
      </div>
    </div>
  )
}

export default withStyles(styles)(ProfileSnippet)
