import React from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'
import withApollo from 'helpers/withApollo'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { TentativeVisits_companion_visits } from 'types/graphql/TentativeVisits'

export const CONFIRM_VISIT_MUTATION = gql`
  mutation ConfirmVisit($input: ConfirmVisitInput!) {
    confirmVisit(input: $input) {
      visit {
        id
      }
    }
  }
`

interface AcceptModalProps {
  visit: TentativeVisits_companion_visits
  closeModal: any
}

export const AcceptModal: React.FC<AcceptModalProps> = ({ closeModal, visit }) => {
  return (
    <ModalBody closeModal={closeModal}>
      {({ modalClasses, CloseX }) => (
        <>
          <CloseX />
          <h3>Accept Visit</h3>
          <p className='tc ph3 mt0'>
            You are booking a visit with <strong>{visit.visitee.customer.person.firstNameAndLastInitial}</strong> for{' '}
            <strong>
              {dayjs(visit.startAt).format('ddd MMM D')} at {dayjs(visit.startAt).format('h:mma')}-
              {dayjs(visit.endAt).format('h:mma')}
            </strong>
          </p>
          <div className={`flex ${modalClasses.buttonContainer}`}>
            <Mutation
              mutation={CONFIRM_VISIT_MUTATION}
              variables={{ input: { visitId: visit.id } }}
              refetchQueries={['TentativeVisits']}
              onCompleted={closeModal}
            >
              {mutation => (
                <div onClick={mutation} data-cy='tentative--accept-visit'>
                  Confirm
                </div>
              )}
            </Mutation>
          </div>
        </>
      )}
    </ModalBody>
  )
}

export default withApollo(AcceptModal)
