import React, { useState } from 'react'
import OffScreenModal from 'components/OffScreenModal'
import { useStateValue, types } from 'components/Availability/StateProvider'
import CalendarDisplay from 'components/CalendarDisplay'
import TimeRangeInput, { timeRangeErrors } from 'components/TimeRangeInput'
import dayjs from 'helpers/dayjs'
import Checkbox from 'components/Checkbox'
import TextField from 'components/TextField'
import Button from 'components/Button'
import withStyles from 'react-jss'
import style from './styles'
import { Mutation } from '@apollo/client/react/components'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import setTimeOnDate from 'helpers/setTimeOnDate'
import datesAreDifferent from 'helpers/datesAreDifferent'
import { equals } from 'ramda'
import { CREATE_AVAILABLE_TIME_MUTATION } from 'components/Availability/queries'

const daysMap = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const AddNewTimeSlot = ({ classes }) => {
  const [state, dispatch] = useStateValue()
  const [recurrenceCountError, setRecurrenceCountError] = useState(null)
  const days = ['M', 'T', 'W', 'TH', 'F', 'SA', 'SU']

  const openCalendar = () => dispatch({ type: types.SHOW_SELECT_DATE_MODAL })
  const updateRecurring = (_, recurring) => dispatch({ type: types.UPDATE_RECURRING, recurring })
  const updateRecurrenceCount = (_, recurrenceCount) =>
    dispatch({ type: types.UPDATE_RECURRENCE_COUNT, recurrenceCount })
  const updateTime = ([startAt, endAt]) => {
    if (datesAreDifferent(startAt, state.startAt)) dispatch({ type: types.UPDATE_START_AT, startAt })
    if (datesAreDifferent(endAt, state.endAt)) dispatch({ type: types.UPDATE_END_AT, endAt })
  }
  const closeAndResetForm = () => {
    dispatch({ type: types.HIDE_NEW_TIME_SLOT_MODAL })
    dispatch({ type: types.RESET_NEW_TIME_SLOT_FORM })
  }

  const updateAdditionalDaysOfWeek = index => () => {
    if (dayOfWeekIsEqualToDayChosenByCalendar(index)) return

    const additionalDaysOfWeek = state.additionalDaysOfWeek.includes(index)
      ? state.additionalDaysOfWeek.filter(i => i !== index)
      : [...state.additionalDaysOfWeek, index]

    dispatch({ type: types.UPDATE_ADDITIONAL_DAYS_OF_THE_WEEK, additionalDaysOfWeek })
  }

  const dayOfWeekIsEqualToDayChosenByCalendar = index => {
    // dayjs starts with Sunday as zero, we treat Monday as zero
    return dayjs(state.date).weekday() - 1 === index
  }

  const validateRecurrenceCountAndCreateAvailableTime = mutation => async () => {
    if (state.recurring && Number(state.recurrenceCount) > 10) {
      return setRecurrenceCountError('Must be between 1 and 10')
    }
    if (recurrenceCountError) setRecurrenceCountError(null)

    try {
      await mutation({
        variables: {
          input: {
            startAt: setTimeOnDate(state.date, state.startAt)
              .second(0)
              .millisecond(0)
              .toJSON(),
            endAt: setTimeOnDate(state.date, state.endAt)
              .second(0)
              .millisecond(0)
              .toJSON(),
            recurrenceCount: state.recurring ? Number(state.recurrenceCount) : 1,
            additionalDaysOfWeek: state.additionalDaysOfWeek.map(i => daysMap[i])
          }
        }
      })
      closeAndResetForm()
    } catch (errors) {
      raiseErrorIfCantBeIgnored(errors)
      dispatch({ type: types.UPDATE_ERRORS, errors })
    }
  }

  return (
    <OffScreenModal show={state.showNewTimeSlotModal} title='Add New Timeslot' close={closeAndResetForm} noPadding>
      <Mutation mutation={CREATE_AVAILABLE_TIME_MUTATION} refetchQueries={['AvailableTimes', 'OpportunitiesQuery']}>
        {(mutation, { error, loading }) => (
          <>
            <div className='pa125'>
              <CalendarDisplay date={state.date} openCalendar={openCalendar} />
              <TimeRangeInput
                timeChangeHandler={updateTime}
                errorMessage={timeRangeErrors(state)}
                time={[state.startAt, state.endAt]}
              />
              <Checkbox
                label={`Make this timeslot recur on ${dayjs(state.date).format('dddd[s]')}`}
                name='recurring'
                className='mt125 mb3'
                checked={state.recurring}
                onChange={updateRecurring}
                id='add-timeslot-recurring'
              />
              <TextField
                disabled={!state.recurring}
                className='ml4'
                styles={{ fieldGroup: ['w4'] }}
                label='For how many weeks?'
                suffix='weeks'
                value={state.recurrenceCount}
                onChange={updateRecurrenceCount}
                name='weeks'
                errorMessage={recurrenceCountError}
                id='add-timeslot-recurring-count'
              />
            </div>
            <div className='hr' />
            <div className='pa125'>
              <div className='fw6 mb3'>I’m also available at this time on:</div>
              <div className={`flex flex-nowrap justify-between margin-auto mb3 ${classes.dayContainer}`}>
                {days.map((day, i) => (
                  <div
                    key={i}
                    data-testid={`dayOfWeek${i}`}
                    data-cy='add-timeslot-day-of-week'
                    className={
                      dayOfWeekIsEqualToDayChosenByCalendar(i)
                        ? classes.calendarChosenDay
                        : state.additionalDaysOfWeek.includes(i)
                        ? classes.chosenDay
                        : classes.day
                    }
                    onClick={updateAdditionalDaysOfWeek(i)}
                  >
                    {day}
                  </div>
                ))}
              </div>
              <div className='i f6 mb3'>If I selected 'Recurring' above, timeslots on these days will also recur</div>
              <Button data-cy='create-timeslot' onClick={validateRecurrenceCountAndCreateAvailableTime(mutation)}>
                Save
              </Button>
            </div>
          </>
        )}
      </Mutation>
    </OffScreenModal>
  )
}

export default React.memo(withStyles(style)(AddNewTimeSlot), equals)
