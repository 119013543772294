import React, { useState } from 'react'
import { prop } from 'ramda'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import TextField from 'components/TextField'
import Checkbox from 'components/Checkbox'
import InfoDisplay from 'helpers/infoDisplay'
import withApollo from 'helpers/withApollo'
import { joinWords } from 'components/Profile'
import Select from 'react-select'
import CustomSelect from 'components/Select'
import getError from 'helpers/getError'
import { UPDATE_COMPANION_AND_PROFILE } from 'mutations/updateCompanionAndProfile'
import { UpdateCompanionAndProfile, UpdateCompanionAndProfileVariables } from 'types/graphql/UpdateCompanionAndProfile'
import { useMutation } from '@apollo/client'
import { ProfileQuery_companion, ProfileQuery_companion_profile, ProfileQuery_availableSkills, ProfileQuery_availableLanguages } from 'types/graphql/ProfileQuery'

interface RegularProfileProps {
  companion: ProfileQuery_companion,
  profile: ProfileQuery_companion_profile,
  availableSkills: ProfileQuery_availableSkills[],
  availableLanguages: ProfileQuery_availableLanguages[],
  setShowEditForm: any,
  showEditForm: any,
  classes: any,
  flashMessage: any
}

const RegularProfile: React.FC<RegularProfileProps> = ({
  companion,
  profile,
  setShowEditForm,
  showEditForm,
  availableSkills,
  availableLanguages,
  classes,
  flashMessage
}) => {
  const [profileInfoMutationData, setProfileInfoMutationData] = useState({
    currentJob: profile.currentJob,
    childhoodCareerAspiration: profile.childhoodCareerAspiration,
    about: profile.about || undefined,
    interestedReason: profile.interestedReason,
    elderlyInteractionExperience: profile.elderlyInteractionExperience,
    lovedOneStory: profile.lovedOneStory,
    hobbiesAndInterests: profile.hobbiesAndInterests,
    favoriteThing: profile.favoriteThing,
    favoriteQuote: profile.favoriteQuote
  })
  const getSkillIds = companion?.skills?.map(prop('id'))
  const [skillIds, setSkillIds] = useState(getSkillIds)

  const allLanguages = availableLanguages.map(language => ({ label: language.name, value: language.id }))
  const companionLanguages = companion.person.languages.map(language => ({ label: language.name, value: language.id }))
  const [selectableLanguages, setSelectableLanguages] = useState(companionLanguages)
  const [selectablePrimaryLanguage, setSelectablePrimaryLanguage] = useState(companion.person.primaryLanguage)

  const inputChangeHandler = (e, value) => {
    setProfileInfoMutationData({
      ...profileInfoMutationData,
      [e.target.name]: value
    })
  }

  const updatePrimaryLanguage = id => {
    setSelectablePrimaryLanguage(availableLanguages.find(l => l.id === id) || null)
  }

  const [updateCompanionProfileMutation, mutationState] = useMutation<UpdateCompanionAndProfile, UpdateCompanionAndProfileVariables>(UPDATE_COMPANION_AND_PROFILE, {
    refetchQueries: ['ProfileQuery'],
    onError: raiseErrorIfCantBeIgnored,
    onCompleted: () => {
      setShowEditForm(!showEditForm)
      flashMessage('Your profile has been updated!')
    }
  })

  const updateSkills = skill => () => {
    if (skillIds) {
      if (skillIds.includes(skill.id)) {
        setSkillIds(skillIds.filter(f => f !== skill.id))
      } else {
        setSkillIds([...skillIds, skill.id])
      }
    }
  }

  return (
    <>
      <div className='pa125'>
        <InfoDisplay title='SCHOOL' content={companion.schoolName} />
        {!showEditForm && (
          <InfoDisplay
            title='INTENDED CAREER'
            content={
              profile.isStudent ? profileInfoMutationData.childhoodCareerAspiration : profileInfoMutationData.currentJob
            }
          />
        )}
        {!showEditForm ? (
          <>
            <InfoDisplay title='PRIMARY LANGUAGE' content={companion.person.primaryLanguage?.name} />
            <InfoDisplay title='OTHER LANGUAGES' content={joinWords(companion.person.languages)} />
            <InfoDisplay title='ABOUT ME' content={profile.about || '-'} />
            <InfoDisplay title="WHY I'M GIVING MY TIME" content={profile.interestedReason} />
            <InfoDisplay title='MY EXPERIENCE WITH ELDERS' content={profile.elderlyInteractionExperience} />
            <InfoDisplay title='A STORY I REMEMBER FONDLY' content={profile.lovedOneStory} />
            <InfoDisplay title='MY HOBBIES AND INTERESTS' content={profile.hobbiesAndInterests} />
            <InfoDisplay
              title='MY SKILLS'
              content={joinWords(availableSkills.filter(skill => skillIds?.includes(skill.id)))}
            />
            <InfoDisplay title='MY FAVORITE THING' content={profile.favoriteThing} />
            <InfoDisplay title='MY FAVORITE QUOTE' content={profile.favoriteQuote} />
          </>
        ) : (
          <>
            Primary Language
            <br />
            <br />
            <CustomSelect
              items={[
                { value: null },
                ...availableLanguages.map(v => ({
                  value: v.id,
                  text: v.name,
                  selected: v.id === selectablePrimaryLanguage?.id
                }))
              ]}
              handler={updatePrimaryLanguage}
            />
            <br />
            Other Languages
            <br />
            <br />
            <Select
              value={selectableLanguages}
              options={allLanguages}
              onChange={languages => setSelectableLanguages(languages)}
              isMulti
            />
            <TextField
              autoAdjustHeight
              className='mb2 mt3'
              label={'Intended career'}
              value={
                (profile.isStudent
                  ? profileInfoMutationData.childhoodCareerAspiration
                  : profileInfoMutationData.currentJob) || undefined
              }
              onChange={inputChangeHandler}
              name={profile.isStudent ? 'childhoodCareerAspiration' : 'currentJob'}
            />
            <br />
            <TextField
              multiline
              rows={5}
              autoAdjustHeight
              className='mb2 mt3'
              label={'About me'}
              value={profileInfoMutationData.about}
              onChange={inputChangeHandler}
              name='about'
              errorMessage={getError('about', mutationState.error)}
            />
            <TextField
              multiline
              rows={5}
              autoAdjustHeight
              className='mb2 mt3'
              label={"Why I'm giving my time"}
              value={profileInfoMutationData.interestedReason || undefined}
              onChange={inputChangeHandler}
              name='interestedReason'
            />
            <TextField
              autoAdjustHeight
              className='mb2 mt3'
              label={'My experience with elders'}
              value={profileInfoMutationData.elderlyInteractionExperience || undefined}
              onChange={inputChangeHandler}
              name='elderlyInteractionExperience'
            />
            <TextField
              autoAdjustHeight
              className='mb2 mt3'
              label={'A story I remember fondly'}
              value={profileInfoMutationData.lovedOneStory || undefined}
              onChange={inputChangeHandler}
              name='lovedOneStory'
            />
            <TextField
              autoAdjustHeight
              className='mb2 mt3'
              label={'My hobbies and interests'}
              value={profileInfoMutationData.hobbiesAndInterests || undefined}
              onChange={inputChangeHandler}
              name='hobbiesAndInterests'
            />
            <br />
            <p className='b'>My skills</p>
            <div className={classes.columns}>
              {availableSkills.map(skill => (
                <div className='flex' key={skill.id}>
                  <Checkbox
                    label={skill.name || undefined}
                    name={skill.name || undefined}
                    className={classes.height}
                    checked={skillIds?.includes(skill.id)}
                    onChange={updateSkills(skill)}
                    id={skill.id}
                  />
                </div>
              ))}
            </div>
            <TextField
              autoAdjustHeight
              className='mb2 mt3'
              label={'My favourite thing'}
              value={profileInfoMutationData.favoriteThing || undefined}
              onChange={inputChangeHandler}
              name='favoriteThing'
            />
            <TextField
              autoAdjustHeight
              className='mb2 mt3'
              label={'My favourite quote'}
              value={profileInfoMutationData.favoriteQuote || undefined}
              onChange={inputChangeHandler}
              name='favoriteQuote'
            />
            <div className='pt3' />
            <Button
              onClick={() => updateCompanionProfileMutation({
                variables: {
                  input: {
                    profile: profileInfoMutationData,
                    companion: {
                      skillIds: skillIds
                    },
                    person: {
                      languageIds: selectableLanguages ? selectableLanguages.map(prop('value')) : [],
                      primaryLanguageId: selectablePrimaryLanguage?.id
                    }
                  }
                }
              })}
            >Save</Button>
            <div className='tc mt3 radius purple' data-cy='no-thanks'>
              <a onClick={() => setShowEditForm(!showEditForm)} className='no-underline pointer'>
                Cancel
              </a>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default withApollo(RegularProfile)
