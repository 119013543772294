import colors from 'helpers/colors'

const day = {
  height: '44px',
  width: '44px',
  lineHeight: '44px',
  textAlign: 'center',
  backgroundColor: colors.eggShell,
  border: `1px solid ${colors.lighterGreen}`,
  borderRadius: '22px',
  cursor: 'pointer'
}

const chosenDay = {
  ...day,
  color: 'white',
  backgroundColor: colors.lighterGreen
}

export default {
  dayContainer: {
    maxWidth: '340px'
  },
  day,
  chosenDay,
  calendarChosenDay: {
    ...chosenDay,
    opacity: '0.5'
  }
}
