import { gql } from '@apollo/client'

const companionCompletedVisitsFragment = gql`
  fragment companionCompletedVisits on Visit {
    id
    status
    endAt
    startAt
    duration
    visitType
    visitTypeFriendlyName
    scheduleType
    expectCompletionAt
    completionAt
    visitCoding {
      id
      name
    }
    visitStory {
      id
      photos {
        id
        cdnUrlTemplate
      }
      startAt
      endAt
      description
      completed
      checkInAt
      noCheckInAtExplanation
    }
    visitee {
      id
      visiteePhoto {
        id
        cdnUrlTemplate
      }
      customer {
        id
        address {
          id
          addressLine1
          addressLine2
          city
          state
          zip
        }
        person {
          id
          preferredOrFirstName
          firstNameAndLastInitial
        }
        organization {
          id
          name
        }
      }
    }
  }
`

export default companionCompletedVisitsFragment
