import React from 'react'
import OffScreenModal from 'components/OffScreenModal'
import Calendar from 'components/Calendar'
import { useStateValue, types } from 'components/Availability/StateProvider'

const EditStartAt = () => {
  const [state, dispatch] = useStateValue()

  const close = () => dispatch({ type: types.HIDE_SELECT_DATE_MODAL })

  const doneHandler = date => {
    dispatch({ type: types.UPDATE_DATE, date })
    dispatch({ type: types.UPDATE_ADDITIONAL_DAYS_OF_THE_WEEK, additionalDaysOfWeek: [] })
    close()
  }

  return (
    <OffScreenModal show={state.showSelectDateModal} title='Select a Date' close={close}>
      <Calendar
        cypressContext='add-new-timeslot'
        singlePick
        doneHandler={doneHandler}
        reactCalendarProps={{ minDate: new Date() }}
      />
    </OffScreenModal>
  )
}

export default React.memo(EditStartAt)
