import React from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'
import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface ModalProps {
  visit: VisitDetailsQuery_companion_visit
  closeModal: any
  cancelFuture: any
}

const Modal: React.FC<ModalProps> = ({ closeModal, visit, cancelFuture }) => {
  const startAt = dayjs(visit.startAt)

  return (
    <ModalBody closeModal={closeModal}>
      {({ CloseX }) => (
        <>
          <CloseX />
          <h3 className=''>Visit Canceled</h3>
          <p className='tc ph3 mt0'>
            You have canceled your{cancelFuture && ' recurring'} {visit.visitTypeFriendlyName} with{' '}
            <strong>{visit.visitee.customer.person.preferredOrFirstName}</strong>
          </p>
          {visit.startAt && (
            <p className='tc fw6 f4 ph3 mt0'>
              {cancelFuture ? `${startAt.format('dddd')}s` : startAt.format('ddd, MMM DD')} at {startAt.format('h:mmA')}{' '}
              - {dayjs(visit.endAt).format('h:mmA')}
            </p>
          )}
          {visit.scheduleType !== 'flexible' && (
            <p className='tc ph3 mt0 i f6'>
              {cancelFuture
                ? `This ${visit.visitTypeFriendlyName} and Upcoming ${visit.visitTypeFriendlyName} have been cancelled`
                : `Upcoming ${visit.visitTypeFriendlyName}s have not been affected by this change`}
            </p>
          )}
        </>
      )}
    </ModalBody>
  )
}

export default Modal
