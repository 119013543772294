import React, { useRef, useState } from 'react'
import withApollo from 'helpers/withApollo'
import ContentWrapper from 'components/ContentWrapper'
import Button from 'components/Button'
import routePaths from 'routes/paths'
import style from './styles'
import { useNavigation } from 'react-navi'
import { NormalizedCacheObject, useMutation, ApolloClient } from '@apollo/client'
import { VERIFY_LOGIN_CODE } from 'mutations/verifyLoginCode'
import { VerifyLoginCode, VerifyLoginCodeVariables } from 'types/graphql/VerifyLoginCode'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { clearNotice, notify } from 'components/ClientNotification'
import ReactCodeInput from '@acusti/react-code-input'
import BackArrow from '../BackArrow'
import { setEndpointEnvAndTrimEmail } from 'helpers/graphqlConfig'
import RequestNewCode from '../RequestNewCode'

interface VerifyLoginCodeProps {
  client: ApolloClient<NormalizedCacheObject>,
  userEmail: string,
  title: string,
  description: string,
  submitText: string
}

const VerifyLoginCodeScreen: React.FC<VerifyLoginCodeProps> = ({ client, userEmail, title, description, submitText }) => {
  const navigation = useNavigation()
  const [code, setCode] = useState<string>('')
  const [attemptCount, setAttemptCount] = useState<number>(1)

  const [confirmCode, { error } ] = useMutation<VerifyLoginCode, VerifyLoginCodeVariables>(VERIFY_LOGIN_CODE, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)

      increaseAttemptCount()
      if(attemptCount >= 5) {
        navigation.navigate(`${routePaths.codeEntryFailed}`)
        notify(client, {
          message: 'Too many attempts',
          type: 'error'
        })
      } else {
        setCode('')
        notify(client, {
          message: 'Sorry, invalid or expired code',
          type: 'error'
        })
      }

    },
    onCompleted: ({verifyLoginCode}) => {
      if(verifyLoginCode) {
        clearNotice(client)
        navigation.navigate(`${routePaths.updatePassword}?token=${verifyLoginCode.resetPasswordToken}`)
      }
    }
  })

  const increaseAttemptCount = () => {
    setAttemptCount(attemptCount + 1)
  }

  return (
    <ContentWrapper styles='justify-center flex'>
      <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
        <div className='h-100 flex flex-column'>
          <BackArrow route={`${routePaths.requestSetupCode}?email=${userEmail}`} />
          <div>
          <h2 className='f3'>{title}</h2>
          <div className='f4 mb3'>{description}</div>
            <ReactCodeInput
              type='text'
              fields={6}
              value={code}
              onChange={setCode}
              data-cy='code--input'
              name=''
              inputMode='numeric'
              inputStyle={style.codeInputInputStyle}
              inputStyleInvalid={style.codeInputInvalidInputStyle}
              isValid={error == null}
            />

            <RequestNewCode userEmail={userEmail}/>

            <Button
              className='mt3'
              disabled={code.length != 6}
              onClick={() => { confirmCode({ variables: { input: { email: setEndpointEnvAndTrimEmail(userEmail), code } } }) }}
              data-cy='verify-login-code--submit'>
              {submitText}
            </Button>
          </div>
          <div></div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default withApollo(VerifyLoginCodeScreen)
