import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import dayjs from 'helpers/dayjs'
import seniorPlaceHolderImage from 'helpers/seniorPlaceHolderImage'
import Badge from 'components/Badge'
import colors from 'helpers/colors'
import MultiClamp from 'react-multi-clamp'
import Link from 'components/Link'
import routePaths, { editVisitStoryPath } from 'routes/paths'
import LazyPhoto from 'components/LazyPhoto'
import withApollo from 'helpers/withApollo'
import { useNavigation } from 'react-navi'
import check from 'assets/icons/accept.svg'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import { CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION } from 'mutations/createVisitStoryBeforeEditing'
import formatPhoneNumber from 'helpers/formatPhoneNumber'
import { UpcomingVisits_companion_visits } from 'types/graphql/UpcomingVisits'
import visitIcon from 'assets/icons/visit-icon.svg'

const initVisitStoryVariables = visit => ({
  status: 'in_progress',
  visitId: visit.id,
  version: 3
})

export const createVisitStoryFromVisit = (client, visit) => client.mutate({
  mutation: CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION,
  refetchQueries: [
    { query: COMPLETED_VISITS, variables: { scope: 'completed' } },
    { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } }
  ],
  variables: {
    input: initVisitStoryVariables(visit)
  }
})

export const redirectToEditVisitStory = (visit, navigation) => {
  navigation.navigate(editVisitStoryPath(visit.id))
}

const TeleLink = ({ children, number }) => <a href={`tel:${number}`}>{children}</a>

interface UpcomingVisitCardProps {
  visit: UpcomingVisits_companion_visits
  client: any
  classes: any
  hideTimeAway: boolean
}

const UpcomingVisitCard: React.FC<UpcomingVisitCardProps> = ({ hideTimeAway, classes, visit, client }) => {
  const navigation = useNavigation()
  const startAt = dayjs(visit.startAt)
  const endAt = dayjs(visit.endAt || visit.expectCompletionAt)
  const isTelepresence = visit.visitType === 'telepresence'
  const isService = visit.visitType === 'service'
  const isPersonal = visit.visitType === 'personal'

  const formattedNumber = formatPhoneNumber(visit.visitee.customer.phoneNumber)

  return (
    <div className={`bg-white relative bottom-shadow mb3 ${classes.container}`} data-cy={`upcoming--visit-container`}>
      {isTelepresence && (
        <a href={`tel:${formattedNumber}`} className='no-underline'>
          <div className={`pa3 b ${classes.type}`}>
            <i className={`mr2 purple icon-call`} />
            Call
          </div>
        </a>
      )}
      {isService && (
        <div className={`pa3 b ${classes.type}`}>
          <i className={`mr2 purple icon-shopping`} />
          Errand Run
        </div>
      )}
      {isPersonal && (
        <div className={`pa3 b ${classes.type}`}>
          <img src={visitIcon} className='mr2' />
          Visit
        </div>
      )}
      <div className='flex flex-nowrap flex-row'>
        <div className='relative w-33'>
          <LazyPhoto
            photo={visit.visitee.visiteePhoto}
            placeholderImage={seniorPlaceHolderImage[visit.visitType]}
            className={classes.photoContainer}
          />
          {visit.visitRecurrenceGroupPresent && (
            <Badge
              // @ts-ignore Need to figure out JSS props
              direction='left'
              text='Recurring'
              color={colors.lighterGreen}
              backgroundColor={colors.yellow}
              containerClass={classes.badge}
            />
          )}
        </div>
        <div className={`w-60 ph3 pt2 pb3 flex-grow-1 bb b--gray`}>
          <div className='tr i dark-gray f6'>
            {visit.scheduleType === 'flexible' ? (
              <>Complete in next {!hideTimeAway && `${endAt.fromNow(true)}`}</>
            ) : (
                <>{!hideTimeAway && `${startAt.fromNow(true)} away`}</>
              )}
          </div>
          <div className='mb2 mt1'>
            <span className='fw6 recoleta-regular'>
              <MultiClamp ellipsis='...' clamp={1}>
                {visit.visitee.customer.person.firstNameAndLastInitial}
              </MultiClamp>
            </span>
          </div>
          {visit.scheduleType === 'flexible' ? (
            <>
              <div className='mb1'>
                <i className='icon-calendar label-green mr2' />
                Timing is flexible. Complete by {endAt.format('ddd, MMM DD')}
              </div>
            </>
          ) : (
              <>
                <div className='mb1'>
                  <i className='icon-calendar label-green mr2' />
                  {startAt.format('ddd, MMM DD')}
                </div>
                <div className='mb1'>
                  <i className='icon-time label-green mr2' />
                  {startAt.format('h:mmA')} - {endAt.format('h:mmA')}
                </div>
              </>
            )}

          <div className='flex'>
            {!isTelepresence && visit.address ? (
              <>
                <i className='icon-location label-green mr2' />
                <div>
                  <div>{visit.address.addressLine1}</div>
                  <div>{visit.address.addressLine2}</div>
                  <div>
                    {visit.address.city}, {visit.address.state} {visit.address.zip}
                  </div>
                </div>
              </>
            ) : (
                <>
                  <i className='icon-econtact label-green mr2' />
                  <TeleLink number={formattedNumber}>{formattedNumber}</TeleLink>
                </>
              )}
          </div>
        </div>
      </div>
      <div>
        {visit.scheduleType === 'flexible' ? (
          <div
            onClick={() => {
              createVisitStoryFromVisit(client, visit)
              redirectToEditVisitStory(visit, navigation)
            }}
            data-cy='upcoming--mark-as-completed'
            className={`pointer dib tc bt br b--gray w-50 bg-egg-shell ${classes.button}`}
          >
            Mark as done
            <img className='ml2' src={check} />
          </div>
        ) : (
            <div className={'pointer dib tc bt br b--gray purple w-50'}>
              <Link
                route={`${routePaths.question}?visitId=${visit.id}`}
                returnTo={`${routePaths.visits}?tab=upcoming`}
                className={`tc ${classes.button}`}
                data-cy='upcoming--ask-question'
              >
                Ask a question
            </Link>
            </div>
          )}

        <div className={'pointer dib tc bt br b--gray w-50'}>
          <Link
            route={`/visits/${visit.id}`}
            returnTo={`${routePaths.visits}?tab=upcoming`}
            className={`tc ${classes.button}`}
            data-cy='upcoming--view-details'
          >
            View Details
            <i className='icon-arrow-right purple ml2' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withStyles(style)(withApollo(UpcomingVisitCard))
