import React from 'react'
import ChoiceGroup from 'components/ChoiceGroup'
import Button from 'components/Button'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { useStateValue } from 'components/BookVisit/StateProvider'
import { useNavigation } from 'react-navi'
import routePaths, { editVisitStoryPath } from 'routes/paths'
import { openModal } from 'components/Layout/Modal'
import withApollo from 'helpers/withApollo'
import Modal from '../Modal'
import { dissoc, equals } from 'ramda'
import ErrorMessage from 'components/ErrorMessage'
import { CREATE_VISIT } from 'mutations/createVisit'
import getVisitFriendlyName from 'helpers/getVisitFriendlyName'
import Select from 'components/Select'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { useMutation } from '@apollo/client'
import { CreateVisitStoryBeforeEditing, CreateVisitStoryBeforeEditingVariables } from 'types/graphql/CreateVisitStoryBeforeEditing'
import { CreateVisit, CreateVisitVariables } from 'types/graphql/CreateVisit'
import dayjs from 'helpers/dayjs'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import { CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION } from 'mutations/createVisitStoryBeforeEditing'

interface DefaultRecurringAndSubmitProps {
  client: any;
  onError: any;
  baseErrors: any;
  addressError: any;
  updateRecurrenceCount: any;
  updateRecurring: any;
  openPastVisitModal: any;
}

const DefaultRecurringAndSubmit: React.FC<DefaultRecurringAndSubmitProps> = ({
  client,
  onError,
  baseErrors,
  addressError,
  updateRecurrenceCount,
  updateRecurring
}) => {
  const [
    {
      recurrenceCount,
      startAt,
      endAt,
      date,
      address,
      visiteeId,
      recurring,
      visitType,
      visitCoding
    },
    dispatch
  ] = useStateValue()
  const navigation = useNavigation()

  const submitDisabled =
    !visiteeId ||
    !date ||
    !startAt ||
    !endAt ||
    !address ||
    (recurring && !Number(recurrenceCount))

  const weeks = Array.from({ length: 9 }, (v, k) => k + 2)
  const items = weeks.map(number => ({ text: `${number} weeks`, value: number }))

  const isDateInPast = dayjs(setTimeOnDate(date, startAt).toDate()).isBefore(dayjs())

  const oneTimeRenderLabel = ({ checked, text }) => {
    return (
      <span id='ChoiceGroupLabel14-recurring' className='ms-ChoiceFieldLabel'>
        <div id='recurring-visit-choice' className={'mb2'} >{text}</div>
        <Select
          required
          handler={updateRecurrenceCount}
          value={recurrenceCount}
          items={items}
          disabled={!checked}
          data-cy='visits-new--recurrence-count'
        />
        {checked && <div className='i mb125'>
          {getVisitFriendlyName(visitType || 'telepresence')}s will occur on{' '}
          {setTimeOnDate(date, startAt).format('dddd[s] [at] h:mm A')} - {setTimeOnDate(date, endAt).format('h:mm A')}
          {' '} weekly for the next {recurrenceCount} weeks.
        </div>}
      </span>
    )
  }

  const visitCreated = visit => {
    navigation.navigate(`${routePaths.visits}?tab=upcoming`)
    openModal(client, {
      Component: ({ closeModal }) => (
        <Modal closeModal={closeModal} visit={visit} recurring={recurring} recurrenceCount={recurrenceCount} />
      )
    })
  }

  const [createVisitStoryFromVisitMutation] = useMutation<CreateVisitStoryBeforeEditing, CreateVisitStoryBeforeEditingVariables>(CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION, {
    refetchQueries: [
      { query: COMPLETED_VISITS, variables: { scope: 'completed' } },
      { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } }
    ],
  })

  const [createVisitMutation] = useMutation<CreateVisit, CreateVisitVariables>(CREATE_VISIT, {
    refetchQueries: [
      { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
      'AvailableTimes',
      'BannerVisitQuery'
    ],
    onError: onError,
    onCompleted: ({ createVisit }) => {
      if (createVisit) {
        if (isDateInPast) {
          createVisitStoryFromVisitMutation({
            variables: {
              input: {
                status: 'in_progress',
                visitId: createVisit.visit.id,
                version: 3
              }
            }
          })
          navigation.navigate(`${editVisitStoryPath(createVisit.visit.id)}?isOldVisitSubmission=true`)
        } else {
          visitCreated(createVisit.visit)
        }
      }
    }
  })

  return (
    <>
      {(!date || (date && !isDateInPast)) && (
          <>
            <div className='mb2 fw6'>Frequency</div>
            <ChoiceGroup
              selectedKey={recurring ? 'recurring' : 'oneTime'}
              options={[
                {
                  key: 'oneTime',
                  text: 'One-Time'
                },
                {
                  key: 'recurring',
                  text: 'Weekly Recurring',
                  onRenderLabel: oneTimeRenderLabel
                }
              ]}
              onChange={updateRecurring}
            />
          </>
        )
      }

      {baseErrors && <ErrorMessage errorMessage={baseErrors} bordered />}
      {addressError && <ErrorMessage errorMessage={"Location can't be blank!"} bordered />}
      {date && isDateInPast && <ErrorMessage errorMessage={`You are reporting this ${getVisitFriendlyName(visitType || 'telepresence').toLowerCase()} as in the past.`} bordered />}

      <Button
        onClick={() => !submitDisabled && createVisitMutation({
          variables: {
            input: {
              address: dissoc('id', address),
              visiteeId,
              visitType: visitType || 'telepresence',
              visitCodingId: visitCoding,
              duration: setTimeOnDate(date, endAt).diff(setTimeOnDate(date, startAt), 'minute'),
              startAt: setTimeOnDate(date, startAt)
                .second(0)
                .millisecond(0)
                .toDate(),
              recurrenceCount: recurring ? Number(recurrenceCount) : 0
            },
          }
        })}
        disabled={submitDisabled}
        data-cy='visits-new--book-visit'
      >
        Submit
      </Button>
      <div className='tc mt3 radius purple' data-cy='no-thanks'>
        <a
          onClick={_ => navigation.navigate(`${routePaths.visits}?tab=completed`)}
          className='no-underline pointer'
        >
          Nevermind
        </a>
      </div>
    </>
  )
}

export default React.memo(withApollo(DefaultRecurringAndSubmit), equals)
