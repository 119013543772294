import { gql } from '@apollo/client'
import companionCompletedVisitsFragment from 'fragments/companionCompletedVisitsFragment'

export const PREVIOUS_VISITEES = gql`
  query PreviousVisitees($scope: String) {
    companion {
      id
      companionType
      organization {
        id
        name
      }
      visitees {
        id
        availableVisitTypes {
          key
          text
          addressRequired
        }
        useVisitCodes
        customer {
          id
          address {
            id
            addressLine1
            addressLine2
            city
            state
            zip
          }
          person {
            id
            firstNameAndLastInitial
          }
          organization {
            id
            visitCodings {
              id
              name
              visitType
            }
          }
        }
      }
      visits(scope: $scope) {
        ...companionCompletedVisits
      }
    }
  }
  ${companionCompletedVisitsFragment}
`
