export default {
  photoContainer: {
    width: '5.625rem',
    height: '5.625rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  location: {
    fontSize: '.9375rem'
  },
  detailHeading: { fontSize: '.8125rem' },
  detailText: { fontSize: '0.9375rem' }
}
