import React, { useEffect } from 'react'
import TimeRangeInput, { timeRangeErrors } from 'components/TimeRangeInput'
import { useStateValue, types } from '../StateProvider'
import RecurringAndSubmit from '../RecurringAndSubmit'
import DateEdit from '../DateEdit'
import { equals, dissoc } from 'ramda'
import dayjs from 'helpers/dayjs'

import { PreviousVisitees_companion_visits } from 'types/graphql/PreviousVisitees'

interface PredefinedBookVisitProps {
  predefinedVisit: PreviousVisitees_companion_visits,
  Container: any,
  updateTime: any
}

const PredefinedBookVisit: React.FC<PredefinedBookVisitProps> = ({ predefinedVisit, Container, updateTime }) => {
  const [state, dispatch] = useStateValue()

  useEffect(() => {
    const date = dayjs(predefinedVisit.visitStory?.startAt || predefinedVisit.startAt).add(1, 'week')
    const startAt = dayjs(predefinedVisit.visitStory?.startAt || predefinedVisit.startAt).toDate()
    const endAt = dayjs(predefinedVisit.visitStory?.endAt || predefinedVisit.endAt).toDate()
    const id = predefinedVisit.visitee.id
    const address = predefinedVisit.visitee.customer.address

    dispatch({ type: types.UPDATE_DATE, date })
    dispatch({ type: types.UPDATE_START_AT, startAt })
    dispatch({ type: types.UPDATE_END_AT, endAt })
    dispatch({ type: types.UPDATE_VISITEE_ID, id })
    dispatch({
      type: types.UPDATE_PARAMS,
      address: address
    })
    dispatch({ type: types.UPDATE_RECURRENCE_COUNT, recurrenceCount: 4 })
  }, [predefinedVisit])

  return (
    <>
      <Container>
        <div className='pa3 pv75 ph125 bb b--gray'>
          <div className='tc'>
            Thank you for submitting your {predefinedVisit.visitTypeFriendlyName} Recap! We look forward to reading all
            about it.
          </div>
        </div>
        <p>
          You can schedule a recurring {predefinedVisit.visitTypeFriendlyName} with {predefinedVisit.visitee.customer.person.preferredOrFirstName}{' '}
          below:
        </p>
        <DateEdit predefinedVisit={predefinedVisit} />
      </Container>
      <Container>
        <div className='mb2 fw6'>Set Time</div>
        <TimeRangeInput
          timeChangeHandler={updateTime}
          errorMessage={timeRangeErrors(state, 'scheduledDuration')}
          time={[state.startAt, state.endAt]}
          setDefaultEndTime
        />
      </Container>
      <Container noBorder>
        <RecurringAndSubmit predefinedVisit={predefinedVisit} />
      </Container>
    </>
  )
}

export default React.memo(PredefinedBookVisit, equals)
