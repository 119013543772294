import React from 'react'
import LogRocket from 'logrocket'
import routePaths from 'routes/paths'
import { useNavigation } from 'react-navi'

const Wrapper = ({ children }) => {
  const navigation = useNavigation()
  return <ErrorBoundary navigation={navigation} children={children} />
}

class ErrorBoundary extends React.Component {
  componentDidCatch = (error, info) => {
    if (NODE_ENV === 'development') return
    LogRocket.captureException(error)
    this.props.navigation.navigate(routePaths.error)
  }

  render = () => this.props.children
}

export default Wrapper
