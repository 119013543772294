export default {
  photoContainer: {
    width: '120px',
    height: '120px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '4px'
  },

  badge: {
    bottom: '418px'
  }
}
