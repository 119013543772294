import React from 'react'
import VerifyLoginCode from '../VerifyLoginCodeScreen'

interface VerifyResetPasswordCodeProps {
  userEmail: string
}

const VerifyResetPasswordCode: React.FC<VerifyResetPasswordCodeProps> = ({ userEmail }) => {
  return (
    <VerifyLoginCode
      userEmail={userEmail}
      title='Enter Verification Code'
      description={`Enter the 6-digit code we sent to ${userEmail} to reset your password`}
      submitText='Submit'
    />
  )
}

export default VerifyResetPasswordCode
