import colors from 'helpers/colors'

const basePill = {
  height: '44px',
  width: '80px',
  borderRadius: '22px',
  border: `1px solid ${colors.borderGreen}`,
  backgroundColor: colors.eggShell,
  lineHeight: '42px'
}

export const ArrowStyle = {
  container: {
    height: '44px',
    lineHeight: '42px',
    color: colors.purple
  }
}

export default {
  dayPill: basePill,
  timePill: basePill,
  selectedDayPill: {
    ...basePill,
    backgroundColor: colors.lighterGreen,
    color: 'white'
  },
  selectedTimePill: {
    ...basePill,
    backgroundColor: colors.lighterGreen,
    color: 'white'
  },
  dayPlaceholder: {
    ...basePill,
    border: 'none',
    backgroundColor: 'white'
  },
  timePlaceholder: {
    ...basePill,
    border: 'none',
    backgroundColor: 'white'
  },
  companionIsAvailableBadge: {
    right: '2px',
    top: '-17px'
  }
}
