import clientOauthProvider from 'queries/clientOauthProvider'
import { gql } from '@apollo/client'
import { isBrowser } from 'helpers/platform'

const LOGOUT_MUTATION = gql`
  mutation LogOut {
    logoutUser {
      id
    }
  }
`

const removeAuthTokenAndReload = (reload) => {
  localStorage.removeItem('authenticationToken')
  localStorage.removeItem('currentPersonId')
  if (reload) window.location.reload()
}

export const logoutOauth = (provider, callback) => {
  if (provider === 'google') global.plugins.googleplus.logout(callback || console.info, console.error)
}

const logout = async (client, reload = true) => {
  try {
    await client.mutate({ mutation: LOGOUT_MUTATION, operationName: 'LogoutUser' })
  } catch (e) {
    console.error(e)
  } finally {
    const { oauthProvider } = client.readQuery({ query: clientOauthProvider })
    if (!isBrowser && oauthProvider) logoutOauth(oauthProvider, removeAuthTokenAndReload)
    else removeAuthTokenAndReload(reload)
  }
}

export default logout
