import React, { useState } from 'react'
import withStyles from 'react-jss'
import { useQuery, gql, useMutation } from '@apollo/client';
import style from './styles'
import withApollo from 'helpers/withApollo'
import { pipe } from 'ramda'
import TopBar from 'components/TopBar'
import TextField from 'components/TextField'
import getError from 'helpers/getError'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import ContentWrapper from 'components/ContentWrapper'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import formatPhoneNumber from 'helpers/formatPhoneNumber'
import { monamiPhone } from 'helpers/globals'

import { QuestionQuery, QuestionQueryVariables } from 'types/graphql/QuestionQuery'
import { CreateSupportQuestion, CreateSupportQuestionVariables } from 'types/graphql/CreateSupportQuestion'


const QUESTION_QUERY = gql`
  query QuestionQuery($visitId: ID!) {
    companion {
      id
      visit(id: $visitId) {
        id
        visitType
        visitTypeFriendlyName
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
            }
            designatedContact {
              id
              person {
                id
                preferredOrFirstName
              }
            }
            monamiPocName
            organization {
              id
              name
              directPhoneNumber {
                id
                number
                extension
              }
            }
          }
        }
      }
    }
  }
`
export const CREATE_QUESTION_MUTATION = gql`
  mutation CreateSupportQuestion($input: CreateSupportQuestionInput!) {
    createSupportQuestion(input: $input) {
      supportQuestion {
        id
      }
    }
  }
`

const QuestionAsked = ({ customer }) => {
  const orgPhone = customer.organization?.directPhoneNumber

  return (
    <div className='pa3'>
      Thank you for submitting your question! {customer.monamiPocName} will get back to you shortly with a
      response via email or text message. <br />
      <br />
      If your question is urgent, please call{' '}
      {formatPhoneNumber(orgPhone?.number || monamiPhone)}{orgPhone?.extension ? ` x${orgPhone?.extension}` : ''}
      <br /> <br />
      <Link route={routePaths.visits} data-cy='navigate-to-visits'>
        <div className='pointer purple tc'>Go back to Visits</div>
      </Link>
    </div>
  )
}

interface QuestionProps {
  visitId: string,
  classes?: any
}

const Question: React.FC<QuestionProps> = ({
  classes,
  visitId
}) => {
  const {
    loading,
    data
  } = useQuery<QuestionQuery, QuestionQueryVariables>(QUESTION_QUERY, { variables: { visitId: visitId } })
  const [createQuestion, mutationState] = useMutation<CreateSupportQuestion, CreateSupportQuestionVariables>(CREATE_QUESTION_MUTATION, { onError: raiseErrorIfCantBeIgnored })

  if (loading || !data || !data.companion?.visit) return null

  const visit = data.companion.visit
  const customer = visit.visitee.customer

  const AskQuestion = () => {
    const [question, setQuestion] = useState<string>()
    return <div className='ph3'>
      <TextField
        multiline
        autoAdjustHeight
        rows={6}
        className='mb2 mt3'
        label={`What would you like to ask about your ${visit.visitTypeFriendlyName} with ${customer.person.preferredOrFirstName}?`}
        defaultValue={question}
        onChange={(_, question) => setQuestion(question)}
        name='question'
        errorMessage={getError('message', mutationState.error)}
        data-cy='question'
      />
      <Button
        className='mt3'
        disabled={mutationState.loading}
        data-cy='question-submit'
        onClick={() => {
          createQuestion({
            variables: {
              input: {
                message: question,
                visitId: visitId
              }
            }
          })
        }}> Submit Question </Button>
    </div>
  }

  return <>
    <TopBar title='Ask a Question' left='visits' visiteeId={visit.visitee.id} />
    <div className='pa3 pt4 bg-egg-shell bb b--yellow'>
      <div className={classes.headerContainer}>
        <div className='flex'>
          <div className='flex-grow-1'>
            This will be seen by {customer.monamiPocName}, a {customer.organization?.name || 'Mon Ami'}{' '}
              administrator who is supporting {customer.person.preferredOrFirstName}.
          </div>
        </div>
      </div>
    </div>
    <ContentWrapper noPadding>
      {!mutationState.called || mutationState.error || mutationState.loading ? (
        <AskQuestion />
      ) : (
          <QuestionAsked customer={customer} />
        )}
    </ContentWrapper>
  </>
}

const QuestionWrapper = pipe(withStyles(style), withApollo)(Question)

export default QuestionWrapper
