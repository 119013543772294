import { gql } from '@apollo/client'
import companionFragment from 'fragments/companionFragment'

export const UPDATE_COMPANION_MUTATION = gql`
  mutation UpdateCompanion($input: UpdateCompanionInput!) {
    updateCompanion(input: $input) {
      companion {
        ...fullnameTypePhoto
      }
    }
  }
  ${companionFragment}
`
