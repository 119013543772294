import * as React from "react";
import withStyles from 'react-jss'
import style from './styles'
import classNames from 'classnames'

interface ContentWrapperProps {
  Header?: React.ReactFragment | boolean | undefined,
  classes?: any,
  styles?: any,
  innerContainerStyles?: any,
  children: any,
  noPadding?: boolean,
  noGrow?: boolean,
  noScroll?: boolean
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  Header,
  classes,
  styles,
  innerContainerStyles,
  children,
  noPadding = false,
  noGrow = false,
  noScroll = false
}) => <>
    <div
      className={classNames(styles, classes.container, {
        'ph125 pt125': !noPadding,
        'flex-grow-1': !noGrow,
        'overflow-y-scroll': !noScroll
      })}
    >
      {Header && Header}
      <div className={classNames(classes.innerContainer, innerContainerStyles)}>
        {children}
      </div>
    </div>
  </>

export default withStyles(style)(ContentWrapper)
