import React, { useState } from 'react'
import TimeRangeInput from 'components/TimeRangeInput'
import CalendarDisplay from 'components/CalendarDisplay'
import OffScreenModal from 'components/OffScreenModal'
import Calendar from 'components/Calendar'
import setTimeOnDate from 'helpers/setTimeOnDate'
import dayjs from 'helpers/dayjs'

const defaultTime = [
  dayjs()
    .hour(11)
    .minute(0)
    .toDate(),
  dayjs()
    .hour(13)
    .minute(0)
    .toDate()
]

const TimeOption = ({ setSlot }) => {
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(defaultTime)
  const [showDateModal, setShowDateModal] = useState(false)

  const timeChangeHandler = time => {
    setTime(time)
    setSlot(slotProps({ time: time, date: date }))
  }

  const calendarChangeHandler = date => {
    setDate(date)
    setSlot(slotProps({ time: time, date: date }))
    setShowDateModal(false)
  }

  const slotProps = ({ time, date }) => {
    const [startAt, endAt] = time
    return {
      startAt: setTimeOnDate(date, startAt),
      endAt: setTimeOnDate(date, endAt)
    }
  }

  return (
    <>
      <OffScreenModal
        show={showDateModal}
        title='Select a Date'
        close={_ => setShowDateModal(false)}
      >
        <Calendar
          cypressContext='suggest'
          singlePick
          defaultDates={[date]}
          doneHandler={calendarChangeHandler}
        />
      </OffScreenModal>
      <CalendarDisplay date={date} openCalendar={() => setShowDateModal(true)} />
      <TimeRangeInput time={time} timeChangeHandler={timeChangeHandler} />
    </>
  )
}

export default TimeOption
