import React, { useState, Fragment } from 'react'
import Modal from './Modal'
import dayjs from 'helpers/dayjs'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import { Mutation } from '@apollo/client/react/components'
import getError from 'helpers/getError'
import Button from 'components/Button'
import { useNavigation } from 'react-navi'
import TextField from 'components/TextField'
import ErrorMessage from 'components/ErrorMessage'
import { openModal } from 'components/Layout/Modal'
import AvailableDatesAndTimes from './AvailableDatesAndTimes'
import { pipe, dissoc, isNil, isEmpty, groupWith } from 'ramda'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { BOOK_VISIT_FROM_OPPORTUNITY } from 'mutations/bookVisitFromOpportunity'

const FixedScheduleOpportunity = ({ opportunity, opportunityMessage, client }) => {
  const shift = opportunity.opportunityType === 'shift'

  const [chosenTimeSlot, setChosenTimeSlot] = useState(null)
  const [companionMessage, setCompanionMessage] = useState(undefined)
  const [companionMessageErrorMessage, setCompanionMessageErrorMessage] = useState(null)

  const groupedSlots = groupWith((a, b) => dayjs(a.startAt).isSame(dayjs(b.startAt), 'day'))(
    opportunity.visitTimeSuggestions
  )

  const bookVisitIfCompanionMessagePresent = bookVisit => async () => {
    if (isEmpty(companionMessage) || isNil(companionMessage)) return setCompanionMessageErrorMessage('Message required')
    else return bookVisit()
  }

  const bookVisitVariables = chosenTimeSlot
    ? {
      input: {
        companionMessage,
        address: pipe(dissoc('__typename'), dissoc('id'))(opportunity.address),
        opportunityId: opportunity.id,
        visiteeId: opportunity.visitee.id,
        duration: dayjs(chosenTimeSlot.endAt).diff(dayjs(chosenTimeSlot.startAt), 'minutes'),
        startAt: chosenTimeSlot.startAt,
        opportunitySlotId: chosenTimeSlot.timeSlotId,
        status: 'pending'
      }
    }
    : {}

  const [errors, setErrors] = useState(undefined)
  const onError = errors => {
    raiseErrorIfCantBeIgnored(errors)
    setErrors(errors)
  }
  const baseErrors = getError('base', errors)

  const visitBooked = () => {
    navigation.navigate(routePaths.opportunities)
    openModal(client, {
      Component: ({ closeModal }) => (
        <Modal closeModal={closeModal} opportunity={opportunity} chosenDate={chosenTimeSlot.startAt} />
      )
    })
  }

  const navigation = useNavigation()

  return (
    <Fragment>
      <div className='ph125 pv125 bb b--gray'>
        <div className='i tc mb125'>
          Select a date and time below to book this {opportunity.opportunityFriendlyName}
        </div>
        <AvailableDatesAndTimes
          timeSlots={groupedSlots}
          setChosenTimeSlot={setChosenTimeSlot}
          length={opportunity.length / 60}
          shift={shift}
          opportunityFriendlyName={opportunity.opportunityFriendlyName}
        />
        <div className='mb3 fw6'>{opportunityMessage}</div>
        <TextField
          multiline
          autoAdjustHeight
          placeholder={`I'm excited for this ${opportunity.opportunityFriendlyName}...`}
          rows={4}
          className='mb125'
          value={companionMessage}
          onChange={(_, notes) => setCompanionMessage(notes)}
          name='notes'
          errorMessage={companionMessageErrorMessage}
          data-cy='opportunities--companion-message'
        />
        {baseErrors && <ErrorMessage errorMessage={baseErrors} bordered />}
        <Mutation
          mutation={BOOK_VISIT_FROM_OPPORTUNITY}
          onError={onError}
          variables={bookVisitVariables}
          onCompleted={visitBooked}
          refetchQueries={['OpportunitiesQuery', 'UpcomingVisits']}
        >
          {bookVisit => (
            <div>
              <Button onClick={bookVisitIfCompanionMessagePresent(bookVisit)} data-cy='opportunities--book-visit'>
                Offer Assistance
              </Button>
            </div>
          )}
        </Mutation>
      </div>
      {!shift && !opportunity.opportunitySuggestions.length && (
        <>
          <div className='i mt125 ml3'>Or, if none of these times work for you, you can...</div>
          <Link
            route={routePaths.opportunitiesSuggestAnotherTime.replace(':id', opportunity.id)}
            returnTo={routePaths.opportunityDetails.replace(':id', opportunity.id)}
            data-cy='opportunities--open-suggest-another-time'
          >
            <div className='tc pv2 radius bg-egg-shell ma125'>
              <i className='icon-message mr1 purple' />
              Suggest Another Time
            </div>
          </Link>
        </>
      )}
    </Fragment>
  )
}

export default FixedScheduleOpportunity
