export default {
  headerContainer: {
    maxWidth: '600px',
    margin: '0 auto'
  },
  headerImageContainer: {
    width: '7.875rem',
    '& img': {
      position: 'absolute',
      right: 0,
      bottom: 0
    }
  }
}
