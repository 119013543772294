import React from 'react'
import { EDIT_VISIT_STORY_QUERY, createVisitStoryFromVisit } from './queries'
import { pipe, prop, isNil } from 'ramda'
import EditVisitStory from './index'
import { StateProvider } from 'components/EditVisitStory/StateProvider'

const visitStoryDoesNotExist = pipe(prop('visitStory'), isNil)

const getView = async ({ params: { id } }, { client }) => {
  const {
    data: {
      metaData,
      companion: { visit }
    }
  } = await client.query({
    query: EDIT_VISIT_STORY_QUERY,
    variables: { visitId: id }
  })

  if (visitStoryDoesNotExist(visit)) {
    const {
      data: {
        createVisitStory: { visitStory }
      }
    } = await createVisitStoryFromVisit(client, visit)
    return (
      <StateProvider>
        <EditVisitStory visit={{ ...visit, visitStory }} metaData={metaData} />
      </StateProvider>
    )
  }

  return (
    <StateProvider>
      <EditVisitStory visit={visit} metaData={metaData} />
    </StateProvider>
  )
}

export default getView
