import React, { useState } from 'react'
import { useStateValue, types } from 'components/VisitDetails/StateProvider'
import OffScreenModal from 'components/OffScreenModal'
import dayjs from 'helpers/dayjs'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import Button from 'components/Button'
import ChoiceGroup from 'components/ChoiceGroup'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import withApollo from 'helpers/withApollo'
import { openModal } from 'components/Layout/Modal'
import Modal from './Modal'
import Calendar from 'components/Calendar'
import CalendarDisplay from 'components/CalendarDisplay'
import TimeRangeInput from 'components/TimeRangeInput'
import { omit } from 'ramda'
import getError from 'helpers/getError'
import setTimeOnDate from 'helpers/setTimeOnDate'
import ErrorMessage from 'components/ErrorMessage'
import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'
import { NormalizedCacheObject, ApolloClient } from '@apollo/client'
import { RescheduleVisit as RescheduleVisitInterface, RescheduleVisitVariables } from 'types/graphql/RescheduleVisit'
import { RESCHEDULE_VISIT_MUTATION } from 'queries/rescheduleVisit'

interface RescheduleVisitProps {
  visit: VisitDetailsQuery_companion_visit;
  client: ApolloClient<NormalizedCacheObject>;
  redirectToKey: string;
}

const RescheduleVisit: React.FC<RescheduleVisitProps> = ({ visit, client, redirectToKey }) => {
  const {
    visitRecurrenceGroupPresent,
    visitee: {
      customer: {
        person: {
          firstNameAndLastInitial
        }
      }
     }
  } = visit
  const startAt = dayjs(visit.startAt)
  const endAt = dayjs(visit.endAt)
  const [state, dispatch] = useStateValue()
  const navigation = useNavigation()
  const [errors, setErrors] = useState(undefined)

  const [showDateModal, setShowDateModal] = useState(false)

  const [rescheduleVisitMutationData, setRescheduleVisitMutationData] = useState({
    visitId: visit.id,
    startAt: startAt.toDate(),
    endAt: endAt.toDate(),
    duration: visit.duration,
    cascadeChangesToFutureVisits: false
  })

  const handleChange = key => value => {
    setRescheduleVisitMutationData({
      ...rescheduleVisitMutationData,
      [key]: value
    })
  }

  const close = () => dispatch({ type: types.HIDE_RESCHEDULE_VISIT_MODAL })

  const submit = rescheduleVisitMutation => async () => {
    return rescheduleVisitMutation({
      variables: {
        input: omit(['endAt'], rescheduleVisitMutationData)
      }
    })
  }

  const visitRescheduled = _ => {
    navigation.navigate(routePaths[redirectToKey])
    openModal(client, {
      Component: ({ closeModal }) => (
        <Modal closeModal={closeModal} visit={visit} rescheduleVisitMutationData={rescheduleVisitMutationData} />
      )
    })
  }

  const timeChangeHandler = ([startTime, endTime]) => {
    const newStartTime = startTime && setTimeOnDate(rescheduleVisitMutationData.startAt, startTime)
    const newEndTime = startTime && setTimeOnDate(rescheduleVisitMutationData.startAt, endTime)
    setRescheduleVisitMutationData(
      Object.assign(
        {},
        rescheduleVisitMutationData,
        newStartTime && { startAt: newStartTime },
        newEndTime && { endAt: newEndTime },
        newStartTime && newEndTime && { duration: (newEndTime - newStartTime) / 1000 / 60 }
      )
    )
  }

  const calendarHandler = date => {
    const startTime = dayjs(rescheduleVisitMutationData.startAt)
    const startAtStr = setTimeOnDate(date, startTime)
    handleChange('startAt')(dayjs(startAtStr).toDate())
    setShowDateModal(false)
  }

  const onError = errors => {
    raiseErrorIfCantBeIgnored(errors)
    setErrors(errors)
  }

  const baseErrors = getError('base', errors)

  return (
    <OffScreenModal show={state.showRescheduleVisitModal} title='Reschedule Visit' close={close}>
      You are rescheduling your {visit.visitTypeFriendlyName} with <strong>{firstNameAndLastInitial}</strong> on{' '}
      <strong>
        {startAt.format('ddd, MMM DD')} at {startAt.format('h:mmA')} - {dayjs(visit.endAt).format('h:mmA')}
      </strong>
      . You should only take this step after you’ve confirmed the new time with them.
      {visitRecurrenceGroupPresent && ' This is a recurring visit.'}
      <Mutation<RescheduleVisitInterface, RescheduleVisitVariables>
        mutation={RESCHEDULE_VISIT_MUTATION}
        refetchQueries={[
          'UpcomingVisits',
          'CompletedVisits',
          'AvailableTimes'
        ]}
        onError={onError}
        onCompleted={visitRescheduled}
      >
        {(rescheduleVisitMutation, { error }) => (
          <div className='mt3'>
            <OffScreenModal show={showDateModal} title='Select a Date' close={_ => setShowDateModal(false)}>
              <div className='mb125'>You can select one date for your visit booking:</div>
              <Calendar
                singlePick
                defaultDates={[rescheduleVisitMutationData.startAt]}
                doneHandler={calendarHandler}
                cypressContext='upcoming'
              />
            </OffScreenModal>
            <CalendarDisplay
              date={dayjs(rescheduleVisitMutationData.startAt)}
              openCalendar={_ => setShowDateModal(true)}
            />
            <TimeRangeInput
              time={[
                dayjs(rescheduleVisitMutationData.startAt).toDate(),
                dayjs(rescheduleVisitMutationData.endAt).toDate()
              ]}
              timeChangeHandler={timeChangeHandler}
              errorMessage={getError('scheduledDuration', error) && 'End time must be greater than start time'}
            />
            {visit.futureOccurrencesCount > 0 && (
              <ChoiceGroup
                label='Reschedule Recurring Visit'
                className='mb3'
                defaultSelectedKey={false}
                options={[
                  {
                    key: false,
                    text: 'This Visit'
                  },
                  {
                    key: true,
                    text: `This and ${visit.futureOccurrencesCount} ${visit.visitTypeFriendlyName} upcoming `
                  }
                ]}
                onChange={(_, { key }) => handleChange('cascadeChangesToFutureVisits')(key)}
              />
            )}
            <Button
              data-testid='rescheduleVisit'
              onClick={submit(rescheduleVisitMutation)}
              data-cy='upcoming--reschedule-visit'
            >
              Reschedule Visit
            </Button>
            {baseErrors && <ErrorMessage errorMessage={baseErrors} bordered />}
          </div>
        )}
      </Mutation>
    </OffScreenModal>
  )
}

export default withApollo(RescheduleVisit)
