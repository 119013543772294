import React, { useState } from 'react'
import withStyles from 'react-jss'
import style from './styles'
import PhotoHandler from 'components/PhotoHandler'

const SinglePhotoInput = ({ classes, uploadPhoto, photo }) => {
  const [file, setFile] = useState()
  const [id, setId] = useState()
  const [uploading, setUploading] = useState(false)

  const setPhoto = ({ target }) => {
    if (target.files.length === 0) return
    setFile(target.files[0])
    setId(Date.now())
  }

  return (
    <div>
      <PhotoHandler
        key={1}
        id={id}
        isSinglePhoto
        {...{
          file,
          uploadPhoto,
          setUploading,
          uploading,
          photo
        }}
      />

      <input
        className={classes.fileInput}
        onChange={setPhoto}
        type='file'
        name='photo'
        id='photo'
        accept='image/jpeg'
        disabled={uploading}
      />
    </div>
  )
}

export default withStyles(style)(SinglePhotoInput)
