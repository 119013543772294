import React from 'react'
import withStyles from 'react-jss'
import style from './styles'

const ModalBody = ({ classes, children, closeModal }) => {
  const CloseX = rest => (
    <i onClick={closeModal} className={`icon-decline darkest-green f6 ${classes.closeX}`} {...rest} />
  )

  return (
    <div className={`flex content-center flex-column items-center ${classes.container}`}>
      {children({ modalClasses: classes, CloseX })}
    </div>
  )
}

export default withStyles(style)(ModalBody)
