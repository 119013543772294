import React, { Fragment } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import { StateProvider, useStateValue, types } from './StateProvider'
import EditDateModal from './EditDateModal'
import datesAreDifferent from 'helpers/datesAreDifferent'
import { equals } from 'ramda'
import BusyIndicator from 'components/BusyIndicator'
import { useCurrentRoute } from 'react-navi'

import PredefinedBookVisit from './PredefinedBookVisit'
import DefaultBookVisit from './DefaultBookVisit'

import { PREVIOUS_VISITEES } from 'queries/previousVisitees'
import { PreviousVisitees } from 'types/graphql/PreviousVisitees'
import { useQuery } from '@apollo/client'

const Container = ({ noBorder = false, children }) => (
  <div className={`ph125 pt125 ${noBorder ? '' : 'bb b--gray'}`}>{children}</div>
)

interface BookVisitProps {
  data?: PreviousVisitees,
  loading?: boolean
}

const BookVisit: React.FC<BookVisitProps> = ({ data, loading }) => {
  const [state, dispatch] = useStateValue()

  const updateTime = ([startAt, endAt]) => {
    if (datesAreDifferent(state.startAt, startAt)) dispatch({ type: types.UPDATE_START_AT, startAt })
    if (datesAreDifferent(state.endAt, endAt)) dispatch({ type: types.UPDATE_END_AT, endAt })
  }

  const currentRoute = useCurrentRoute()
  const visitId = currentRoute.data.visitId
  const companionVisits = data?.companion?.visits

  const predefinedVisit = companionVisits && companionVisits.find(v => v.id === visitId)

  if (loading) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  const title = data?.companion?.companionType === 'lite' ? 'Book or report a call' : 'Book or report a visit'

  return (
    <>
      <Fragment>
        <EditDateModal />
         <TopBar title={title} left='menu' visiteeId={state.visiteeId} />
        <ContentWrapper noPadding>
          {predefinedVisit ? (
            <PredefinedBookVisit
              predefinedVisit={predefinedVisit}
              Container={Container}
              updateTime={updateTime}
            />
          ) : (
              <DefaultBookVisit
                data={data}
                loading={loading}
                Container={Container}
                updateTime={updateTime}
              />
            )}
        </ContentWrapper>
      </Fragment>
    </>
  )
}

const MemoizedBookVisit = React.memo(BookVisit, equals)

export default () => {

  const { data, loading } = useQuery<PreviousVisitees>(PREVIOUS_VISITEES, { variables: { scope: 'completed' }, fetchPolicy: 'cache-and-network' })

  return <StateProvider>
    <MemoizedBookVisit {...{ data, loading }} />
  </StateProvider>

}
