import { gql } from '@apollo/client'

export const COMPANION_QUERY = gql`
  query CompanionQuery {
    companion {
      id
      visitsCompleted
      visitMinutesCompleted
      hasEverBooked
      staleSchedule
      hasIncompleteVisitStories
      earnedBadges
      companionType
      phoneNumber {
        id
        number
      }
      person {
        id
        preferredOrFirstName
        photo {
          id
          cdnUrlTemplate
        }
      }
      opportunities {
        id
      }
      organization {
        id
        name
        logoUrl
        importantInformationLink
      }
    }
  }
`
