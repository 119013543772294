import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import Tab from 'components/Tab'
import ContentWrapper from 'components/ContentWrapper'
import Schedule from './Schedule'
import { StateProvider } from './StateProvider'
import AddNewTimeSlot from './Schedule/AddNewTimeSlot'
import EditStartAt from './Schedule/EditStartAt'
import DaysOff from './DaysOff'
import NewDaysOff from './DaysOff/NewDaysOff'
import Advanced from './Advanced'

const tabs = {
  schedule: 'schedule',
  daysOff: 'daysOff',
  advanced: 'advanced'
}

const componentToRender = tab => {
  switch (tab) {
    case tabs.schedule:
      return <Schedule />
    case tabs.daysOff:
      return <DaysOff />
    case tabs.advanced:
      return <Advanced />
  }
}

const Availability = ({ tab = tabs.schedule }) => {
  const [selectedTab, setSelectedTab] = useState(tab)

  const currySetSelectedTab = tab => () => setSelectedTab(tab)
  return (
    <>
      <AddNewTimeSlot />
      <EditStartAt />
      <NewDaysOff />
      <TopBar title='Availability' left='menu' hideDropShadow />
      <div>
        <div className='flex flex-row justify-between content-center bottom-shadow relative'>
          <Tab
            active={selectedTab === tabs.schedule}
            onClick={currySetSelectedTab(tabs.schedule)}
          >
            SCHEDULE
          </Tab>
          <Tab
            active={selectedTab === tabs.daysOff}
            onClick={currySetSelectedTab(tabs.daysOff)}
            data-cy='availabilty--days-off-tab'
          >
            DAYS OFF
          </Tab>
          <Tab
            active={selectedTab === tabs.advanced}
            onClick={currySetSelectedTab(tabs.advanced)}
            data-cy='availabilty--advanced-tab'
          >
            ADVANCED
          </Tab>
        </div>
      </div>
      <ContentWrapper styles='bg-light-gray'>
        {componentToRender(selectedTab)}
      </ContentWrapper>
    </>
  )
}

export default () => (
  <StateProvider>
    <Availability />
  </StateProvider>
)
