import React, { createContext, useContext, useReducer } from 'react'
import dayjs from 'helpers/dayjs'

const initialState = {
  date: null,
  showSelectDateModal: false,
  showEditVisitTimeModal: false,
  editTimeReason: '',
  startAt: dayjs()
    .hour(9)
    .minute(0)
    .toDate(),
  endAt: dayjs()
    .hour(10)
    .minute(0)
    .toDate()
}

export const types = {
  UPDATE_DATE: 'UPDATE_DATE',
  SHOW_SELECT_DATE_MODAL: 'SHOW_SELECT_DATE_MODAL',
  HIDE_SELECT_DATE_MODAL: 'HIDE_SELECT_DATE_MODAL',
  SHOW_EDIT_VISIT_TIME_MODAL: 'SHOW_EDIT_VISIT_TIME_MODAL',
  HIDE_EDIT_VISIT_TIME_MODAL: 'HIDE_EDIT_VISIT_TIME_MODAL',
  UPDATE_START_AT: 'UPDATE_START_AT',
  UPDATE_END_AT: 'UPDATE_END_AT',
  EDIT_TIME_REASON: 'EDIT_TIME_REASON'
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.UPDATE_DATE:
      return { ...state, date: action.date }
    case types.SHOW_SELECT_DATE_MODAL:
      return { ...state, showSelectDateModal: true }
    case types.HIDE_SELECT_DATE_MODAL:
      return { ...state, showSelectDateModal: false }
    case types.SHOW_EDIT_VISIT_TIME_MODAL:
      return { ...state, showEditVisitTimeModal: true }
    case types.HIDE_EDIT_VISIT_TIME_MODAL:
      return { ...state, showEditVisitTimeModal: false }
    case types.UPDATE_START_AT:
      return { ...state, startAt: action.startAt }
    case types.UPDATE_END_AT:
      return { ...state, endAt: action.endAt }
    case types.EDIT_TIME_REASON:
      return { ...state, editTimeReason: action.editTimeReason }
  }
}

export const StateContext = createContext()

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
