import React, { useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { COMPLETED_VISITS } from 'queries/completedVisits'
import { UPDATE_VISIT_STORY_MUTATION } from 'mutations/updateVisitStory'
import { UpdateVisitStory, UpdateVisitStoryVariables } from 'types/graphql/UpdateVisitStory'
import { EditVisitStoryQuery_companion_visit } from 'types/graphql/EditVisitStoryQuery'
import { ReimbursementQuestion } from '../ReimbursementQuestion'
import { currentMileageRepresentation } from '../helpers'

interface PersonalProps {
  visit: EditVisitStoryQuery_companion_visit
  visitStoryCreated: () => void;
  state: any;
  anythingPrivateToShare: any;
  anyPhotosOrSpecialMoments: any;
  howVisiteeIsDoing: any;
  usesReimbursements: Boolean;
}

const Personal: React.FC<PersonalProps> = ({ visit, visitStoryCreated, state, anythingPrivateToShare, anyPhotosOrSpecialMoments, howVisiteeIsDoing, usesReimbursements }) => {
  const [healthCondition, setHealthCondition] = useState<boolean | null>(null)
  const [healthConcern, setHealthConcern] = useState<boolean | null>(null)
  const [hasAnyPhotosMemories, setHasAnyPhotosMemories] = useState<boolean | null>(null)
  const [needReimbursement, setNeedReimbursement] = useState<boolean | null>(null)
  const [anythingPrivate, setAnythingPrivate] = useState<boolean | null>(null)
  const [completeButton, setCompleteButton] = useState<boolean>(false)
  const [visitMutationData, setVisitMutationData] = useState({
    companionAdminNotes: '',
    description: '',
    editTimeReason: '',
    willRevisit: true,
    expensesCents: undefined,
    mileage: undefined
  })

  const stateStartAtEndAt = () => {
    return {
      startAt: setTimeOnDate(state.date, state.startAt),
      endAt: setTimeOnDate(state.date, state.endAt)
    }
  }

  const createVS = createVSMutation => async () => {
    setCompleteButton(true)
    if (healthCondition === null || hasAnyPhotosMemories === null || anythingPrivate === null || (usesReimbursements && needReimbursement === null)) return
    const expensesCents = Math.round((visitMutationData.expensesCents || 0) * 100)
    return createVSMutation({
      variables: {
        input: {
          ...visitMutationData,
          ...stateStartAtEndAt(),
          id: visit.visitStory?.id,
          status: 'completed',
          editTimeReason: state.editTimeReason,
          surveyWellbeing: healthCondition,
          surveyHealthConcern: healthConcern,
          expensesCents: expensesCents === 0 || !needReimbursement ? null : expensesCents,
          paymentDetails: needReimbursement ? 'reimbursement' : null,
          mileage: currentMileageRepresentation(visitMutationData.mileage)
        }
      }
    })
  }

  const inputChangeHandler = (e, value) => {
    setVisitMutationData({
      ...visitMutationData,
      [e.target.name]: value
    })
  }

  return (
    <>
      <Mutation<UpdateVisitStory, UpdateVisitStoryVariables>
        mutation={UPDATE_VISIT_STORY_MUTATION}
        refetchQueries={[
          { query: COMPLETED_VISITS, variables: { scope: 'completed' } },
          { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
          'CompanionQuery',
          'CompanionMilestoneMessages'
        ]}
        onError={raiseErrorIfCantBeIgnored}
        context={{ hasUpload: true }}
        onCompleted={visitStoryCreated}
      >
        {(createVSMutation, { error, loading }) => (
          <>
            <div className='ph3'>
              <h4 className='fw6'>Recap</h4>
              {howVisiteeIsDoing(
                healthCondition,
                setHealthCondition,
                healthConcern,
                setHealthConcern,
                completeButton,
                error
              )}
              {anyPhotosOrSpecialMoments(
                setHasAnyPhotosMemories,
                hasAnyPhotosMemories,
                completeButton,
                visitMutationData,
                inputChangeHandler,
                error
              )}
              { usesReimbursements &&
                <ReimbursementQuestion
                  setNeedReimbursement={setNeedReimbursement}
                  needReimbursement={needReimbursement}
                  completeButton={completeButton}
                  visitMutationData={visitMutationData}
                  inputChangeHandler={inputChangeHandler}
                  error={error}
                  visit={visit}
                />
              }
              {anythingPrivateToShare(
                setAnythingPrivate,
                anythingPrivate,
                completeButton,
                visitMutationData,
                inputChangeHandler
              )}
              <Button onClick={createVS(createVSMutation)} className='mt3' data-cy='story--submit'>
                Complete Visit Recap
              </Button>
            </div>
          </>
        )}
      </Mutation>
    </>
  )
}

export default Personal
