import colors from 'helpers/colors'

export default {
  timeRangeContainer: {
    position: 'relative',
    padding: '0.75rem',
    border: props => `1px solid ${props.errorMessage ? colors.red : colors.darkestGreen2}`,
    borderRadius: '4px'
  },
  timeRange: {
    '& > div': {
      border: 0
    }
  },
  timeRangeRight: {
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    '& i': {
      fontSize: '1.3rem',
      padding: '0 1rem',
      lineHeight: '1rem'
    }
  }
}
