import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
  showCancelVisitModal: false,
  showRescheduleVisitModal: false
}

export const types = {
  SHOW_CANCEL_VISIT_MODAL: 'SHOW_CANCEL_VISIT_MODAL',
  HIDE_CANCEL_VISIT_MODAL: 'HIDE_CANCEL_VISIT_MODAL',
  SHOW_RESCHEDULE_VISIT_MODAL: 'SHOW_RESCHEDULE_VISIT_MODAL',
  HIDE_RESCHEDULE_VISIT_MODAL: 'HIDE_RESCHEDULE_VISIT_MODAL'
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.SHOW_CANCEL_VISIT_MODAL:
      return { ...state, showCancelVisitModal: true }
    case types.HIDE_CANCEL_VISIT_MODAL:
      return { ...state, showCancelVisitModal: false }
    case types.SHOW_RESCHEDULE_VISIT_MODAL:
      return { ...state, showRescheduleVisitModal: true }
    case types.HIDE_RESCHEDULE_VISIT_MODAL:
      return { ...state, showRescheduleVisitModal: false }
    default:
      throw new Error('Not a valid action for <VisitDetails /> reducer')
  }
}

export const StateContext = createContext()

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
