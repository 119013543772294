import React, { createContext, useContext, useReducer } from 'react'
import dayjs from 'helpers/dayjs'

const initialState = {
  date: null,
  visiteeId: null,
  address: null,
  availableVisitTypes: null,
  availableOrganizationCodes: [],
  useVisitCodes: null,
  showSelectDateModal: false,
  showEditLocationModal: false,
  startAt: dayjs()
    .hour(9)
    .minute(0)
    .toDate(),
  endAt: dayjs()
    .hour(10)
    .minute(0)
    .toDate(),
  recurring: false,
  recurrenceCount: 10
}

export const types = {
  UPDATE_DATE: 'UPDATE_DATE',
  UPDATE_VISITEE_ID: 'UPDATE_VISITEE_ID',
  UPDATE_PARAMS: 'UPDATE_PARAMS',
  SHOW_SELECT_DATE_MODAL: 'SHOW_SELECT_DATE_MODAL',
  HIDE_SELECT_DATE_MODAL: 'HIDE_SELECT_DATE_MODAL',
  SHOW_EDIT_LOCATION_MODAL: 'SHOW_EDIT_LOCATION_MODAL',
  HIDE_EDIT_LOCATION_MODAL: 'HIDE_EDIT_LOCATION_MODAL',
  UPDATE_START_AT: 'UPDATE_START_AT',
  UPDATE_END_AT: 'UPDATE_END_AT',
  UPDATE_RECURRENCE_COUNT: 'UPDATE_RECURRENCE_COUNT',
  UPDATE_RECURRING: 'UPDATE_RECURRING',
  UPDATE_ERRORS: 'UPDATE_ERRORS',
  UPDATE_VISIT_TYPE: 'UPDATE_VISIT_TYPE',
  UPDATE_VISIT_CODING_TYPE: 'UPDATE_VISIT_CODING_TYPE'
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.UPDATE_DATE:
      return { ...state, date: action.date }
    case types.UPDATE_VISITEE_ID:
      return { ...state, visiteeId: action.id }
    case types.UPDATE_PARAMS:
      return {
        ...state,
        address: action.address ? { ...state.address, ...action.address } : null,
        availableVisitTypes: action.availableVisitTypes,
        availableOrganizationCodes: action.availableOrganizationCodes,
        useVisitCodes: action.useVisitCodes
      }
    case types.UPDATE_VISIT_TYPE:
      return { ...state, visitType: action.visitType }
    case types.UPDATE_VISIT_CODING_TYPE:
      return { ...state, visitCoding: action.visitCoding }
    case types.SHOW_SELECT_DATE_MODAL:
      return { ...state, showSelectDateModal: true }
    case types.HIDE_SELECT_DATE_MODAL:
      return { ...state, showSelectDateModal: false }
    case types.SHOW_EDIT_LOCATION_MODAL:
      return { ...state, showEditLocationModal: true }
    case types.HIDE_EDIT_LOCATION_MODAL:
      return { ...state, showEditLocationModal: false }
    case types.UPDATE_START_AT:
      return { ...state, startAt: action.startAt }
    case types.UPDATE_END_AT:
      return { ...state, endAt: action.endAt }
    case types.UPDATE_RECURRENCE_COUNT:
      return { ...state, recurrenceCount: action.recurrenceCount }
    case types.UPDATE_RECURRING:
      return { ...state, recurring: action.recurring }
    case types.UPDATE_ERRORS:
      return { ...state, errors: action.errors }
  }
}

export const StateContext = createContext()

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
