import React, { useState } from 'react'
import ContentWrapper from 'components/ContentWrapper'
import withApollo from 'helpers/withApollo'
import Button from 'components/Button'
import InputAndLabel from 'components/InputAndLabel'
import { Verify2fa, Verify2faVariables } from 'types/graphql/Verify2fa'
import { VERIFY_2FA } from 'mutations/verify2fa'
import { ApolloClient, NormalizedCacheObject, useMutation } from '@apollo/client'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { notify } from 'components/ClientNotification'
import { useNavigation } from 'react-navi'
import TopBar from 'components/TopBar'
import HiddenPhoneNumber from '../HiddenPhoneNumber'
import { Request2fa, Request2faVariables } from 'types/graphql/Request2fa'
import { REQUEST_2FA } from 'mutations/request2fa'
import { TWO_FACTOR_AUTH } from 'queries/clientTwoFactorAuth'

enum TwoFactorChannel {
  call = "call",
  sms = "sms",
}

interface VerifyTwoFactorViewProps {
  client: ApolloClient<NormalizedCacheObject>,
  channel: TwoFactorChannel,
}

const VerifyTwoFactorView: React.FC<VerifyTwoFactorViewProps> = ({ client, channel }) => {

  const [verificationCode, setVerificationCode] = useState<string>('')
  const navigation = useNavigation()

  const [verify2fa] = useMutation<Verify2fa, Verify2faVariables>(VERIFY_2FA, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Error sending your verification code',
        type: 'error'
      })
    },
    onCompleted: ({ verify2fa }) => {
      if (verify2fa?.session?.verificationApproved) {
        notify(client, {
          message: 'Successfully verified',
          type: 'success'
        })
        client.writeQuery({ query: TWO_FACTOR_AUTH, data: { twoFactorAuth: null } })
        navigation.navigate('/')
      } else {
        notify(client, {
          message: 'Verification code is incorrect',
          type: 'error'
        })
      }
    }
  })

  const [resendVerificationCode] = useMutation<Request2fa, Request2faVariables>(REQUEST_2FA, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Error while sending you verification code',
        type: 'error'
      })
    },
    onCompleted: () => {
      notify(client, {
        message: 'Verification code resent',
        type: 'success'
      })
    }
  })

  return (
    <>
      <TopBar title='Enter verification code' />
      <ContentWrapper noPadding>
        <div>
          <p>A 6-digit verification code was sent to your phone:</p>
          <HiddenPhoneNumber client={client} />
          <br />

          <InputAndLabel type='text' label='Verification code' updateValue={setVerificationCode} value={verificationCode} data-cy='verification-code' placeholder='xxxxxx' />
          <a onClick={() => resendVerificationCode({ variables: { input: { channel } } })}>I haven’t received it, re-send</a>

          <div className='pt3' />
          <Button onClick={() => { verify2fa({ variables: { input: { channel, code: verificationCode } } }) }} data-cy='verify-auth'>Verify</Button>
        </div>
      </ContentWrapper>
    </>
  )
}

export default withApollo(VerifyTwoFactorView)

