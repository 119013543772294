import { gql } from '@apollo/client'

const companionOpportunitiesFragment = gql`
  fragment companionOpportunities on Companion {
    opportunities {
      id
      hasLinkage
      marketingHeadline
      distance
      opportunityType
      opportunityFriendlyName
      scheduleType
      flexibleScheduleEndAt
      opportunitySuggestions {
        id
      }
      timeSlots {
        id
        startAt
        endAt
      }
      visitee {
        id
        customer {
          id
          person {
            id
            preferredOrFirstName
            firstNameAndLastInitial
          }
        }
        visiteePhoto {
          id
          cdnUrlTemplate
        }
      }
      companionPendingVisit {
        id
        startAt
        endAt
      }
    }
  }
`

export default companionOpportunitiesFragment
