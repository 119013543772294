import React, { useState } from 'react'
import { prop } from 'ramda'
import Button from 'components/Button'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import TextField from 'components/TextField'
import InfoDisplay from 'helpers/infoDisplay'
import withApollo from 'helpers/withApollo'
import { joinWords } from 'components/Profile'
import Select from 'react-select'
import CustomSelect from 'components/Select'
import getError from 'helpers/getError'
import { UPDATE_COMPANION_AND_PROFILE } from 'mutations/updateCompanionAndProfile'
import { UpdateCompanionAndProfile, UpdateCompanionAndProfileVariables } from 'types/graphql/UpdateCompanionAndProfile'
import { useMutation } from '@apollo/client'
import { ProfileQuery_companion, ProfileQuery_companion_profile, ProfileQuery_availableSkills, ProfileQuery_availableLanguages } from 'types/graphql/ProfileQuery'

interface NotRegularProfileProps {
  companion: ProfileQuery_companion,
  profile: ProfileQuery_companion_profile,
  availableSkills: ProfileQuery_availableSkills[],
  availableLanguages: ProfileQuery_availableLanguages[],
  setShowEditForm: any,
  showEditForm: any,
  classes: any,
  flashMessage: any
}

const NotRegularProfile: React.FC<NotRegularProfileProps> = ({
  companion,
  profile,
  setShowEditForm,
  showEditForm,
  availableLanguages,
  flashMessage
}) => {
  const [profileInfoMutationData, setProfileInfoMutationData] = useState({
    about: profile.about || undefined,
    interestedReason: profile.interestedReason
  })
  const allLanguages = availableLanguages.map(language => ({ label: language.name, value: language.id }))
  const companionLanguages = companion.person.languages.map(language => ({ label: language.name, value: language.id }))
  const [selectableLanguages, setSelectableLanguages] = useState(companionLanguages)
  const [selectablePrimaryLanguage, setSelectablePrimaryLanguage] = useState(companion.person.primaryLanguage)

  const inputChangeHandler = (e, value) => {
    setProfileInfoMutationData({
      ...profileInfoMutationData,
      [e.target.name]: value
    })
  }

  const updatePrimaryLanguage = id => {
    setSelectablePrimaryLanguage(availableLanguages?.find(l => l.id === id) || null)
  }

  const [updateCompanionProfileMutation, mutationState] = useMutation<UpdateCompanionAndProfile, UpdateCompanionAndProfileVariables>(UPDATE_COMPANION_AND_PROFILE, {
    refetchQueries: ['ProfileQuery'],
    onError: raiseErrorIfCantBeIgnored,
    onCompleted: ({ }) => {
      setShowEditForm(!showEditForm)
      flashMessage('Your profile has been updated!')
    }
  })

  return <>
    <div className='pa125'>
      {!showEditForm ? (
        <>
          <InfoDisplay title='PRIMARY LANGUAGE' content={companion.person.primaryLanguage?.name} />
          <InfoDisplay title='OTHER LANGUAGES' content={joinWords(companion.person.languages)} />
          <InfoDisplay title='ABOUT ME' content={profile.about || '-'} />
          <InfoDisplay title="WHY I'M GIVING MY TIME" content={profile.interestedReason} />
        </>
      ) : (
        <>
          Primary Language
          <br />
          <br />
          <CustomSelect
            items={[
              { value: null },
              ...availableLanguages.map(v => ({
                value: v.id,
                text: v.name,
                selected: v.id === selectablePrimaryLanguage?.id
              }))
            ]}
            handler={updatePrimaryLanguage}
          />
          <br />
          Other Languages
          <br />
          <br />
          <Select
            value={selectableLanguages}
            options={allLanguages}
            onChange={languages => setSelectableLanguages(languages)}
            isMulti
          />
          <TextField
            multiline
            rows={5}
            autoAdjustHeight
            className='mb2 mt3'
            label={'About me'}
            value={profileInfoMutationData.about}
            onChange={inputChangeHandler}
            name='about'
            errorMessage={getError('about', mutationState.error)}
          />
          <TextField
            multiline
            rows={5}
            autoAdjustHeight
            className='mb2 mt3'
            label={"Why I'm giving my time"}
            value={profileInfoMutationData.interestedReason || undefined}
            onChange={inputChangeHandler}
            name='interestedReason'
          />
          <div className='pt3' />
          <Button
            onClick={() => updateCompanionProfileMutation({
              variables: {
                input: {
                  profile: profileInfoMutationData,
                  companion: {},
                  person: {
                    languageIds: selectableLanguages ? selectableLanguages.map(prop('value')) : [],
                    primaryLanguageId: selectablePrimaryLanguage?.id
                  }
                }
              }
            })}
          >Save</Button>
          <div className='tc mt3 radius purple' data-cy='no-thanks'>
            <a onClick={() => setShowEditForm(!showEditForm)} className='no-underline pointer'>
              Cancel
            </a>
          </div>
        </>
      )}
    </div>
  </>
}

export default withApollo(NotRegularProfile)
