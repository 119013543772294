import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import { pipe, prop, path, cond, T, always, defaultTo } from 'ramda'
import withApollo from 'helpers/withApollo'
import Button from 'components/Button'
import withStyles from 'react-jss'
import style, { FurtherActionButtonStyles } from './styles'
import Details from './Details'
import { VISIT_CHECKIN_QUERY, uploadPhoto, endVisitMutation, deletePhoto } from './queries'
import OffScreenModal from 'components/OffScreenModal'
import MultiPhotoInput from 'components/MultiPhotoInput'
import { Query } from '@apollo/client/react/components'
import PhotoCarousel from 'components/PhotoCarousel'
import { useNavigation } from 'react-navi'
import paths, { editVisitStoryPath } from 'routes/paths'
import Link from 'components/Link'
import dayjs from 'helpers/dayjs'
import pluralize from 'helpers/pluralize'

const getPhotos = pipe(path(['companion', 'visitStory', 'photos']), defaultTo([]))

const TeleLink = ({ children, number }) => (
  <a href={`tel:${number}`} className='no-underline'>
    {children}
  </a>
)

const FurtherActionButton = withStyles(FurtherActionButtonStyles)(({ classes, children, icon, ...rest }) => (
  <Button className={`${classes.button} mb3`} {...rest}>
    <i className={`mr2 purple icon-${icon}`} />
    {children}
  </Button>
))

export const contactNumber = cond([
  [prop('visiteeConversationNumber'), prop('visiteeConversationNumber')],
  [path(['visitee', 'phoneNumber']), path(['visitee', 'phoneNumber'])],
  [T, always(null)]
])

export const VisitCheckIn = ({ visit, client, endVisit }) => {
  const navigation = useNavigation()
  const [showPhotoCarousel, setShowPhotoCarousel] = useState(false)
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0)
  const hoursDuration = parseInt(visit.duration / 60)
  const minutesDuration = parseInt(visit.duration % 60)

  const redirectToEditVisitStory = () => navigation.navigate(`${editVisitStoryPath(visit.id)}`)

  const endVisitAndRedirect = async () => {
    await endVisitMutation(visit, client)
    redirectToEditVisitStory()
  }

  const closePhotoCarousel = () => setShowPhotoCarousel(false)

  const endAt = dayjs(visit.visitStory.checkInAt).add(visit.duration, 'minutes')
  const now = dayjs()
  const differenceInMilliseconds = endAt.diff(now)
  setTimeout(() => {
    endVisitAndRedirect()
  }, differenceInMilliseconds)

  const duration = (hoursDuration, minutesDuration) => {
    const minutes = `${minutesDuration} ${pluralize(minutesDuration, 'minute')}`
    const hours = `${hoursDuration} ${pluralize(hoursDuration, 'hour')}`
    if (hoursDuration === 0) return minutes
    else if (minutesDuration === 0) return hours
    else return `${hours} and ${minutes}`
  }

  return (
    <>
      <OffScreenModal show={showPhotoCarousel} title='Photos' close={closePhotoCarousel}>
        <Query query={VISIT_CHECKIN_QUERY} variables={{ visitStoryId: visit.visitStory.id }}>
          {({ data }) => {
            return (
              <PhotoCarousel
                photos={getPhotos(data)}
                visible={showPhotoCarousel}
                selectedIndex={selectedPhotoIndex}
                deletePhotoMutation={deletePhoto(client)}
                close={closePhotoCarousel}
                setSelectedIndex={setSelectedPhotoIndex}
              />
            )
          }}
        </Query>
      </OffScreenModal>
      <TopBar title={`Visit With ${visit.visitee.customer.person.preferredOrFirstName}`} left='menu' />
      <ContentWrapper noPadding>
        <div className='pa125 bb b--gray tc f4'>
          <i>You started this visit at {dayjs(visit.visitStory.checkInAt).format('h:mmA')}.</i>
          <br />
          <i>It is scheduled for {duration(hoursDuration, minutesDuration)}.</i>
        </div>
        <div className='pa125 bb b--gray'>
          <Details visit={visit} />
        </div>
        <div className='pa125'>
          <Query query={VISIT_CHECKIN_QUERY} variables={{ visitStoryId: visit.visitStory.id }}>
            {({ data }) => (
              <MultiPhotoInput
                uploadPhoto={uploadPhoto(visit, client)}
                photos={getPhotos(data)}
                setShowPhotoCarousel={setShowPhotoCarousel}
                setSelectedPhotoIndex={setSelectedPhotoIndex}
              />
            )}
          </Query>
          <FurtherActionButton icon='call'>
            <TeleLink number='(650) 300-3606'>Call Mon Ami Support</TeleLink>
          </FurtherActionButton>
          {contactNumber(visit) && (
            <FurtherActionButton icon='call'>
              <TeleLink number={contactNumber(visit)}>Call {visit.visitee.customer.person.preferredOrFirstName}'s Family</TeleLink>
            </FurtherActionButton>
          )}
          <FurtherActionButton icon='details'>
            <Link route={`/visits/${visit.id}`} returnTo={paths.visitCheckIn} inline>
              View Details
            </Link>
          </FurtherActionButton>
        </div>
      </ContentWrapper>
    </>
  )
}

export default pipe(withApollo, withStyles(style))(VisitCheckIn)
