import React from 'react'
import Button from 'components/Button'
import { useStateValue, types } from 'components/Availability/StateProvider'
import WeekPicker from './WeekPicker'

const Schedule = () => {
  const [, dispatch] = useStateValue()

  const openNewTimeSlotModal = () => dispatch({ type: types.SHOW_NEW_TIME_SLOT_MODAL })
  return (
    <>
      <Button data-cy='create-availability' onClick={openNewTimeSlotModal}>
        <i className='icon-add' /> Add New
      </Button>
      <WeekPicker />
    </>
  )
}

export default Schedule
