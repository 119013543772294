import { loadTheme } from 'office-ui-fabric-react'
import colors from 'helpers/colors'
import fonts from 'helpers/fonts'

loadTheme({
  fonts: {
    medium: {
      fontFamily: fonts.fontFamily,
      fontSize: fonts.fontSize.regular
    },
    small: {
      fontFamily: fonts.fontFamily,
      fontSize: fonts.fontSize.small
    }
  },
  semanticColors: {
    accentButtonBackground: '#0078d4',
    accentButtonText: '#ffffff',
    actionLink: colors.darkestGreen,
    actionLinkHovered: '#212121',
    blockingBackground: 'rgba(234, 67, 0, .2)',
    bodyBackground: '#ffffff',
    bodyDivider: '#eaeaea',
    bodyFrameBackground: '#ffffff',
    bodyFrameDivider: '#eaeaea',
    bodyStandoutBackground: '#f8f8f8',
    bodySubtext: '#666666',
    bodyText: colors.darkestGreen,
    bodyTextChecked: '#000000',
    buttonBackground: '#f4f4f4',
    buttonBackgroundChecked: '#c8c8c8',
    buttonBackgroundCheckedHovered: '#eaeaea',
    buttonBackgroundDisabled: '#f4f4f4',
    buttonBackgroundHovered: '#eaeaea',
    buttonBackgroundPressed: '#eaeaea',
    buttonBorder: 'transparent',
    buttonBorderDisabled: 'transparent',
    buttonText: colors.darkestGreen,
    buttonTextChecked: '#212121',
    buttonTextCheckedHovered: '#000000',
    buttonTextDisabled: '#a6a6a6',
    buttonTextHovered: '#212121',
    buttonTextPressed: '#212121',
    defaultStateBackground: '#f8f8f8',
    disabledBackground: '#f4f4f4',
    disabledBodySubtext: '#c8c8c8',
    disabledBodyText: '#a6a6a6',
    disabledSubtext: '#d0d0d0',
    disabledText: '#a6a6a6',
    errorBackground: 'rgba(232, 17, 35, .2)',
    errorText: colors.red,
    focusBorder: '#666666',
    inputBackground: '#ffffff',
    inputBackgroundChecked: colors.purple,
    inputBackgroundCheckedHovered: colors.purple,
    inputBorder: colors.darkestGreen2,
    inputBorderHovered: colors.darkestGreen2,
    inputFocusBorderAlt: colors.yellow,
    inputForegroundChecked: '#ffffff',
    inputPlaceholderText: '#666666',
    inputText: colors.darkestGreen,
    inputTextHovered: '#212121',
    link: '#0078d4',
    linkHovered: '#004578',
    listBackground: '#ffffff',
    listHeaderBackgroundHovered: '#f4f4f4',
    listHeaderBackgroundPressed: '#eaeaea',
    listItemBackgroundChecked: '#eaeaea',
    listItemBackgroundCheckedHovered: '#dadada',
    listItemBackgroundHovered: '#f4f4f4',
    listText: colors.darkestGreen,
    listTextColor: colors.darkestGreen,
    menuBackground: '#ffffff',
    menuDivider: '#c8c8c8',
    menuHeader: '#0078d4',
    menuIcon: '#0078d4',
    menuItemBackgroundChecked: '#eaeaea',
    menuItemBackgroundHovered: '#f4f4f4',
    menuItemBackgroundPressed: '#eaeaea',
    menuItemText: colors.darkestGreen,
    menuItemTextHovered: '#212121',
    primaryButtonBackground: '#0078d4',
    primaryButtonBackgroundDisabled: '#f4f4f4',
    primaryButtonBackgroundHovered: colors.yellow,
    primaryButtonBackgroundPressed: '#005a9e',
    primaryButtonBorder: 'transparent',
    primaryButtonText: '#ffffff',
    primaryButtonTextDisabled: '#d0d0d0',
    primaryButtonTextHovered: '#ffffff',
    primaryButtonTextPressed: '#ffffff',
    smallInputBorder: colors.gray,
    successBackground: 'rgba(186, 216, 10, .2)',
    variantBorder: '#eaeaea',
    variantBorderHovered: '#a6a6a6',
    warningBackground: 'rgba(255, 185, 0, .2)',
    warningHighlight: '#ffb900',
    warningText: colors.darkestGreen
  },
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: colors.yellow,
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: colors.darkestGreen,
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff'
  }
})
