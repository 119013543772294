import { menuWidth } from 'helpers/globals'

export default {
  container: {
    transform: ({ showMenu }) =>
      showMenu ? 'translateX(0px)' : `translateX(-${menuWidth})`,
    width: menuWidth,
    transition: '.2s',
    overflowX: 'hidden'
  },
  links: {
    lineHeight: '50px'
  },
  linksContainer: {
    padding: '30px 0 30px 30px'
  },
  image: {
    width: '181px',
    right: '.5rem'
  },
  '@media screen and (max-width: 374px)': {
    image: {
      width: '145px'
    }
  }
}
