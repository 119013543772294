import React from 'react'
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup'
import colors from 'helpers/colors'

const ChoiceGroupWithStylesOptions = props => (
  <ChoiceGroup
    {...props}
    styles={{ root: [{ fontSize: '1rem' }] }}
    options={props.options.map(o => ({
      ...o,
      value: o.key,
      styles: ({ checked }) => ({
        root: [{ selectors: { '.ms-ChoiceFieldLabel': { fontSize: '1rem' } } }],
        field: [
          checked
            ? { selectors: { ':before': { borderWidth: 6 } } }
            : {
              selectors: {
                ':before': { borderWidth: 1, borderColor: colors.gray }
              }
            },
          { selectors: { ':after': { borderColor: 'transparent' } } }
        ]
      })
    }))}
  />
)

export default ChoiceGroupWithStylesOptions
