import { gql } from '@apollo/client'
import companionOpportunitiesFragment from 'fragments/companionOpportunitiesFragment'

export const OPPORTUNITIES_QUERY = gql`
  query OpportunitiesQuery {
    companion {
      id
      staleSchedule
      availableTimes {
        id
        startAt
        duration
        futureOccurrenceCount
      }
      ...companionOpportunities
    }
  }
  ${companionOpportunitiesFragment}
`

export const AVAILABLE_TIMES_QUERY = gql`
  query AvailableTimes($scope: String) {
    companion {
      id
      staleSchedule
      availableTimes {
        id
        startAt
        duration
        futureOccurrenceCount
      }
      unavailableDays {
        id
        date
      }
      visits(scope: $scope) {
        id
        startAt
        duration
        status
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
          }

        }
      }
    }
  }
`

export const CREATE_AVAILABLE_TIME_MUTATION = gql`
  mutation CreateAvailableTime($input: CreateAvailableTimeInput!) {
    createAvailableTime(input: $input) {
      availableTimes {
        id
      }
    }
  }
`

export const DELETE_AVAILABLE_TIME_MUTATION = gql`
  mutation DeleteAvailableTime($input: DeleteAvailableTimeInput!) {
    deleteAvailableTime(input: $input) {
      availableTimes {
        id
      }
    }
  }
`

export const DAYS_OFF_QUERY = gql`
  query DaysOff {
    companion {
      id
      unavailableDays {
        id
        date
      }
    }
  }
`

export const CREATE_DAY_OFF = gql`
  mutation CreateDayOff($input: CreateUnavailableDayInput!) {
    createUnavailableDay(input: $input) {
      unavailableDay {
        id
      }
    }
  }
`

export const DELETE_DAYS_OFF_MUTATION = gql`
  mutation DeleteUnavailableDay($input: DeleteUnavailableDayInput!) {
    deleteUnavailableDay(input: $input) {
      id
    }
  }
`
