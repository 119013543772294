import React from 'react'
import TimeRangeInput, { timeRangeErrors } from 'components/TimeRangeInput'
import VisiteeAndLocationEdit from '../VisiteeAndLocationEdit'
import { useStateValue } from '../StateProvider'
import RecurringAndSubmit from '../RecurringAndSubmit'
import DateEdit from '../DateEdit'
import { equals } from 'ramda'
import { PreviousVisitees } from 'types/graphql/PreviousVisitees'

interface DefaultBookVisitProps {
  data?: PreviousVisitees | undefined | null,
  loading?: boolean,
  Container: any,
  updateTime: any
}

const DefaultBookVisit: React.FC<DefaultBookVisitProps> = ({ data, loading, Container, updateTime }) => {
  const [state, dispatch] = useStateValue()
  const visitees = data?.companion?.visitees || []
  const organization = data?.companion?.organization?.name || 'Mon Ami'

  return (
    <>
      {visitees.length > 0 ? (
        <>
          <Container>
            <VisiteeAndLocationEdit data={data} loading={loading} />
          </Container>
          <Container>
            <DateEdit />
          </Container>
          <Container>
            <div className='mb2 fw6'>Set Time</div>
            <TimeRangeInput
              timeChangeHandler={updateTime}
              errorMessage={timeRangeErrors(state, 'scheduledDuration')}
              time={[state.startAt, state.endAt]}
              setDefaultEndTime
            />
          </Container>
          <Container noBorder>
            <RecurringAndSubmit />
          </Container>
        </>
      ) : (
        <p className='pa3 b'>
          You have not yet been matched to a client. Please check with {organization}.
        </p>
      )}
    </>
  )
}

export default React.memo(DefaultBookVisit, equals)
