import { gql } from '@apollo/client'

export const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      address {
        addressLine1
        addressLine2
        city
        state
        zip
      }
    }
  }
`
