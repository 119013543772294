import { gql } from '@apollo/client'

export const UPDATE_CURRENT_COMPANION = gql`
  mutation UpdateCurrentCompanion($input: UpdateCurrentCompanionInput!) {
    updateCurrentCompanion(input: $input) {
      companion {
        id
        person {
          id
          firstNameAndLastInitial
          preferredName
          preferredOrFirstName
        }
      }
    }
  }
`
