import React from 'react'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import changeOrganization from 'assets/icons/change-organization.svg'
import withApollo from 'helpers/withApollo'
import { ProfileSnippetQuery } from 'types/graphql/ProfileSnippetQuery'
import { PROFILE_SNIPPET_QUERY } from 'queries/profileSnippetQuery'
import { useQuery, NormalizedCacheObject, ApolloClient } from '@apollo/client'

interface SwitchOrganizationProps {
  client: ApolloClient<NormalizedCacheObject>
}

const SwitchOrganization: React.FC<SwitchOrganizationProps> = ({ client }) => {

  const { data, loading } = useQuery<ProfileSnippetQuery>(PROFILE_SNIPPET_QUERY)
  const companions = data?.companions

  if (loading || !companions || companions.length == 1) return null

  console.log("companions.length", companions.length)

  return (
    <Link route={routePaths.personSelection} data-cy='navigate-to-person-selection' className='flex'>
      <img src={changeOrganization} className='mr2'/>
      <div className='pointer purple underline'>Switch Organization</div>
    </Link>
  )
}

export default withApollo(SwitchOrganization)
