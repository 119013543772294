import React, { useState } from 'react'
import withStyles from 'react-jss'
import style from './styles'

const Tab = ({ children, classes, onClick, active, isRefreshable = false, refreshQuery, width = 'w-33', ...rest }) => {
  const [isLoading, setIsLoading] = useState(false)
  const refreshData = async () => {
    setIsLoading(true)
    if (refreshQuery) await refreshQuery()
    setIsLoading(false)
  }

  return (
    <div
      onClick={active ? refreshData : onClick}
      className={`pointer bb bw1 tc ${width} h-100 f7 ${classes.container} ${
        active
          ? isRefreshable
            ? isLoading
              ? 'fw6 b--border-green'
              : 'fw6 b--border-green'
            : 'fw6 b--border-green'
          : 'fw4 b--white'
      }`}
      {...rest}
    >
      {children}{' '}
      {active && isRefreshable && (
        <i className={`icon-refresh ml1 purple  ${classes.icon} ${isLoading ? classes.rotating : ''}`} />
      )}
    </div>
  )
}

export default withStyles(style)(Tab)
