import React from 'react'
import withApollo from 'helpers/withApollo'
import { ApolloClient, NormalizedCacheObject, useQuery } from '@apollo/client'
import { CompanionQuery } from 'types/graphql/CompanionQuery'
import { COMPANION_QUERY } from 'queries/companionQuery'

interface HiddenPhoneNumberProps {
  client: ApolloClient<NormalizedCacheObject>,
}

const HiddenPhoneNumber: React.FC<HiddenPhoneNumberProps> = () => {
  const { data, loading } = useQuery<CompanionQuery>(COMPANION_QUERY)
  if (loading || !data || !data.companion) return null

  const phoneNumber = data?.companion.phoneNumber.number
  const last4PhoneNumberDigits = phoneNumber.slice(phoneNumber?.length - 4)

  return <p className="strong">XXX-XXX-{last4PhoneNumberDigits}</p>
}

export default withApollo(HiddenPhoneNumber)

