export default {
  shortcutContainer: {
    lineHeight: '38px'
  },
  dateShortcut: {
    borderRadius: '4px',
    paddingLeft: '10px',
    paddingRight: '10px'
  }
}
