import { gql } from '@apollo/client'

export const UPDATE_COMPANION_AND_PROFILE = gql`
  mutation UpdateCompanionAndProfile($input: UpdateCompanionAndProfileInput!) {
    updateCompanionAndProfile(input: $input) {
      companion {
        id
        profile {
          id
        }
        skills {
          id
        }
        languages {
          id
        }
      }
    }
  }
`
