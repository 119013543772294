import React from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'

import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface ModalProps {
  visit: VisitDetailsQuery_companion_visit
  closeModal: any
  rescheduleVisitMutationData: any
}

const Modal: React.FC<ModalProps> = ({ closeModal, visit, rescheduleVisitMutationData }) => {
  const { startAt, endAt, cascadeChangesToFutureVisits } = rescheduleVisitMutationData

  return (
    <ModalBody closeModal={closeModal}>
      {({ CloseX }) => (
        <>
          <CloseX />
          <h3 className=''>Visit Rescheduled</h3>
          <p className='tc ph3 mt0'>
            You have rescheduled your{cascadeChangesToFutureVisits && ' recurring'} {visit.visitTypeFriendlyName} with{' '}
            <strong>{visit.visitee.customer.person.preferredOrFirstName}</strong>
          </p>
          <p className='tc ph3 mt0'>
            <strong>Old Time:</strong>{' '}
            {cascadeChangesToFutureVisits
              ? `${dayjs(visit.startAt).format('dddd')}s`
              : dayjs(visit.startAt).format('ddd, MMM DD')}{' '}
            at {dayjs(visit.startAt).format('h:mmA')} - {dayjs(visit.endAt).format('h:mmA')}
            <br />
            <strong>New Time:</strong>{' '}
            {cascadeChangesToFutureVisits ? `${dayjs(startAt).format('dddd')}s` : dayjs(startAt).format('ddd, MMM DD')}{' '}
            at {dayjs(startAt).format('h:mmA')} - {dayjs(endAt).format('h:mmA')}
          </p>
          <p className='tc ph3 mt0 i f6'>
            {cascadeChangesToFutureVisits
              ? `Upcoming ${visit.visitTypeFriendlyName}s have been affected by this change`
              : `Upcoming ${visit.visitTypeFriendlyName}s have not been affected by this change`}
          </p>
        </>
      )}
    </ModalBody>
  )
}

export default Modal
