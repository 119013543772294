import React from 'react'
import ContentWrapper from 'components/ContentWrapper'
import withApollo from 'helpers/withApollo'
import { ApolloClient, NormalizedCacheObject, useMutation, useQuery } from '@apollo/client'
import { CompanionsQuery } from 'types/graphql/CompanionsQuery'
import { raiseErrorIfCantBeIgnored } from 'helpers/setupApollo'
import { notify } from 'components/ClientNotification'
import { COMPANIONS_QUERY } from 'queries/companionsQuery'
import { UPDATE_CURRENT_COMPANION } from 'mutations/updateCurrentCompanion'
import { UpdateCurrentCompanion, UpdateCurrentCompanionVariables } from 'types/graphql/UpdateCurrentCompanion'
import { useNavigation } from 'react-navi'
import TopBar from 'components/TopBar'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import { PROFILE_SNIPPET_QUERY } from 'queries/profileSnippetQuery'
import { COMPANION_QUERY } from 'queries/companionQuery'
import { CURRENT_USER_QUERY } from 'queries/currentUser'
import withStyles from 'react-jss'
import style from './styles'
import logout from 'helpers/logout'
import Button from 'components/Button'

interface PersonProps {
  client: ApolloClient<NormalizedCacheObject>,
  classes: any,
}

const PersonSelection: React.FC<PersonProps> = ({ client, classes }) => {
  const navigation = useNavigation()
  const { data, loading } = useQuery<CompanionsQuery>(COMPANIONS_QUERY, { fetchPolicy: 'network-only' })
  const [updateCurrentCompanion] = useMutation<UpdateCurrentCompanion, UpdateCurrentCompanionVariables>(UPDATE_CURRENT_COMPANION, {
    onError: (error) => {
      raiseErrorIfCantBeIgnored(error)
      notify(client, {
        message: 'Error switching person, please try again',
        type: 'error'
      })
    },
    onCompleted: ({ updateCurrentCompanion }: UpdateCurrentCompanion) => {
      const companion = updateCurrentCompanion?.companion
      if (companion) {
        localStorage.setItem('currentPersonId', companion.person.id)
        navigation.navigate('/')
      }
    },
    refetchQueries: [
      { query: CURRENT_USER_QUERY },
      { query: COMPANION_QUERY },
      { query: PROFILE_SNIPPET_QUERY }
    ]
  })

  const selectUser = (isCompanionSelected: boolean, id: string) => {
    if (isCompanionSelected) {
      navigation.navigate('/')
    } else {
      updateCurrentCompanion({ variables: { input: { id } } })
    }
  }

  updateBusyIndicators(client, { bar: loading })

  if (loading || !data || !data.companions) return null

  const currentPersonId = localStorage.getItem('currentPersonId')

  return <>
    <TopBar title='Switch Organization' />
    <ContentWrapper styles='bg-light-gray' noPadding>
      {data.companions.map((companion, index) => {
        const organization = companion.person.organization
        const isCompanionSelected = companion.person.id === currentPersonId

        return <div
          key={index}
          className={`bg-white bottom-shadow radius mv3 ba-ns ${classes.cardContainer}`}
          onClick={() => { selectUser(isCompanionSelected, companion.id) }}
          data-cy={`organization-${index}`}
        >
          {isCompanionSelected &&
            <div className={`${classes.signedInCompanionContainer}`}>
              Currently logged in
            </div>
          }
          {organization.logoUrl ?
            <>
              <div className={`bottom-shadow pv1-ns ${classes.logoContainer}`}>
                <img src={organization.logoUrl} className={`${classes.logoImage}`} />
              </div>
              <div>
                <p className='ma0 pa2'>{organization.name}</p>
              </div>
            </>
            :
            <>
              <div className={classes.noLogoContainer} />
              <h2 className='ma0'>{organization.name}</h2>
            </>
          }
        </div>
      })}
      <Button
        className='mb3 mt3 btnMuted bg-red'
        onClick={logout.bind(null, client)}
        data-cy='log-out'
      >
        Log Out
      </Button>
    </ContentWrapper>
  </>
}

export default withApollo(withStyles(style)(PersonSelection))
