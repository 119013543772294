import React from 'react'
import URI from 'uri-template-lite'
import URLSafeBase64 from 'urlsafe-base64'

const variantOptions = (props, options) => {
  return JSON.stringify({
    operation: props.operation || 'resize_to_fill',
    width: options.width,
    height: options.height,
    crop: props.crop || 'attention'
  })
}

const variantKey = options => {
  return URLSafeBase64.encode(Buffer.from(options))
}

const expandedUri = (props, options) => {
  if (props.photo) {
    const opts = variantOptions(props, options)
    return URI.expand(props.photo.cdnUrlTemplate, { variant_key: variantKey(opts) })
  }
}

export default class LazyPhoto extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: null,
      height: null,
      imageUri: null
    }
    this.element = React.createRef()
  }

  componentDidUpdate() {
    this.fetchPhoto()
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchPhoto()
    }, 300)
  }

  fetchPhoto() {
    if (this.element.current) {
      const { width, height } = this.element.current.getBoundingClientRect()
      const imageUri = expandedUri(this.props, { width, height })
      if (imageUri !== this.state.imageUri) {
        this.setState(() => ({
          width: width,
          height: height,
          imageUri: imageUri
        }))
      }
    }
  }

  render() {
    const uri = this.state.imageUri || this.props.placeholderImage
    return (
      <img
        ref={this.element}
        onClick={this.props.onClick}
        className={this.props.className}
        src={uri}
        alt='Preview Image'
        data-cy={this.props.dataCy}
      />
    )
  }
}
