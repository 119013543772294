import { gql } from '@apollo/client'

export const REQUEST_2FA = gql`
  mutation Request2fa($input: Request2faInput!) {
    request2fa(input: $input) {
      session {
        id
        verificationApproved
        verificationCheckSid
      }
    }
  }
`
