import fonts from 'helpers/fonts'
import colors from 'helpers/colors'
import extensions from 'helpers/tachyons/extensions'
import baseStyles from 'helpers/tachyons'

export default {
  '@keyframes slideDown': {
    from: {
      opacity: 0,
      transform: 'translate3d(0px, -20px, 0px)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0px, 0px, 0px)'
    }
  },
  errorMessage: {
    letterSpacing: '1px',
    fontStyle: 'italic',
    animationName: 'slideDown',
    animationDuration: '0.367s',
    animationTimingFunction: 'cubic-bezier(0.1, 0.9, 0.2, 1)',
    animationFillMode: 'both',
    WebkitFontSmoothing: 'antialiased',
    fontSize: fonts.fontSize.small,
    fontWeight: 400,
    color: colors.red,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 5,
    display: 'flex',
    alignItems: 'center'
  },
  bordered: {
    padding: '0.5rem 0.8rem',
    borderColor: colors.red,
    ...extensions['@global']['.mb75'],
    ...baseStyles['@global']['@media screen and (min-width: 30em)']['.ba-ns']
  }
}
