export default {
  container: {
    fontSize: '15px'
  },
  flex: {
    display: 'flex',
    '@media screen and (max-width: 374px)': {
      display: 'block'
    }
  },
  width: {
    '@media screen and (max-width: 374px)': {
      width: '100%'
    }
  },
  photoContainer: {
    width: '100%',
    borderTopLeftRadius: '4px',
    'vertical-align': 'bottom'
  },
  button: {
    minHeight: '52px',
    padding: '1rem'
  },
  badge: {
    bottom: 0,
    zIndex: 1
  }
}
