import { gql } from '@apollo/client'

export const UPDATE_MESSAGE_MUTATION = gql`
  mutation UpdateMilestoneMessage($input: UpdateMilestoneMessageInput!) {
    updateMilestoneMessage(input: $input) {
      milestoneMessage {
        id
      }
    }
  }
`
