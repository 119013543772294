import React, { useState, useEffect } from 'react'
import Flickity from 'react-flickity-component'
import './flickity.css'
import styles from './style'
import withStyle from 'react-jss'
import LazyPhoto from 'components/LazyPhoto'
import { notify } from 'components/ClientNotification'

const PhotoCarousel = ({
  classes,
  photos,
  visible,
  selectedIndex,
  deletePhotoMutation,
  client,
  close,
  setSelectedIndex
}) => {
  const [flickity, setFlickity] = useState<any>(null)

  const flashMessage = message => {
    notify(client, {
      message: message,
      type: 'success'
    })
  }

  useEffect(() => {
    visible && flickity.resize()
  }, [visible])

  useEffect(() => {
    flickity && flickity.select(selectedIndex, false, true)
  }, [selectedIndex])

  const deletePhoto = async () => {
    const photo = photos[flickity.selectedIndex]
    await deletePhotoMutation(photo.id)
    flickity.resize()
    flashMessage('Your photo has been deleted!')
    if (photos.length === 1) return close()

    const newIndex = photos[selectedIndex + 1] ? selectedIndex + 1 : selectedIndex - 1
    flickity.select(newIndex)
    setSelectedIndex(newIndex)
  }

  return (
    <>
      <Flickity
        flickityRef={ref => !flickity && setFlickity(ref)}
        options={{ adaptiveHeight: true }}
        className={classes.container}
      >
        {photos.map((photo, i) => (
          <LazyPhoto
            key={i}
            className={`radius ${classes.image}`}
            photo={photo}
            operation={'resize_and_pad'}
            crop={'none'}
          />
        ))}
      </Flickity>
      <div className={`f4 tc ${classes.deleteButton}`} data-cy='story--delete-photo' onClick={deletePhoto}>
        <i className='mr2 icon-delete purple' />
        Delete Photo
      </div>
    </>
  )
}

export default withStyle(styles)(PhotoCarousel)
