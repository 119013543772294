import { gql } from '@apollo/client'
import DELETE_PHOTO_MUTATION from 'mutations/deletePhoto'
import { initVisitStoryVariables } from 'components/VisitCheckIn/queries'
import { UPDATE_VISIT_STORY_MUTATION } from 'mutations/updateVisitStory'
import { CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION } from 'mutations/createVisitStoryBeforeEditing'

export const EDIT_VISIT_STORY_QUERY = gql`
  query EditVisitStoryQuery($visitId: ID!) {
    metaData {
      visitStorySurveyWellbeings {
        key
        value
      }
      visitStorySurveyHealthConcerns {
        key
        value
      }
      visitStoryPaymentDetails {
        key
      }
      visitStoryShiftExperiences {
        key
        value
      }
    }
    companion {
      id
      visit(id: $visitId) {
        id
        visitType
        visitTypeFriendlyName
        scheduleType
        startAt
        endAt
        duration
        firstVisitForLinkage
        visitRecurrenceGroupPresent
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
            designatedContact {
              id
              designated
              person {
                id
                preferredOrFirstName
                firstNameAndLastInitial
              }
            }
            organization {
              id
              name
              usesReimbursements
            }
          }
        }
        visitStory {
          id
          checkInAt
          photos {
            id
            cdnUrlTemplate
          }
          receiptPhotos {
            id
            cdnUrlTemplate
          }
        }
      }
    }
  }
`

export const VISIT_STORY_PHOTOS_QUERY = gql`
  query VisitStoryPhotosQuery($visitId: ID!) {
    companion {
      id
      visit(id: $visitId) {
        id
        visitStory {
          id
          photos {
            id
            cdnUrlTemplate
          }
          receiptPhotos {
            id
            cdnUrlTemplate
          }
        }
      }
    }
  }
`

export const createVisitStoryFromVisit = (client, visit) =>
  client.mutate({
    mutation: CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION,
    variables: {
      input: initVisitStoryVariables(visit)
    }
  })

export const uploadPhoto = (visit, client, isReceipt) => blob => {
  const input = () => {
    if (isReceipt)
      return {
        id: visit.visitStory.id,
        receiptPhotos: [blob]
      }
    else
      return {
        id: visit.visitStory.id,
        photos: [blob]
      }
  }
  client.mutate({
    mutation: UPDATE_VISIT_STORY_MUTATION,
    refetchQueries: ['VisitStoryPhotosQuery'],
    context: { hasUpload: true },
    variables: {
      input: input()
    }
  })
}

export const deletePhoto = client => photoId =>
  client.mutate({
    mutation: DELETE_PHOTO_MUTATION,
    refetchQueries: ['VisitStoryPhotosQuery'],
    awaitRefetchQueries: true,
    variables: {
      input: {
        photoId
      }
    }
  })
