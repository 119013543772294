import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import DELETE_PHOTO_MUTATION from 'mutations/deletePhoto'
import { UPDATE_VISIT_STORY_MUTATION } from 'mutations/updateVisitStory'

export const initVisitStoryVariables = visit => ({
  status: 'in_progress',
  visitId: visit.id,
  version: 3
})

const CREATE_VISIT_STORY_FROM_CHECK_IN_MUTATION = gql`
  mutation CreateVisitStoryFromCheckIn($input: CreateVisitStoryInput!) {
    createVisitStory(input: $input) {
      visitStory {
        id
        checkInAt
      }
    }
  }
`

export const NEXT_VISIT_DETAILS_QUERY = gql`
  query NextVisitDetailsQuery {
    companion {
      id
      nextVisit {
        id
        visitType
        startAt
        endAt
        duration
        visiteeConversationNumber
        address {
          id
          addressLine1
          addressLine2
          city
          state
          zip
          latitude
          longitude
        }
        visitee {
          id
          visiteePhoto {
            id
            cdnUrlTemplate
          }
          phoneNumber
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
          }
        }
        visitStory {
          id
          checkInAt
          photos {
            id
            cdnUrlTemplate
          }
        }
      }
    }
  }
`

export const VISIT_CHECKIN_QUERY = gql`
  query VisitCheckinQuery($visitStoryId: ID!) {
    companion {
      id
      visitStory(visitStoryId: $visitStoryId) {
        id
        photos {
          id
          cdnUrlTemplate
        }
      }
    }
  }
`

export const createVisitStoryFromCheckInVariables = (visit, variables) => ({
  mutation: CREATE_VISIT_STORY_FROM_CHECK_IN_MUTATION,
  refetchQueries: ['BannerVisitQuery'],
  variables: {
    input: {
      checkInAt: dayjs().toJSON(),
      ...initVisitStoryVariables(visit),
      ...variables
    }
  }
})

export const endVisitMutation = async (visit, client) =>
  client.mutate({
    mutation: UPDATE_VISIT_STORY_MUTATION,
    refetchQueries: ['BannerVisitQuery', 'UpcomingVisits', 'CompletedVisits'],
    awaitRefetchQueries: true,
    variables: {
      input: {
        id: visit.visitStory.id
      }
    }
  })

export const uploadPhoto = (visit, client) => async blob =>
  client.mutate({
    mutation: UPDATE_VISIT_STORY_MUTATION,
    refetchQueries: ['VisitStoryQuery'],
    context: { hasUpload: true },
    variables: {
      input: {
        id: visit.visitStory.id,
        photos: [blob]
      }
    }
  })

export const deletePhoto = client => photoId =>
  client.mutate({
    mutation: DELETE_PHOTO_MUTATION,
    refetchQueries: ['VisitStoryQuery'],
    awaitRefetchQueries: true,
    variables: {
      input: {
        photoId
      }
    }
  })

export const createVisitStoryFromCheckIn = async (visit, client, [checkInLatitude, checkInLongitude]) => {
  return client.mutate(
    createVisitStoryFromCheckInVariables(visit, {
      checkInLatitude,
      checkInLongitude
    })
  )
}

export const updateCheckInDifferentLocationDescriptionVariables = (visit, checkInDifferentLocationDescription) => ({
  mutation: UPDATE_VISIT_STORY_MUTATION,
  refetchQueries: ['BannerVisitQuery'],
  variables: {
    input: {
      id: visit.visitStory.id,
      checkInDifferentLocationDescription
    }
  }
})
