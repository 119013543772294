import { gql } from '@apollo/client'

const DELETE_PHOTO_MUTATION = gql`
  mutation DeletePhoto($input: DeletePhotoInput!) {
    deletePhoto(input: $input) {
      void {
        nothing
      }
    }
  }
`
export default DELETE_PHOTO_MUTATION
