import React from 'react'
import { useNavigation } from 'react-navi'
import withApollo from 'helpers/withApollo'
import clientShowMenu from 'queries/clientShowMenu'
import clientReturnTo from 'queries/clientReturnTo'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import classNames from 'classnames'

const Link = ({
  client,
  route,
  className,
  text,
  children,
  returnTo,
  persistLoadingBar = false,
  inline = false,
  ...rest
}) => {
  const navigation = useNavigation()

  const navigateAndCloseMenu = async () => {
    if (!persistLoadingBar) updateBusyIndicators(client, { bar: false })
    client.writeQuery({ query: clientShowMenu, data: { showMenu: false } })
    await navigation.navigate(route)
    if (returnTo) client.writeQuery({ query: clientReturnTo, data: { returnTo: returnTo } })
  }

  const classes = classNames('pointer', className, { di: inline })

  return <div className={classes} onClick={navigateAndCloseMenu} {...rest}>
    {text || children}
  </div>
}

export default withApollo(Link)
