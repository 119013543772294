import colors from 'helpers/colors'

export default {
  container: {
    width: 'calc(100vw - 2rem)',
    maxWidth: '500px',
    paddingTop: '2rem',
    '& h3': {
      color: colors.darkestGreen,
      marginTop: '0',
      fontWeight: '600'
    }
  },
  closeX: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    padding: '6px'
  },
  buttonContainer: {
    marginTop: '2rem',
    width: '100%',
    '& > div': {
      textAlign: 'center',
      flex: 1,
      padding: '1rem',
      borderTop: `1px solid ${colors.gray}`,
      cursor: 'pointer'
    },
    '& div + div': {
      borderLeft: `1px solid ${colors.gray}`
    }
  }
}
