import React from 'react'
import { gql, useQuery } from '@apollo/client';
import MessageBox from './MessageBox'
import withApollo from 'helpers/withApollo'
import {
  CompanionMilestoneMessages,
  CompanionMilestoneMessagesVariables
} from 'types/graphql/CompanionMilestoneMessages'

export const MILESTONE_MESSAGES_QUERY = gql`
  query CompanionMilestoneMessages($scope: String) {
    companion {
      id
      milestoneMessages(scope: $scope) {
        id
        title
        subtext
        messageType
      }
    }
  }
`

const MessageSlot = ({ client, scope }) => {
  const {
    data,
    loading
  } = useQuery<CompanionMilestoneMessages, CompanionMilestoneMessagesVariables>(
    MILESTONE_MESSAGES_QUERY, {
    variables: { scope: scope }
  })

  const currentMessage = data?.companion?.milestoneMessages[0]

  if (loading || !currentMessage) return null

  return <MessageBox
    message={currentMessage}
    refreshQuery={() =>
      client.query({
        query: MILESTONE_MESSAGES_QUERY,
        variables: { scope: scope },
        fetchPolicy: 'network-only'
      })
    }
  />
}

const MilestoneMessages = ({ client }) => {
  const messageSlots = ['celebration', 'badges']
  return <>{messageSlots.map((scope) => <MessageSlot client={client} scope={scope} key={scope} />)}</>
}

export default withApollo(MilestoneMessages)
