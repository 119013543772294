import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import TentativeVisits, { tabRefreshTentativeVisits } from './TentativeVisits'
import UpcomingVisits, { tabRefreshUpcomingVisits } from './UpcomingVisits'
import CompletedVisits, { tabRefreshCompletedVisits } from './CompletedVisits'
import withApollo from 'helpers/withApollo'
import Tab from 'components/Tab'
import { Query } from '@apollo/client/react/components'
import { COMPANION_TYPE_QUERY } from 'queries/companionType'
import { CompanionTypeQuery } from 'types/graphql/CompanionTypeQuery'

enum VisitTab {
  Tentative = 'tentative',
  Upcoming = 'upcoming',
  Completed = 'completed'
}

const componentToRender = (tab: VisitTab) => {
  switch (tab) {
    case VisitTab.Tentative:
      return <TentativeVisits />
    case VisitTab.Upcoming:
      return <UpcomingVisits />
    case VisitTab.Completed:
      return <CompletedVisits />
  }
}

const Visits = ({ client, tab = VisitTab.Upcoming }) => {
  const [selectedTab, setSelectedTab] = useState<VisitTab>(tab)

  const currySetSelectedTab = tab => () => setSelectedTab(tab)

  const queryOptions = { fetchPolicy: 'network-only' }

  return (
    <>
      <Query<CompanionTypeQuery> query={COMPANION_TYPE_QUERY}>
        {({ data, loading }) => {
          if (loading) {
            return <TopBar title='Loading' left='menu' hideDropShadow />
          } else {
            return (
              <TopBar title={data?.companion?.companionType === 'lite' ? 'Manage Calls' : 'Manage Visits'} left='menu' hideDropShadow />
            )
          }
        }}
      </Query>
      <div>
        <div className='flex flex-row justify-between content-center bottom-shadow relative'>
          <Tab
            active={selectedTab === VisitTab.Tentative}
            onClick={currySetSelectedTab(VisitTab.Tentative)}
            isRefreshable
            refreshQuery={() => client.query({ ...tabRefreshTentativeVisits, ...queryOptions })}
            data-cy='tentative--tab'
          >
            TENTATIVE
          </Tab>
          <Tab
            active={selectedTab === VisitTab.Upcoming}
            onClick={currySetSelectedTab(VisitTab.Upcoming)}
            isRefreshable
            refreshQuery={() => client.query({ ...tabRefreshUpcomingVisits, ...queryOptions })}
            data-cy='upcoming--tab'
          >
            UPCOMING
          </Tab>
          <Tab
            active={selectedTab === VisitTab.Completed}
            onClick={currySetSelectedTab(VisitTab.Completed)}
            isRefreshable
            refreshQuery={() => client.query({ ...tabRefreshCompletedVisits, ...queryOptions })}
            data-cy='story--tab'
          >
            COMPLETED
          </Tab>
        </div>
      </div>
      <ContentWrapper styles='bg-light-gray'>{componentToRender(selectedTab)}</ContentWrapper>
    </>
  )
}

export default withApollo(Visits)
