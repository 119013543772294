import React, { ReactElement, Suspense } from 'react'
import { useQuery } from '@apollo/client';
import { Router, View } from 'react-navi'
import Layout from 'components/Layout'
import routes from 'routes'
import { CURRENT_USER_QUERY } from 'queries/currentUser'
import setErrorTrackerIdentity from 'helpers/setErrorTrackerIdentity'
import ErrorBoundary from 'components/ErrorBoundary'
import { CurrentUser } from 'types/graphql/CurrentUser'

const Entry = ({ client, history }): ReactElement | null => {
  const { data, loading } = useQuery<CurrentUser>(CURRENT_USER_QUERY)

  if (loading) return null

  const currentUser = data?.currentUser

  if (currentUser) setErrorTrackerIdentity(currentUser)

  return <Router routes={routes} history={history} context={{ client, currentUser }}>
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  </Router>
}

export default Entry
