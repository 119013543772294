import React, { ReactElement } from 'react'
import NoVisits from 'components/Visits/NoVisits'
import { useQuery } from '@apollo/client';
import { isEmpty, isNil } from 'ramda'
import Button from 'components/Button'
import UpcomingVisitCard from './UpcomingVisitCard'
import BusyIndicator from 'components/BusyIndicator'
import Link from 'components/Link'
import routePaths from 'routes/paths'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { UpcomingVisits, UpcomingVisitsVariables, UpcomingVisits_companion, UpcomingVisits_companion_organization, UpcomingVisits_companion_visits } from 'types/graphql/UpcomingVisits'

const variables = { scope: 'upcoming' }

export const tabRefreshUpcomingVisits = { query: UPCOMING_VISITS, variables }

const VisitList = ({companion, visits} : {companion: UpcomingVisits_companion, visits: UpcomingVisits_companion_visits[] | null}) => {

  if(isNil(visits) || isEmpty(visits)) return <NoVisits companionName={companion.person.preferredOrFirstName || ''} visitType='upcoming' />

  return <>
    {visits.map(visit => (
        <UpcomingVisitCard
          visit={visit}
          key={visit.id}
          hideTimeAway={companion?.nextVisit?.id === visit.id}
        />
      ))}
    </>
}

const AwaitingOrgAdminMessage = ({ organization }: { organization: UpcomingVisits_companion_organization | null }) => {
  const organizationName = organization?.name || 'Mon Ami'

  return <div className='f3 tc mt3 mb4'>You have not yet been matched to a client. Please check with {organizationName}.</div>
}

export default (): ReactElement => {
  const { loading, data } = useQuery<UpcomingVisits, UpcomingVisitsVariables>(UPCOMING_VISITS, { variables: variables });

  if (loading || !data || !data.companion) return <BusyIndicator isBusy delayMs={0} color='#ffc20d' />

  const companion = data.companion
  const { visits, visitees } = companion

  if (isEmpty(visitees) && (isNil(visits) || isEmpty(visits))) return <AwaitingOrgAdminMessage organization={companion.organization}/>

  return <>
    {!isEmpty(visitees) &&
      <Link route={routePaths.bookVisit} returnTo={`${routePaths.visits}?tab=upcoming`}>
        <Button className='mb3'>
          {companion.companionType === 'lite' ? 'Book or report another call' : 'Book or report another visit'}
        </Button>
      </Link>
    }
    <VisitList companion={companion} visits={visits}/>
  </>

}
