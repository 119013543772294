import React from 'react'
import withStyles from 'react-jss'
import styles from './styles'
import Button from 'components/Button'
import oops from 'assets/images/oops.png'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import logout from 'helpers/logout'
import { ApolloProvider } from '@apollo/client'
import { client } from 'helpers/setupApollo'

const ErrorView = ({ classes }) => {
  const navigation = useNavigation()
  const redirectToDashboard = () => navigation.navigate(routePaths.root)

  const handleLogout = async () => {
    await logout(client, false)
    redirectToDashboard()
  }

  const isLoggedIn = () => localStorage.getItem('authenticationToken')

  return (
    <div className='ph4 h-100 flex flex-column justify-center items-center'>
      <div>
        <img src={oops} className={`${classes.image} mb3`} />
      </div>
      <div className={`${classes.oops} mb3 dark-gray`}>Oops!</div>
      <div className='f3 tc mb4'>
        Something went wrong. <br />
        We've been notified of the issue and are working on it!
      </div>
      <ApolloProvider client={client}>
        <Button onClick={redirectToDashboard}>Back to Dashboard</Button>
        {isLoggedIn() && <Button className='bg-red' onClick={() => handleLogout()} data-cy='log-out'>Log Out</Button> }
      </ApolloProvider>
    </div>
  )
}

export default withStyles(styles)(ErrorView)
