import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import withApollo from 'helpers/withApollo'
import { useStateValue, types, DISPLAY_WEEKS } from 'components/Availability/StateProvider'
import withStyles from 'react-jss'
import style from './styles'
import DayDisplay from './DayDisplay'
import { path, isEmpty, equals } from 'ramda'
import dayjs from 'helpers/dayjs'
import Swipe from 'react-easy-swipe'
import { AVAILABLE_TIMES_QUERY } from 'components/Availability/queries'
import { AvailableTimes } from 'types/graphql/AvailableTimes'

interface WeekPickerProps {
  classes?: any
}

const WeekPicker: React.FC<WeekPickerProps> = React.memo(({ classes }) => {
  const variables = { scope: 'upcoming' }
  const { data } = useQuery<AvailableTimes>(AVAILABLE_TIMES_QUERY, { variables: variables })

  const [state, dispatch] = useStateValue()
  const [viewableWeeks, setViewableWeeks] = useState(0)
  const [swipeLocked, setSwipeLocked] = useState(false)

  const showPills =
    (!isEmpty(data) && !isEmpty(path(['companion', 'availableTimes'], data))) ||
    !isEmpty(path(['companion', 'visits'], data))

  const updateChosenWeekAndNewTimeSlotDate = (index, mondayOfChosenWeek) => () => {
    const date = mondayOfChosenWeek.isBetween(dayjs().weekday(1), dayjs().weekday(7), 'day', '[]')
      ? new Date()
      : mondayOfChosenWeek

    dispatch({ type: types.UPDATE_CHOSEN_WEEK, chosenWeek: index })
    dispatch({ type: types.UPDATE_DATE, date })
  }

  const displayedWeeks = state.weeks
    .slice(viewableWeeks * 3, viewableWeeks * 3 + 3)
    .map((week, i) => [...week, viewableWeeks * 3 + i])

  const changeViewableWeeks = weeksIndex => () => {
    if (weeksIndex < 0 || weeksIndex > (DISPLAY_WEEKS - 1) / 3) return
    setViewableWeeks(weeksIndex)
  }

  const weekPillText = (monday, sunday, i) => {
    if (i === 0) return 'This WK'
    else if (i === 1) return 'Next Wk'
    else return `${monday.format('M/D')} - ${sunday.format('M/D')}`
  }

  const onSwipeMove = (position, event) => {
    if (swipeLocked) return
    if (position.x > 20) {
      setSwipeLocked(true)
      changeViewableWeeks(viewableWeeks - 1)()
    } else if (position.x < -20) {
      setSwipeLocked(true)
      changeViewableWeeks(viewableWeeks + 1)()
    }
  }

  const onSwipeEnd = () => setSwipeLocked(false)
  return (
    <div>
      {showPills && (
        <>
          <div className='hr mv3' />
          <div className='flex flex-row flex-nowrap mb4'>
            <div className={`self-start ${viewableWeeks === 0 ? 'disabled' : ''} ${classes.changeWeek}`}>
              <i
                onClick={changeViewableWeeks(viewableWeeks - 1)}
                className='pointer icon-arrow-left purple'
                data-cy='week-picker-previous-set'
              />
            </div>
            <div className='flex-grow-1' data-cy='week-picker-swipe-container'>
              <Swipe onSwipeMove={onSwipeMove} onSwipeEnd={onSwipeEnd}>
                <div className='flex flex-row flex-nowrap justify-between ph2'>
                  {displayedWeeks.map(([monday, sunday, chosenWeekIndex]) => {
                    return (
                      <div
                        className={`pointer f6 tc ${
                          chosenWeekIndex === state.chosenWeek ? classes.selectedWeekPill : classes.weekPill
                        }`}
                        onClick={updateChosenWeekAndNewTimeSlotDate(chosenWeekIndex, monday)}
                        key={chosenWeekIndex}
                        data-cy='week-picker-next'
                      >
                        {weekPillText(monday, sunday, chosenWeekIndex)}
                      </div>
                    )
                  })}
                </div>
              </Swipe>
            </div>
            <div
              className={`self-end ${viewableWeeks === DISPLAY_WEEKS / 3 - 1 ? 'disabled' : ''} ${classes.changeWeek}`}
            >
              <i
                onClick={changeViewableWeeks(viewableWeeks + 1)}
                className='pointer icon-arrow-right purple'
                data-cy='week-picker-next-set'
              />
            </div>
          </div>
        </>
      )}
      {data && data.companion && <DayDisplay data={data} />}
    </div>
  )
}, equals)

export default withStyles(style)(withApollo(WeekPicker))
