import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import ErrorMessage from '../ErrorMessage'
import { isBrowser } from 'helpers/platform'
import NativeTimePicker from './NativeTimePicker'
import getError from 'helpers/getError'
import dayjs from 'helpers/dayjs'

export const timeRangeErrors = (state, key = 'duration') => {
  const errorExists = getError(key, state.errors)
  if (errorExists) return getError(key, state.errors)
  else return null
}

interface TimeRangeInputProps {
  classes: any;
  errorMessage: any;
  time: any;
  timeChangeHandler: any;
  setDefaultEndTime?: any;
}

const TimeRangeInput: React.FC<TimeRangeInputProps> = ({
  classes,
  errorMessage,
  time,
  timeChangeHandler,
  setDefaultEndTime
}) => {
  return (
    <div className='mb3'>
      <div className={classes.timeRangeContainer}>
        {isBrowser ? (
          <TimeRangePicker
            className={classes.timeRange}
            onChange={([startAt, endAt]) => {
              timeChangeHandler([
                typeof startAt === 'string'
                  ? dayjs()
                      .hour(parseInt(startAt.split(':')[0]))
                      .minute(parseInt(startAt.split(':')[1]))
                      .toDate()
                  : startAt,
                typeof endAt === 'string'
                  ? dayjs()
                      .hour(parseInt(endAt.split(':')[0]))
                      .minute(parseInt(endAt.split(':')[1]))
                      .toDate()
                  : setDefaultEndTime && typeof startAt === 'string'
                  ? dayjs()
                      .hour(parseInt(startAt.split(':')[0]))
                      .minute(parseInt(startAt.split(':')[1]))
                      .add(1, 'hour')
                      .toDate()
                  : endAt
              ])
            }}
            value={time}
            disableClock
            clearIcon={null}
          />
        ) : (
          <NativeTimePicker
            time={time}
            timeChangeHandler={timeChangeHandler}
            setNativeDefaultEndTime={setDefaultEndTime}
          />
        )}
        <div className={classes.timeRangeRight}>
          <div className='flex h-100 items-center'>
            <i className='db icon-time gray' />
          </div>
        </div>
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  )
}

export default withStyles(style)(TimeRangeInput)
