import LogRocket from 'logrocket'

const setErrorTrackerIdentity = user => {
  if (user) {
    LogRocket.identify(`${user.id}`, {
      email: user.email,
      platform: PLATFORM
    })
  }
}

export default setErrorTrackerIdentity
