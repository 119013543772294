import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import seniorPlaceHolderImage from 'helpers/seniorPlaceHolderImage'
import MultiClamp from 'react-multi-clamp'
import LazyPhoto from 'components/LazyPhoto'
import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

interface HeaderProps {
  visit: VisitDetailsQuery_companion_visit,
  classes: any,
}

const Header: React.FC<HeaderProps> = ({ visit, classes }) => (
  <div className='mb3 flex flex-nowrap flex-row'>
    <div>
      <LazyPhoto
        photo={visit.visitee.visiteePhoto}
        placeholderImage={seniorPlaceHolderImage[visit.visitType]}
        className={`relative br2 ${classes.photoContainer}`}
      />
    </div>
    <div className='ph3 pt2 pb3 flex-grow-1 recoleta-regular'>
      <div className='fw6 f4' style={{ wordBreak: 'break-all' }}>
        <MultiClamp ellipsis='...' clamp={1}>
          {visit.visitee.customer.person.firstNameAndLastInitial}
        </MultiClamp>
      </div>
      <div>
        {visit.address && (
          <span className={classes.location}>
            {visit.address.usa
              ? `${visit.address.city}, ${visit.address.state}`
              : `${visit.address.city}, ${visit.address.country}`}
          </span>
        )}
        {visit.distance && !(visit.visitType === 'telepresence') && (
          <span className={`ml1 dark-gray f6`}> {visit.distance}mi away</span>
        )}
        {visit.visiteeConversationNumber && visit.visitType === 'personal' && (
          <div>
            <a href={`sms:${visit.visiteeConversationNumber}`}>
              <div className='br2 pa2 bg-egg-shell darkest-green dib mt1'>
                <i className='icon-message purple' /> Send Message
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  </div>
)

export default withStyles(style)(Header)
