import React from 'react'
import { isEmpty, complement } from 'ramda'

const isPresent = complement(isEmpty)

const InfoDisplay = ({ title, content }) =>
  isPresent(content) ? (
    <div className='mb3'>
      <div className='mb1 f6 fw6 label-green'>{title}</div>
      <div data-testid={title}>{content}</div>
    </div>
  ) : null

export default InfoDisplay
