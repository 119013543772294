import { gql } from '@apollo/client'

export const CREATE_VISIT = gql`
  mutation CreateVisit($input: CreateVisitInput!) {
    createVisit(input: $input) {
      visit {
        id
        duration
        startAt
        visitType
        visitTypeFriendlyName
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
            designatedContact {
              id
              person {
                id
                preferredOrFirstName
              }
            }
          }
        }
      }
    }
  }
`
