import React from 'react'
import Detail from '../Detail'
import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'
import { isEmpty } from 'ramda'


interface AboutRequestProps {
  visit: VisitDetailsQuery_companion_visit
}

const AboutRequest: React.FC<AboutRequestProps> = ({ visit }) => {
  const {
    visitee: { moreAboutMyInterests, interests }
  } = visit
  const firstName = visit.visitee.customer.person.preferredOrFirstName

  if(isEmpty(moreAboutMyInterests) && isEmpty(interests)) {
    return null
  }

  return (
    <div>
      <div className='pb3 ph3'>
        <div className='bt bb b--border-green pa3 fw6 f6'>
          <i className='icon-about-request label-green mr2' />
          About the Request
        </div>
        {interests && interests.length > 0 && <Detail heading='Activities'>{interests.map(i => i.name).join(', ')}</Detail>}
        {moreAboutMyInterests && (
          <Detail heading={`More about ${firstName}'s interests`}>{moreAboutMyInterests}</Detail>
        )}
      </div>
    </div>
  )
}

export default AboutRequest
