import telepresence from 'assets/images/telepresence-placeholder.png'
import service from 'assets/images/service-placeholder.png'
import shift from 'assets/images/shift-placeholder.png'
import visit from 'assets/images/visit-placeholder.png'

const seniorPlaceHolderImage = {
  telepresence: telepresence,
  shift: shift,
  service: service,
  personal: visit
}

export default seniorPlaceHolderImage
