import React from 'react'
import ContentWrapper from 'components/ContentWrapper'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import Button from 'components/Button'
import { ONBOARDING } from 'queries/clientOnboarding'
import { NormalizedCacheObject } from '@apollo/client'
import { ApolloClient } from '@apollo/client/core';
import withApollo from 'helpers/withApollo'

interface CodeEntryFailedProps {
  client: ApolloClient<NormalizedCacheObject>
}

const CodeEntryFailed: React.FC<CodeEntryFailedProps> = ({client}) => {
  const navigation = useNavigation()
  const { onboarding } = client.readQuery({ query: ONBOARDING })
  const redirectRoute = onboarding ? routePaths.requestSetupCode : routePaths.resetPassword

  return (
    <ContentWrapper styles='justify-center flex'>
      <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
        <div className='h-100 flex flex-column align-center justify-center'>
          <h2 className='f3'>Too many attempts</h2>
          <div className='f4 mb3'>We were unable to recognise the code. Please start over.</div>

          <Button className='mt3' onClick={() => { navigation.navigate(redirectRoute)}} data-cy='start-over'>
            Start Over
          </Button>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default withApollo(CodeEntryFailed)
