import React from 'react'
import Detail from '../Detail'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { VisitDetailsQuery_companion_visit } from 'types/graphql/VisitDetailsQuery'

const getLanguages = ({ visitee: { primaryLanguage, secondaryLanguage } }: VisitDetailsQuery_companion_visit) =>
  [primaryLanguage, secondaryLanguage].filter(Boolean).map(capitalizeFirstLetter).join(', ')

const getMemory = (metaData, memory) => metaData.find(item => item.key === memory).value

const getMobility = (metaData, mobility) => metaData.find(item => item.key === mobility).value

interface AboutVisiteeProps {
  visit: VisitDetailsQuery_companion_visit;
  metaData: any;
}

const AboutVisitee: React.FC<AboutVisiteeProps> = ({ visit, metaData }) => {
  const {
    visitee: { pastCareer, memory, mobility, moreAboutMe, limitations, culturalFactors },
    visitType
  } = visit
  const firstName = visit.visitee.customer.person.preferredOrFirstName
  const { visiteeMemories, visiteeMobilities } = metaData
  const personal = visitType === 'personal'
  return (
    <div>
      <div className='pb3 ph3'>
        <div className='bt bb b--border-green pa3 fw6 f6'>
          <i className='icon-about label-green mr2' />
          {`About ${firstName}`}
        </div>
        {pastCareer && <Detail heading='Previous Career'>{pastCareer}</Detail>}
        <Detail heading='Languages'>{getLanguages(visit) || '-'}</Detail>
        {personal && memory && <Detail heading='Memory'>{getMemory(visiteeMemories, memory)}</Detail>}
        {personal && mobility && <Detail heading='Mobility'>{getMobility(visiteeMobilities, mobility)}</Detail>}
        {!personal && culturalFactors && <Detail heading='Cultural Factors'>{culturalFactors}</Detail>}
        {!personal && limitations && <Detail heading='Limitations'>{limitations}</Detail>}
        {moreAboutMe && <Detail heading={`More about ${firstName}`}>{moreAboutMe}</Detail>}
      </div>
    </div>
  )
}

export default AboutVisitee
