import React from 'react'
import { pipe, slice, keys } from 'ramda'
import { milestoneImages } from 'components/Dashboard/milestoneImages'

const Badge = ({ earnedBadges, type }) => (
  <div className='h4 w-33 mb4 flex justify-center items-end'>
    {earnedBadges.includes(type) ? (
      <img src={milestoneImages[type].active} />
    ) : (
      <img src={milestoneImages[type].inactive} />
    )}
  </div>
)

const MilestoneBadges = ({ companion }) => {
  const badgesNumber = companion.earnedBadges.length > 4 ? 12 : 6
  const badges = pipe(keys, slice(0, badgesNumber))

  return (
    <>
      <div className='f3 mt4 fw6 pb3 recoleta-regular'>Milestones</div>
      <div className={`flex flex-wrap justify-between tc mb3`}>
        {badges(milestoneImages).map((type, i) => (
          <Badge earnedBadges={companion.earnedBadges} type={type} key={i} />
        ))}
      </div>
    </>
  )
}

export default MilestoneBadges
