import getError from 'helpers/getError'
import { pipe, split, last, flatten } from 'ramda'

export const getTimeRangeErorr = (error: any): string => {
  const startAtErrors = getError('start_at', error)
  const endAtErrors = getError('end_at', error)
  const generalError = getError('visitStory', error)

  if (generalError && generalError.includes('duration')) return generalError

  return flatten(
    [
      startAtErrors && `Start time invalid: ${startAtErrors}`,
      endAtErrors && `End time invalid: ${endAtErrors}`
    ].filter(v => v)).join(', ')
}

export const getMileageError = (error: any): string | undefined => {
  const mileageErrors = getError('mileage', error)
  if (mileageErrors) {
    const lastError = pipe(split(', '), last)(mileageErrors)
    if (lastError)
      return String(lastError)
    else return undefined
  } else {
    return undefined
  }
}
