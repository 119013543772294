import React from 'react'
import withStyles from 'react-jss'
import style from './styles'

const Detail = ({ heading, children, classes }) => {
  return (
    <div className='mt3'>
      <div className={`mt3 fw6 ttu label-green ${classes.heading}`}>{heading}</div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  )
}

export default withStyles(style)(Detail)
