import React from 'react'
import MultiClamp from 'react-multi-clamp'
import seniorPlaceHolderImage from 'helpers/seniorPlaceHolderImage'
import Badge from 'components/Badge'
import colors from 'helpers/colors'
import dayjs from 'dayjs'
import withStyle from 'react-jss'
import style from './styles'
import LazyPhoto from 'components/LazyPhoto'

const Details = ({ visit, classes }) => (
  <div className={'relative'}>
    <div className='flex flex-nowrap flex-row'>
      <div>
        <LazyPhoto
          photo={visit.visitee.visiteePhoto}
          placeholderImage={seniorPlaceHolderImage[visit.visitType]}
          className={`relative ${classes.photoContainer}`}
        />
        {!visit.visitRecurrenceGroupPresent && (
          <Badge
            direction='left'
            text='Recurring'
            color={colors.lighterGreen}
            backgroundColor={colors.yellow}
            containerClass={'bottom-0'}
          />
        )}
      </div>
      <div className='pl3 flex-grow-1'>
        <div className='mb2 mt1'>
          <span className='fw6'>
            <MultiClamp ellipsis='...' clamp={1}>
              {visit.visitee.customer.person.firstNameAndLastInitial}
            </MultiClamp>
          </span>
        </div>
        <div className='mb1'>
          <i className='icon-calendar purple mr2' />
          {dayjs(visit.startAt).format('ddd, MMM DD')}
        </div>
        <div className='mb1'>
          <i className='icon-time purple mr2' />
          {dayjs(visit.startAt).format('h:mmA')} - {dayjs(visit.endAt).format('h:mmA')}
        </div>
        <div className='flex'>
          <i className='icon-location purple mr2' />
          <div>
            <div>{visit.address.addressLine1}</div>
            <div>{visit.address.addressLine2}</div>
            <div>
              {visit.address.city}, {visit.address.state} {visit.address.zip}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default withStyle(style)(Details)
