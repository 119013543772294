interface Addressable {
  addressLine1: string | null
  addressLine2?: string | null
  city: string | null
  state: string | null
  zip: string | null
}

export default ({ addressLine1, addressLine2, city, state, zip }: Addressable) => {
  return `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}${city ? `, ${city}` : ''}${state ? `, ${state}` : ''} ${zip}`
}
