import React from 'react'
import ModalBody from 'components/ModalBody'
import dayjs from 'helpers/dayjs'

import { CreateVisit_createVisit_visit } from 'types/graphql/CreateVisit'

const dateDisplay = ({ startAt, duration }) => {
  const date = dayjs(startAt)
  return (
    <b>
      {date.format('ddd, MMM D [at] h:mm A')} -{date.add(duration, 'minute').format('h:mm A')}
    </b>
  )
}

const textDisplay = (visit, recurring) => {
  if (recurring) {
    const date = dayjs(visit.startAt)
    return (
      <>
        <b>
          {date.format('dddd[s] [at] h:mm A')} -{date.add(visit.duration, 'minute').format('h:mm A')}
        </b>
        . The first one will be <b>{date.format('ddd, MMM D')}</b>.
      </>
    )
  } else {
    return dateDisplay(visit)
  }
}

interface ModalProps {
  visit: CreateVisit_createVisit_visit
  closeModal: any
  recurring: boolean | undefined
  recurrenceCount: number | undefined
}

const Modal: React.FC<ModalProps> = ({ closeModal, visit, recurring, recurrenceCount }) => {
  return (
    <ModalBody closeModal={closeModal}>
      {({ CloseX }) => (
        <>
          <CloseX data-cy='visits-new--close-modal' />
          <h3 className=''>{recurring ? `${visit.visitTypeFriendlyName}s` : visit.visitTypeFriendlyName} Booked</h3>
          <div className='tc ph3 mt0'>
            You have{' '}
            {recurring ? (
              <>
                booked <b>{recurrenceCount} recurring {visit.visitTypeFriendlyName}s</b>
              </>
            ) : (
                `booked a ${visit.visitTypeFriendlyName}`
              )}{' '}
            with {visit.visitee.customer.person.firstNameAndLastInitial} for {textDisplay(visit, recurring)}
          </div>
        </>
      )}
    </ModalBody>
  )
}

export default Modal
