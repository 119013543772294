import { MINIMUM_REQUIRED_APP_VERSION_QUERY } from 'queries/minimumRequiredAppVersion'
import compareVersions from 'compare-versions'

const hasOldAppVersionInstalled = async client => {
  const {
    data: { minimumRequiredAppVersion }
  } = await client.query({ query: MINIMUM_REQUIRED_APP_VERSION_QUERY, fetchPolicy: 'network-only' })
  const installedVersion = await window.cordova.getAppVersion()

  return isOldVersion(minimumRequiredAppVersion, installedVersion.toString())
}

const isOldVersion = (serverVersion, installedVersion) => compareVersions(serverVersion, installedVersion) > 0

export default hasOldAppVersionInstalled
