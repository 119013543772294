import React from 'react'
import withStyles from 'react-jss'
import style from './styles'

export const Badge = ({ text, classes, containerClass }) => {
  return (
    <div className={`${classes.container} ${containerClass}`}>
      <span>{text}</span>
    </div>
  )
}

export default withStyles(style)(Badge)
