import Pica from 'pica'
import piexifjs from 'piexifjs'
import { blobToBinaryString, binaryStringToBlob } from 'blob-util'

const MAX_WIDTH = 1200

const photoToBlob = async ({ data, srcCanvas, destinationCanvas, image }) => {
  const originalCanvas = srcCanvas.current
  const resizedCanvas = destinationCanvas.current

  const ctx = originalCanvas.getContext('2d')
  ctx.drawImage(image, 0, 0)
  data['0th'][piexifjs.ImageIFD.Orientation] = 1

  // calculate resize ratio from exif data or canvas sizing
  const originalWidth = originalCanvas.width
  const originalHeight = originalCanvas.height
  var ratio = MAX_WIDTH / originalWidth

  // resize, blob, and glue exif data
  const pica = Pica({ features: ['js', 'wasm', 'ww', 'cib'] })
  resizedCanvas.width = Math.round(originalWidth * ratio)
  resizedCanvas.height = Math.round(originalHeight * ratio)

  return pica
    .resize(originalCanvas, resizedCanvas)
    .then(resized => pica.toBlob(resized, 'image/jpeg', 0.9))
    .then(blob => blobToBinaryString(blob))
    .then(blobString => piexifjs.insert(piexifjs.dump(data), blobString))
    .then(binaryStringWithGluedExifData => binaryStringToBlob(binaryStringWithGluedExifData))
}

export default photoToBlob
