import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import { isNil } from 'ramda'

const DiscourageCancelationMessages = ({ messages, classes }) => (
  <>
    {!isNil(messages) && (
      <div className={`mt3 mb3 pa3 pv1 br2 darkest-green ${classes.alertDanger}`}>
        {messages.map((message, index) =>
          index === 0 ? (
            <p key={index}>
              <strong>{message}</strong>
            </p>
          ) : (
              <p key={index} dangerouslySetInnerHTML={{ __html: message }} />
            )
        )}
      </div>
    )}
  </>
)

export default withStyles(style)(DiscourageCancelationMessages)
