import React from 'react'
import NoVisits from 'components/Visits/NoVisits'
import { useQuery } from '@apollo/client';
import { isEmpty } from 'ramda'
import TentativeVisitCard from './TentativeVisitCard'
import BusyIndicator from 'components/BusyIndicator'
import { TENTATIVE_VISITS } from 'queries/tentativeVisits'
import {TentativeVisits, TentativeVisitsVariables} from 'types/graphql/TentativeVisits'

const variables = { scope: 'tentative' }

export const tabRefreshTentativeVisits = { query: TENTATIVE_VISITS, variables }

const TentativeVisitsComponent = () => {
  const { loading, data } = useQuery<TentativeVisits, TentativeVisitsVariables>(TENTATIVE_VISITS, { variables: variables });
  const visits = data?.companion?.visits

  return (
    <>
      { loading && <BusyIndicator isBusy delayMs={0} color='#ffc20d' /> }
      { isEmpty(visits) && data?.companion && <NoVisits visitType='tentative' companionName={data?.companion?.person?.preferredOrFirstName} /> }
      { visits && visits.map(visit => (
        <TentativeVisitCard
          cancellationReasons={data?.metaData.visitCancelationReasons}
          visit={visit}
          key={visit.id}
        />
      ))}
    </>
  )
}

export default TentativeVisitsComponent
