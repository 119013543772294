import React from 'react'
import { useQuery, gql } from '@apollo/client';
import { useLoadingRoute, NotFoundBoundary, useCurrentRoute } from 'react-navi'
import BusyIndicator from 'components/BusyIndicator'
import Menu from './Menu'
import ClientNotification from 'components/ClientNotification'
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import Modal from './Modal'
import BackgroundDimmer from 'components/BackgroundDimmer'
import { unauthenticatedPaths } from 'routes/paths'
import withStyles from 'react-jss'
import styles from './styles'
import RefreshDisplayCache from './RefreshDisplayCache'
import withApollo from 'helpers/withApollo'

import { LayoutAttributeQuery } from 'types/graphql/LayoutAttributeQuery'

const LAYOUT_ATTRIBUTE_QUERY = gql`
  query LayoutAttributeQuery {
    isBusy @client {
      bar
      screen
    }
    showMenu @client
  }
`

const shouldRefreshCache = pathname => !unauthenticatedPaths.includes(pathname)

const NotFound = () => {
  return <div>Not found!</div>
}

export const Body = ({ showMenu, children }) => (
  <>
    <ClientNotification />
    <BackgroundDimmer showMenu={showMenu} />
    <Menu showMenu={showMenu} />
    <Fabric className='h-100'>
      <div className='overflow-x-hidden flex flex-column flex-nowrap h-100'>
        <Modal />
        {children}
      </div>
    </Fabric>
  </>
)

const Layout = ({ client, children, classes }) => {
  const loadingRoute = useLoadingRoute()
  const currentPath = useCurrentRoute().url.pathname
  const { data } = useQuery<LayoutAttributeQuery>(LAYOUT_ATTRIBUTE_QUERY, { fetchPolicy: 'cache-only' })

  if (!data) return null

  return (
    <>
      <div
        className={`relative overflow-x-hidden bl br b--gray ${classes.wrapper}`}
        style={{
          height: currentPath.includes('visit_story')
            ? undefined
            : '100%'
        }}
      >
        <BusyIndicator
          isBusy={!!loadingRoute || data.isBusy?.bar || false}
          delayMs={0}
          color='#ffc20d'
          style={{ top: 'env(safe-area-inset-top)' }}
        />
        <div className='h-100'>
          {!!loadingRoute || data.isBusy?.screen &&
            <div className='absolute h-100 w-100 z-1' style={{ backgroundColor: '#ffffffd6' }} />
          }
          <NotFoundBoundary render={NotFound}>
            <Body showMenu={data.showMenu} children={children} />
          </NotFoundBoundary>
        </div>
      </div>
      {shouldRefreshCache(currentPath) && <RefreshDisplayCache />}
    </>
  )
}

export default withApollo(withStyles(styles)(Layout))
