export default {
  container: {
    '-webkit-overflow-scrolling': 'touch'
  },
  innerContainer: {
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto'
  }
}
