import React from 'react'
import Toggle from 'components/Toggle'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { path } from 'ramda'
import withApollo from 'helpers/withApollo'
import updateBusyIndicators from 'helpers/updateBusyIndicators'
import { notify } from 'components/ClientNotification'
import { UPDATE_COMPANION_MUTATION } from 'mutations/updateCompanion'

export const COMPANION_AUTO_BOOK_QUERY = gql`
  query CompanionAutoBookQuery {
    companion {
      id
      autoBook
    }
  }
`

const Advanced = ({ client }) => {
  const flashMessage = message => {
    notify(client, {
      message: message,
      type: 'success'
    })
  }

  const toggleAutoBook = autoBook => async () => {
    updateBusyIndicators(client, { bar: true, screen: true })
    await client.mutate({
      mutation: UPDATE_COMPANION_MUTATION,
      variables: { input: { autoBook: !autoBook } },
      refetchQueries: ['CompanionAutoBookQuery']
    })
    updateBusyIndicators(client, { bar: false, screen: false })
    flashMessage('Your choice has been updated!')
  }

  return (
    <Query query={COMPANION_AUTO_BOOK_QUERY}>
      {({ data, loading }) => {
        return (
          <>
            {/* props onText and offText needed to place label on left :sad_panda: */}
            <Toggle
              disabled={loading}
              onText=' '
              offText=' '
              inlineLabel
              label='Set me to Auto-Book'
              checked={path(['companion', 'autoBook'], data)}
              onChange={toggleAutoBook(path(['companion', 'autoBook'], data))}
              data-cy='availability--advanced-toggle'
            />
            <p>Setting yourself to auto-book allows us to automatically book you on visits that match your schedule</p>
          </>
        )
      }}
    </Query>
  )
}

export default withApollo(Advanced)
