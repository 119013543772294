import React, { useState } from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import { updateCheckInDifferentLocationDescriptionVariables } from '../queries'
import TextField from 'components/TextField'
import Button from 'components/Button'
import { useNavigation } from 'react-navi'
import paths from 'routes/paths'
import { isEmpty, equals } from 'ramda'
import withApollo from 'helpers/withApollo'

const LocationExplanation = React.memo(({ visit, client }) => {
  const [differentLocationDescription, setDifferentLocationDescription] = useState('')
  const navigation = useNavigation()

  const createVisitStoryAndRedirectToVisitCheckIn = async () => {
    await client.mutate(
      updateCheckInDifferentLocationDescriptionVariables(
        visit,
        differentLocationDescription
      )
    )
    navigation.navigate(paths.visitCheckIn)
  }
  return (
    <>
      <TopBar title={`Visit With ${visit.visitee.customer.person.preferredOrFirstName}`} />
      <ContentWrapper>
        This visit is booked for:
        <div>
          <b>{visit.address.addressLine1}</b>
        </div>
        <div>
          <b>{visit.address.addressLine2}</b>
        </div>
        <div>
          <b>
            {visit.address.city} {visit.address.state}, {visit.address.zip}
          </b>
        </div>
        and you are too far away from there.
        <div>Tell us what happened:</div>
        <TextField
          multiline
          autoAdjustHeight
          rows={5}
          className='mv3'
          value={differentLocationDescription}
          onChange={(_, value) => setDifferentLocationDescription(value)}
          placeholder='Let us know why you are in a different location.'
        />
        <Button
          disabled={isEmpty(differentLocationDescription)}
          onClick={createVisitStoryAndRedirectToVisitCheckIn}
        >
          Start Visit
        </Button>
      </ContentWrapper>
    </>
  )
}, equals)

export default withApollo(LocationExplanation)
