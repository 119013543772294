import React from 'react'
import { useStateValue, types } from 'components/BookVisit/StateProvider'
import CalendarDisplay from 'components/CalendarDisplay'
import dayjs from 'helpers/dayjs'
import withStyles from 'react-jss'
import style from './styles'
import { equals } from 'ramda'

interface DateEditProps {
  classes?: any,
  predefinedVisit?: any
}

const DateEdit: React.FC<DateEditProps> = ({ classes, predefinedVisit }) => {
  const [{ date }, dispatch] = useStateValue()

  const openCalendar = () => dispatch({ type: types.SHOW_SELECT_DATE_MODAL })

  const updateDate = date => () => dispatch({ type: types.UPDATE_DATE, date })

  return (
    <>
      <div className={`flex flex-wrap mb3 fw6 ${classes.shortcutContainer}`}>
        <div className='flex-grow-1'>Select a Date</div>
        {!predefinedVisit && (
          <div className='flex'>
            <div className={`pointer purple bg-egg-shell tc mr2 ${classes.dateShortcut}`} onClick={updateDate(dayjs())}>
              Today
            </div>
            <div
              className={`pointer purple bg-egg-shell tc ${classes.dateShortcut}`}
              onClick={updateDate(dayjs().add(1, 'day'))}
              data-cy='visits-new--set-date-tomorrow'
            >
              Tomorrow
            </div>
          </div>
        )}
      </div>
      <CalendarDisplay date={date} openCalendar={openCalendar} />
    </>
  )
}

export default React.memo(withStyles(style)(DateEdit), equals)
