import React from 'react'
import { TextField } from "office-ui-fabric-react"
import YesNoQuestion from "../YesNoQuestion"
import getError from '../../../helpers/getError'
import PhotoInput from "../PhotoInput"
import { ApolloError } from '@apollo/client'
import { EditVisitStoryQuery_companion_visit } from 'types/graphql/EditVisitStoryQuery'
import { getMileageError } from '../getErrors'

interface ReimbursementQuestionProps {
  setNeedReimbursement: any
  needReimbursement: any
  completeButton: any
  visitMutationData: any,
  inputChangeHandler: any
  error: ApolloError | undefined,
  visit: EditVisitStoryQuery_companion_visit,
}

export const ReimbursementQuestion: React.FC<ReimbursementQuestionProps> = ({ setNeedReimbursement, needReimbursement, completeButton, visitMutationData, inputChangeHandler, error, visit }) => (
  <>
    <YesNoQuestion
      question='Do you need to get reimbursed? *'
      setMethod={setNeedReimbursement}
      getMethod={needReimbursement}
      completeButton={completeButton}
      name='need-reimbursement'
    />
    {needReimbursement && (
      <>
        <TextField
          styles={{ fieldGroup: ['w4'] }}
          label='Total spent for reimbursement *'
          prefix='$'
          value={visitMutationData.expensesCents}
          onChange={inputChangeHandler}
          name='expensesCents'
          errorMessage={getError('expensesCents', error)}
          data-cy='total-spent'
          type='number'
        />
        <TextField
          className='mt2'
          styles={{ fieldGroup: ['w4'] }}
          label='Mileage'
          suffix='miles'
          value={visitMutationData.mileage}
          onChange={inputChangeHandler}
          name='mileage'
          errorMessage={getMileageError(error)}
          data-cy='story--mileage-container'
        />
        <div className='mt3'>
          <PhotoInput
            error={error}
            photoLabel='Add photos of your receipts'
            isReceipt={true}
            id={2}
            visit={visit}
          />
        </div>
      </>
    )}
  </>
)
