import { gql } from '@apollo/client'

export const CREATE_VISIT_STORY_BEFORE_EDITING_MUTATION = gql`
  mutation CreateVisitStoryBeforeEditing($input: CreateVisitStoryInput!) {
    createVisitStory(input: $input) {
      visitStory {
        id
      }
    }
  }
`
