import React from 'react'
import withStyles from "react-jss"
import styles from './styles';

interface YesNoQuestionProps {
  question: string
  setMethod: React.Dispatch<React.SetStateAction<boolean | null>>
  getMethod: boolean | null
  completeButton: boolean,
  name: string,
  classes: any
}

const displayError = (getMethod: boolean | null, completeButton: boolean) => {
  return getMethod === null && completeButton && <div className='red'>This is a required question.</div>
}

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({ question, setMethod, getMethod, completeButton, name, classes }) => (
  <>
    <div className='flex flex-row flex-nowrap justify-between mt3'>
      <p className='fw6 w-60'>{question}</p>
      <a
        onClick={() => setMethod(true)}
        className={`${classes.yesNoCircle} ${getMethod && 'activeGreen'} mt3`}
        data-cy={`story--${name}-yes`}
      >
        Yes
      </a>
      <a
        onClick={() => setMethod(false)}
        className={`${classes.yesNoCircle} ${getMethod === false && 'activePurple'} mt3 mr2`}
        data-cy={`story--${name}-no`}
      >
        No
      </a>
    </div>
    {displayError(getMethod, completeButton)}
  </>
)

export default withStyles(styles)(YesNoQuestion)
