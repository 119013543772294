import React from 'react'
import { deletePhoto, VISIT_STORY_PHOTOS_QUERY } from '../queries'
import OffScreenModal from 'components/OffScreenModal'
import withApollo from 'helpers/withApollo'
import { Query } from '@apollo/client/react/components'
import Carousel from 'components/PhotoCarousel'

const PhotoCarousel = ({ show, close, visit, client, photosFromData, visible, selectedIndex, setSelectedIndex }) => {
  return (
    <OffScreenModal show={show} title='Photos' close={close}>
      <Query query={VISIT_STORY_PHOTOS_QUERY} variables={{ visitId: visit.id }}>
        {({ data }) => (
          <Carousel
            photos={photosFromData}
            visible={visible}
            selectedIndex={selectedIndex}
            deletePhotoMutation={deletePhoto(client)}
            close={close}
            setSelectedIndex={setSelectedIndex}
            client={client}
          />
        )}
      </Query>
    </OffScreenModal>
  )
}

export default withApollo(PhotoCarousel)
