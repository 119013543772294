import React from 'react'
import Button from 'components/Button'
import setTimeOnDate from 'helpers/setTimeOnDate'
import { useStateValue } from 'components/BookVisit/StateProvider'
import { useNavigation } from 'react-navi'
import routePaths from 'routes/paths'
import { openModal } from 'components/Layout/Modal'
import withApollo from 'helpers/withApollo'
import Modal from '../Modal'
import { dissoc, equals } from 'ramda'
import ErrorMessage from 'components/ErrorMessage'
import { CREATE_VISIT } from 'mutations/createVisit'
import dayjs from 'helpers/dayjs'
import Select from 'components/Select'
import { PreviousVisitees_companion_visits } from 'types/graphql/PreviousVisitees'
import { UPCOMING_VISITS } from 'queries/upcomingVisits'
import { useMutation } from '@apollo/client'
import { CreateVisit, CreateVisitVariables } from 'types/graphql/CreateVisit'
import getVisitFriendlyName from 'helpers/getVisitFriendlyName'

interface PredefinedRecurringAndSubmitProps {
  client: any;
  predefinedVisit: PreviousVisitees_companion_visits,
  onError: any;
  baseErrors: any;
  addressError: any;
  updateRecurrenceCount: any;
  openPastVisitModal: any;
}

const PredefinedRecurringAndSubmit: React.FC<PredefinedRecurringAndSubmitProps> = ({
  client,
  predefinedVisit,
  onError,
  baseErrors,
  addressError,
  updateRecurrenceCount,
  openPastVisitModal
}) => {
  const [{ recurrenceCount, startAt, endAt, date, address, visiteeId, recurring, errors }, dispatch] = useStateValue()
  const navigation = useNavigation()
  const isDateInPast = dayjs(setTimeOnDate(date, startAt).toDate()).isBefore(dayjs())

  const weeks = Array.from({ length: 9 }, (v, k) => k + 2)
  const items = weeks.map(number => ({ text: `${number} weeks`, value: number }))

  const visitCreated = visit => {
    navigation.navigate(`${routePaths.visits}?tab=upcoming`)
    openModal(client, {
      Component: ({ closeModal }) => (
        <Modal closeModal={closeModal} visit={visit} recurring={recurring} recurrenceCount={recurrenceCount} />
      )
    })
  }

  const [createVisitMutation] = useMutation<CreateVisit, CreateVisitVariables>(CREATE_VISIT, {
    refetchQueries: [
      { query: UPCOMING_VISITS, variables: { scope: 'upcoming' } },
      'AvailableTimes',
      'BannerVisitQuery'
    ],
    onError: onError,
    onCompleted: ({ createVisit }) => {
      if (createVisit) {
        visitCreated(createVisit.visit)
      }
    }
  })

  return (
    <>
      <span id='ChoiceGroupLabel14-recurring' className='ms-ChoiceFieldLabel'>
        <div className='mb2 fw6'>How many weeks?</div>
        <Select
          required
          handler={updateRecurrenceCount}
          value={recurrenceCount}
          items={items}
          data-cy='visits-new--recurrence-count'
        />
      </span>
      <div className='i mb125' style={{ marginLeft: '26px' }}>
        {predefinedVisit.visitTypeFriendlyName}s will occur on{' '}
        {setTimeOnDate(date, startAt).format('dddd[s] [at] h:mm A')} - {setTimeOnDate(date, endAt).format('h:mm A')}
        {' '} weekly for the next {recurrenceCount} weeks.
      </div>
      {baseErrors && <ErrorMessage errorMessage={baseErrors} bordered />}
      {addressError && <ErrorMessage errorMessage={"Location can't be blank!"} bordered />}
      {date && isDateInPast && <ErrorMessage errorMessage={`You are reporting this ${getVisitFriendlyName(predefinedVisit.visitType || 'telepresence').toLowerCase()} as in the past.`} bordered />}

      <Button
        onClick={() => createVisitMutation({
          variables: {
            input: {
              address: dissoc('id', address),
              visiteeId,
              visitType: predefinedVisit.visitType,
              visitCodingId: predefinedVisit.visitCoding && predefinedVisit.visitCoding.id,
              duration: setTimeOnDate(date, endAt).diff(setTimeOnDate(date, startAt), 'minute'),
              startAt: setTimeOnDate(date, startAt)
                .second(0)
                .millisecond(0)
                .toDate(),
              recurrenceCount: Number(recurrenceCount)
            },
          }
        })}
        data-cy='visits-new--book-visit'
      >
        Book Recurring
      </Button>

      <div className='tc mt3 radius purple' data-cy='no-thanks'>
        <a onClick={_ => navigation.navigate(`${routePaths.visits}?tab=completed`)} className='no-underline pointer'>
          No, thanks
        </a>
      </div>
    </>
  )
}

export default React.memo(withApollo(PredefinedRecurringAndSubmit), equals)
