import React, { useEffect } from 'react'
import { useNavigation } from 'react-navi'

const Redirect = ({ to }) => {
  const navigation = useNavigation()

  useEffect(() => {
    navigation.navigate(to)
  }, [])

  return <div />
}

export default Redirect
