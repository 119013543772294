import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import dayjs from 'dayjs'

import { EditVisitStoryQuery_companion_visit } from 'types/graphql/EditVisitStoryQuery'

const text = (visit: EditVisitStoryQuery_companion_visit) => {
  switch (visit.visitType) {
    case 'telepresence':
      return (
        <>
          <p className='f4-5 b lighter-green recoleta-regular'>
            We’re excited to hear about your call{' '}
            {visit.scheduleType !== 'flexible' && `on ${dayjs(visit.startAt).format('ddd, MMM DD')}`} with{' '}
            {visit.visitee.customer.person.preferredOrFirstName}. <br />
            <span className='i'>Thank you for checking in on them.</span>
          </p>
        </>
      )
    case 'service':
      return (
        <>
          <p className='f4-5 b lighter-green'>
            Thanks for helping {visit.visitee.customer.person.preferredOrFirstName} get their essentials. <br />
            You’re amazing!
          </p>
        </>
      )
    case 'shift':
      return (
        <>
          <p className='f4-5 b lighter-green'>
            Thanks for giving your time to {visit.visitee.customer.person.preferredOrFirstName}.<br />
            It makes such a difference!
          </p>
        </>
      )

    default:
      return (
        <>
          <p className='mb0 mt0'>We’re excited to read all about your visit</p>
          <p className='mt0'>
            with {visit.visitee.customer.person.preferredOrFirstName}{' '}
            {visit.scheduleType !== 'flexible' && `on ${dayjs(visit.startAt).format('ddd, MMM DD')}`}
          </p>
          <p className='i'>
            {visit.visitee.customer.designatedContact
              ? `${visit.visitee.customer.designatedContact.person.preferredOrFirstName}, ${visit.visitee.customer.person.preferredOrFirstName}'s designated contact,`
              : visit.visitee.customer.person.preferredOrFirstName
            }
            {''} will see this story
          </p>
        </>
      )
  }
}

const Header = ({ classes, visit }) => (
  <div className='pa3 pv2 tc bg-egg-shell bb b--border-green'>
    <div className={classes.headerContainer}>
      <div>{text(visit)}</div>
    </div>
  </div>
)

export default withStyles(style)(Header)
