import React from 'react'
import formatAddress from 'helpers/formatAddress'
import Header from 'components/VisitDetails/Logistics/Header'
import Detail from 'components/VisitDetails/Detail'

import { VisitDetailsQuery_companion_visit, VisitDetailsQuery_metaData } from 'types/graphql/VisitDetailsQuery'

const getLivingPlace = (visiteeLivingPlaces, livingPlace) => visiteeLivingPlaces.find(item => item.key === livingPlace).value.toLowerCase()

interface ServiceProps {
  visit: VisitDetailsQuery_companion_visit
  metaData: VisitDetailsQuery_metaData,
  CustomerPhoneNumber: React.FC<{}>;
}

const Service: React.FC<ServiceProps> = ({ visit, metaData, CustomerPhoneNumber }) => {
  const { visitee: { livingPlace, customer } } = visit
  const { visiteeLivingPlaces } = metaData

  return (
    <div className='pa125'>
      <Header visit={visit} />
      <div className={`b`}>
        <i className={`mr2 purple icon-shopping`} />
        Errand Run
      </div>
      {visit.visitCoding?.name && <Detail heading='Visit Coding'>{visit.visitCoding.name}</Detail>}
      {visit.visitInfo && <Detail heading='Errand info'>{visit.visitInfo}</Detail>}
      {visit.address && <Detail heading='Special Instructions'>{visit.address.notes}</Detail>}

      <Detail heading='Address'>
        {visit.facility && <div>{visit.facility.name}</div>}
        {visit.address && formatAddress(visit.address)}
      </Detail>
      {livingPlace && (
        <Detail heading='Living Situation'>
          {customer.person.preferredOrFirstName} lives {getLivingPlace(visiteeLivingPlaces, livingPlace)}
        </Detail>
      )}
      <CustomerPhoneNumber />
    </div>
  )
}

export default Service
