import React from 'react'
import { render } from 'react-dom'
import Entry from 'components/Entry'
import { isBrowser } from 'helpers/platform'
import { createHashHistory, createBrowserHistory } from 'history'
import { ApolloProvider } from '@apollo/client'
import { client } from 'helpers/setupApollo'
import { storeDeepLink } from 'helpers/branch'
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons'
import 'helpers/baseStyles'
import initializeAccessibility from 'helpers/initializeAccessibility'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import 'helpers/reactCustomHTMLAttributes'

if (NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

if (!DISABLE_LOGROCKET) {
  LogRocket.init('z7masw/mon-ami-companion', {
    release: REVISION
  })
  setupLogRocketReact(LogRocket)
}

initializeIcons(/* optional base url */)

const initializePushNotifications = (client, history) => {
  const push = PushNotification.init({
    android: {
      icon: 'push',
      iconColor: 'blue'
    },
    ios: {
      alert: 'true',
      badge: 'true',
      sound: 'true'
    }
  })

  push.on('registration', data => {
    localStorage.setItem('notificationRegistrationId', data.registrationId)
    localStorage.setItem('notificationRegistrationType', data.registrationType)
  })

  push.on('notification', data => {
    if (data.additionalData.foreground === false) {
      if (history.location.pathname === '/login' || history.location.pathname === '/')
        client.writeQuery({ data: { deepLink: data.additionalData.url } })
      history.push(data.additionalData.url)
    }
  })

  push.on('error', error => console.error(error))
}

const history = isBrowser ? createBrowserHistory() : createHashHistory()

const Root = _ => (
  <ApolloProvider client={client}>
    <Entry history={history} client={client} />
  </ApolloProvider>
)

if (isBrowser) {
  render(<Root />, document.getElementById('app'))
} else {
  document.addEventListener('deviceready', () => {
    initializePushNotifications(client, history)
    storeDeepLink(global.Branch, client, history)
    initializeAccessibility()
    render(<Root />, document.getElementById('app'))
  })

  document.addEventListener('resume', () => {
    storeDeepLink(global.Branch, client, history)
  })
}
