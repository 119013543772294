import dayjs from 'helpers/dayjs'
import { range, pair, pipe, map, always } from 'ramda'

const mondayAndSundayTuple = week => {
  const sunday = 7 * week
  // [monday, sunday] of the week
  return pair(dayjs().weekday(sunday - 6), dayjs().weekday(sunday))
}

export const createWeeks = pipe(
  always(range(1, 22)),
  map(mondayAndSundayTuple)
)
