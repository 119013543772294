import { gql } from '@apollo/client'

export const BOOK_VISIT_FROM_OPPORTUNITY = gql`
  mutation BookVisitFromOpportunity($input: CreateVisitInput!) {
    createVisit(input: $input) {
      visit {
        id
        duration
        startAt
        companionMessage
        status
        visitee {
          id
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
          }
        }
      }
    }
  }
`
