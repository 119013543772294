import { gql } from '@apollo/client'

export const localStateTypeDefs = gql`
  type IsBusy {
    bar: Boolean
    screen: Boolean
  }

  extend type Query {
    onboarding: String
    deepLink: String
    oauthProvider: String
    showMenu: Boolean
    notification: String
    twoFactorAuth: String
    modal: Boolean
    returnTo: String
    isBusy: IsBusy
  }
`
