import React from 'react'
import TopBar from 'components/TopBar'
import ContentWrapper from 'components/ContentWrapper'
import { useQuery, gql } from '@apollo/client';
import withApollo from 'helpers/withApollo'
import Header from './Header'
import Logistics from './Logistics'
import AboutVisitee from './AboutVisitee'
import AboutRequest from './AboutRequest'
import EmergencyContact from './EmergencyContact'
import CancelVisit from './CancelVisit'
import RescheduleVisit from './RescheduleVisit'
import { StateProvider } from './StateProvider'
import { isNil } from 'ramda'
import { VisitDetailsQuery, VisitDetailsQuery_companion_nextVisit } from 'types/graphql/VisitDetailsQuery'

const showCancelAndReschedule = (visitId: string, nextVisit: VisitDetailsQuery_companion_nextVisit | null): boolean => {
  return (visitId !== visitId) || isNil(nextVisit?.visitStory?.checkInAt)
}

const VISIT_DETAILS_QUERY = gql`
  query VisitDetailsQuery($visitId: ID!) {
    metaData {
      visiteeLivingPlaces {
        key
        value
      }
      visiteeMemories {
        key
        value
      }
      visiteeMobilities {
        key
        value
      }
      visitCancelationReasons {
        key
        value
      }
    }
    companion {
      id
      nextVisit {
        id
        visitStory {
          id
          checkInAt
        }
      }
      organization {
        id
        name
        directPhoneNumber {
          id
          number
          extension
        }
      }
      visit(id: $visitId) {
        id
        visitType
        visitTypeFriendlyName
        scheduleType
        expectCompletionAt
        companionCanCancel
        discourageCompanionCancelationMessages
        firstVisitForLinkage
        distance
        startAt
        endAt
        duration
        visitRecurrenceGroupPresent
        futureOccurrencesCount
        visiteeConversationNumber
        visitInfo
        opportunity {
          id
          drivingRequired
        }
        address {
          id
          addressLine1
          addressLine2
          city
          state
          zip
          country
          usa
          notes
        }
        facility {
          id
          name
        }
        visitCoding {
          id
          name
        }
        visitee {
          id
          phoneNumber
          limitations
          culturalFactors
          customer {
            id
            person {
              id
              preferredOrFirstName
              firstNameAndLastInitial
            }
            designatedContact {
              id
              person {
                id
                preferredOrFirstName
              }
            }
            emergencyContact {
              id
              person {
                id
                firstNameAndLastInitial
              }
              phoneNumber {
                id
                number
              }
              relationship
            }
            phoneNumber
            textable
            acceptsTexts
          }
          interests {
            id
            name
          }
          visiteePhoto {
            id
            cdnUrlTemplate
          }
          livingPlace
          pastCareer
          primaryLanguage
          secondaryLanguage
          memory
          mobility
          moreAboutMe
          moreAboutMyInterests
          ongoingSchedulingNotes
        }
      }
    }
  }
`

const Sections = ({ children }) => {
  return <div className='bg-white'>{children}</div>
}

interface VisitDetailsProps {
  visitId: string,
  redirectToKey: string,
}

const VisitDetails: React.FC<VisitDetailsProps> = ({ visitId, redirectToKey }) => {
  const { data, loading } = useQuery<VisitDetailsQuery>(VISIT_DETAILS_QUERY, { variables: { visitId } })

  if (!data?.companion || loading) return null

  const { visit, nextVisit, organization } = data.companion
  const metaData = data.metaData
  const customer = data.companion.visit.visitee.customer

  const isFixedSchedule = visit.scheduleType === 'fixed'

  return <StateProvider>
    <CancelVisit visit={visit} visitCancelationReasons={metaData.visitCancelationReasons} />
    {isFixedSchedule && <RescheduleVisit visit={visit} redirectToKey={redirectToKey} />}
    <TopBar
      title={`${visit.visitTypeFriendlyName} Details`}
      left='menu'
      visiteeId={visit.visitee.id}
    />
    <ContentWrapper noPadding Header={showCancelAndReschedule(visitId, nextVisit) && <Header visit={visit} />}>
      <Sections>
        <Logistics visit={visit} metaData={metaData} />
        {visit.visitType !== 'shift' && <AboutVisitee visit={visit} metaData={metaData} />}
        {(visit.visitType === 'personal' || visit.visitType === 'telepresence') && (
          <AboutRequest visit={visit} />
        )}
        {visit.visitee.customer.emergencyContact && (
          <EmergencyContact
            emergencyContact={visit.visitee.customer.emergencyContact}
            visiteeFirstName={customer.person.preferredOrFirstName}
            visitType={visit.visitType}
            organization={organization}
          />
        )}
      </Sections>
    </ContentWrapper>
  </StateProvider>
}

export default withApollo(VisitDetails)
