import colors from 'helpers/colors'
import { isBrowser } from 'helpers/platform'

export default {
  logo: {
    height: '40px'
  },
  header: {
    marginBottom: '3rem'
  },
  borderDivide: {
    borderBottom: isBrowser ? '' : `1px solid ${colors.gray}`
  }
}
