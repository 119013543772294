export default {
  columns: {
    '@media screen and (max-width: 576px)': {
      columns: 2
    },
    columns: 3
  },
  height: {
    padding: '1rem'
  }
}
