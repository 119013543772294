import { gql } from '@apollo/client'

export const USER_ROUTE_DETAILS_QUERY = gql`
  query UserRouteDetailsQuery {
    currentSession {
      id
      verificationApproved
      verificationCheckSid
    }
    companion {
      ...CompanionFields
    }
    companions {
      ...CompanionFields
    }
  }
  fragment CompanionFields on Companion {
    id
    phoneNumber {
      id
      number
    }
    person {
      id
      fullName
      organization {
        id
        name
        twoFactorAuth
      }
    }
  }
`
