import React from 'react'
import withStyles from 'react-jss'
import style from './styles'
import { Modal } from 'office-ui-fabric-react/lib/Modal'
import { getId } from 'office-ui-fabric-react/lib/Utilities'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import withApollo from 'helpers/withApollo'

const query = gql`
  query Modal {
    modal @client
  }
`

export const openModal = (client, modal) => client.writeQuery({ query, data: { modal } })

const LayoutModal = ({ client, modal, classes }) => {
  if (!modal) return null

  const { Component, containerClassName, isBlocking } = modal

  const titleId = getId('modalTitle')
  const subtitleId = getId('modalSubTitle')

  const closeModal = _ => client.writeQuery({ query, data: { modal: null } })

  return (
    <Modal
      titleAriaId={titleId}
      subtitleAriaId={subtitleId}
      isOpen
      onDismiss={closeModal}
      isBlocking={isBlocking}
      containerClassName={`mh3 br2 ${containerClassName}, ${classes.container}`}
    >
      <Component closeModal={closeModal} />
    </Modal>
  )
}

const QueryWrap = props => (
  <Query query={query}>{({ data: { modal } }) => <LayoutModal modal={modal} {...props} />}</Query>
)

export default withStyles(style)(withApollo(QueryWrap))
