import { gql } from '@apollo/client'
import companionUpcomingVisitsFragment from 'fragments/companionUpcomingVisitsFragment'

export const UPCOMING_VISITS = gql`
  query UpcomingVisits($scope: String) {
    companion {
      id
      companionType
      nextVisit {
        id
      }
      person {
        id
        preferredOrFirstName
      }
      visits(scope: $scope) {
        ...companionUpcomingVisits
      },
      visitees {
        id
      }
      organization {
        id
        name
      }
    }
  }
  ${companionUpcomingVisitsFragment}
`
