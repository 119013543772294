const getVisitFriendlyName = visitType => {
  switch (visitType) {
    case 'telepresence':
      return 'Call'
    case 'service':
      return 'Errand Run'
    case 'shift':
      return 'Shift'
    default:
      return 'Visit'
  }
}

export default getVisitFriendlyName
