export default {
  '@global': {
    '.pointer': {
      cursor: 'pointer'
    },
    '.m0auto': {
      margin: '0 auto'
    },
    '.b-left--white': {
      borderLeftColor: '#FFFFFF'
    },
    '.mh-100': {
      maxHeight: '100%'
    },
    '.ml125': {
      marginLeft: '1.25rem'
    },
    '.mr125': {
      marginRight: '1.25rem'
    },
    '.ma125': {
      margin: '1.25rem'
    },
    '.mt125': {
      marginTop: '1.25rem'
    },
    '.mb125': {
      marginBottom: '1.25rem'
    },
    '.mh125': {
      marginLeft: '1.25rem',
      marginRight: '1.25rem'
    },
    '.mv125': {
      marginTop: '1.25rem',
      marginBottom: '1.25rem'
    },
    '.ma75': {
      margin: '.75rem'
    },
    '.mt75': {
      marginTop: '.75rem'
    },
    '.mb75': {
      marginBottom: '.75rem'
    },
    '.mh75': {
      marginLeft: '.75rem',
      marginRight: '.75rem'
    },
    '.mv75': {
      marginTop: '.75rem',
      marginBottom: '.75rem'
    },
    '.ml75': {
      marginLeft: '.75rem'
    },
    '.mr75': {
      marginRight: '.75rem'
    },
    '.pl125': {
      paddingLeft: '1.25rem'
    },
    '.pr125': {
      paddingRight: '1.25rem'
    },
    '.pa125': {
      padding: '1.25rem'
    },
    '.pt125': {
      paddingTop: '1.25rem'
    },
    '.pb125': {
      paddingBottom: '1.25rem'
    },
    '.ph125': {
      paddingLeft: '1.25rem',
      paddingRight: '1.25rem'
    },
    '.pv125': {
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem'
    },
    '.pl75': {
      paddingLeft: '.75rem'
    },
    '.pr75': {
      paddingRight: '.75rem'
    },
    '.pa75': {
      padding: '.75rem'
    },
    '.pt75': {
      paddingTop: '.75rem'
    },
    '.pb75': {
      paddingBottom: '.75rem'
    },
    '.ph75': {
      paddingLeft: '.75rem',
      paddingRight: '.75rem'
    },
    '.pv75': {
      paddingTop: '.75rem',
      paddingBottom: '.75rem'
    }
  }
}
