import React from 'react'
import withStyles from 'react-jss'
import style from './styles'

interface NoVisitsProps {
  visitType: string,
  companionName: string
}

const NoVisits: React.FC<NoVisitsProps> = ({ visitType, companionName }) => {
  const message = () => {
    if (visitType === 'tentative') return 'You don’t have any pending visit opportunities right now.'
    else if (visitType === 'upcoming')
      return `Hi ${companionName}! Your upcoming visits will appear here, but there aren’t any scheduled at the moment.`
    else if (visitType === 'completed')
      return 'You haven’t completed any visits yet. When you do, they’ll appear here for review and payment.'
  }

  return (
    <div>
      <div className='f3 tc mt3 mb4'>{message()}</div>
    </div>
  )
}

export default withStyles(style)(NoVisits)
