import { gql } from '@apollo/client'

export const RESCHEDULE_VISIT_MUTATION = gql`
  mutation RescheduleVisit($input: RescheduleVisitInput!) {
    rescheduleVisit(input: $input) {
      visit {
        id
      }
    }
  }
`
